﻿; window.eplans = window.eplans || {};

/* French initialisation for the jQuery UI date picker plugin. */
/* Written by Keith Wood (kbwood{at}iinet.com.au) and Stéphane Nahmani (sholby@sholby.net). */
jQuery(function ($) {
    $.datepicker.regional['fr'] = {
        closeText: 'Fermer',
        prevText: '&#x3c;Préc',
        nextText: 'Suiv&#x3e;',
        currentText: 'Courant',
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun',
        'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['fr']);

    $.fn.checkDateValidity = function (dts, allowNulls) {
        if (dts == null || dts == '') {
            return allowNulls;
        }

        try {
            $.datepicker.parseDate('dd/mm/yy', dts);
            return true;
        } catch (e) {
        }
        return false;
    };
});

(function () {
    // Polyfill IE
    [].filter || (Array.prototype.filter = // Use the native array filter method, if available.
        function (a, //a function to test each value of the array against. Truthy values will be put into the new array and falsy values will be excluded from the new array
            b, // placeholder
            c, // placeholder 
            d, // placeholder
            e // placeholder
        ) {
            c = this; // cache the array
            d = []; // array to hold the new values which match the expression
            for (e in c) // for each value in the array, 
                ~~e + '' == e && e >= 0 && // coerce the array position and if valid,
                    a.call(b, c[e], +e, c) && // pass the current value into the expression and if truthy,
                    d.push(c[e]); // add it to the new array

            return d; // give back the new array
        });

    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (str) {
            return this.lastIndexOf(str, 0) === 0;
        };
    }
}());

$('.follow-scroll').each(function () {
    var element = $(this);
    var originalY = element.offset().top;

    // Space between element and top of screen (when scrolling)
    var topMargin = 0;

    $(window).on('scroll', function (event) {
        var scrollTop = $(window).scrollTop();

        element.stop(false, false).animate({
            top: scrollTop < originalY
                    ? 0
                    : scrollTop - originalY + topMargin
        }, 0, "linear");
    });
});

var er = function () {
    var p = {};
    p.push('t');
};

(function ($) {
    // Guids spéciaux
    $.extend(eplans, {
        guidEmpty: "00000000-0000-0000-0000-000000000000",
        guidNobody: "11111111-1111-1111-1111-111111111111",
        guidSomebody: "22222222-2222-2222-2222-222222222222"
    });
    $.validationTokenSelector = 'input[type=hidden][name$=RequestVerificationToken]';

    $.fn.valIncrement = function () {
        var actualVal = $(this).valInt();
        $(this).val(actualVal + 1);

        return $(this);
    };

    $.fn.valDecrement = function () {
        var actualVal = $(this).valInt();
        $(this).val(actualVal - 1);

        return $(this);
    };

    $.fn.valInt = function () {
        return parseInt($(this).val());
    };

    $.fn.htmlInt = function () {
        return parseInt($(this).html());
    };

    $.fn.htmlIncrement = function () {
        var actualVal = $(this).htmlInt();

        $(this).html(actualVal + 1);

        return $(this);
    };

    $.fn.htmlDecrement = function () {
        var actualVal = $(this).htmlInt();

        $(this).html(actualVal - 1);

        return $(this);
    };

    $.fn.postLongUri = function (toLookUp, limit) {
        if (limit == null) {
            limit = 400;
        }

        return $(this).each(function () {
            var me = $(this);

            var dict = {};
            var baseUrl = '';

            var rawUrl = me.attr('href');

            if (rawUrl == null || rawUrl == '') {
                return;
            }

            if (rawUrl.indexOf('?') > 0) {
                try {
                    var url = rawUrl.split('?');
                    baseUrl = url[0];

                    var split = url[1].split('&');
                    for (var n = 0; n < split.length; n++) {
                        var keyValue = split[n].split('=');
                        dict[keyValue[0]] = keyValue[1];
                    }

                } catch (e) {
                    // Tant pis, on a pas reussi à parser l'URI
                    return;
                }
            }

            if ((baseUrl != null && baseUrl != '') && (dict != null && dict[toLookUp] != null && dict[toLookUp] != '' && dict[toLookUp].length > limit)) {
                me.attr('href', '#').click(function () {
                    var f = $('<form />').attr('action', baseUrl).attr('method', 'post');
                    // Token
                    f.append($('<input type="hidden" />').attr('name', '__RequestVerificationToken').attr('value', me.findValidationToken()));

                    // Tous les params passés à l'URL
                    $.each(dict, function (key, value) {
                        f.append($('<input type="hidden" />').attr('name', key).attr('value', value));
                    });

                    // Pour qu'un form soit submitable, il faut qu'il soit injecté dans le DOM
                    $('body').append(f);

                    f.submit();

                    return false;
                });
            }
        });
    };

    //confirmBoxLoading 
    $.fn.confirmBoxLoading = function (message, url, loadingMsg) {
        $('#dialog-confirm .modal-body p').html(message);

        var $confirm = $('#dialog-confirm a.primary'),
            $m = $('#dialog-confirm');

        var cleanup = function () {
            $('#dialog-confirm a.primary').attr('href', null).off('click');
            $('#dialog-confirm .secondary').off('click');
            $m.off('hide');
        };

        $confirm.attr('href', "#").click(function (e) {
            e.preventDefault();
            cleanup();

            $('#dialog-confirm .modal-footer').hide();
            $('#dialog-confirm span').html(loadingMsg);
            $('#dialog-confirm-loading').show();
            $.fn.injectFormVT(url).submit();
        });

        $m.modal({
            backdrop: true,
            keyboard: true,
            show: true
        });

        $m.on('hide', function () {
            cleanup();
        });

        $('#dialog-confirm .secondary').click(function () {
            $m.modal('hide');
            cleanup();
        });
    };

    //confirmBox
    $.fn.confirmBox = function (message, url) {
        $('#dialog-confirm .modal-body p').html(message);

        var $confirm = $('#dialog-confirm a.primary'),
            $m = $('#dialog-confirm');

        var cleanup = function () {
            $('#dialog-confirm a.primary').attr('href', null).off('click');
            $('#dialog-confirm .secondary').off('click');
            $m.off('hide');
        };

        $confirm.attr('href', "#").click(function (e) {
            e.preventDefault();
            cleanup();

            $.fn.injectFormVT(url).submit();
        });

        $m.modal({
            backdrop: true,
            keyboard: true,
            show: true
        });

        $m.on('hide', function () {
            cleanup();
        });

        $('#dialog-confirm .secondary').click(function () {
            $m.modal('hide');
            cleanup();
        });
    };

    $.fn.confirmBoxCallback = function (message, cb) {
        var $m = $('#dialog-confirm');

        var cleanup = function () {
            $m.off('hide');
            $('#dialog-confirm a.primary').off('click');
            $('#dialog-confirm .secondary').off('click');
        };
       
       
        $('#dialog-confirm .modal-body p').html(message);

        $m.modal({
            backdrop: true,
            keyboard: true,
            show: true
        });

        $m.on('hide', function () {
            cleanup();
            if (cb !== null) {
                cb(false);
                cb = null;
            }
        });

        $('#dialog-confirm a.primary').on('click', function (e) {
            e.preventDefault();
            cleanup();

            if (cb !== null) {
                cb(true);
                cb = null;
            }

            $m.modal('hide');
        });

        $('#dialog-confirm .secondary').click(function () {
            cleanup();
            if (cb !== null) {
                cb(false);
                cb = null;
            }

            $m.modal('hide');
        });
    };

    $.fn.alertBox = function (title, message, cb) {
        var $m = $('#dialog-confirm'),
            $title = $m.find('.modal-header h3'),
            oldTitle = $title.html();

        var cleanup = function () {
            $m.off('hide');
            $title.html(oldTitle);
            $('#dialog-confirm a.primary').off('click');
            $('#dialog-confirm .secondary').show();
        };
       
        $('#dialog-confirm .modal-body p').html(message);
       
        $m.modal({
            backdrop: true,
            keyboard: true,
            show: true
        });

        $title.html(title);

        $m.on('hide', function () {
            cleanup();
            if (cb != null) {
                cb();
                cb = null;
            }
        });

        $('#dialog-confirm a.primary').on('click', function (e) {
            e.preventDefault();
            cleanup();
            if (cb != null) {
                cb();
                cb = null;
            }
            $m.modal('hide');
        });

        $('#dialog-confirm .secondary').hide();
    };

    $.fn.simpleConfirmBox = function (title, message, cb) {
        var $m = $('#dialog-confirm'),
            $title = $m.find('.modal-header h3'),
            oldTitle = $title.html();

        var cleanup = function () {
            $m.off('hide');
            $title.html(oldTitle);
            $('#dialog-confirm a.primary').off('click');
            $('#dialog-confirm .secondary').show();
        };

        $('#dialog-confirm .modal-body p').html(message);

        $m.modal({
            backdrop: true,
            keyboard: true,
            show: true
        });

        $title.html(title);

        $m.on('hide', function () {
            cleanup();
            if (cb != null) {
                cb();
                cb = null;
            }
        });

        $('#dialog-confirm a.primary').on('click', function (e) {
            e.preventDefault();
            cleanup();
            if (cb != null) {
                cb();
                cb = null;
            }
            $m.modal('hide');
        });

        $('#dialog-confirm .secondary').hide();
    };

    $.fn.findValidationToken = function () {
        var me = $(this);

        var myToken = me.find($.validationTokenSelector);

        // Recherche sur l'element lui meme
        if (myToken.length > 0) {
            return myToken.val();
        }

        // Recherche sur les parents direct
        var myParentForm = me.parents('form').find($.validationTokenSelector);

        if (myParentForm.length > 0) {
            return myParentForm.val();
        }

        // N'importe le quel sur la page à defaut
        return $($.validationTokenSelector).first().val();
    };

    $.fn.putValidationToken = function (data) {
        if (typeof data == "string") {
            data += "&__RequestVerificationToken=" + $.fn.findValidationToken();
        } else if (typeof data == "object") {
            data = $.extend(data, {
                __RequestVerificationToken: $.fn.findValidationToken()
            });
        }

        return data;
    };

    $.fn.checkAddContrat = function (options) {
        if (options == null) {
            return false;
        }

        $(options.base).find('.error').removeClass('error').end().find('.error-contrat').hide();

        var numContrat = $(options.fieldContrat);
        var typeIntervenant = $(options.fieldTypeIntervenant);

        //16 est l'Id du type intervenant travaux
        var isTypeIntervTravaux = parseInt(typeIntervenant.val(), 10) === 16; 
        
        if (!isTypeIntervTravaux && (numContrat.val() === null || numContrat.val() === '' || numContrat.val().length > 20)  ) {
            numContrat.parents('.clearfix').addClass('error').find('.error-contrat').show();
            return false;
        }
        //18 est l'Id du type AppuisCommun
        var isTypeAppuisCommun = parseInt(typeIntervenant.val(), 10) === 18;
        var isGestionOperateur = parseInt(typeIntervenant.val(), 10) === 33;
        if (!isGestionOperateur && !isTypeAppuisCommun && !isTypeIntervTravaux && (numContrat.val() === null || numContrat.val() === '' || numContrat.val().length > 20)) {
            numContrat.parents('.clearfix').addClass('error').find('.error-contrat').show();
            return false;
        }
        if (isTypeIntervTravaux && numContrat.val() !== null && numContrat.val() !== '') {
            typeIntervenant.parents('.clearfix').addClass('error').find('.error-activite').show();
            return false;
        }

        var mailC = $(options.fieldMail);

        if (mailC.val() == null || mailC.val() == "") {
            mailC.parents('.clearfix').addClass('error').find('.error-contrat').show();
            return false;
        }

        //MR 08/10/2012 : peu maintenant contenir plusieur mail
        var allMailAreOk = true;
        var mails = mailC.val().split(/,|;/);
        for (var i = 0; i < mails.length; i++) {
            allMailAreOk &= window.eplans.regexMail.test(mails[i].replace(" ", ""));
        }

        if (!allMailAreOk) {
            mailC.parents('.clearfix').addClass('error').find('.error-contrat').show();
            return false;
        }

        return true;
    };

    window.eplansDatepickerOptions = {
        showWeek: true,
        changeMonth: true,
        changeYear: true,
        buttonImage: '/Content/assets/calendar.png',
        buttonImageSize: '25px',
        buttonImageOnly: true,
        showOn: "both"
    };

    $.fn.eplansDatepicker = function () {
        return $(this).datepicker(window.eplansDatepickerOptions);
    };

    /* Selection déselection de tous qui doit être forcement le 1er élément */
    $.fn.handleMultiSelectAll = function (sender) {
        var $s = $(sender);
        var hld = function (e) {
            if (e.added != null && (
                    e.added.id == "0" || e.added.id == "-1" || e.added.id == "-2" || e.added.id == eplans.guidEmpty || e.added.id == eplans.guidNobody || e.added.id == eplans.guidSomebody)
            ) {
                // Click sur tous on vire les autres
                $s.select2("val", e.added.id);
            } else {
                // Click sur un vrai item on vire tous
                var newdata = [],
                    data = $s.select2("val");

                for (var i = 0; i < data.length; i++) {
                    if (data[i] != "0" && data[i] != "-1" && data[i] != "-2" && data[i] != eplans.guidEmpty && data[i] != eplans.guidNobody && data[i] != eplans.guidSomebody) {
                        newdata.push(data[i]);
                    }
                }
                $s.select2("val", newdata);
            }
        };

        $s.on("change", hld);
        $s.data('handleMultiSelectAll', {
            destroy: function () {
                $s.off('change', hld);
                $s.data('handleMultiSelectAll', null);
                return $s;
            }
        });
    };

    $.fn.injectForm = function (url) {
        var $f = $('<form />').attr('action', url).attr('method', 'post');

        $('body').append($f);

        return $f;
    };

    $.fn.injectFormVT = function (url) {
        var $f = $.fn.injectForm(url);

        $f.append('<input type="hidden" name="__RequestVerificationToken" value="' + $.fn.findValidationToken() + '" />');

        return $f;
    };

    //GDA
    $.fn.checkIntervenantIsReachable = function(options) {
        if (options == null) {
            return false;
        }

        var isIntervenantTravaux = $(options.fieldIntervenantTravaux);
        var telephoneBis = $(options.fieldTelephoneBis);

        if ((isIntervenantTravaux.val() == "true" || isIntervenantTravaux.val() == "True") && (telephoneBis.val() == "" || telephoneBis.val() == null)) {
            telephoneBis.parents('.clearfix').addClass('error').find('.error-telephoneBis').show();
            return false;
        }

        return true;
    };

    $.fn.checkMail = function (options) {
        if (options == null) {
            return false;
        }
        
        var mail = $(options.fieldmail);
        if (mail.val() == "" || mail.val() == null) {
            mail.parents('.clearfix').addClass('error').find('.error-missingmail').show();
            return false;
        }

        return true;
    };

    $.fn.checkLibelleAndDR = function (options) {
        if (options == null) {
            return false;
        }

        var libelle = $(options.fieldLibelle);
        var dr = $(options.fieldDR);
        if (libelle.val() == null || libelle.val() == "" || libelle.val() == " ") {
            libelle.parents('.clearfix').addClass('error').find('.error-libelle').show();
            return false;
        }

        if (dr.val() == null || dr.val() == "" ) {
            dr.parents('.clearfix').addClass('error').find('.error-dr').show();
            return false;
        }

        return true;
    };
})(jQuery);

$(document).ready(function () {
    if ($.tablesorter !== undefined) {
        $.tablesorter.defaults.dateFormat = 'fr';
    }
    if ($.mask !== undefined) {
        $.mask.definitions["&"] = "+";
    }

    // Outils pour les dates
    window.eplans.dates = {
        debutMois: function (date) {
            return date.clearTime().moveToFirstDayOfMonth();
        },
        finMois: function (date) {
            return date.clearTime().moveToLastDayOfMonth();
        },
        ceMoisCi: function () {
            return [this.debutMois(Date.today()), this.finMois(Date.today())];
        },
        ceMoisGlissant: function () {
            return [Date.today().addDays(-15), Date.today().addDays(15)];
        },
        leNMoisDernier: function (nMois) {
            if (nMois == null) {
                nMois = 1;
            }

            var ceMoisCi = this.ceMoisCi();

            return [this.debutMois(new Date(ceMoisCi[0].addMonths(-1 * nMois))), this.finMois(new Date(ceMoisCi[1].addMonths(-1 * nMois)))];
        },
        leNMoisQuiSuit: function (nMois) {
            if (nMois == null) {
                nMois = 1;
            }

            return this.leNMoisDernier(-1 * nMois);
        },
        cetteAnnee: function () {
            return new Date().getFullYear();
        },
        annee: function (y) {
            return [new Date(y, 0, 1), new Date(y, 11, 31)];
        },
        lAnDernier: function () {
            return this.cetteAnnee() - 1;
        },
        trimestresAnnee: function (annee) {
            if (annee == null) {
                annee = this.cetteAnnee();
            }

            var begin = new Date(annee, 0, 1); // 1jv <annee>

            return this.trimestres(begin);
        },
        trimestres: function (begin) {
            var start = begin.clearTime().moveToFirstDayOfMonth();
            return [
                [start, new Date(start).addMonths(3)],
                [new Date(start).addMonths(3), new Date(start).addMonths(6)],
                [new Date(start).addMonths(6), new Date(start).addMonths(9)],
                [new Date(start).addMonths(9), new Date(start).addMonths(12)]
            ];
        }
    };

    // TM 19072017 : toujours vérifier la concordance avec la PhoneNumberPattern dans PhoneNumberUtil.cs
    window.eplans.regexTel = /^[+]?(?:[0-9](?:\s|\.|-)*)+$/;
    window.eplans.regexMail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;

    window.eplans.regexGpsLat = /^[-+]?([1-8]?\d((\.|\,)\d+)?|90((\.|\,)0+)?)$/;
    window.eplans.regexGpsLon = /^[-+]?(180((\.|\,)0+)?|((1[0-7]\d)|([1-9]?\d))((\.|\,)\d+)?)$/;

    window.eplans.siretFormat = "999 999 999 99999";

    //divers intialisation
    $('.datepicker').eplansDatepicker();

    if ($.tabs !== undefined) {
        $('.tabs').tabs();
    }


    $('#topbar').dropdown();
    $().data('twipsy');

    if ($.twipsy !== undefined) {
        $('a[rel=twipsy]').twipsy({ live: true });
    }
    var selectorVal = 'a[rel=popover], img[rel=popover]';
    $(selectorVal).popover({ live: true, html: true, selector: selectorVal});

    $('a[rel=popoverAjax], input[type=submit][rel=popoverAjax]').popoverAjax({ live: true, html: true });

    if ($.fn.echangeInformation != undefined) {
        $(".modal-echange-info, .accobs-on-page").echangeInformation();
    }
    //$(".accobs-container").echangeInformation();

    $('.add-on :checkbox').click(function () {
        var $me = $(this);

        if ($me.attr('checked')) {
            $me.parents('.add-on').addClass('active');
        } else {
            $me.parents('.add-on').removeClass('active');
        }
    });

    if ($.mask !== undefined) {
        // input siret
        $(".numero-siret").mask(window.eplans.siretFormat);
    }

    if ($.numeric !== undefined) {
        // input numeriques
        $('.numerique').numeric({
            decimal: ".",
            altDecimal: ",",
            negative: false
        });

        $('.numeriqueEntier').numeric({
            decimal: false,
            negative: false
        });
        //AC:style qui ne s'applique que sur des champs spécifiques appuis commun
        $('.numeriqueAppuisCommun').numeric({
            decimal: false,
            negative: false
        });
    }

    // input lettres
    $('.letterTextBox').bind('input', function (evt) {
        var out = "";
        var str = this.value;
        for (var i = 0; i < str.length; i++) {
            if (/[ A-Za-z]/.test(str.charAt(i))) {
                out = out.concat(str.charAt(i));
            }
        }

        this.value = out;
    });

    //autoclose des alertes BS
    if ($(".alert-message:not(.persist)").length > 0) {
        setTimeout(function () {
            // On garde les ".persist"
            $(".alert-message:not(.persist)").fadeOut();
        }, 3500);
    }

    $(document).on('mouseout', '[data-toggle="popover"],[rel="popover"]', function () {
        $('body > div.popover').remove();
    });

    //modify la visibiliet d'un element dont l'id ccorespond au href du lien
    $("a.toogleVisibility").click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        var selector = $(this).attr("href");
        if (selector === "#") {
            return;
        }

        $(selector).toggle();

        //mais des fois y'a des popover qui traine
        $('div.popover').remove();
    });
    $("input[type=checkbox].toogleVisibility").click(function () {
        $($(this).data("href")).toggle();
        //mais des fois y'a des popover qui traine
        $('div.popover').remove();
    });
    $("input[type=radio].toogleVisibility").click(function () {
        var $me = $(this);
        $($me.data("tohide")).hide();
        $($me.data("toshow")).show();

        //mais des fois y'a des popover qui traine
        $('div.popover').remove();
    });

    var errorBootstrap = function () {
        $('span.field-validation-valid, span.field-validation-error').addClass('help-inline');

        //input error
        $(":input.input-validation-error").each(function () {
            $(this).parents("div.clearfix").addClass("error");
        });

        //input valid
        $(":input.valid").each(function () {
            $(this).parents("div.clearfix").removeClass("error");
        });

        //s'il ya des tabs
        $("div.tab-content > div").each(function () {
            var id = $(this).attr('id');
            if ($("input.input-validation-error, select.input-validation-error", $(this)).length > 0) {
                $('ul.tabs a[href="#' + id + '"]').addClass("error");
            } else {
                $('ul.tabs a[href="#' + id + '"]').removeClass("error");
            }
        });

        //summery
        var validationSum = $(".validation-summary-errors");

        if (validationSum.length > 0) {
            // Les items sont présentés sous la forme d'un ul li
            var lis = validationSum.find('ul li');

            if (lis.length == 1 && lis.first().is(':not(:visible)')) {
                // Un seul li qui est display:none, on cache tout
                validationSum.hide();

            } else {
                validationSum.addClass("alert-message block-message error");
            }
        }
    };

    //MR 03/06/2013 => remonter des erreur JS enfin... IE de merde
    var multiplaque = $('.multiplaque');
    if (multiplaque && $.select2 !== undefined) {
        multiplaque.select2();
    }

    $("form").bind('errorChanged', errorBootstrap);

    // URL trop longues, passage en POST
    $('a.listAffaireLink').postLongUri('listAffaires');
    $('a.file-zip').postLongUri('listFiles');

    // Tableau de bord asynchrone
    $.fn.asyncTdB = function () {
        var $me = $(this),
            url = $me.data('url');

        $.ajax({
            url: url,
            type: 'POST',
            data: {
                tbt: $me.data('tbt')
            }
        }).done(function (html) {
            if (html == "RES_EMPTY") {
                $me.closest('.well').remove();
                return;
            }

            var $html = $(html);
            $me.replaceWith($html);
            $html.find('a.listAffaireLink').postLongUri('listAffaires');
        }).fail(function (xhr, status, text) {
            var replacement = "";
            switch (xhr.status) {
                case 503:
                    replacement = "<i>Ce pilotage est momentanément indisponible</i>";
                    break;

                default:
                    replacement = "<i>Une erreur est survenue lors du calcul</i>";
                    break;
            }

            $me.replaceWith(replacement);
        });
    };

    $('.async-tb').each($.fn.asyncTdB);

    $('.async-tb-fire').on('click', function () {
        $(this).asyncTdB();
        $(this).html('<div style="text-align:center"><img src="/Content/assets/ajax-loader.gif" class="center-loading"></div>');
    });

    $('.async-tb-remover').each(function () {
        $(this).closest('.well').remove();
    });

    // Erreurs apres post
    errorBootstrap();

    var mSent = $('#mailsSent');

    if (mSent.length === 1) {
        mSent.bind('shown', function () {
            setTimeout(function () {
                mSent.modal('hide');
            }, 6000);
        });
        mSent.bind('hidden', function () {
            mSent.remove();
        });
        mSent.find('a').click(function (e) {
            e.preventDefault();
            mSent.modal("hide");
        });
        mSent.modal({
            backdrop: true,
            keyboard: true,
            show: true
        });
    }

    // Affaire Validation Article

    $(document).on('click','div.ValidationAffContainer button' , function () {
        var $this = $(this), action = $this.data('target-action');

        $('.modal-valide-affaire' + $this.data('target-id') + ' [data-target]:not([data-target=' + action + '])').hide();
        $('.modal-valide-affaire' + $this.data('target-id') + ' [data-target=' + action + ']').show();
        $('.modal-valide-affaire' + $this.data('target-id') + ' input:hidden[name=actionValidation]').val(action);
        $('.modal-valide-affaire' + $this.data('target-id')).modal('show');

        return false;
    });

    $(document).on('click', 'div[class*=modal-valide-affaire] .modal-action button', function() {
        var $this = $(this), idValidation = $this.data('id-validation');

        if ($this.hasClass('danger')) {
            $this.parents('div.modal-valide-affaire' + idValidation).modal('hide');
        } else {
            var idGroup = $this.data('id-group'), idAff = $this.data('aff-id');

            window.location.href='/Affaire/AffaireValidationManagement?etape=' + idGroup + '&idAffaire=' + idAff + '&idValidation=' + idValidation + '&message=' + $('.input-obs-just', $(this).parents('.modal-body:first')).val() + '&actionValidation=' + $('#actionValidation', $(this).parents('.modal-body:first')).val();
        }
    });


    var modalAntiUtilisation = $('#modal-anti-utilisation');

    $(document).ready(function () {
        $(".DossierEtudeIsClosed").each(function () {
            var $me = $(this);
            $me.parent().parent().find("table").fadeOut();
            $me.parent().next().next().find("div").parent().fadeOut();
            $me.parent().parent().next().fadeOut();
            modalAntiUtilisation.modal('hide');
        });
    });
    $("img[id*='Header_DossierEtude']").parent().click(function () {

        var $me = jQuery(this).children("img").first(); 
        
        if ($me.hasClass("DossierEtudeIsClosed")) {
            
            $me.parent().parent().find("table").fadeIn();
            $me.parent().next().next().find("div").parent().fadeIn();
            if (!$me.hasClass("DossierRefuser")) {
                $me.parent().parent().next().fadeIn();
            }
            $me.removeClass("DossierEtudeIsClosed");
            $me.attr('src', '/Content/assets/signature-commande/grey/down-arrow.png');
        }
        else {
           
            $me.parent().parent().find("table").fadeOut();
            $me.parent().next().next().find("div").parent().fadeOut();
            $me.parent().parent().next().fadeOut();
            $me.addClass("DossierEtudeIsClosed");
            $me.attr('src', '/Content/assets/signature-commande/grey/play-arrow.png');
        }

    });
    $(".BtnForHideTrue:not(:checked)").each(function () {
        $("label[for= '" + $(this).data('btnforhidetrue') + "']").parent().hide();
    });


    modalAntiUtilisation.modal({ show: true, backdrop: true });
    modalAntiUtilisation.find('[data-dismiss="modal"]').click(function (e) {
        e.preventDefault();
        modalAntiUtilisation.modal('hide');
    });

});

(function () {
    $('.merge-with-previous-row').each(function () {
        var comment = $(this);
        if (comment) {
            var commentRow = comment.closest("tr");
            var prev = commentRow.prev();
            prev.find('td div:first').append(commentRow.find('td div:first').html());
            commentRow.remove();
        }
    });
}());
