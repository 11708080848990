import { ControleFichier } from "../controle-chantiers/controle-fichier";
import * as ko from "knockout";

// ReSharper disable InconsistentNaming
export class FileUploadWidget {
    options: any = {

    };

    baseConfig = {
        max_file_size: '10485760b',
        flash_swf_url: '/Scripts/pupload/plupload.flash.swf',
        silverlight_xap_url: '/Scripts/pupload/plupload.silverlight.xap',
        runtimes: 'html5,flash,html4',
        multipart: true
    };
    forbiddenRegex = new RegExp("^.*\\.(exe|dll|bat|vbs|js)$", "i");/* Non sensible à la casse*/

    $el: JQuery;
    uploadButton: JQuery;
    holdStart = false;
    filesToRemove = [];

    constructor($el: JQuery, options: any) {
        this.$el = $el;
        options = options || {};

        this.options = $.extend({}, this.options, options);

    }

    toggleUploadButton = (enable) => {
        if (enable) {
            this.uploadButton.removeClass('disabled').prop('disabled', false);
            $('.waitOnAjaxUpload').removeClass('wait');

        } else {
            this.uploadButton.addClass('disabled').prop('disabled', true);
            $('.waitOnAjaxUpload').addClass('wait');
        }
    }

    addFichier() { }

    build() {
        const myGuid = window["plupload"].guid(),
            idBtn = `btnUpload-${myGuid}`,
            idContainer = `objectContainer-${myGuid}`;

        const uploadBar = $('<span class="btn btn-default btnAjaxUpload">Rattacher</span><span class="uploading"></span>');
        this.uploadButton = uploadBar.first();
        this.uploadButton.attr('id', idBtn);

        const objectContainer = this.$el.parent();
        objectContainer.attr('id', idContainer);

        this.$el.after(uploadBar);
        this.$el.remove();

        let multipartData = {};

        if ($.isFunction(this.options.multipartData)) {
            multipartData = $.proxy(this.options.multipartData, this.$el)();
        } else if ($.isPlainObject(this.options.multipartData)) {
            multipartData = this.options.multipartData;
        }

        const cfg = $.extend(this.baseConfig, {
            url: this.options.action,
            container: idContainer,
            multipart_params: multipartData,
            browse_button: idBtn,
        });

        const uploader = new window["plupload"].Uploader(cfg);

        objectContainer.data('uploader', uploader);
        const self = this;

        uploader.bind('FilesAdded', (up, files: any) => {
            $.each(files, (index, file) => {
                if (self.forbiddenRegex.test(file.name)) {
                    alert("'" + file.name + "' : Ce type de fichier n'est pas autorisé");
                    self.holdStart = true;
                    self.filesToRemove.push(file);

                } else {
                    self.holdStart = false;
                }
            });

            if (this.options.onFilesAdded != null && $.isFunction(this.options.onFilesAdded)) {
                this.options.onFileUploaded.bind(this.$el)(up, files);
            }
        });

        uploader.bind('QueueChanged', (up, files) => {
            // Suppression des fichiers marqués commme indésirables
            this.filesToRemove.forEach(file => {
                up.removeFile(file);
            });

            self.filesToRemove = [];

            if (this.holdStart) {
                return;
            }

            this.toggleUploadButton(false);

            // Autostart upload
            uploader.start();

            // MR 16/12/2011 : ajout loading
            $(uploadBar).filter(".uploading").html("<img style='position: relative; top: 10px; left: 10px;' src='/Content/assets/ajax-loader.gif' width='31' height='31'  />");

            if (this.options.onQueueChanged != null && $.isFunction(this.options.onQueueChanged)) {
                this.options.onQueueChanged.bind(this.$el)(up, files);
            }
        });

        uploader.bind('Error', (up, err) => {
            up.refresh(); // Reposition Flash/Silverlight

            if (err.code == window["plupload"].FILE_SIZE_ERROR /* Fichier trop grand*/) {
                alert("La taille des fichiers est limitée à 10 Mo");
            }

            if (err.code == window["plupload"].FILE_EXTENSION_ERROR /* Extension interdite */) {
                alert("Ce type de fichier n'est pas autorisé");
            }

            this.toggleUploadButton(true);

            if (this.options.onError != null && $.isFunction(this.options.onError)) {
                this.options.onError.bind(this.$el)(up, err);
            }
        });

        // Un fichier est passé
        uploader.bind('UploadFile', (up, file) => {

            if (this.options.onUploadFile != null && $.isFunction(this.options.onUploadFile)) {
                this.options.onUploadFile.bind(this.$el)(up, file);
            }
        });

        // Toute la queue est passée
        uploader.bind('FileUploaded', (up, file, response) => {
            this.toggleUploadButton(true);
            // MR 16/12/2011 : Suppr loading
            $(uploadBar).filter(".uploading").html("");

            uploader.refresh(); // Reposition Flash/Silverlight (le layout a pu changer)

            if (this.options.onUploadFinished != null && $.isFunction(this.options.onUploadFinished)) {
                this.options.onUploadFinished.bind(this.$el)(up, file, response);
            }
        });

        uploader.init();
        uploader.refresh();
    }

    destroy() {

    }
}

$.fn.extend({
    FileUpload: function (options: any = null) {
        options = options || {
            value: null
        };

        return $(this).each(function () {
            const $el = $(this),
                lopts = $.extend({}, options);

            if ($el.data('FileUploadWidget') != null) {
                return;
            }

            if (window["plupload"] === undefined) {
                return;
            }

            const widget = new FileUploadWidget($el, lopts);

            widget.build();
            $el.data('FileUploadWidget', widget);
        });
    }
});

export class UploadOnBindingHandler implements KnockoutBindingHandler {
    init(element, valueAccessor: () => any): void {
        const $el = $(element),
            value = valueAccessor(),
            actionName = value.ActionName != null ? value.ActionName : 'UploadFileControleChantier';

        const d = $el.FileUpload({
            action: '/GED/' + actionName,
            multipartData() {
                return {
                    __RequestVerificationToken: $.fn.findValidationToken(),
                    IdAffaire: value.IdAffaire(),
                    FkId: value.FkId(),
                    FkIdTypeDocument: value.TypeFichier()
                };
            },
            onUploadFinished(up, file, r) {

                const response = $.parseJSON(r.response),
                    f = new ControleFichier();
                f.Id(response.Id);
                f.NomFichier(response.Value);
                f.Url(response.ValueURL);

                value.AddFichier(f);
            }
        });

        $el.data('FileUploadWidget', d);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            // This will be called when the element is removed by Knockout or
            // if some other part of your code calls ko.removeNode(element)
            $el.off();
            $el.data('FileUploadWidget').destroy();
            $el.data('FileUploadWidget', null);
        });
    }
}

interface KnockoutBindingHandlers {
    uploadOn: KnockoutBindingHandler;
}

if (typeof ko != "undefined") {
    ko.bindingHandlers.uploadOn = new UploadOnBindingHandler();
}
