﻿import { InstructionEntrepriseViewModel } from "./InstructionEntrepriseViewModel";

export class InstructionEntrepriseReponseViewModel extends InstructionEntrepriseViewModel {
    ModeReponse: KnockoutObservable<boolean>;
    Statut: KnockoutObservable<string>;
    DateFinDisabled: KnockoutComputed<boolean>;
    CanOnlyView: KnockoutObservable<boolean>;
    DateDebutOptions: KnockoutComputed<any>;
    DateDebutT: KnockoutObservable<moment.Moment>;
    DateDebutTComputed: KnockoutComputed<string>;
    DateFinOptions: KnockoutComputed<any>;
    DateFinT: KnockoutObservable<moment.Moment>;
    DateFinTComputed: KnockoutComputed<string>;

    constructor(model: any) {
        super(model);
        this.ModeReponse = ko.observable<boolean>(true);
        this.VersionsApplicables = model.VersionsActives;
        this.TypesInstructions(model.TypesInstructions);
        this.TypesOuvrages(model.TypesOuvrages);
        this.SelectedVersion(model.SelectedVersion);
        this.SelectedNumeroCalcule(model.SelectedNumeroCalcule);
        this.Statut = ko.observable<string>(model.StatutLibelle);
        this.CorrespondingNumeroCalculeVersions(this.VersionsApplicables);
        this.CanOnlyView = ko.observable<boolean>(model.CanOnlyView);


        var minDateDebut = new Date(model.MinDateDebut.toString().match(/\d+/) * 1);
        var maxDateFin = model.MaxDateFin != null ? new Date(model.MaxDateFin.toString().match(/\d+/) * 1) : null;

        this.DateDebutT = ko.observable<moment.Moment>(this.getMomentDate(model.DateDebut));
        this.DateFinT = ko.observable<moment.Moment>(this.getMomentDate(model.DateFin));

        this.DateFinDisabled = ko.computed<boolean>({
            read: () => {
                return (this.DateDebutT() == null || this.DateDebutT() === undefined) || (this.CanOnlyView());
            }
        });

        this.DateDebutOptions = ko.computed(() => {
            return {
                minDate: moment(new Date(minDateDebut.toString()).setHours(0, 0, 0, 0)),
                locale: "fr",
                format: "DD/MM/YYYY",
                useCurrent: false
            }

        });
        this.DateFinOptions = ko.computed(() => {
            const dateDebutT = this.DateDebutT();
            let res: any = {
                // HF Clément Bourgeois 2018 11 21
                minDate: moment(new Date(dateDebutT != null ? dateDebutT.toString() : null).setHours(0, 0, 0, 0)),
                locale: "fr",
                format: "DD/MM/YYYY",
                useCurrent: false
            };

            if (maxDateFin != null) {
                res.maxDate = moment(new Date(maxDateFin.toString()).setHours(0, 0, 0, 0));
            }

            return res;
        });
    }

    getMomentDate(date: any): any {
        let res = null;
        if (date != null) {
            res = moment(new Date(date));
            if (res.year() < 1000 || res.year() > 3000) return null;
        }
        return res;
    }

}
