﻿import { Intervenant } from "./Intervenant";

export class PhasePlanning {
    Id = ko.observable<number>();

    FkIdIntervenantHasIcp = ko.observable<number>();

    NbSalariesAffectes = ko.observable<number>(0);
    Libelle = ko.observable<string>().extend({ required: "" });
    DateDebutPhase = ko.observable<Date>().extend({ required: "" });
    DateFinPhase = ko.observable<Date>().extend({ required: "" });

    //Afin de brider le nombre maximum de salariés affectable avec le nombre de salariés affectés à l'entreprise exterieure
    MaxNbSalariesAffectes = ko.observable<number>();

    _nameIntervenant: string;

    constructor(data: number, intervenant: Intervenant, fromForm?: Boolean);
    constructor(data: any, intervenant: Intervenant, fromForm: Boolean) {
        if (intervenant) {
            this._nameIntervenant = intervenant.NomPrenomLibelle();
        }
        if (typeof fromForm !== 'undefined') {
            if (fromForm) {
                this.Id(data.Id());
                this.FkIdIntervenantHasIcp(data.FkIdIntervenantHasIcp());
                this.Libelle(data.Libelle());
                this.DateDebutPhase(data.DateDebutPhase());
                this.DateFinPhase(data.DateFinPhase());
                this.NbSalariesAffectes(data.NbSalariesAffectes());
                this.MaxNbSalariesAffectes(data.MaxNbSalariesAffectes());
            } else {
                this.Id(data.Id);
                this.FkIdIntervenantHasIcp(data.FkIdIntervenantHasIcp);
                this.Libelle(data.Libelle);
                this.DateDebutPhase(data.DateDebutPhase);
                this.DateFinPhase(data.DateFinPhase);
                this.NbSalariesAffectes(data.NbSalariesAffectes);
                this.MaxNbSalariesAffectes(data.MaxNbSalariesAffectes);
            }
        } else {
            this.MaxNbSalariesAffectes(data);
        }
    }

    isValid = ko.computed({
        owner: this, read: () => {
            if (!this.Libelle() || this.Libelle.hasError() || !this.DateDebutPhase() || this.DateDebutPhase.hasError() || !this.DateFinPhase() || this.DateFinPhase.hasError() || this.MaxNbSalariesAffectes() < this.NbSalariesAffectes()) {
                return false;
            }
            return true;
        }
    });

    LibelleForSelect = ko.computed({
        owner: this, read: () => {
            //On le clean a l'affichage pour pas être emmerdé après car on parse... Pas ouf je sais mais évol plus évol plus évol
            var returnValue = '';
            if (this.Libelle()) {
                returnValue = this.Libelle();
                if (this._nameIntervenant) {
                    returnValue += " (" + this._nameIntervenant + ")";
                }
            }
            return returnValue;
        }
    });
}
