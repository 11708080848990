﻿import { SousCategorieRisque } from "./SousCategorieRisque";
import { Risque } from "./Risque";
import { MiniFile } from "./MiniFile";
import { Intervenant } from "./Intervenant";

export class PlanPrevention {
    Id = ko.observable<number>(); //Id FAR ou ICP
    IsBrouillon = ko.observable<boolean>();

    _ListTypeSousCategorie = ko.observableArray<SousCategorieRisque>();

    _Risques = ko.observableArray<Risque>();
    _RisquesVides = ko.observableArray<Risque>();

    IsICP = ko.observable<boolean>();

    constructor(data, isICP: boolean) {
        this.IsICP(isICP);
        this.Id(data.Id);
        if (data) {

            for (var index in data.ListTypeSousCategorie) {
                var sousCat = new SousCategorieRisque(data.ListTypeSousCategorie[index]);
                this._ListTypeSousCategorie().push(sousCat);
                if (sousCat.Id() > 0) {
                    let risqueVide = new Risque();
                    risqueVide.FkTypeSousCategorieRisque(sousCat.Id());
                    this._RisquesVides().push(risqueVide);
                }
            }

            for (var index in data.RisquePlaqueKo) {
                var risqueIcp = new Risque(data.RisquePlaqueKo[index]);
                risqueIcp.IsTemporaire(true); //Vu que c'est de l'auto completion a partir des trucs saisies a l'echelle de la DR, faudra les valider
                this._Risques().push(risqueIcp);
            }
            this.IsBrouillon(data.IsBrouillon);
        }
    }

    _validRisque(risque: Risque) {
        risque.IsTemporaire(false);
        window.onbeforeunload = () => "";
    }

    _devalidRisque(risque: Risque) {
        risque.IsTemporaire(true);
        window.onbeforeunload = () => "";
    }

    addFile(file: MiniFile, listToAdd: KnockoutObservableArray<MiniFile>) {
        listToAdd.push(file);
    }

    _hasSousCategorieDeleted = ko.computed({
        owner: this,
        pure: true,
        read: () => {
            return this._ListTypeSousCategorie().some(_ => _.Id() == 0);
        }
    });

    removeFile(file: MiniFile, listToRemove: KnockoutObservableArray<MiniFile>) {
        $.ajax({
            url: '/Affaire/DeleteFileICPFAR',
            type: 'POST',
            data: {
                __RequestVerificationToken: $.fn.findValidationToken(),
                id: file.Id
            }
        });
        listToRemove.remove(file);
    }

    removeIntervenant(intervenant: Intervenant, listConcerned: KnockoutObservableArray<Intervenant>) {
        listConcerned.remove(intervenant);
        window.onbeforeunload = function () { return ""; };
    }

    _removeRisque(risque: Risque, listConcerned: KnockoutObservableArray<Risque>) {
        listConcerned.remove(risque);
    }

    _allRisquesValid = ko.computed({
        owner: this,
        pure: true,
        read: () => {
            var allRisques = new Array<Risque>();
            var risque: Risque;
            for (var index in this._Risques()) {
                risque = this._Risques()[index];
                if (!risque.IsTemporaire()) {
                    allRisques.push(risque);
                }
            }

            return allRisques;
        }
    });

    _needToDisplayCatDeleted(): boolean {
        return this._Risques().some(_ => _.FkTypeSousCategorieRisque() == 0);
    }

    _hasAnyRisque(idSousCat: number): boolean {
        return this._Risques().some(_ => _.FkTypeSousCategorieRisque() == idSousCat && _.IsTemporaire() == false);
    }

    _hasAnySousCategorie(idCat: number): boolean {
        return this._ListTypeSousCategorie().some(_ => _.FkIdTypeRisque() == idCat);
    }
}

$(document).ready(() => {
    // Traitements à effectuer quand l'utilisateur saisit une valeur
    $('.icp-editor').find(':input').each(function () {
        $(this).change(() => {
            // Modif en cours, alerte si on cherche à quitter
            window.onbeforeunload = () => "";
        });
    });
});
