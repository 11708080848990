﻿export class GdaTools {
    private config: any; // de type [...]
    constructor() { }


    // #region []  -  PostAjaxAction

    public PostAjaxAction(url, model, fbLoad) {
        this.PostAjaxActionMode(url, model, fbLoad, "html");
    }

    public PostAjaxActionMode(url, model, fbLoad, mode) {
        $.post(url,
            { model: model, __RequestVerificationToken: $().findValidationToken() }
            , function (data) { fbLoad(data); }
            , mode);
    }



    // #endregion

    // #region [] -  Loading Layer 

    public setLoadingBloc(mode, loadingMessage = null) {
        var selLoding = "#det" + mode + "BlocLoading"
        if (loadingMessage != null) {

            var htmlMessage = "<center><h3><i>" + loadingMessage + "</i></h3></center>";

            var divMessage = $(selLoding).find("div.loadingMessage");
            if (divMessage.length == 1) {
                $(divMessage).html(htmlMessage);
            }
            else {
                $('<div/>', { 'class': 'loadingMessage', html: htmlMessage }).appendTo(selLoding);
            }
        }
        $(selLoding).show();

        $("#det" + mode + "Bloc").hide();
    }

    public loadBloc(mode, data) {
        $("#det" + mode + "BlocLoading").hide();
        var bloc = $("#det" + mode + "Bloc");
        if ($(bloc).length > 0) {
            $("#det" + mode + "Bloc").show();
            $("#det" + mode + "Bloc").html(data);
        }
    }
    public showBloc(mode) {
        $("#det" + mode + "BlocLoading").hide();
        var bloc = $("#det" + mode + "Bloc");
        if ($(bloc).length > 0) {
            $("#det" + mode + "Bloc").show();
        }
    }


    // #endregion

    // #region []  PickerAutoComplete
    public SetSourcePickerAutoComplete(model) {
        var pickerCode = model.pickerCode;
        var sourceData = model.sourceData;
        var slinkBtn = '#quickSearchBtAdd' + pickerCode;
        var slinktext = '#quickSearch' + pickerCode;
        var slinkSelected = '#quickSearchSelected' + pickerCode;

        var modelAutocomplete: any = {
            minLength: 0,
            autoFocus: true,
            cacheLength: 1,
            scroll: true,
            highlight: true,

            search: function (oEvent, oUi) {
                $(slinkBtn).hide();

                // get current input value
                var sValue = $(oEvent.target).val().toString();
                // init new search array
                var aSearch = [];
                // for each element in the main array ...
                $(sourceData).each(function (iIndex, sElement: any) {
                    if (sElement.label.toUpperCase().indexOf(sValue.toUpperCase()) !== -1) {
                        aSearch.push(sElement);
                    }
                });
                // change search array
                $(this).autocomplete('option', 'source', aSearch);
            },

            source: function (request, response) {
                response(sourceData);
            },
            select: function (event, ui) {
                $(slinkSelected).val(ui.item.id);
                $(slinkBtn).show();
            }
        };


        //autocomplete
        $(slinktext).autocomplete(modelAutocomplete);
    }

    public getSelectedPickerAutoComplete(pickerCode) {
        return $('#quickSearchSelected' + pickerCode).val();
    }
    // #endregion


}
