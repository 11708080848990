﻿import { Utils } from "../typings/Utils";

// ReSharper disable InconsistentNaming

// Doit être en concordance avec TypeGeneralPrestationPposr
export enum TypeGeneralPrestation {
    EtudeDevis = "Etude Devis" as any,
    EtudeTechnique = "Etude Technique" as any,
    Branchement = "Branchement" as any,
    Terrassement = "Terrassement" as any,
    BranchementTerrassement = "Branchement et Terrassement" as any
}

export class TravauxValue {
    // A l'initialisation du modèle Knockout, les valeurs reçues ne sont pas des observables, ensuite si
    constructor(public travail, public objectIsObservable) {
        if (objectIsObservable) {
            // vient d'un observable du modèle ts
            this._Label(travail._Label());
            this._Value(travail._Value());
            this._PotentialValue(travail._PotentialValue());
            this._IsVisible(travail._IsVisible());
        } else {
            // vient du modèle C#
            this._Label(travail.Label);
            this._Value(travail.Value);
            this._PotentialValue(travail.PotentialValue);
            this._IsVisible(travail.IsVisible);
        }
    }

    _Label = ko.observable<string>();
    _Value = ko.observable<number>();
    _PotentialValue = ko.observable<number>();
    _IsVisible = ko.observable<boolean>();

    _affectTravauxValue() {
        if (this._Value() === 0) {
            this._Value(this._PotentialValue());
        } else {
            this._Value(0);
        }

        // Attention, sans le return true; le comportement par défaut de la checkbox est empêché par le binding sur le click event
        return true;
    }

    _boxIsChecked = ko.pureComputed({
        owner: this,
        read: () => {
            return this._Value() > 0;
        }
    });
}

export class Base {
    constructor(prestationId: number, affaireId: number, affaireEtapeId: number) {
        this.PrestationId(prestationId);
        this.AffaireId(affaireId);
        this.AffaireEtapeId(affaireEtapeId);
    }

    PrestationId = ko.observable<number>();
    AffaireId = ko.observable<number>();
    AffaireEtapeId = ko.observable<number>();

    _updateDatas() {
        // On va récupérer le bandeau et la barre d'avancement à jour
        $.ajax({
            url: "/Affaire/GetAvancementEtBandeauForPrestation",
            type: 'POST',
            dataType: 'json',
            data: $.fn.putValidationToken({
                affaireId: this.AffaireId(),
                prestationId: this.PrestationId()
            })
        }).done((dataToInject: any) => {
            if (dataToInject != null) {
                $(`.barre-avancement-prestation[data-id-prestation="${this.PrestationId()}"]`).html(dataToInject.barreAvancement);
                $(`.bandeau-prestation[data-id-prestation="${this.PrestationId()}"]`).html(dataToInject.bandeau);
            }
        });
    }

    _submitPresta() {
        const prestationId = this.PrestationId();

        $.ajax({
            url: "/Affaire/SubmitPrestation",
            type: 'POST',
            data: $.fn.putValidationToken({
                idPrestation: prestationId
            })
        }).done((data: any) => {
            const $modalSoumission = $('#submit-prestation-modal');

            $modalSoumission.modal({
                backdrop: false,
                show: false
            });

            if (data.Status) {
                const $prestationDatas = $(`.prestation-data-block[data-id-prestation="${prestationId}"]`);

                $prestationDatas.each(function () {
                    // ReSharper disable once SuspiciousThisUsage
                    const $me = $(this),
                        $myButtModif = $me.find('.modif-button'),
                        $myButtSubmit = $me.find('.submit-button');

                    if ($myButtModif !== null && $myButtModif !== undefined) {
                        $myButtModif.remove();
                    }
                    if ($myButtSubmit !== null && $myButtSubmit !== undefined) {
                        $myButtSubmit.remove();
                    }
                });

                $.fn.sticky("Prestation soumise avec succès", { cssClass: 'green' });

                this._updateDatas();
            } else {
                $.fn.sticky(data.Data.slice(0), { cssClass: 'orange' });
            }
        });
    }

    _SaveRefusPresta(prestationId: number, context: any) {
        $.ajax({
            url: "/Affaire/RefusSubmissionPrestation",
            type: 'POST',
            data: $.fn.putValidationToken({
                idPrestation: prestationId
            })
        }).done((data: any) => {
            if (!data.Status) {
                $.fn.sticky(data.Data.slice(0), { cssClass: 'orange' });
                } else {
                    $.fn.sticky("Prestation refusée avec succès", { cssClass: 'green' });
                    document.location.reload();
                }
        });
    }

    _refusPrestaConfirmCallback(v, self) {
            if (v !== true)
                return;

            pointArret.createFromJs(false,
                self.AffaireEtapeId(),
                "Autre",
            (source) => this._SaveRefusPresta(self.PrestationId(), source),
                null);
    }

    _refusPresta() {
        const self = this;
        $.fn.confirmBoxCallback("Vous êtes sur le point de refuser la prestation. Cette action permettra au prestataire de re saisir des dates de réalisation ainsi que compléter son attachement.",
            v => this._refusPrestaConfirmCallback(v, self));
    }
}

export class CommonInfosPrestation extends Base {
    constructor(data, sourceBlock: string) {
        super(data.PrestationId, data.AffaireId, data.AffaireEtapeId);

        this._UserEmail(data.UserEmail);

        this._TypeDrLabel(data.TypeDrLabel);
        this._CanModify(data.CanModify);
        this._ModeModificationGlobal(false);
        this._TypeGeneralPrestation(data.TypeGeneralPrestation);

        this._AdjustedMaxCommentLength(this._HighSeuilMaxNbCar - this._NbCarDiffForUpdateCommentaire - this._UserEmail().length);

        this._PrestationLink(`presta-${sourceBlock}-id${this.PrestationId()}`);
        this._PrestationLinkWithHash(`#${this._PrestationLink()}`);

        this._IsMyPrestation(data._IsMyPrestation);

        this._PendingModification(false);

        $('#dialog-confirm .modal-body p').html('Vous allez perdre vos modifications, voulez-vous continuer ?');
    }

    _UserEmail = ko.observable<string>();

    _TypeDrLabel = ko.observable<string>();
    _PrestationLink = ko.observable<string>();
    _PrestationLinkWithHash = ko.observable<string>();

    _IsMyPrestation = ko.observable();

    _CanModify = ko.observable<boolean>();
    _ModeModificationGlobal = ko.observable<boolean>();
    _TypeGeneralPrestation = ko.observable<TypeGeneralPrestation>();

    _ValueNonRenseigne = ko.observable<string>("(Non renseigné)");

    _AdjustedMaxCommentLength = ko.observable<number>();

    // Modification en cours, sert à savoir si on affiche une alerte en cas de clique fermant la prestation
    _PendingModification = ko.observable<boolean>();

    // Au retour du save, sert à mettre à jour si nécessaire les emails liés aux commentaires clients
    _PendingSave = ko.observable<boolean>(false);

    _HighSeuilMaxNbCar: number = 2000;
    _LowSeuilMaxNbCar: number = 200;
    _NbCarDiffForUpdateCommentaire: number = 3;

    // LES MESSAGES D'ERREUR DOIVENT RESTER EN COHERENCE AVEC PpOsrErrorMessageLibrary.cs
    _ErrorEmailInvalide: string = "Veuillez renseigner une adresse mail valide.";
    _ErrorNumeroTelephoneInvalide: string = "Veuillez renseigner un numéro de téléphone valide.";
    _ErrorNumeroFaxInvalide: string = "Veuillez renseigner un numéro de fax valide.";
    _ErrorTexteTropLong200: string = "Veuillez renseigner un texte ne dépassant pas la limite autorisée (200 caractères).";
    _ErrorTexteTropLong2000: string = "Veuillez renseigner un texte ne dépassant pas la limite autorisée (2000 caractères).";
    _ErrorDateInvalide: string = "Veuillez renseigner une date valide.";
    _ErrorNumeroInvalide: string = "Veuillez renseigner un numéro valide.";
    _ErrorNumero10ChiffresInvalide: string = "Veuillez renseigner un numéro de 10 chiffres.";
    _ErrorNombreNonEntier: string = "Veuillez renseigner un nombre entier positif.";
    _ErrorNombre: string = "Le nombre renseigné doit être positif et inférieur ou égal à 999999,9 m.";
    _ErrorNombreNegatifInvalide: string = "Veuillez renseigner un nombre valide.";

    // Contournement pour le passage en mode modif. quand il y a des dates, et les contraintes liées 
    // aux dates, au datepicker et à Knockout... pas trouvé mieux pour l'instant
    _firstSwitchToModifyPlanifTravaux: boolean;

    _isEtude() {
        return this._isEtudeDevis() || this._isEtudeTechnique();
    }

    _isEtudeDevis() {
        return this._TypeGeneralPrestation() === TypeGeneralPrestation.EtudeDevis;
    }

    _isEtudeTechnique() {
        return this._TypeGeneralPrestation() === TypeGeneralPrestation.EtudeTechnique;
    }

    _isTravaux() {
        return this._isBranchement() || this._isTerrassement() || this._isBranchementTerrassement();
    }

    _isBranchement() {
        return this._TypeGeneralPrestation() === TypeGeneralPrestation.Branchement;
    }

    _isTerrassement() {
        return this._TypeGeneralPrestation() === TypeGeneralPrestation.Terrassement;
    }

    _isBranchementTerrassement() {
        return this._TypeGeneralPrestation() === TypeGeneralPrestation.BranchementTerrassement;
    }

    // Avetissement si on cherche à quitter la page alors que des modifications sont en cours
    // En même temps on set PendingModification pour les clics sans rechargement
    _enableChangeAlert() {
        // On ne le fait que si on est bien en ModeModificationGlobal, car on peut aussi être en train de reinit les valeurs à celles d'origine --> pas d'alerte
        if (this._ModeModificationGlobal()) {
            window.onbeforeunload = () => "";

            this._PendingModification(true);
        }

        return true;
    }

    // Disable de l'avetissement après succès à l'enregistrement
    _disableChangeAlert() {
        window.onbeforeunload = null;

        this._PendingModification(false);
    }

    // Juste pour le texte ("Non renseigné", ou autre dans certains cas)
    _getValueOrDefault(value: string, defaultValue?: string) {
        if (value != null && value.length > 0) {
            return value;
        } else {
            return defaultValue ? defaultValue : this._ValueNonRenseigne();
        }
    }

    _getValueOrDefaultForNumber(value: number) {
        if (!this._numberIsNull(value)) {
            return value.toString();
        } else {
            return this._ValueNonRenseigne();
        }
    }

    // Retourne le nb de caractères que l'on peut encore saisir pour un commentaire donné
    _getNbCarsRestants(comment: string) {
        let nbCars = this._AdjustedMaxCommentLength();

        if (comment != null && comment != undefined) {
            nbCars = nbCars - comment.length;
        }

        return nbCars >= 0 ? nbCars : 0;
    }

    // Retourne l'adresse mail que l'on va afficher pour un caractère donné
    _getCommentLastMail(comment: string, lastMail: string) {
        const commentLastMail = lastMail || 'inconnu';
        return comment ? `(par ${commentLastMail})` : "";
    }

    _isValidComment(comment: string) {
        return comment == null || comment == undefined || comment.length <= this._AdjustedMaxCommentLength();
    }

    _numberIsNull(value: number) {
        return value === null;
    }

    // Dimanches non sélectionnables
    _noSunday(date) {
        const day = date.getDay();
        return [(day !== 0), ''];
    }

    // On ne définit pas les valeurs grisées dans le DOM mais seulement au "beforeShow" pour laisser affichée la valeur d'origine
    // avec maxDate
    _allowOldValueMax() {
        $(this).datepicker("option", "maxDate", new Date());
    }
    // avec minDate
    _allowOldValueMin() {
        $(this).datepicker("option", "minDate", new Date());
    }

    // Au click, la valeur minimum s'affiche automatiquement (et remplace la valeur d'origine le cas échéant),
    // mais il faut forcer son affectation côté Knockout... pas trouvé mieux
    _setMinOnClick(date) {
        if (this._firstSwitchToModifyPlanifTravaux) {
            const today = moment().startOf('day');

            if (moment(date(), 'DD/MM/YYYY') < today) {
                date(today.format('DD/MM/YYYY'));
            }
        }
    }

    // Au click, la valeur maximum s'affiche automatiquement (et remplace la valeur d'origine le cas échéant),
    // mais il faut forcer son affectation côté Knockout... pas trouvé mieux
    _setMaxOnClick(date) {
        if (this._firstSwitchToModifyPlanifTravaux) {
            const today = moment().startOf('day');

            if (moment(date(), 'DD/MM/YYYY') > today) {
                date(today.format('DD/MM/YYYY'));
            }
        }
    }

    _switchToModify() {
        if (this._CanModify()) {
            this._ModeModificationGlobal(true);

            this._firstSwitchToModifyPlanifTravaux = true;

            this._scrollToBandeauPrestation(this._PrestationLinkWithHash());
        }
    }

    _save(url: string, anchor: string, callback, source) {
        $.ajax({
            url: url,
            type: 'POST',
            data: $.fn.putValidationToken({
                viewModel: ko.toJSON(this, Utils.cleanPrivateProps)
            })
        }).done((data: any) => {
            if (data.Status) {
                $.fn.sticky(data.Message, { cssClass: 'green' });

                this._PendingSave(true);

                // Modification enregistrée, plus d'alerte
                window.onbeforeunload = null;
                this._PendingModification(false);

                this._updateDatas();
            } else {
                $.fn.sticky(data.Error, { cssClass: 'orange' });
            }

            // On rappelle la fonction setNewStateAfterSave propre à l'objet source pour mettre à jour ce qu'il faut
            if (callback !== null) {
                callback(source);
            }

            this._scrollToBandeauPrestation(anchor);
        }).fail(() =>
        {
            $.fn.sticky("Une erreur est survenue lors de la sauvegarde, veuillez vérifier que vous n'avez pas saisi de caractères interdits.", { cssClass: 'red' });
        });
    }

    _scrollToBandeauPrestation(anchor: string) {
        $(document).scrollTop($(anchor).prev('.bandeau-prestation').offset().top);
    }
}
// ReSharper restore InconsistentNaming
