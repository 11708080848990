﻿export class MesurePrevention {
    Libelle = ko.observable<string>('').extend({ required: "" });
    ResponsableMiseEnOeuvre = ko.observable<string>('').extend({ required: "" });
    IsTemporaire = ko.observable<Boolean>();
    Id = ko.observable<number>(0);
    FkIdRisque = ko.observable<number>(0);

    constructor();
    constructor(data: MesurePrevention, fromForm: Boolean);
    constructor(data?: any, fromForm?: Boolean) {
        if (data) {
            if (fromForm) {
                this.Libelle(data.Libelle());
                this.ResponsableMiseEnOeuvre(data.ResponsableMiseEnOeuvre());
                this.IsTemporaire(data.IsTemporaire());
                this.FkIdRisque(data.FkIdRisque());
                this.Id(data.Id());
            } else {
                this.Libelle(data.Libelle);
                this.ResponsableMiseEnOeuvre(data.ResponsableMiseEnOeuvre);
                this.IsTemporaire(data.IsTemporaire);
                this.FkIdRisque(data.FkIdRisque);
                this.Id(data.Id);
            }
        } else {
            this.IsTemporaire(false); //Ca sera pour les vides donc un ajout et non une validation
        }
    }

    isValid = ko.computed({
        owner: this, pure: true, read: () => {
            if (!this.Libelle() || this.Libelle.hasError() || !this.ResponsableMiseEnOeuvre() || this.ResponsableMiseEnOeuvre.hasError()) {
                return false;
            }
            return true;
        }
    });

    valid() {
        if (this.isValid()) {
            this.IsTemporaire(false);
        }
    }

    _devalid(mesure: MesurePrevention) {
        mesure.IsTemporaire(true);
    }

    raz() {
        this.Libelle('');
        this.ResponsableMiseEnOeuvre('');
        this.Id(0);
        this.FkIdRisque(0);
    }
}
