﻿export class SousCategorieRisque {
    Id = ko.observable<number>();
    Libelle = ko.observable<string>();
    FkIdTypeRisque = ko.observable<number>();
    WantToBeShown = ko.observable<boolean>(false);

    constructor(data) {
        this.Id(data.Id);
        this.Libelle(data.Libelle);
        this.FkIdTypeRisque(data.FkIdTypeRisque);
    }

    Show() {
        if (this.WantToBeShown()) {
            this.WantToBeShown(false);
        } else {
            this.WantToBeShown(true);
        }
    }
}
