﻿export class MiniFile {
    TypeFichier = ko.observable<number>();
    Id = ko.observable<number>();
    NomFichier = ko.observable<string>();
    Url = ko.observable<string>();
    Ext = ko.pureComputed(() => {

        const patt1 = /\.[0-9a-z]+$/i;
        const filename = this.NomFichier();
        if (filename == null) {
            return "";
        }

        return filename.match(patt1);
    });

    constructor(data) {
        this.Id(data.Id);
        this.NomFichier(data.Value);
        this.Url(data.ValueURL);
        this.TypeFichier(data.FkIdTypeDocument);
    }

}
