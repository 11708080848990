﻿import {Options} from "../Options";
import {GdaTools} from "../gda.tools";
export class InterventionViewModel {
    Bexs: KnockoutObservableArray<Options>;
    Instructions: KnockoutComputed<Options[]>;
    DR: KnockoutObservable<string>;
    idDR: KnockoutObservable<number>;
    Domaine: KnockoutObservable<string>;
    FkIdCodeInsee: KnockoutObservable<number>;
    FkIdIntervenant: KnockoutObservable<string>;
    FkIdInstruction: KnockoutObservable<number>;
    FkIdBEX: KnockoutObservable<number>;
    FkIdTypeAffaireIntervention: KnockoutObservable<number>;
    ListeTypeAffaireIntervention: KnockoutObservableArray<Options>;
    ListeInstructionNonObligatoireCodeGdo: KnockoutObservableArray<string>;
    ListeInstructionNonObligatoireDomaine: KnockoutObservableArray<string>;

    Adresse: KnockoutObservable<string>;
    DateDebutTravaux: KnockoutObservable<moment.Moment>;
    DateFinTravaux: KnockoutObservable<moment.Moment>;
    DateAffOuvertureFouille: KnockoutObservable<moment.Moment>;
    DateOuvertureFouilleOptions: KnockoutComputed<any>;
    DateFinOptions: KnockoutComputed<any>;
    DateDebutOptions: KnockoutComputed<any>;
    DateFinDisabled: KnockoutComputed<boolean>;
    DatePrevFinAccesDisabled: KnockoutComputed<boolean>;
    DatePrevDebutAccesOptions: KnockoutComputed<any>;
    DatePrevFinAccesOptions: KnockoutComputed<any>;
    DatePrevDebutAcces: KnockoutObservable<moment.Moment>;
    DatePrevFinAcces: KnockoutObservable<moment.Moment>;
    Commune: KnockoutObservable<any>;
    Communes: KnockoutObservableArray<any>;
    CommuneLibelle: KnockoutObservable<string>;
    CommuneId: KnockoutObservable<string>;
    cancelCodeInsee: KnockoutObservable<string>;

    AccesGdoPoste: KnockoutObservable<string>;
    AccesNomPoste: KnockoutObservable<string>;

    IntervenantsTravaux: KnockoutObservableArray<any>;
    Intervenant: KnockoutObservable<any>;
    NomIntervenant: KnockoutObservable<string>;
    IdIntervenant: KnockoutObservable<string>;

    Affaire: KnockoutObservable<any>;
    Affaires: KnockoutObservableArray<any>;
    FkIdAffaire: KnockoutObservable<number>;
    AffaireLibelle: KnockoutObservable<string>;
    IconStyle: KnockoutObservable<string>;
    StyleComputer: KnockoutComputed<string>;
    IsLoadElement: boolean = false;

    BlocExterneDataDisabled: KnockoutObservable<boolean>;
    BlocAccesDataDisabled: KnockoutObservable<boolean>;
    BlocCommentDisabled: KnockoutObservable<boolean>;
    IsInterne: KnockoutObservable<boolean>;

    IdBexOrigin: KnockoutObservable<number>;

    FkIdDomaine: KnockoutObservable<number>;
    Domaines: KnockoutComputed<Options[]>;
    CurrentIntervenantTravaux: KnockoutObservable<any>;

    protected gdaTools: GdaTools;

    isFirst: boolean = true;

    constructor(model: any) {
        this.IdBexOrigin = ko.observable<number>(model.Intervention.FkIdBEX);
        this.BlocExterneDataDisabled = ko.observable<boolean>(model.BlocExterneDataDisabled);
        this.BlocAccesDataDisabled = ko.observable<boolean>(model.BlocAccesDataDisabled);
        this.BlocCommentDisabled = ko.observable<boolean>(model.BlocCommentDisabled);
        this.IsInterne = ko.observable<boolean>(model.IsInterne);

        this.Bexs = ko.observableArray<Options>([]);
        this.Affaires = ko.observableArray<any>();

        this.CommuneLibelle = ko.observable<string>();
        this.CommuneId = ko.observable<string>();
        this.Communes = ko.observableArray(model.Communes);

        this.Intervenant = ko.observable<any>();
        this.NomIntervenant = ko.observable<string>(model.LibelleIntervenant);
        this.IdIntervenant = ko.observable<string>(model.Intervention.FkIdIntervenant);

        this.IntervenantsTravaux    = ko.observableArray<any>(model.IntervenantsTravaux);

        this.FkIdCodeInsee = ko.observable<number>(model.Intervention.FkIdCodeInsee);
        this.FkIdInstruction = ko.observable<number>();
        this.FkIdBEX = ko.observable<number>();

        this.ListeTypeAffaireIntervention = ko.observableArray<Options>(model.ListeTypeAffaireIntervention);
        this.FkIdTypeAffaireIntervention = ko.observable<number>(model.Intervention.FkIdTypeAffaireIntervention);
        this.ListeInstructionNonObligatoireCodeGdo = ko.observableArray<string>(model.ListeInstructionNonObligatoireCodeGdo);
        this.ListeInstructionNonObligatoireDomaine = ko.observableArray<string>(model.ListeInstructionNonObligatoireDomaine);

        this.idDR = ko.observable<number>();
        this.IconStyle = ko.observable<string>();

        this.FkIdIntervenant = ko.observable<string>();
        this.FkIdAffaire = ko.observable<number>(model.Intervention.FkIdAffaire);

        this.Domaine = ko.observable<string>();
        this.DR = ko.observable<string>(model.LibelleDR);
        this.Commune = ko.observable<any>();
        this.AccesNomPoste = ko.observable<string>(model.Intervention.AccesNomPoste);
        this.AccesGdoPoste = ko.observable<string>(model.Intervention.AccesGdoPoste);

        this.Adresse = ko.observable<string>(model.Intervention.AdresseTrav);
        this.DateDebutTravaux = ko.observable<moment.Moment>(this.getMomentDate(model.Intervention.DateAffDebutTrav));
        this.DateFinTravaux = ko.observable<moment.Moment>(this.getMomentDate(model.Intervention.DateAffFinTrav));
        this.DateAffOuvertureFouille = ko.observable<moment.Moment>(this.getMomentDate(model.Intervention.DateAffOuvertureFouille));
        this.DatePrevDebutAcces = ko.observable<moment.Moment>(this.getMomentDate(model.Intervention.DatePrevDebutAcces));
        this.DatePrevFinAcces = ko.observable<moment.Moment>(this.getMomentDate(model.Intervention.DatePrevFinAcces));

        this.AffaireLibelle = ko.observable<string>(model.Intervention.NumeroIEP);
        this.Affaire = ko.observable<any>(null);
        this.StyleComputer = ko.computed<string>({
            read: () => {
                if (this.FkIdInstruction() && this.FkIdInstruction() > 0 && this.DatePrevDebutAcces() && this.DatePrevFinAcces()) {
                    const payload = {
                        __RequestVerificationToken: $.fn.findValidationToken(),
                        idInstructionEntreprise: this.FkIdInstruction(),
                        datePrevDebut: this.getMomentDate(this.DatePrevDebutAcces()).format("MM/DD/YYYY hh:mm"), //bien mettre les lettres en majuscule dans le format
                        datePrevFin: this.getMomentDate(this.DatePrevFinAcces()).format("MM/DD/YYYY hh:mm")
                    };

                    $.ajax({
                        url: '/GDA/Interventions/CheckInstruction',
                        data: payload
                    }).done((result: any) => {
                        let vhtml: string | Object;

                        if (result == null) {
                            this.IconStyle('fa fa-check green');
                            vhtml = "";

                        } else {
                            this.IconStyle('fa fa-times red');
                            $("#tooltipCheckInstruction").tooltip({title: result});
                            vhtml = result;
                        }

                        $("#CheckInstruction").html("<span title=\"" + vhtml + "\"><span>");
                    });
                }
                this.IconStyle(null);
                return null;
            }
        });

        this.FkIdDomaine = ko.observable<number>();

        this.Instructions = ko.computed<Options[]>(() => {
            if (this.idDR() != null && this.FkIdIntervenant() != null) {

                if (this.IntervenantsTravaux() != null && this.IntervenantsTravaux().length > 0) {
                    const intervenant = this.IntervenantsTravaux().filter(i => i.id === this.FkIdIntervenant())[0];
                    if (intervenant != null) {
                        if (intervenant.instructions != null && intervenant.instructions.length > 0) {

                            const result =
                                intervenant.instructions.filter(i => i.Value.Value == this.idDR()).map(i => new Options(i.Libelle, i.Value.Key));

                            if (result.length > 1) {
                                result.push(new Options("", "-1"));
                            }

                            result.sort(function (i1, i2) {
                                return i1.Libelle > i2.Libelle ? 1 : i1.Libelle < i2.Libelle ? -1 : 0;
                            });
                            return result;
                        }
                    }
                } else {
                    return [];
                }
            }
            return [];
        });


        this.CurrentIntervenantTravaux = ko.computed<any>(() => {
            if (this.idDR() != null && this.FkIdIntervenant() != null) {
                if (this.IntervenantsTravaux() != null && this.IntervenantsTravaux().length > 0) {
                    const intervenant = this.IntervenantsTravaux().filter(i => i.id == this.FkIdIntervenant())[0];
                    if (intervenant != null) {
                        return intervenant;
                    }
                }
            }
            return null;
        });

        this.Domaines = ko.computed<Options[]>(() => {
            const interv = this.CurrentIntervenantTravaux();
            if (interv != null) {
                let list = [];
                const instrInfo = interv.InstructionsInfo.filter(i => i.Id === this.FkIdInstruction());
                if (instrInfo.length > 0) {
                    const domaineInstruc = instrInfo[0].Dom;

                    if (domaineInstruc & 1) {
                        list.push(new Options("HTA", "1"));
                    }
                    if (domaineInstruc & 2) {
                        list.push(new Options("BT", "2"));
                    }
                    if (domaineInstruc & 4) {
                        list.push(new Options("PS", "4"));
                    }
                    if (list.length > 1) {
                        list.unshift(new Options("", "0"));
                    }

                }
                return list;
            }
            return [];
        });

        // Disabled
        this.DateFinDisabled = ko.computed<boolean>(() => {
            const r = this.DateDebutTravaux() == null || this.DateDebutTravaux() == undefined || this.BlocExterneDataDisabled();
            return r;
        });
        this.DatePrevFinAccesDisabled = ko.computed<boolean>(() => {
            return this.DatePrevDebutAcces() == null || this.DatePrevDebutAcces() == undefined || this.BlocExterneDataDisabled();
        });
        // Date Options
        this.DateDebutOptions = ko.computed<any>(() => {
            return {
                locale: "fr",
                format: "DD/MM/YYYY",
                useCurrent: false
            }
        });
        this.DateFinOptions = ko.computed<any>(() => {
            if (this.DateDebutTravaux() == null) {
                return {
                    locale: "fr",
                    format: "DD/MM/YYYY",
                    useCurrent: false
                }
            } else {
                return {
                    minDate: this.DateDebutTravaux(),
                    locale: "fr",
                    format: "DD/MM/YYYY",
                    useCurrent: false

                }
            }
        });
        this.DateOuvertureFouilleOptions = ko.computed<any>(() => {
            return {
                locale: "fr",
                format: "DD/MM/YYYY",
                useCurrent: false
            }
        });
        this.DatePrevDebutAccesOptions = ko.computed<any>(() => {
            return {
                minDate: moment(new Date().setHours(0, 0, 0, 0)),
                locale: "fr",
                format: 'DD/MM/YYYY HH:mm',
                useCurrent: false,
            }
        });
        this.DatePrevFinAccesOptions = ko.computed<any>(() => {
            return {
                minDate: this.DatePrevDebutAcces() != null ? moment(new Date(this.DatePrevDebutAcces().toString()).setHours(0, 0, 0, 0)) : moment(new Date().setHours(0, 0, 0, 0)),
                locale: "fr",
                format: 'DD/MM/YYYY HH:mm',
                useCurrent: false,
            }
        });

        // subscribe
        this.CommuneLibelle.subscribe( (newValue: string) => {
            if(this.isFirst)
            {
                this.Commune(this.Communes()[0]);
                this.FkIdBEX(model.Intervention.FkIdBEX);
                this.FkIdInstruction(model.Intervention.FkIdInstructionEntreprise)
                this.isFirst = false;
            }
            else if (newValue != null && newValue != "" && !this.Commune() && newValue.length > 2)
            {
                $.ajax({
                    url: "/GDA/Interventions/AutocompleteCommune",
                    data: {
                        term: newValue,
                        fkIdBex: this.FkIdBEX,
                    },
                }).done((resp) => {
                    this.Communes(resp);
                });

            }
        });

        this.FkIdBEX.subscribe((newValue: number) => {
            if (newValue != null && newValue != -1) {
                if (this.Commune() != null) {
                    const selectedBex = this.Commune().Items.filter(i => i.IdBex == newValue)[0];

                    if (selectedBex != null) {
                        this.DR(selectedBex.LibelleDR);
                        this.idDR(selectedBex.IdDR);
                        this.Domaine(selectedBex.LibelleDomain);
                    }
                }
            } else {
                this.DR(null);
                this.idDR(-1);
                this.Domaine(null);
            }

            this.hideShowButton(newValue);
        });

        this.FkIdInstruction.subscribe((newValue: number) => {
            this.hideShowButton(this.FkIdBEX());
            this.setLabelGdoPoste();
            this.setLabelDomaine();
        });

        this.Affaire.subscribe((newValue: any) => {
            if (newValue != null) {
                this.FkIdAffaire(newValue.Id);
                this.Adresse(newValue.Adresse);
                this.Communes(newValue.Communes);
                this.CommuneLibelle((newValue.Commune).charAt(0).toUpperCase() + (newValue.Commune).slice(1).toLowerCase());
                this.AccesNomPoste(newValue.NomPoste);
                this.AccesGdoPoste(newValue.GdoPoste);
                $('#Intervention_AccesNomPoste').valid();

                this.DateDebutTravaux(this.getMomentDate(newValue.DateDebutTravaux));
                this.DateFinTravaux(this.getMomentDate(newValue.DateFinTravaux));

            } else {
                this.FkIdAffaire(null);
                if (this.AffaireLibelle() != null) {
                    this.AffaireLibelle(this.AffaireLibelle());
                }
            }
        });
        this.Intervenant.subscribe((newValue: any) => {
            if (newValue != null) {
                this.FkIdIntervenant(newValue.id);
                this.NomIntervenant(newValue.value);
                this.autoCompleteSet("Intervenant", newValue.value);
            } else {
                this.NomIntervenant(null);
                this.FkIdIntervenant(null);
                this.autoCompleteSet("Intervenant", null);
            }
        });
        this.NomIntervenant.subscribe((newValue: string) => {
            if (newValue != null && newValue != "") {
                const hiddenFieldIntervenant = $("input[name='Intervention.FkIdIntervenant']");
                if (hiddenFieldIntervenant.val() != undefined && hiddenFieldIntervenant.val() != null) {
                    hiddenFieldIntervenant.valid();
                }
            }
        });
        this.Commune.subscribe((newValue: any) => {
            if (newValue != null) {
                this.FkIdCodeInsee(newValue.id);
                const result = this.Commune().Items.map(i => new Options(i.Libelle, i.IdBex.toString()));

                if (result.length > 1) {
                    result.push(new Options("", "-1"));

                    result.sort(function (i1, i2) {
                        return i1.Libelle > i2.Libelle ? 1 : i1.Libelle < i2.Libelle ? -1 : 0;
                    });
                }

                this.Bexs(result);
                if (this.Bexs().length === 1) {
                    const id = parseInt(this.Bexs()[0].Value);
                    this.FkIdBEX(id);
                } else {
                    this.FkIdBEX(-1);
                }

                const hiddenFieldCodeInsee = $("input[name='Intervention.FkIdCodeInsee']");
                if (hiddenFieldCodeInsee.val() != undefined && hiddenFieldCodeInsee.val() != null) {
                    hiddenFieldCodeInsee.valid();
                }

                const displayValue = newValue.CodeInsee + " (" + newValue.id + ")";
                this.autoCompleteSet("CodeInsee", displayValue);
            } else {
                this.autoCompleteSet("CodeInsee");
                this.CommuneLibelle(null);
                this.FkIdCodeInsee(null);
                this.Bexs([]);
                this.FkIdBEX(-1);
                this.idDR(-1);
                this.DR(null);
                this.Domaine(null);
            }
        });
        this.CommuneId.subscribe((newValueId: string) => {
            if (newValueId != null && newValueId != "") {
                const commune = this.Communes().filter(c => c.id == newValueId)[0];
                if (commune != null) {
                    this.CommuneLibelle(commune.CodeInsee);
                }
            }
            this.hideShowButton(this.FkIdBEX());
        });

         this.initCommuneId(model.Intervention.FkIdCodeInsee);
         this.initIntervenantId(model.Intervention.FkIdIntervenant);
         this.FkIdBEX(model.Intervention.FkIdBEX);
         this.FkIdInstruction(model.Intervention.FkIdInstructionEntreprise);
         this.FkIdDomaine(model.Intervention.FkIdDomaine);

        this.gdaTools = new GdaTools();
        this.gdaTools.showBloc("EditIntervention");
    }

    // init methodes de chargement
    initCommuneId(codeInsee: string): void {
        if (codeInsee != null && codeInsee != "") {
            const commune = this.Communes().filter(c => c.id == codeInsee)[0];
            if (commune != null) {
                this.CommuneLibelle(commune.CodeInsee);
            }
        }
    }

    initIntervenantId(fkIdIntervenant: string): void {
        const intervenant = this.IntervenantsTravaux().filter(i => i.id == fkIdIntervenant)[0];
        if (intervenant != null) {
            this.Intervenant(intervenant);
        }
    }

    setLabelGdoPoste(): void {
        let isGdo = true;
        let label = "";
        const intruc = this.Instructions().filter(i => i.Value == String(this.FkIdInstruction()))[0];
        if (intruc != null) {
            if (this.ListeInstructionNonObligatoireCodeGdo().filter(_ => intruc.Libelle.indexOf(_) === 0).length > 0) {
                isGdo = false;
            }
        }
        const idGdoPoste = "#Intervention_AccesGdoPoste";
        const idLabelGdoPoste = "#labelAccesGdoPoste";
        if (isGdo) {
            label = "GDO Poste *";
            if ($(idLabelGdoPoste).text() !== label) {
                $(idGdoPoste).rules("add",
                    {
                        required: true,
                        messages: {required: "Le GDO poste est obligatoire"}
                    }
                );
            }
        } else {
            label = "GDO Poste";
            if ($(idLabelGdoPoste).text() !== label) {
                $(idGdoPoste + '-error').text('');
                $(idGdoPoste).rules('remove');
            }

        }
        $(idLabelGdoPoste).text(label);
    }

    hideShowButton(newValue: number): void {
        // Si c'est charge on fait le traitement
        if (this.IsLoadElement) {
            if ($("#intervention-form").valid()) {
                if (this.IsInterne()) {
                    if (newValue == this.IdBexOrigin()) {
                        $("#btnAccepter").show();
                        $("#btnRefuser").show();
                        $("#btnTransmettre").hide();
                    } else {
                        $("#btnAccepter").hide();
                        $("#btnRefuser").hide();
                        $("#btnTransmettre").show();
                    }
                } else {
                    $("#btnAnnuler").show();
                }
            } else {
                if (this.IsInterne()) {
                    $("#btnAccepter").hide();
                    $("#btnRefuser").hide();
                    $("#btnTransmettre").hide();
                } else {
                    $("#btnAnnuler").hide();
                }
            }
        }
    }

    // domaines
    setLabelDomaine(): void {
        let isMandatory = true;
        let label = "";
        const intruc = this.Instructions().filter(i => i.Value == String(this.FkIdInstruction()))[0];
        if (intruc != null) {
            if (this.ListeInstructionNonObligatoireDomaine().filter(_ => intruc.Libelle.indexOf(_) === 0).length > 0) {
                isMandatory = false;
            }
        }
        const id = "#Intervention_Domaine";
        const idLabel = "#labelAccesDomaine";
        if (isMandatory) {
            label = "Domaine *";
            if ($(idLabel).text() !== label) {
                $(id).rules("add",
                    {
                        required: true,
                        messages: {required: "Le GDO poste est obligatoire"}
                    }
                );
            }
        } else {
            label = "Domaine";
            if ($(idLabel).text() !== label) {
                $(id + '-error').text('');
                $(id).rules('remove');
            }
        }
        $(idLabel).text(label);
    }

    // autoComplete
    autoCompleteSet(codePicker: string, displayLabel: string = null): void {
        if (displayLabel != null) {
            $(`.dl-search-${codePicker}`).hide();
            $(`.dl-gr-${codePicker}`).show();
            $(`.dl-tx-${codePicker}`).val(displayLabel);
        } else {
            $(`.dl-search-${codePicker}`).val("");
            $(`.dl-search-${codePicker}`).show();
            $(`.dl-gr-${codePicker}`).hide();
        }
    }

    autoCompleteCancel(codePicker: string): void {
        if (this.IsLoadElement) {
            if (codePicker == "CodeInsee") {
                this.Commune(null);
            }
            if (codePicker == "Intervenant") {
                this.Intervenant(null);
            }
        }
    }

    getAffaireList(searchTerm: string, callback: any): void {
        $.ajax({
            url: '/GDA/Interventions/InterventionAffaireList',
            data: {
                term: searchTerm
            }
        }).done(callback);
    }

    getMomentDate(date: any): any {
        let res = null;
        if (date != null) {
            res = moment(new Date(date));
            if (res.year() < 1000 || res.year() > 3000) return null;
        }
        return res;
    }

    triggerSelectNumeroIep() {
        let affaires = this.Affaires().filter(_ => _.label == this.AffaireLibelle());
        if (affaires?.length == 1) /* on ne sait pas quoi faire si on a des doublons */
        {
            this.Affaire(affaires[0]);
        }
    }
}
