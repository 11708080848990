﻿interface JQuery {
    dataTable(): any;
    dataTable(config: any): any;
    row(): any;
}

import { InstructionReferentielViewModel } from "./InstructionsReferentielViewModel";

export class InstructionsReferentielEditionViewModel extends InstructionReferentielViewModel {
    CanEditAccessOuvrage: KnockoutComputed<boolean>;
    CreateVersionVisible: KnockoutComputed<boolean>;
    SaveVisible: KnockoutComputed<boolean>;
    SelectedInstructionId: KnockoutObservable<number>;
    SelectedVersion: KnockoutObservable<number>;
    Instruction: any;

    constructor(model: any) {
        super(model);
        this.IsDuplicateOnNationalPerimeter = ko.computed<boolean>(() => {
            return false;
        });
        this.Instructions = model.Instructions;
        this.BlocInstructionDisabled(true);
        this.Referentiel = ko.observable<string>(model.Perimeter);
        this.SelectedTypeOuvrage = ko.observable<number>(model.TypeOuvrage);
        this.SelectedTypeInstruction(model.TypeInstruction.toString());
        this.NumeroCourtFirstDigit = ko.observable<number>(model.FirstDigit);
        this.NumeroCourtLastDigit = ko.observable<number>(model.LastDigit);
        this.FkIdInstructionNationale(model.FkIdInstructionNationale);
        this.SelectedInstructionId = ko.observable<number>();
        this.DateApplication(model.DateApplication);
        this.BlockVersionDisabled = ko.computed<boolean>(() => {
            if (this.SelectedInstructionId() != null && this.SelectedInstructionId() != 0) {
                const selectedInstruction = this.Instructions.filter(ins => ins.Id == this.SelectedInstructionId())[0];
                if (selectedInstruction != null && selectedInstruction.Statut != 1)
                    return true;
                return false;
            }
            return false;
        });
        this.CanEditAccessOuvrage = ko.computed<boolean>(() => {
            return !this.IsAccesPermanentEnabled() || this.BlockVersionDisabled();
        });
        this.CreateVersionVisible = ko.computed<boolean>(() => {
            const versionArray = this.Instructions.map(instruction => instruction.Version);
            const maxVersion = Math.max.apply(null, versionArray);
            const maxVersionInstruction = this.Instructions.filter(instruction => instruction.Version == maxVersion)[0];
            if (maxVersionInstruction != null && maxVersionInstruction.Statut != 1) {
                return true;
            }
            return false;
        })
        this.SaveVisible = ko.computed<boolean>(() => {
            if (this.SelectedInstructionId() !== null && this.SelectedInstructionId() !== undefined) {
                const selectedInstruction = this.Instructions.filter(ins => ins.Id == this.SelectedInstructionId())[0];
                if (selectedInstruction !== null && (selectedInstruction.Statut === 1))
                    return true;
                return false;
            }
            return false;
        });
        this.SelectedInstructionId.subscribe((newValue: number) => {
            if (newValue != null) {
                $('tr#' + newValue.toString()).addClass('selected');
                const instruction = this.Instructions.filter(ins => ins.Id == newValue)[0];
                if (instruction != null) {
                    const dateApplicationNumbers = instruction.DateApplication.match(/\d+/);
                    const dateApplication = new Date(dateApplicationNumbers);
                    if (instruction.Statut == 1) {
                        this.MinDate(this.MinDate().valueOf() > dateApplication.valueOf() ? this.MinDate() : dateApplication);
                    }
                }
            }
        });
        this.SelectedVersion = ko.observable<number>();
        this.SelectedVersion.subscribe((newValue: number) => {
            if (newValue != 0) {
                const selectedInstructionId = this.Instructions.filter(ins => ins.Version == newValue)[0].Id;
                this.SelectedInstructionId(selectedInstructionId);
            }
        });
        this.SelectedVersion(model.SelectedVersion);

        this.Instruction = ko.computed<any>(() => {
            if (this.SelectedInstructionId() != null && this.SelectedInstructionId() != 0) {
                const instruction = this.Instructions.filter(ins => ins.Id == this.SelectedInstructionId())[0];
                this.SetFields(instruction);
                return instruction;
            }
            return null;
        });
    }

    SetFields(instruction: any): void {
        this.Titre(instruction.Titre);
        const dateApplicationNumbers = instruction.DateApplication.toString().match(/\d+/)[0] * 1;
        const dateApplication = new Date(dateApplicationNumbers);
        this.MinDate(dateApplication);
        this.DateApplication(dateApplication);
        if (instruction.Statut == 1) {
            const tomorrow: Date = new Date(new Date().setDate(new Date().getDate() + 1));
            this.MinDate(dateApplication.valueOf() > tomorrow.valueOf() ? dateApplication : tomorrow);
        }
        this.Version(instruction.Version);
        this.HasTravauxUrgents(instruction.HasTravauxUrgents);
        this.AccesPermanent(instruction.AccesPermanent);
        this.FkIdInstructionNationale(instruction.FkIdInstructionNationale);
        this.DomaineHta(instruction.DomaineHta);
        this.DomaineBt(instruction.DomaineBt);
        this.DomainePs(instruction.DomainePs);
    }
}
