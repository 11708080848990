﻿// Hestion d'une ligne RC/FSS
export class RcFssCommandeViewModel {
    isIntern: boolean;

    id = ko.observable<number>(null);
    pgiIdRc = ko.observable<number>(null);
    statusRc = ko.observable<number>();
    created = ko.observable<string>();
    validated = ko.observable<string>(null);

    idFss = ko.observable<number>(null);
    pgiIdFss = ko.observable<string>(null);
    statusFss = ko.observable<number>();
    montantFss = ko.observable<string>(null);
    createdFss = ko.observable<string>();
    validatedFss = ko.observable<string>(null);

    rcFromPgi = ko.observable<boolean>();
    fssFromPgi = ko.observable<boolean>(false);
    fssWithoutRc = ko.observable<boolean>();

    hasMultipleFss = ko.observable<boolean>(false);

    hasRcExcelFile = ko.observable<boolean>(false);
    hasRcPdfFile = ko.observable<boolean>(false);

    hasFssAttachedFile = ko.observable<boolean>();
    hasFssFiles = ko.observable<boolean>(false);

    isManualRcFss = ko.observable<boolean>(false);
    attachRcFilename = ko.observable<string>();
    attachFssFilename = ko.observable<string>();

    constructor(data: any, isFss: boolean, isIntern: boolean, manualPjs: Array<any>) {
        this.isIntern = isIntern;

        // Attachement manuel d'un RC ou FSS
        if (data === null) {
            this.rcFromPgi(false);
            this.isManualRcFss(true);

        } else {
            if (!isFss) {
                this.id(data.Id);
                this.pgiIdRc(data.PGIIdRC);
                this.statusRc(data.TypeStatutAffaireCommandeRC);
                this.created(new Date(data.Created).toLocaleDateString());
                this.rcFromPgi(data.PGIIdRC !== null);
                this.fssWithoutRc(!this.rcFromPgi());

                this.hasRcExcelFile(data.ExcelOGEDId !== null);
                this.hasRcPdfFile(data.PdfOGEDId !== null);

                if (data.Validated !== null) {
                    this.validated(new Date(data.Validated).toLocaleDateString());
                }

                if (this.statusRc() >= 4 && data.AffaireCommandeFSS.length > 0) {
                    let montant = 0;
                    let isCreated = true;
                    let isValidated = true;
                    let allHasFile = true;
                    let allFssFromPgi = true;

                    for (let i = 0; i < data.AffaireCommandeFSS.length; i++) {
                        montant += data.AffaireCommandeFSS[i].MontantEnCents;
                        isCreated = isCreated && data.AffaireCommandeFSS[i].TypeStatutAffaireCommandeFSS === 1;
                        isValidated =
                            isValidated && data.AffaireCommandeFSS[i].TypeStatutAffaireCommandeFSS === 3;
                        allHasFile = allHasFile && (data.AffaireCommandeFSS[i].PdfOGEDId !== null);
                        allFssFromPgi = allFssFromPgi && (data.AffaireCommandeFSS[i].PGIIdFSS !== null);

                        if (this.pgiIdFss() === null) {
                            this.pgiIdFss(data.AffaireCommandeFSS[i].PGIIdFSS);
                        } else {
                            this.pgiIdFss(this.pgiIdFss() + ", " + data.AffaireCommandeFSS[i].PGIIdFSS);
                        }
                    }

                    if (this.isAttachRc() && !allFssFromPgi) {
                        this.idFss(data.AffaireCommandeFSS[0].Id);
                        this.createdFss(new Date(data.AffaireCommandeFSS[0].Created).toLocaleDateString());
                    }

                    if (allFssFromPgi) {
                        this.montantFss(montant.toString());
                    }

                    this.fssFromPgi(allFssFromPgi);

                    if (!(isCreated || isValidated)) {
                        this.statusFss(2);
                    } else if (isCreated) {
                        this.statusFss(1);
                    } else {
                        this.statusFss(3);
                        this.hasFssFiles(allHasFile);
                            if (data.AffaireCommandeFSS[0].Validated !== null) {
                                this.validatedFss(new Date(data.AffaireCommandeFSS[0].Validated).toLocaleDateString());
                            }
                    }

                    if (data.AffaireCommandeFSS.length > 1) {
                        this.hasMultipleFss(true);
                    }

                    if (data.AffaireCommandeFSS.length === 1) {
                        this.idFss(data.AffaireCommandeFSS[0].Id);
                    }
                }

                this.isManualRcFss(!this.rcFromPgi() && !this.fssFromPgi());
            } else {
                this.rcFromPgi(false);

                this.idFss(data.Id);
                this.statusFss(data.TypeStatutAffaireCommandeFSS);

                this.fssFromPgi(data.PGIIdFSS !== null);
                this.pgiIdFss(data.PGIIdFSS);
                this.fssWithoutRc(true);
                this.hasFssFiles(data.PdfOGEDId !== null);

                this.isManualRcFss(!this.fssFromPgi());
                if (this.fssFromPgi()) {
                    this.montantFss(data.MontantEnCents.toString());
                } else {
                    this.createdFss(new Date(data.Created).toLocaleDateString());
                }

                    if (data.Validated !== null) {
                        this.validatedFss(new Date(data.Validated).toLocaleDateString());
                    }
            }

            if (manualPjs !== undefined) {
                if (!this.rcFromPgi()) {
                    var pj = manualPjs.filter((elem, index, array) => {
                        return elem.Id === this.id() && elem.IsRc;
                    });
                    if (pj.length === 1) {
                        this.attachRcFilename(pj[0].Filename);
                    }
                }

                if (!this.fssFromPgi() && !this.hasMultipleFss()) {
                    pj = manualPjs.filter((elem, index, array) => {
                        return elem.Id === this.idFss() && !elem.IsRc;
                    });
                    if (pj.length === 1) {
                        this.attachFssFilename(pj[0].Filename);
                    }
                }
            }
        }
    }

    // Gestion de la possibilité d'attacher un RC manuellement
    canAttachRcFile = ko.pureComputed(() => {
        return this.id() === null;
    });

    // Gestion de la possibilité de mettre à jour un RC rattaché manuellement
    canUpdateAttachRcFile = ko.pureComputed(() => {
        return this.id() === null || (this.statusRc() >= 6 && this.statusRc() < 9);
    });

    // Gestion du type d'un RC (attaché non attaché)
    isAttachRc = ko.pureComputed(() => {
        return this.id() !== null && !this.rcFromPgi();
    });

    // Gestion de la possibilité d'attacher un FSS manuellement
    canAttachFssFile = ko.pureComputed(() => {
        return this.isManualRcFss() && this.idFss() === null;
    });

    // Gestion de la possibilité de mettre à jour un RC rattaché manuellement
    canUpdateAttachFssFile = ko.pureComputed(() => {
        return this.isManualRcFss() && this.idFss() === null || (this.statusFss() >= 6 && this.statusFss() < 9);
    });

    // Gestion du type d'un FSS (attaché non attaché)
    isAttachFss = ko.pureComputed(() => {
        return this.idFss() !== null && !this.fssFromPgi();
    });

    // Conversion du statut du RC en texte
    statusRcDisplay = ko.pureComputed(() => {
        let statut = "";
        switch (this.statusRc()) {
            case 1:
                statut = "Brouillon";
                break;
            case 2:
                statut = this.isIntern ? "Attente retour FRN" : "A contrôler";
                break;
            case 3:
                statut = this.isIntern ? "A contrôler" : "Attente retour Enedis";
                break;
            case 4:
                statut = "Validé";
                break;
        }

        return statut;
    });

    // Conversion du statut FSS en texte
    statusFssDisplay = ko.pureComputed(() => {
        let statut = "";
        switch (this.statusFss()) {
            case 1:
                statut = this.isIntern ? "Créée / A soumettre" : "En attente de validation";
                break;
            case 2:
                statut = "En attente de validation";
                break;
            case 3:
                statut = "Validée";
                break;
        }

        return statut;
    });

    hasValidatedValue = ko.pureComputed(() => {
        const de = this.validated();
        return typeof de !== "undefined" && de !== null;
    });

    hasMontantFssValue = ko.pureComputed(() => {
        return this.montantFss() !== null;
    });

    // Gestion de l'affichage du montant de FSS (partie euros)
    montantFssEuros = ko.pureComputed(() => {
        if (this.hasMontantFssValue()) {
            const montant = this.montantFss();
            return montant.substring(0, montant.length - 2);
        } else {
            return null;
        }
    });

    // Gestion de l'affichage du montant de FSS (partie cents)
    montantFssCents = ko.pureComputed(() => {
        if (this.hasMontantFssValue()) {
            const montant = this.montantFss();
            return montant.substring(montant.length - 2, montant.length);
        } else {
            return null;
        }
    });

        hasValidatedFssValue = ko.pureComputed(() => {
            let de = this.validatedFss();
            return typeof de !== "undefined" && de !== null;
        });

    // Gestion des lien pour le téléchargement des fichiers de RC et FSS

    ogedRcExcelFilePath = ko.pureComputed(() => {
        return `/AffaireCommandeRcFss/GetRcExcelFile/${this.id()}`;
    });

    ogedRcPdfFilePath = ko.pureComputed(() => {
        return `/AffaireCommandeRcFss/GetRcPdfFile/${this.id()}`;
    });

    ogedFssPdfFilePath = ko.pureComputed(() => {
        return `/AffaireCommandeRcFss/GetFssPdfFile/${this.idFss()}`;
    });

    ogedFssZipFilePath = ko.pureComputed(() => {
        return `/AffaireCommandeRcFss/GetFssZipFile/${this.id()}`;
    });

    attachedRcFilePath = ko.pureComputed(() => {
        return `/AffaireCommandeRcFss/GetRcFile/${this.id()}`;
    });

    attachedFssFilePath = ko.pureComputed(() => {
        return `/AffaireCommandeRcFss/GetFssFile/${this.idFss()}`;
    });

    deleteAttachedRcFile() {
        this.deleteAttachedRcFssFile(true);
    };

    deleteAttachedFssFile() {
        this.deleteAttachedRcFssFile(false);
    }

    private deleteAttachedRcFssFile(isRc: boolean) {
        let deleteFunction = "DeleteFssFile";
        let message = "de la FSS";
        let id = this.idFss();

        if (isRc) {
            deleteFunction = "DeleteRcFile";
            message = "du RC";
            id = this.id();
        }

        $.sticky(`<b>Suppression ${message} rattaché manuellement en cours...</b>`, { cssClass: 'orange' });
        $.ajax({
            url: `/AffaireCommandeRcFss/${deleteFunction}`,
            type: "POST",
            data: $.fn.putValidationToken({
                id: id
            })
        }).done((response: any) => {
            if (response.Status) {
                $.sticky(`<b>La suppression ${message} rattaché manuellement s'est terminée avec succès</b>`,
                    { cssClass: 'green' });
                if (isRc) {
                    this.id(null);
                    this.created("");
                    this.attachRcFilename("");
                    this.statusRc(undefined);
                } else {
                    this.idFss(null);
                    this.createdFss("");
                    this.attachFssFilename("");
                    this.statusFss(undefined);
                }
            } else {
                $.sticky(`<b>Une erreur s'est produite pendant la suppression ${message} rattaché manuellement</b>`, { cssClass: 'red' });
            }
        }).fail((response) => {
            $.sticky(`<b>Une erreur s'est produite pendant la suppression ${message} rattaché manuellement</b>`, { cssClass: 'red' });
        });
    }
}

export class AttachRcFssFileViewModel {
    linkedListeRcFssCommandeVM: ListeRcFssCommandeViewModel;
    linkedRcFssCommandeVM: RcFssCommandeViewModel;

    id = ko.observable<number>(null);
    idAffaire = ko.observable<string>(null);
    numCommande = ko.observable<string>(null);
    isRc = ko.observable<boolean>(false);
    associateId = ko.observable<number>(null);
    isIntern = ko.observable<boolean>(false);
    attachStatus = ko.observable<number>(null);

    isSignedByMe = ko.observable<boolean>(false);
    isSignedByOther = ko.observable<boolean>(false);

    errorMessage = ko.observable<string>(null);

    constructor(currentListeRcFssCommandeVm: ListeRcFssCommandeViewModel,
        currentRcFssCommandeVm: RcFssCommandeViewModel,
        idAffaire: string,
        numCommande: string,
        isRc: boolean,
        isIntern: boolean) {
        this.linkedListeRcFssCommandeVM = currentListeRcFssCommandeVm;
        this.linkedRcFssCommandeVM = currentRcFssCommandeVm;
        this.id(isRc ? currentRcFssCommandeVm.id() : currentRcFssCommandeVm.idFss());
        this.associateId(isRc ? currentRcFssCommandeVm.idFss() : currentRcFssCommandeVm.id());
        this.attachStatus(isRc ? currentRcFssCommandeVm.statusRc() : currentRcFssCommandeVm.statusFss());
        this.idAffaire(idAffaire);
        this.isRc(isRc);
        this.numCommande(numCommande);
        this.isIntern(isIntern);

        this.isSignedByMe((this.attachStatus() === 7 && this.isIntern()) || (this.attachStatus() === 8 && !this.isIntern()));
        this.isSignedByOther((this.attachStatus() === 7 && !this.isIntern()) || (this.attachStatus() === 8 && this.isIntern()));
    }

    isSignedByOtherLabel = ko.pureComputed(() => {
        return this.isIntern() ? "Le prestataire a signé le document." : "Enedis a signé le document.";
    });

    isSignedByMeIsVisible = ko.pureComputed(() => {
        switch (this.attachStatus()) {
            case undefined:
                return true;
            case 6:
                return true;
            case 7:
                return !this.isIntern();
            case 8:
                return this.isIntern();
            default:
                return false;
        }
    });

    isSignedByOtherIsVisible = ko.pureComputed(() => {
        switch (this.attachStatus()) {
            case undefined:
                return true;
            case 6:
                return true;
            case 7:
                return this.isIntern();
            case 8:
                return !this.isIntern();
            default:
                return false;
        }
    });

    isSignedByEnedis = ko.pureComputed(() => {
        return (this.isIntern() && this.isSignedByMe()) || (!this.isIntern() && this.isSignedByOther());
    });

    isSignedByFnr = ko.pureComputed(() => {
        return (this.isIntern() && this.isSignedByOther()) || (!this.isIntern() && this.isSignedByMe());
    });

    preventText = ko.pureComputed(() => {
        switch (this.attachStatus()) {
            case 7:
            case 8:
                return "Merci de renseigner le statut de signature du document. Attention : Tout dépôt de document, écrasera le précédent. Veuillez vous assurer que le document déposé comprend bien la signature de l'autre partie.";
            default:
                return "Merci de renseigner le statut de signature du document.";
        }
    });

    cancel() {
        window.onbeforeunload = null;
        $('#attach-rc-fss-modal').modal('hide');
        ($('#attach-rc-fss-modal form').get(0) as HTMLFormElement).reset();
    }

    submit() {
        window.onbeforeunload = null;

        const form = $('#attach-rc-fss-modal form')[0] as HTMLFormElement;
        const data = new FormData(form);
        const fileRaw = data.get("PostedPiece");
        const file = fileRaw as File;

        if (fileRaw === "" || file.name === "") {
            this.errorMessage("Veuillez sélectionner un fichier.");
            return;
        }

        this.errorMessage(null);

        data.append("__RequestVerificationToken", $.fn.findValidationToken());
        data.append("idAffaire", this.idAffaire());
        data.append("numCommande", this.numCommande());
        data.append("isRc", this.isRc().toString());

        if (this.id() !== null) {
            data.append("id", this.id().toString());
        }

        if (this.associateId() !== null) {
            data.append("associateId", this.associateId().toString());
        }

        data.append("isSignedByEnedis", this.isSignedByEnedis().toString());
        data.append("isSignedByFnr", this.isSignedByFnr().toString());

        $.sticky("<b>Fichier en cours :</b><br/>" + file.name, { cssClass: 'orange' });

        $.ajax({
            url: "/AffaireCommandeRcFss/UploadFile",
            type: "POST",
            enctype: "multipart/form-data",
            contentType: false,
            processData: false,
            data: data
        }).done((response: any) => {
            if (response.Status) {
                const ret = $.parseJSON(response.Data);
                if (this.isRc()) {
                    this.linkedRcFssCommandeVM.id(ret.Id);
                    this.linkedRcFssCommandeVM.created(new Date(ret.Created).toLocaleDateString());
                    this.linkedRcFssCommandeVM.attachRcFilename(ret.Filename);
                    this.linkedRcFssCommandeVM.statusRc(ret.Status);
                } else {
                    this.linkedRcFssCommandeVM.idFss(ret.Id);
                    this.linkedRcFssCommandeVM.createdFss(new Date(ret.Created).toLocaleDateString());
                    this.linkedRcFssCommandeVM.attachFssFilename(ret.Filename);
                    this.linkedRcFssCommandeVM.statusFss(ret.Status);
                }

                this.linkedListeRcFssCommandeVM.canAttachManualRcFss(true);
            }

            $.sticky("<b>Fichier uploadé :</b><br/>" + file.name, { cssClass: 'green' });

            this.cancel();
        });
    }
}

// Gestion de l'affichage d'une liste de RC et FSS associé à une commande
export class ListeRcFssCommandeViewModel {
    isIntern: boolean;
    urlErcIntern: string;
    urlErcExternal: string;

    rcFssCommandeList = ko.observableArray<RcFssCommandeViewModel>();

    idCommande = ko.observable<number>();
    numCommande = ko.observable<string>();
    isVisible = ko.observable<boolean>(false);
    isRefreshing = ko.observable<boolean>(false);
    infosMessage = ko.observable<string>();
    canAttachManualRcFss = ko.observable<boolean>(true);

    hasRcs = ko.pureComputed(() => {
        return this.rcFssCommandeList().length !== 0;
    });

    constructor(data?: any) {
        if (data === null) {
            return;
        }

        this.isIntern = data.isIntern;
        this.urlErcIntern = data.urlErcIntern;
        this.urlErcExternal = data.urlErcExternal;

        this.idCommande(data.idCommande);
        this.numCommande(data.numCommande);

        this.initRcListAndFssList(data.rcs, data.fsss, data.manualPjs);
    }

    // Initialisation des listes de RC et FSS
    initRcListAndFssList(rcs: any, fsss: any, pjs: any) {
        this.rcFssCommandeList.removeAll();
        this.canAttachManualRcFss(true);

        for (let i = 0; i < rcs.length; i++) {
            if (rcs[i].TypeStatutAffaireCommandeRC !== 1 || rcs[i].IsInternAuthor === this.isIntern) {
                this.rcFssCommandeList.push(new RcFssCommandeViewModel(rcs[i], false, this.isIntern, pjs));
            }
        }

        for (let i = 0; i < fsss.length; i++) {
            this.rcFssCommandeList.push(new RcFssCommandeViewModel(fsss[i], true, this.isIntern, pjs));
        }
    }

    // Gestion du dépliage de la liste RC/FSS d'une commande, avec lancement du refresh lors de l'affichage
    toggleVisibility() {
        this.isVisible(!this.isVisible());

        if (this.isVisible()) {
            this.updateRcFss();
        }
    }

    // Gestion du refresh des données de RC et FSS
    updateRcFss() {
        if (this.isRefreshing()) {
            return;
        }
        this.infosMessage("Mise à jour des RC et FSS en cours...");
        this.isRefreshing(true);
         
        // Appel du controler pour lancer le refresh des données
        $.ajax({
            url: "/AffaireCommandeRcFss/UpdateRcFss",
            type: "POST",
            data: $.fn.putValidationToken({
                numCommande: this.numCommande()
            })
        }).done((response: any) => {
            // Lorsque le refresh est fini, on relance l'init des donnée avec les données fraiches
            if (response.Status) {
                const data = $.parseJSON(response.Data);

                // Vérification si il y a des RC/FSS à afficher
                if (data !== null && data !== undefined) {
                    this.initRcListAndFssList(data.rcs, data.fsss, data.manualPjs);

                    // Récupération du montant réceptionné pour affichage sur la ligne commande. Cette donnée peut ne pas exister avant le refresh
                    if (data.montantReceptionne !== null) {
                        this.setMontantReceptionne(data.montantReceptionne.toString());
                    }
                }

                if (response.Message)
                    this.infosMessage(response.Message);
                else
                    this.infosMessage("Mise à jour des RC et FSS terminée.");

                this.initEvents();
            } else {
                this.infosMessage("Une erreur s'est produite pendant la mise à jour des RC et FSS.");
            }
            setTimeout(() => this.isRefreshing(false), 2000);

        }).fail((response) => {
            this.isRefreshing(false);
        });
    }

    // Init des listeners sur les différents click possible
    initEvents() {
        this.initFileUpload();
        this.initOpenErc();
    }

    // init des chargements de fichiers pour le rattachement manuel de RC et FSS
    initFileUpload() {
        $('.rc-fss-row .rc-fss-file-upload', $(`div[data-id-commande=${this.idCommande()}]`)).click((e) => {
            const $me = $(e.currentTarget);
            const isRc = $me.data('is-rc');
            const rcFssCommandeVm = ko.dataFor(e.currentTarget) as RcFssCommandeViewModel;

            const attachFileVm = new AttachRcFssFileViewModel(this, rcFssCommandeVm, $('#IdAffaire').val().toString(), this.numCommande(), isRc, this.isIntern);

            const $modal = $('#attach-rc-fss-modal');
            ko.cleanNode($modal[0]);
            ko.applyBindings(attachFileVm, $modal[0]);

            $modal.modal({
                backdrop: true,
                show: true
            });
        });
    }

    // Init du click pour la consultation d'un RC
    initOpenErc() {
        $('.eRC-button').off("click").click((e) => {
            this.openErc(e, false);
        });
    }

    // Init du click pour la création d'un RC
    openErcCreate(item, event) {
        this.openErc(event, true);
    }

    // Gestion de l'ouverture d'eRC en création/consultation d'un RC
    openErc(event: JQuery.ClickEvent, create: boolean) {
        //Génération de l'URL pour eRC
        const startUrl = this.isIntern ? this.urlErcIntern : this.urlErcExternal;
        const actionUrl = `#/${create ? 'PosteCommande' : 'SyntheseRC'}/`;
        const endUrl = create ? this.numCommande() : (ko.dataFor(event.currentTarget) as RcFssCommandeViewModel).pgiIdRc();
        const url = startUrl + actionUrl + endUrl;

        // Ouverture dans un nouvel onglet
        const tab = window.open(url, "eRC");
        tab.focus();
    }

    // Gestion de l'ajout manuel d'une ligne RC/FSS
    attachManualRcFss(data, event) {
        this.rcFssCommandeList.push(new RcFssCommandeViewModel(null, null, this.isIntern, null));
        this.canAttachManualRcFss(false);
        this.initEvents();
    }

    // Affichage du montant receptionnée losrque ce dernier est disponible
    setMontantReceptionne(montantReceptionne: string) {
        if (montantReceptionne !== null) {
            $('.montant-receptionne-commande .montant-euros', $(`tr[data-id-commande=${this.idCommande()}]`)).text(montantReceptionne.substring(0, montantReceptionne.length - 2));
            $('.montant-receptionne-commande .montant-centimes', $(`tr[data-id-commande=${this.idCommande()}]`)).text(montantReceptionne.substring(montantReceptionne.length - 2, montantReceptionne.length));
            $('.montant-receptionne-commande', $(`tr[data-id-commande=${this.idCommande()}]`)).show();
        }
    }
}
