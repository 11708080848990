﻿// ReSharper disable InconsistentNaming

export class Consuel {
    ConsuelRemis = ko.observable<boolean>();
    NumeroConsuel = ko.observable<string>();
    ConventionEnedisFranceTelecom = ko.observable<string>();
    TrancheeAvecFranceTelecom = ko.observable<boolean>();
    Commentaire = ko.observable<string>();

    constructor(data) {
        this.ConsuelRemis(data.ConsuelRemis);
        this.NumeroConsuel(data.NumeroConsuel);
        this.ConventionEnedisFranceTelecom(data.ConventionEnedisFranceTelecom);
        this.TrancheeAvecFranceTelecom(data.TrancheeAvecFranceTelecom);
        this.Commentaire(data.Commentaire);
    }
}
