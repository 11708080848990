﻿import {eplans} from "../eplans-static";

declare const instance: IntervenantSupport;

export class IntervenantSupport {
    // ReSharper disable InconsistentNaming
    Id = ko.observable<number>();
    Prenom = ko.observable<string>();
    Nom = ko.observable<string>();
    Telephone = ko.observable<string>();
    Email = ko.observable<string>();
    IsGenerique = ko.observable<boolean>();
    PrenomErrorMessage = ko.observable(null);
    //FkIdTypeIntervenant = ko.observable<number>();
    NomErrorMessage = ko.observable(null);
    TelephoneErrorMessage = ko.observable(null);
    MailErrorMessage = ko.observable(null);
    // ReSharper restore InconsistentNaming

    sortableTargetOptions = {
        items: '> :not(.generique)',
        dropOnEmpty: true,
        cursor: "pointer",
        connectWith: ".support-source",
        placeholder: 'ui-state-highlight',
    };

    constructor(data) {
        this.Id(data);

        $('.support-source').sortable({
            dropOnEmpty: true,
            cursor: "pointer",
            connectWith: ".support-target",
            placeholder: 'ui-state-highlight'
        });

        $('.support-target').sortable(this.sortableTargetOptions);

        $(".support-source").on("sortreceive", <any>function (e, ui) {
            const $item = $(ui.item);
            instance.deleteUtilisateur($item, e.type);
            $item.find("a").remove();
        });

        //Remove with icon on list support utilisateur
        $(".support-target").off("sortreceive").on("sortreceive", (e, ui) => {
            $.ajax({
                type: "POST",
                url: "/BEX/AddSupport",
                dataType: "json",
                data: $.fn.putValidationToken({
                    Id: instance.Id(),
                    IdIntervenant: ui.item.data("idintervenant"),
                    Prenom: ui.item.data("prenom"),
                    Nom: ui.item.data("nom"),
                    Email: ui.item.data("mail"),
                    Telephone: ui.item.data("telephone"),
                    Type: ui.item.data("type"),
                    generique: false
                })
            }).done((result: any) => {
                ui.item.attr("data-id", result.Data);
                ui.item[0].firstChild.classList.add("col-md-11");
            });
        });
    }

    deleteSupport(item, event) {
        const $item = $(event.target).parent();
        this.deleteUtilisateur($item, event.type);
    }

    addCollab() {
        let canAddSupport = true;

        // moche mais sinon seul le premier message va s'afficher
        if (!this.checkNom()) {
            canAddSupport = false;
        }
        if (!this.checkTelephone()) {
            canAddSupport = false;
        }
        if (!this.checkMail()) {
            canAddSupport = false;
        }

        if (canAddSupport) {
            this.addUtilisateurGenerique();
        }
    }

    deleteUtilisateur($itm: JQuery, evtType: string) {
        const id = $itm.data("id");
        $itm.find("a").remove();
        $itm.removeAttr("data-id");
        $itm.remove();

        $.ajax({
            type: "POST",
            url: "/BEX/DeleteSupport",
            dataType: "json",
            data: $.fn.putValidationToken({id: id})
        }).done(() => {
            if (evtType === "click") {
                $itm.remove();
                if ($itm.hasClass("support-generique")) {
                    $itm.fadeOut();
                    return;
                }
            }
            $('.support-source').append($itm[0]);
        });
    }

    addUtilisateurGenerique() {
        $.ajax({
            type: "POST",
            url: "/BEX/AddSupport",
            dataType: "json",
            data: $.fn.putValidationToken({
                Id: this.Id(),
                Prenom: this.Prenom(),
                Nom: this.Nom(),
                Telephone: this.Telephone(),
                Email: this.Email(),
                generique: true
            })
        }).done((data: any) => {
            //les petits nouveaux on leur met une classe generique pour les reconnaitres
            let item: string;
            if (this.Prenom() != null && this.Prenom() !== '') {
                item = `<li class="support-generique" data-id="${data.Data}"><span class="pull-right span6 col-md-11">${this.Prenom()} ${this.Nom()}  ${this.Email()}${this.Telephone()}</span></li>`;
            } else {
                item = `<li class="support-generique" data-id="${data.Data}"><span class="pull-right span6 col-md-11">${this.Nom()} ${this.Email()} ${this.Telephone()} </span></li>`;
            }


            $(".support-target").append(item);
            this.Prenom("");
            this.Nom("");
            this.Telephone("");
            this.Email("");
        });

    }


    checkNom() {
        this.NomErrorMessage(null);

        const nom = this.Nom();

        if (nom == null || nom === '') {
            this.NomErrorMessage('<span class="support-error">Veuillez saisir un nom</span>');
            return false;
        }
        if (nom.length > 50) {
            this.NomErrorMessage('<span class="support-error">écrivez votre texte en 50 caractères </span>');
            return false;
        }

        return true;
    }

    checkTelephone() {
        this.TelephoneErrorMessage(null);

        const telephone = this.Telephone();

        if (!/^0[0-9]([-. ]?\d{2}){4}[-. ]?$/.test(telephone) || telephone == null || telephone === '') {
            this.TelephoneErrorMessage('<span class="support-error">Veuillez saisir un numéro de téléphone valide</span>');
            return false;
        }

        return true;
    }

    checkMail() {
        this.MailErrorMessage(null);

        let mail = this.Email();

        if (mail != null) {
            mail = mail.trim().toLowerCase();
        }

        if (!eplans.regexMail.test(mail) || mail == null || mail === '') {
            this.MailErrorMessage('<span class="support-error">Veuillez saisir une adresse mail valide</span>');
            return false;
        }

        return true;
    }
}
