﻿import { CommonInfosPrestation } from "./pposr-general";
// ReSharper disable InconsistentNaming

export class TypeModificationPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'typemodif');

        this.CasModification(data.CasModification);

        this._initOrigValues();
    }

    CasModification = ko.observable<string>();

    _imageSrcFromCasModification = ko.pureComputed({
        owner: this,
        read: () => {
            var src: string;

            switch (this.CasModification()) {
                case "1":
                    src = "/Content/assets/pposr/TypeModification/DeplacementLiaisonPrivativeSouterraine.png";
                    break;
                case "2":
                    src = "/Content/assets/pposr/TypeModification/ModificationBranchementSurPotelet.png";
                    break;
                case "3":
                    src = "/Content/assets/pposr/TypeModification/DeplacementOuModificationBranchementAerien.png";
                    break;
                case "4":
                    src = "/Content/assets/pposr/TypeModification/DeplacementTableauComptage.png";
                    break;
                case "5":
                    src = "/Content/assets/pposr/TypeModification/DeplacementOuModificationBranchementSouterrain.png";
                    break;
                // NB : il n'y a pas d'image pour le cas 6 (Passage Brt Longue Utilisation sans comptage vers Brt avec comptage)
                case "7":
                    src = "/Content/assets/pposr/TypeModification/RemplacementPanneauComptageRaisonEsthetique.png";
                    break;
                case "8":
                    src = "/Content/assets/pposr/TypeModification/DeplacementDerivationEnImmeuble.png";
                    break;
                case "9":
                    src = "/Content/assets/pposr/TypeModification/PoseTeleReport.png";
                    break;
                case "10":
                    src = "/Content/assets/pposr/TypeModification/PassageBranchementAerienEnAeroSouterrain.png";
                    break;
                case "11":
                    src = "/Content/assets/pposr/TypeModification/DeposeBranchement.png";
                    break;

                default:
                    return "";
            }

            return src;
        }
    });

    _CasModificationOrig: string;

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            return true;
        }
    });

    // Sortie du mode modification
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            this._ModeModificationGlobal(false);

            if (resetValues) {
                this.CasModification(this._CasModificationOrig);
            }

            this._disableChangeAlert();
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        this._CasModificationOrig = this.CasModification();
    }

    _prepareSave() {
        const self = this;

        if (self._isValid()) {
            self._save('/Affaire/EnregistrerModifTypeModification', self._PrestationLinkWithHash(), this._setNewStateAfterSave, self);
        }
    }

    _setNewStateAfterSave(self: TypeModificationPrestationViewModel) {
        self._disableModify(false);

        self._initOrigValues();
    }
}
// ReSharper restore InconsistentNaming
