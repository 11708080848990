﻿import { InstructionReferentielViewModel } from "./InstructionsReferentielViewModel";

export class InstructionsReferentielCreateVersionViewModel extends InstructionReferentielViewModel {
    CanEditAccessOuvrage: KnockoutComputed<boolean>;
    CreateVersionVisible: KnockoutComputed<boolean>;
    SaveVisible: KnockoutComputed<boolean>;
    SelectedInstructionId: KnockoutObservable<number>;
    SelectedVersion: KnockoutObservable<number>;
    Instruction: any;

    constructor(model: any) {
        super(model);
        this.IsDuplicateOnNationalPerimeter = ko.computed<boolean>(() => {
            return false;
        });
        this.Instructions = model.Instructions;
        this.BlocInstructionDisabled(true);
        this.Referentiel = ko.observable<string>(model.Perimeter);
        this.Version(model.NumeroVersion);
        this.SelectedTypeOuvrage = ko.observable<number>(model.TypeOuvrage);
        this.SelectedTypeInstruction(model.TypeInstruction.toString());

        this.NumeroCourtFirstDigit = ko.observable<number>(model.FirstDigit);
        this.NumeroCourtLastDigit = ko.observable<number>(model.LastDigit);
        this.FkIdInstructionNationale(model.FkIdInstructionNationale);
        this.SelectedInstructionId = ko.observable<number>();

        const dateApplicationNumber: number = model.DateApplication.match(/\d+/) * 1;
        const currentDateApplication: Date = new Date(new Date(dateApplicationNumber).setDate(new Date(dateApplicationNumber).getDate() + 1));
        this.MinDate(this.MinDate().valueOf() > currentDateApplication.valueOf()
            ? this.MinDate()
            : currentDateApplication);

        const baseInstructionDateApplicationNumber: number = model.BaseInstructionDateApplication.match(/\d+/) * 1;
        this.BaseInstructionDateApplication = new Date(new Date(baseInstructionDateApplicationNumber).setDate(new Date(baseInstructionDateApplicationNumber).getDate() + 1));


        this.BlockVersionDisabled = ko.computed<boolean>(() => {
            return false;
        });
        this.CanEditAccessOuvrage = ko.computed<boolean>(() => {
            return !this.IsAccesPermanentEnabled() || this.BlockVersionDisabled();
        });
        this.CreateVersionVisible = ko.computed<boolean>(() => {
            return false;
        })
        this.SaveVisible = ko.computed<boolean>(() => {
            return true;
        });


        this.SelectedVersion = ko.observable<number>();

        this.SelectedVersion(model.SelectedVersion);

        this.FkIdInstructionNationale.subscribe((newValue: number) => {
            if (newValue != null) {
                this.MinDate(new Date(new Date().setDate(new Date().getDate() + 1)));
                const instructionParent: any = this.InstructionsNationalesActives().filter(i => i.Id == newValue)[0];
                if (instructionParent != null) {
                    this.AccesPermanent(instructionParent.AccesPermanent);
                    const dateApplicationNumbers = instructionParent.DateApplication.match(/\d+/)[0] * 1;
                    const dateApplicationInstructionNationaleParente = new Date(dateApplicationNumbers);
                    const max = Math.max(this.BaseInstructionDateApplication.valueOf(), this.MinDate().valueOf(), dateApplicationInstructionNationaleParente.valueOf());
                    this.MinDate(new Date(max));
                }
            }
            else {
                this.MinDate(new Date(new Date().setDate(new Date().getDate() + 1)));
            }
        });
    }
    UpdateSelectedInstructionId(): void {
    }
}
