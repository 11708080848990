﻿export class InstructionReferentielViewModel {
    TypesOuvrage: KnockoutComputed<any[]>;
    InstructionsNationales: any[];
    InstructionsDR: any[];
    InstructionsNationalesActives: KnockoutComputed<any[]>;
    SelectedTypeInstruction: KnockoutObservable<string>;
    Referentiel: KnockoutObservable<string>;
    SelectedTypeOuvrage: KnockoutObservable<number>;
    FirstDigitDisabled: KnockoutComputed<boolean>;
    MaxFirstDigit: KnockoutComputed<number>;
    NumeroCourtFirstDigit: KnockoutObservable<number>;
    NumeroCourtLastDigit: KnockoutObservable<number>;
    Version: KnockoutObservable<number>;
    DisplayedVersion: KnockoutComputed<string>;
    NumeroCalcule: KnockoutComputed<string>;
    NumeroCalculeCourt: KnockoutComputed<string>;
    AccesPermanentItemsForceEnabled: KnockoutObservable<any[]>;

    Titre: KnockoutObservable<string>;
    DateApplication: KnockoutObservable<Date>;
    DateApplicationOptions: KnockoutComputed<any>;
    AccesPermanent: KnockoutObservable<boolean>;
    HasTravauxUrgents: KnockoutObservable<boolean>;
    BlocInstructionDisabled: KnockoutObservable<boolean>;
    ReferentielDisabled: KnockoutObservable<boolean>;
    BlockVersionDisabled: KnockoutComputed<boolean>;
    IsAccesPermanentEnabled: KnockoutComputed<boolean>;
    MinDate: KnockoutObservable<Date>;
    NumeroDR: KnockoutObservable<number>;
    IsDuplicateOnNationalPerimeter: KnockoutComputed<boolean>;
    IsDuplicateOnDrPerimeter: KnockoutComputed<boolean>;
    DoesActiveModelNationalExists: KnockoutComputed<boolean>;
    FkIdInstructionNationale: KnockoutObservable<number>;
    Instructions: any[];
    Id: number;
    BaseInstructionId: number;
    BaseInstructionDateApplication: Date;

    DomaineHta: KnockoutObservable<boolean>;
    DomaineBt: KnockoutObservable<boolean>;
    DomainePs: KnockoutObservable<boolean>;

    constructor(model: any) {
        ko.subscribable.fn.subscribeChanged = function (callback) {
            let oldValue;
            this.subscribe(function (_oldValue) {
                oldValue = _oldValue;
            }, this, 'beforeChange');

            this.subscribe(function (newValue) {
                callback(newValue, oldValue);
            });
        };
        this.BlockVersionDisabled = ko.computed<boolean>(() => { return false; });
        this.BaseInstructionId = model.BaseInstructionId;
        this.Id = model.Id;
        this.Instructions = [];
        this.InstructionsNationales = model.InstructionsNationales;
        this.InstructionsDR = model.InstructionsDR;
        this.NumeroCourtFirstDigit = model.FirstDigit == null ? ko.observable<number>() : ko.observable<number>(model.FirstDigit);
        this.NumeroCourtLastDigit = model.LastDigit == null ? ko.observable<number>() : ko.observable<number>(model.LastDigit);
        this.HasTravauxUrgents = ko.observable<boolean>();
        this.AccesPermanent = ko.observable<boolean>(model.AccesPermanent);
        this.AccesPermanentItemsForceEnabled = ko.observable<any[]>(model.AccesPermanentItemsForceEnabled);
        this.Titre = model.Titre == null ? ko.observable<string>() : ko.observable<string>(model.Titre);
        this.DateApplication = ko.observable<Date>();
        this.BlocInstructionDisabled = ko.observable<boolean>(false);
        this.ReferentielDisabled = model.NumeroDR != null ? ko.observable<boolean>(false) : ko.observable<boolean>(true);
        this.SelectedTypeInstruction = ko.observable<string>("1");
        this.Referentiel = model.Perimeter == null ? ko.observable<string>("National") : ko.observable<string>(model.Perimeter);
        this.SelectedTypeOuvrage = model.TypeOuvrage == 0 ? ko.observable<number>() : ko.observable(model.TypeOuvrage);
        this.Version = ko.observable<number>(1);
        this.NumeroDR = ko.observable<number>(model.NumeroDR);
        this.FkIdInstructionNationale = model.FkIdInstructionNationale == null ? ko.observable<number>() : ko.observable<number>(model.FkIdInstructionNationale);
        this.DomaineHta = ko.observable<boolean>(model.DomaineHta);
        this.DomaineBt = ko.observable<boolean>(model.DomaineBt);
        this.DomainePs = ko.observable<boolean>(model.DomainePs);


        this.Referentiel.subscribeChanged((newValue: string, oldValue: string) => {
            switch (newValue) {
                case "National":
                case "National décliné en DR":
                    if (oldValue == "Spécifique DR") {
                        this.NumeroCourtFirstDigit(null);
                        if (this.IsAccesPermanentEnabled() && this.NumeroCalcule() == null && this.SelectedTypeInstruction() == "2")
                            this.AccesPermanent(false);
                    }
                    break;
                case "Spécifique DR":
                    this.NumeroCourtFirstDigit(9);
                    if (this.SelectedTypeInstruction() == "2")
                        this.AccesPermanent(true);
                    break;
            }
        });

        this.NumeroCalculeCourt = ko.computed<string>(() => {
            const typeInstruction = this.SelectedTypeInstruction() === "1" ? "IPS" : "ITST";
            if (this.NumeroCourtFirstDigit() && this.NumeroCourtLastDigit()) {
                const numeroCourt = this.NumeroCourtFirstDigit() + '.' + this.NumeroCourtLastDigit();
                const num = typeInstruction + '-' + numeroCourt;
                return num;
            }
            return null;
        });

        this.NumeroCalcule = ko.computed<string>(() => {
            const numeroCalculeCourt = this.NumeroCalculeCourt();
            const typeouvrage: any = this.SelectedTypeOuvrage() !== undefined ?
                model.TypesOuvrage.filter(ouvrage => ouvrage.Id == this.SelectedTypeOuvrage())[0]
                : undefined;
            if (numeroCalculeCourt && typeouvrage && this.Referentiel()) {
                const libelleCourtOuvrage = typeouvrage.LibelleCourt;
                const referentiel = this.Referentiel() == "National" ? "000" : this.NumeroDR();
                const num = numeroCalculeCourt + '-' + libelleCourtOuvrage + '-' + referentiel;
                return num;
            }
            return null;
        });

        this.DisplayedVersion = ko.computed<string>(() => {
            return `v${this.Version().toString()}`;
        });
        this.MinDate = ko.observable<Date>(new Date(new Date().setDate(new Date().getDate() + 1)));
        this.DateApplicationOptions = ko.computed<any>(() => {
            return {
                altFormat: "dd/mm/yy",
                minDate: this.MinDate(),
                showOn: "both",
                buttonImage: "/Content/assets/calendar.png",
                buttonImageOnly: true
            }
        });
        this.SelectedTypeInstruction.subscribe((newValue: string) => {
            if (newValue == "2" && !this.AccesPermanent())
                this.AccesPermanent(true);
        });
        this.TypesOuvrage = ko.computed<any[]>(() => {
            let filteredOuvrages = [];
            let typeOuvrages = [];

            switch (this.SelectedTypeInstruction()) {
                case "1":
                    filteredOuvrages = model.TypesOuvrage.filter(ouvrage => ouvrage.IsIPS == true).sort(ouvrage => ouvrage.LibelleLong);
                    break;
                case "2":
                    filteredOuvrages = model.TypesOuvrage.filter(ouvrage => ouvrage.IsITST == true).sort(ouvrage => ouvrage.LibelleLong);
                    break;
                default:
                    console.log("something went wrong %o", this.SelectedTypeInstruction());
                    break;
            }
            $.each(filteredOuvrages, function (i, item) {
                typeOuvrages.push({ Id: item.Id, LibelleLong: item.LibelleLong });
            });

            return typeOuvrages.sort(function (ouvrage1: any, ouvrage2: any) {
                return ((ouvrage1.LibelleLong > ouvrage2.LibelleLong)
                    ? 1
                    : (ouvrage1.LibelleLong < ouvrage2.LibelleLong)
                        ? -1
                        : 0);
            });
        });
        this.IsAccesPermanentEnabled = ko.computed<any>(() => {

            //= 'IPS-5.2';  // Liste des interventions dont l'accès permannent est éditable

            const accesPermanentItemsForceEnabled = this.AccesPermanentItemsForceEnabled(); // accesPermanentItemsForceEnabled = ['IPS-5.2'];
            if (accesPermanentItemsForceEnabled != null && accesPermanentItemsForceEnabled.indexOf(this.NumeroCalculeCourt()) >= 0) {
                return true;
            }
            if (this.NumeroDR() != null) {
                return this.SelectedTypeInstruction() != "2" && this.Referentiel() == "Spécifique DR";
            }
            else {
                return this.SelectedTypeInstruction() != "2";
            }
        });
        this.FirstDigitDisabled = ko.computed<boolean>(() => {
            return this.BlocInstructionDisabled() || this.Referentiel() == "Spécifique DR";
        });
        this.MaxFirstDigit = ko.computed<number>(() => {
            return this.Referentiel() == "Spécifique DR" ? 9 : 8;
        });
        this.IsDuplicateOnNationalPerimeter = ko.computed<boolean>(() => {
            const numeroCalcule: JQuery = $('input[name="NumeroCalcule"]');
            if (this.NumeroDR() == null && this.NumeroCalcule() != null) {
                const isInvalid: boolean = this.IsNumeroCalculeValidOnNationalPerimeter(this.NumeroCalcule());
                if (isInvalid) {
                    this.AddClassError(numeroCalcule);
                }
                else {
                    this.RemoveErrorClass(numeroCalcule);
                }
                return isInvalid;
            }
            this.RemoveErrorClass(numeroCalcule);
            return false;
        });
        this.IsDuplicateOnDrPerimeter = ko.computed<boolean>(() => {
            var numeroCalcule: JQuery = $('input[name="NumeroCalcule"]');
            if (this.NumeroDR() != null && this.Referentiel() && this.NumeroCalcule() != null) {
                const isDuplicatedOnDrPerimeter = this.IsNumeroCalculeDuplicateOnDrPerimeter(this.NumeroCalcule());
                if (isDuplicatedOnDrPerimeter) {
                    this.AddClassError(numeroCalcule);
                }
                else {
                    this.RemoveErrorClass(numeroCalcule);
                }
                return isDuplicatedOnDrPerimeter;
            }
            this.RemoveErrorClass(numeroCalcule);
            return false;
        });
        this.DoesActiveModelNationalExists = ko.computed<boolean>(() => {
            const numeroCalcule: JQuery = $('input[name="NumeroCalcule"]');
            if (this.NumeroDR() != null && (this.Referentiel() == "National"
                || this.Referentiel() == "National décliné en DR") && this.NumeroCalcule() != null) {
                const exists: boolean = this.ModelNationalExists(this.NumeroCalcule());
                if (exists && !this.IsDuplicateOnDrPerimeter()) {
                    this.RemoveErrorClass(numeroCalcule);
                }
                else {
                    this.AddClassError(numeroCalcule);
                }
                return !exists;
            }
            return false;
        });
        this.InstructionsNationalesActives = ko.computed<any[]>(() => {
            if (!this.NumeroCalcule())
                return [];
            if (!this.NumeroDR())
                return [];
            const instructionsActives =
                this.InstructionsNationales.filter(
                    i => i.NumeroCalcule.slice(0, -4) == this.NumeroCalcule().slice(0, -4) && i.Status == 2);
            const result = instructionsActives.map((i) => {
                const dateApplication = moment(i.DateApplication).toDate();
                return {
                    Id: i.Id,
                    NumeroCalcule: i.NumeroCalule,
                    AccesPermanent: i.AccesPermanent,
                    DateApplication: i.DateApplication,
                    Status: i.Status,
                    Summary: `v${i.NumVersion.toString()} version applicable au ${dateApplication.getDate()}/${dateApplication.getMonth() + 1}/${dateApplication.getFullYear()} - ${i.Titre}`
                }
            });
            return result;
        });

        this.InstructionsNationalesActives.subscribe((newValue: any[]) => {
            if (newValue.length > 0) {
                const fkIdInstructionNationale = Math.min.apply(null, newValue.map(ins => ins.Id));
                this.FkIdInstructionNationale(fkIdInstructionNationale);
            }
        });

        this.FkIdInstructionNationale.subscribe((newValue: number) => {
            if (newValue != null) {
                this.MinDate(new Date(new Date().setDate(new Date().getDate() + 1)));
                const instructionParent: any = this.InstructionsNationalesActives().filter(i => i.Id == newValue)[0];
                if (instructionParent != null) {
                    this.AccesPermanent(instructionParent.AccesPermanent);
                    const dateApplicationNumbers = instructionParent.DateApplication.match(/\d+/)[0] * 1;
                    const dateApplicationInstructionNationaleParente = new Date(dateApplicationNumbers);
                    this.MinDate(this.MinDate().valueOf() < dateApplicationInstructionNationaleParente.valueOf() ? dateApplicationInstructionNationaleParente : this.MinDate());
                }
            }
            else {
                this.MinDate(new Date(new Date().setDate(new Date().getDate() + 1)));
            }
        });

        this.NumeroCalcule.subscribe((newValue) => {
            //dev spécifique - il est possible d'avoir une meilleur solution avec un sélecteur approprié
            const errorMessageSpan = $('#NumeroCalcule').nextAll('span').eq(3);
            if (newValue != null && errorMessageSpan.hasClass('field-validation-error')) {
                errorMessageSpan.removeClass('field-validation-error').addClass('field-validation-valid');
                errorMessageSpan.text('');
                if (!this.IsDuplicateOnDrPerimeter() && !this.IsDuplicateOnNationalPerimeter())
                    $('#NumeroCalcule').closest('div').removeClass('has-error');
            }
        })
        this.Titre.subscribe((newValue) => {
            //dev spécifique - il est possible d'avoir une meilleur solution avec un sélecteur approprié
            const errorMessageSpan = $('#Titre').nextAll('span').eq(0);
            if (newValue != null && errorMessageSpan.hasClass('field-validation-error')) {
                errorMessageSpan.removeClass('field-validation-error').addClass('field-validation-valid');
                errorMessageSpan.text('');
                $('#Titre').closest('div').removeClass('has-error');
            }
        })
        this.DateApplication.subscribe((newValue) => {
            //dev spécifique - il est possible d'avoir une meilleur solution avec un sélecteur approprié
            const errorMessageSpan = $('#DateApplication').nextAll('span').eq(0);
            if (newValue != null && errorMessageSpan.hasClass('field-validation-error')) {
                errorMessageSpan.removeClass('field-validation-error').addClass('field-validation-valid');
                errorMessageSpan.text('');
                $('#DateApplication').closest('div').removeClass('has-error');
            }
        })
    }

    IsNumeroCalculeValidOnNationalPerimeter(numeroCalcule: string): boolean {
        if (this.NumeroDR() == null && numeroCalcule != null && this.Id == 0 && this.BaseInstructionId == 0) {
            const existingInstructionNationale = this.InstructionsNationales.filter(i => i.NumeroCalcule.slice(0, -4) == numeroCalcule.slice(0, -4))[0];
            if (existingInstructionNationale != null) {
                return true;
            }
            return false;
        }
        return false;
    }

    IsNumeroCalculeDuplicateOnDrPerimeter(numeroCalcule: string): boolean {
        if (this.Id == 0 && this.BaseInstructionId == 0) {
            const existingInstructionDR = this.InstructionsDR.filter(i => i.NumeroCalcule == numeroCalcule)[0];
            if (existingInstructionDR != null || existingInstructionDR !== undefined)
                return true;
            return false;
        }
        return false;
    }

    ModelNationalExists(numeroCalcule: string): boolean {
        if (this.NumeroDR() != null && (this.Referentiel() == "National" || this.Referentiel() == "National décliné en DR")) {
            const instructionsNationalesActives = this.InstructionsNationales.filter(i => i.NumeroCalcule.slice(0, -4) == numeroCalcule.slice(0, -4) && i.Status == 2);
            if (instructionsNationalesActives != null && instructionsNationalesActives.length > 0) {
                return true;
            }
            return false;
        }
        return true;
    }

    AddClassError(selector: JQuery): void {
        if (selector != undefined)
            selector.parents(".form-group").addClass("has-error");
    }
    RemoveErrorClass(selector: JQuery): void {
        if (selector != undefined) {
            selector.parents(".form-group").removeClass("has-error");
        }
    }
}
