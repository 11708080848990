﻿export interface IMail {
    // ReSharper disable InconsistentNaming
    Destinataires: string;
    Subject: string;
    Body: string;
    SmtpSender: string;
    BccDest: string;
    DateReception: string;
    PiecesJointes: Array<SimplePieceJointeViewModel>;
    // ReSharper restore InconsistentNaming
}

export class Mail implements IMail {
    // ReSharper disable InconsistentNaming
    Destinataires: string = null;
    Subject: string = null;
    Body: string = null;
    SmtpSender: string = null;
    BccDest: string = null;
    DateReception: string = null;
    PiecesJointes: Array<SimplePieceJointeViewModel> = null;
    // ReSharper restore InconsistentNaming

    bodyOpen = ko.observable(false);

    constructor(m: IMail) {
        m = m || <IMail>{};

        for (let k in m) {
            if (this.hasOwnProperty(k)) {
                this[k] = m[k];
            }
        }
    }

    toggle() {
        this.bodyOpen(!this.bodyOpen());
    }

}

export interface ISimplePieceJointe {
    Path: string;
    Name: string;
}

export class SimplePieceJointeViewModel implements ISimplePieceJointe {
    Name: string;
    Path: string;
}

export class MailSpyViewModel {
    constructor() {

        this.originalTitle = document.title;
    }

    private originalTitle = "";

    // ReSharper disable InconsistentNaming
    Connected = ko.observable(false);
    Received = ko.observableArray<IMail>();
    // ReSharper restore InconsistentNaming

    push(mail: IMail) {
        if (mail != null) {
            this.Received.push(new Mail(mail));
            this.updateTitle();
        }
    }

    remove(mail: IMail) {
        if (mail != null) {
            this.Received.remove(mail);
        }
    }

    updateTitle() {
        document.title = `(${this.Received().length}) ${this.originalTitle}`;
    }
}
