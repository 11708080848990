﻿import { CommonInfosPrestation, TravauxValue } from "./pposr-general";
// ReSharper disable InconsistentNaming

export class SoumissionTravauxPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'soumissiontravaux');

        this._CanSubmit(data.CanSubmit);
        this._CanValidate(data.CanValidate);
        this._CanRefus(data.CanRefus);

        this.CommentairePrestataire(data.CommentairePrestataire);
        this.CommentairePrestataireLastMail(this._getCommentLastMail(this.CommentairePrestataire(), data.CommentairePrestataireLastMail));

        this._IsPhotogrammetrie(data.IsPhotogrammetrie);
        this._IsTriangulation(data.IsTriangulation);
        this.DateDepotPhoto(data.DateDepotPhoto);
        this.NomEntrepriseTraitement(data.NomEntrepriseTraitement);
        this.IsMiseAJourCartoObligatoire(String(data.IsMiseAJourCartoObligatoire));
        this.NbMarqueurs(data.NbMarqueurs);
        this.TravauxCompletsRealises(String(data.TravauxCompletsRealises));
        this.TravauxRealises(data.TravauxRealises);

        this.DateValidationPlanMinute(data.DateValidationPlanMinute);
        this._CanValidatePlan(data.CanValidatePlanMinute);
        this._DisplayTravauxComplets(data.DisplayTravauxComplets);
        this._DisplayDetailTravauxBranchement(data.DisplayDetailTravauxBranchement);
        this._DisplayDetailTravauxTerrassement(data.DisplayDetailTravauxTerrassement);

        this._TravauxRealisesArrayOrig = new Array<TravauxValue>();

        for (let i = 0; i < data.TravauxRealisesArray.length; i++) {
            this._TravauxRealisesArray.push(new TravauxValue(data.TravauxRealisesArray[i], false));
        }

        this._IsMiseAJourCartoObligatoireName = ko.observable<string>(`isMiseAJourCartoObligatoire${this.PrestationId()}`);
        this._TravauxCompletRealisesName = ko.observable<string>(`travauxCompletsRealises{this.PrestationId()}`);

        // On conserve les valeurs d'origine
        this._initOrigValues();
    }

    _CanSubmit = ko.observable<boolean>();
    _CanValidate = ko.observable<boolean>();
    _CanRefus = ko.observable<boolean>();
    _CanValidatePlan = ko.observable<boolean>();

    CommentairePrestataire = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    CommentairePrestataireLastMail = ko.observable<string>();

    _IsPhotogrammetrie = ko.observable<boolean>();
    _IsTriangulation = ko.observable<boolean>();
    DateDepotPhoto = ko.observable<string>();
    NomEntrepriseTraitement = ko.observable<string>().extend({ maxLength: 200 });
    IsMiseAJourCartoObligatoire = ko.observable<string>();
    NbMarqueurs = ko.observable<number>();
    TravauxCompletsRealises = ko.observable<string>();
    TravauxRealises = ko.observable<number>();

    DateValidationPlanMinute = ko.observable<string>();

    _DisplayTravauxComplets = ko.observable<boolean>();
    _DisplayDetailTravauxBranchement = ko.observable<boolean>();
    _DisplayDetailTravauxTerrassement = ko.observable<boolean>();

    _IsMiseAJourCartoObligatoireName = ko.observable<string>();
    _TravauxCompletRealisesName = ko.observable<string>();

    _TravauxRealisesArray = ko.observableArray<TravauxValue>();

    _ErrorTravauxRealises: string = "Vous devez sélectionner au moins un des travaux dans la liste.";
    _IsPrepareSubmitting: boolean = false;

    _CommentairePrestataireOrig: string;
    _CommentairePrestataireLastMailOrig: string;
    _DateDepotPhotoOrig: string;
    _NomEntrepriseTraitementOrig: string;
    _IsMiseAJourCartoObligatoireOrig: string;
    _NbMarqueursOrig: number;
    _TravauxCompletsRealisesOrig: string;
    _TravauxRealisesOrig: number;

    _TravauxRealisesArrayOrig: TravauxValue[];

    _travauxRealisesArray() {
        return this._TravauxRealisesArray().filter(_ => _._Label().length > 0 && _._IsVisible());
    }

    _nbMarqueursIsVisible = ko.pureComputed({
        owner: this,
        read: () => {
            return this._IsTriangulation() && (this._isTerrassement() || this._isBranchementTerrassement());
        }
    });

    // Si on a coché "Non" pour les travaux complets réalisés, au moins un doit être sélectionné
    _travauxRealisesIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this._DisplayTravauxComplets()
                || this.TravauxCompletsRealises() === "true"
                || (this.TravauxCompletsRealises() === "false"
                    && this._TravauxRealisesArray().reduce((accumulator, currentValue) => accumulator + currentValue._Value(), 0) > 0);
        }
    });

    _travauxCompletsRealises = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TravauxCompletsRealises() === "true";
        }
    });

    _dateDepotPhotoIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this._IsPhotogrammetrie() || this.DateDepotPhoto();
        }
    });

    _nbMarqueursIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            var nbMarq = this.NbMarqueurs();
            return !this._nbMarqueursIsVisible()
                || (nbMarq !== null && nbMarq !== undefined && String(nbMarq).match(/^[0-9]+$/));
        }
    });

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this.CommentairePrestataire.hasError()
                && this._nbMarqueursIsValid()
                && this._dateDepotPhotoIsValid()
                && this._travauxRealisesIsValid();
        }
    });

    // Sortie du mode modification
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            this._ModeModificationGlobal(false);

            if (resetValues) {
                this.CommentairePrestataire(this._CommentairePrestataireOrig);
                this.CommentairePrestataireLastMail(this._CommentairePrestataireLastMailOrig);
                this.DateDepotPhoto(this._DateDepotPhotoOrig);
                this.NomEntrepriseTraitement(this._NomEntrepriseTraitementOrig);
                this.IsMiseAJourCartoObligatoire(this._IsMiseAJourCartoObligatoireOrig);
                this.NbMarqueurs(this._NbMarqueursOrig);
                this.TravauxCompletsRealises(this._TravauxCompletsRealisesOrig);
                this.TravauxRealises(this._TravauxRealisesOrig);

                this._TravauxRealisesArray.removeAll();

                for (let i = 0; i < this._TravauxRealisesArrayOrig.length; i++) {
                    this._TravauxRealisesArray.push(new TravauxValue(this._TravauxRealisesArrayOrig[i], true));
                }
            }

            this._disableChangeAlert();
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        this._CommentairePrestataireOrig = this.CommentairePrestataire();
        // sera modifié automatiquement à la sortie de la sauvegarde
        this._CommentairePrestataireLastMailOrig = this.CommentairePrestataireLastMail();
        this._DateDepotPhotoOrig = this.DateDepotPhoto();
        this._NomEntrepriseTraitementOrig = this.NomEntrepriseTraitement();
        this._IsMiseAJourCartoObligatoireOrig = this.IsMiseAJourCartoObligatoire();
        this._NbMarqueursOrig = this.NbMarqueurs();
        this._TravauxCompletsRealisesOrig = this.TravauxCompletsRealises();
        this._TravauxRealisesOrig = this.TravauxRealises();

        this._TravauxRealisesArrayOrig = new Array<TravauxValue>();

        for (let i = 0; i < this._TravauxRealisesArray().length; i++) {
            this._TravauxRealisesArrayOrig.push(new TravauxValue(this._TravauxRealisesArray()[i], true));
        }
    }

    _prepareSave() {
        const self = this;

        if (self._isValid()) {
            // Si "Travaux complets réalisés" est à "Oui", on "coche" tout (virtuellement) et on retourne la somme de toutes les PotentialValue
            // Sinon on retournera les valeurs réellement cochées
            if (self.TravauxCompletsRealises() === "true") {
                for (let i = 0; i < self._TravauxRealisesArray().length; i++) {
                    self._TravauxRealisesArray()[i]._Value(self._TravauxRealisesArray()[i]._PotentialValue());
                }
            }

            // Dans les deux cas maintenant on peut faire la somme des Value
            const sumtravauxRealises = self._TravauxRealisesArray().reduce(((pv, cv) => pv + cv._Value()), 0);

            self.TravauxRealises(sumtravauxRealises);

            this._save('/Affaire/EnregistrerModifSoumissionTravaux', this._PrestationLinkWithHash(), this._setNewStateAfterSave, self);
        }
    }

    _setNewStateAfterSave(self: SoumissionTravauxPrestationViewModel) {
        const success = self._PendingSave();

        if (success) {
            self._disableModify(false);

            if (self.CommentairePrestataire() !== self._CommentairePrestataireOrig) {
                self.CommentairePrestataireLastMail(self._UserEmail());
            }

            // Les valeurs sauvées sont les nouvelles valeurs d'origine
            self._initOrigValues();

            self._PendingSave(false);
        }
    }

    _prepareSubmit() {
        if (this._IsPrepareSubmitting === false) {
            this._IsPrepareSubmitting = true;

            const $modalSoumission = $('#submit-prestation-modal'),
                idPrestation = this.PrestationId();

            $.ajax({
                url: '/Affaire/GetFormSubmitPrestation',
                type: 'POST',
                data: $.fn.putValidationToken({
                    idPrestation: idPrestation
                })
            }).done((contentData: any) => {
                var $contentBlock = $modalSoumission.find('#content-submit-prestation-modal');

                $contentBlock.html(contentData);

                $modalSoumission.modal({
                    backdrop: true,
                    show: true
                });

                this._IsPrepareSubmitting = false;
            });
        }
    }

    _prepareRefus() {
        if (this._IsPrepareSubmitting === false) {
            this._IsPrepareSubmitting = true;

            this._refusPresta();

            this._IsPrepareSubmitting = false;
        }
    }

    _validationPlanMinute = ko.pureComputed({
        owner: this,
        read: () => {
            return this.DateValidationPlanMinute() ? "Oui" : null;
        }
    });

    _validerPlan() {
        const idPrestation = this.PrestationId();
        const ctx = this;
        $.fn.confirmBoxCallback("Vous vous apprêtez à valider le plan minute ; cela signifie que vous avez au préalable vérifié sa conformité dans la liste des PJ OGED. Le service cartographie est ainsi averti par mail (si configuré).",
            (result: boolean) => {
                if (result) {
                    $.ajax({
                        url: '/Affaire/ValidatePlanMinute',
                        type: 'POST',
                        data: $.fn.putValidationToken({
                            idPrestation: idPrestation
                        })
                    }).done((data: any) => {
                        ctx.DateValidationPlanMinute(data.Data.DateValidation);
                        $.fn.sticky(data.Message, { cssClass: 'green' });
                    }).fail(err => {
                        $.fn.sticky(`Une erreur est survenue : ${err.responseJSON.message}`, { cssClass: 'orange' });
                    });
                }
            });
    }
}
// ReSharper restore InconsistentNaming
