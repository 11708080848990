﻿import { IMappingCallbacks, TypeModeControle, TypeStatutControleEnum, TypeStatutNonConformiteEnum, TypeControleChantierReponse, IgnoredFields } from "./common";
import { ControleQuestion } from "./controle-question";
import { ControleReponse } from "./controle-reponse";
import { ControleFichier } from "./controle-fichier";
import { ControleAction } from "./controle-action";
// ReSharper disable  InconsistentNaming

export class ControleChantierForm implements IMappingCallbacks {
    constructor(data: any) {
        const mapping = {
            'Questions': {
                create(options) {

                    //la question
                    const question = new ControleQuestion(data.ModeControle, data.CurrentUtilisateur);
                    question.Id(options.data.Id);
                    question.AideCourte(options.data.AideCourte);
                    question.AideLongue(options.data.AideLongue);
                    question.Bloquante(options.data.Bloquante);
                    question.Libelle(options.data.Libelle);
                    question.Ordre = options.data.Ordre;
                    question.Theme = options.data.Theme;

                    //la réponses associé
                    const reponse = new ControleReponse(data.ModeControle, data.StatutControle, data.Id, data.CurrentUtilisateur);
                    reponse.Id(options.data.Reponse.Id);
                    reponse.OldValue(options.data.Reponse.OldReponse.toString());
                    reponse.Value(options.data.Reponse.Reponse.toString());
                    reponse.Bloquante(options.data.Bloquante);
                    reponse.Justification(options.data.Reponse.Justification);
                    reponse.NouvelleJustification(options.data.Reponse.NouvelleJustification);
                    reponse.CommentairePrestaEvalFournisseur(options.data.Reponse.CommentairePrestaEvalFournisseur);
                    reponse.Author(options.data.Reponse.Author);
                    reponse.AuthorId(options.data.Reponse.AuthorId);
                    reponse.Valideur(options.data.Reponse.Valideur);
                    reponse.ValideurId(options.data.Reponse.ValideurId);
                    reponse.StatutReponse(options.data.Reponse.StatutReponse);
                    reponse.Niveau = options.data.Reponse.Niveau;
                    reponse.DateValidation(options.data.Reponse.DateValidation);
                    reponse.DateNonConforme(options.data.Reponse.DateNonConforme);
                    reponse.SetDroit();

                    //les fichiers de la réponse
                    $.each(options.data.Reponse.ListeFichier, (ix, f: any) => {
                        let fichier = new ControleFichier();
                        fichier.Id(f.Id);
                        fichier.TypeFichier(f.TypeFichier);
                        fichier.NomFichier(f.NomFichier);
                        fichier.Url(f.Url);
                        fichier.Attached = true;

                        reponse.ListeFichiers.push(fichier);
                    });

                    //les actions
                    //
                    $.each(options.data.Reponse.Actions, (ix, a: any) => {
                        const action = new ControleAction(reponse, data.ModeControle, data.CurrentUtilisateur, a.TypeAction);
                        action.Id(a.Id);
                        action.Author(a.Author);
                        action.AuthorId(a.AuthorId);
                        action.DestinataireId(a.DestinataireId);
                        action.Message(a.Message);
                        action.DateAction(a.DateAction);

                        // Les fichiers de la réponse
                        $.each(a.ListeFichier, (ix2, f2: any) => {
                            let fichierAction = new ControleFichier();
                            fichierAction.Id(f2.Id);
                            fichierAction.TypeFichier(f2.TypeFichier);
                            fichierAction.NomFichier(f2.NomFichier);
                            fichierAction.Url(f2.Url);
                            fichierAction.Attached = true;

                            action.ListeFichiers.push(fichierAction);
                        });

                        reponse.Actions.push(action);
                    });

                    // Juste pour identifier la dernière actions
                    if (reponse.Actions().length > 0) {
                        reponse.Actions()[reponse.Actions().length - 1].IsLastAction(true);
                    }
                    question.Reponse = reponse;
                    return question;
                }
            }
        };

        //pour baisser typescript
        ko["mapping"].fromJS(data, mapping, this);

        // la date de contrôle ne doit pas dépasser la date du jour
        $(".datepicker").datepicker({
            maxDate: 'now'
        });

        // Situation ou les nombres clés sont bloqués
        if (data.KeyNumbersAreLocked === true) {
            this._fixedCompletudeControle = data.FixedCompletudeControle;
            this._fixedCompletudeConformiteControle = data.FixedCompletudeConformiteControle;
            this._fixedConformiteControle = data.FixedConformiteControle;
        }

        this.Questions.extend({ rateLimit: 50 });

        // on mets l'event de sortie de page
        //MP:Je le vire j'en est mise une vachement plus sexy
        window.onbeforeunload = null;
    }

    // information générale sur le controle en cours
    Id = ko.observable<number>(0);
    FkIdAffaire = ko.observable<number>(0);
    FkIdMesureQualite = ko.observable<number>(0);
    TypeLiaison = ko.observable<number>(0);
    Tranche = ko.observable<number>(0);
    Niveau = ko.observable<number>(0);
    Entreprise = ko.observable<string>("");
    UrlRedirection = ko.observable<string>("");
    IsEvaluationFournisseur = ko.observable<boolean>(false);
    // conplément d'information
    NumeroAffaire = ko.observable<string>("NumeroAffaire");
    GammeControle = ko.observable<string>("GammeControle");
    SeuilConformite = ko.observable<number>(0);
    SeuilCompletude = ko.observable<number>(0);
    StatutControle = ko.observable<number>(0);

    DateControle = ko.observable<string>();

    CurrentUtilisateur = ko.observable<string>("MyName");
    Commentaire = ko.observable<string>("");
    CommentaireAll = ko.observable<string>("");
    Questions = ko.observableArray<ControleQuestion>();
    ValideurVouluControle = ko.observable<string>("");

    // Pour que le délai affiché soit bien celui de la DR sur un des messages à la validation
    DelaiReponsePrestataire = ko.observable<number>();

    /**
     * Pour désactiver les boutons pendant le POST
     */
    Posting = ko.observable(false);

    HasBeenSentForValidation = ko.pureComputed(() => {
        return this.StatutControle() >= 2 /*EnAttenteValidation*/ && this.StatutControle() !== 50 /*ControleARealiser*/;
    });

    IsTermine = ko.computed(() => {
        return this.StatutControle() === 10/*terminé*/;
    });

    /**
     * Mode de fonctionnement du formulaire
     */
    ModeControle = ko.observable<string>(TypeModeControle.Controleur.toString());

    CanReset = ko.computed(() => {
        return this.ModeControle() === TypeModeControle.Controleur.toString()
            && this.StatutControle() === 0/*brouillon*/;
    });

    IsControleur = ko.computed(() => {
        return this.ModeControle() === TypeModeControle.Controleur.toString() || this.ModeControle() === TypeModeControle.EvalFounisseurEvaluateur.toString()
            && !this.IsTermine();
    });

    IsValideur = ko.computed(() => {
        return (this.ModeControle() === TypeModeControle.Validateur.toString() || this.ModeControle() === TypeModeControle.EvalFounisseurValideur.toString())
            && !this.IsTermine();
    });

    IsPrestataire = ko.computed(() => {
        return this.ModeControle() === TypeModeControle.Prestataire.toString()
            && !this.IsTermine();
    });

    IsLectureSeul = ko.computed(() => {
        return this.ModeControle() === TypeModeControle.LectureSeul.toString()
            && !this.IsTermine();
    });


    ShowValidAndSend = ko.computed(() => {
        const sc = this.StatutControle();
        if (this.IsEvaluationFournisseur()) {
            return this.ModeControle() === TypeModeControle.EvalFounisseurEvaluateur.toString()
                && sc !== TypeStatutControleEnum.Termine
                && sc !== TypeStatutControleEnum.EnAttenteReponsePrestataire;
        }
        else {
            return this.ModeControle() === TypeModeControle.Controleur.toString()
                && sc !== TypeStatutControleEnum.Termine
                && sc !== TypeStatutControleEnum.EnAttenteReponsePrestataire;
        }

    });


    ShowValiderPrestataire = ko.computed(() => {
        const sc = this.StatutControle();

        return (this.ModeControle() === TypeModeControle.Prestataire.toString()
            && (sc !== TypeStatutControleEnum.Termine || this.IsEvaluationFournisseur())
            && sc !== TypeStatutControleEnum.EnAttenteReponseEnedis)
            ;
    });

    ShowSaveDraft = ko.computed(() => {
        return this.IsControleur() && (this.StatutControle() === TypeStatutControleEnum.Brouillon
            || this.StatutControle() === TypeStatutControleEnum.ControleARealiser);
    });

    ShowSaveForERDF = ko.computed(() => {
        const sc = this.StatutControle();

        return this.IsValideur() && (sc === TypeStatutControleEnum.EnAttenteValidation /*EnAttenteValidation*/
            || sc === TypeStatutControleEnum.EnAttenteReponseEnedis
            || sc === TypeStatutControleEnum.EnAttenteReponseEnedisEtPrestataire
            || sc === 9 /*DemandeDeLevee*/
        );
    });

    OrderBy = ko.observable("ordre");

    OrderByTheme() {
        this.OrderBy("theme");
    };

    IsOrderByTheme = ko.computed(() => {
        return this.OrderBy() === "theme";
    });

    OrderByOrdre() {
        this.OrderBy("ordre");
    };

    IsOrderByOrdre = ko.computed(() => {
        return this.OrderBy() === "ordre";
    });

    getTextValideur = ko.computed(() => {
        const statutControle = this.StatutControle();
        let valideur = '';

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse != null && q.Reponse.Valideur() != null) {
                valideur = q.Reponse.Valideur();
                // on a trouvé le valideur on sort du $.each
                return false;
            } else {
                // poursuite de l'itération (pour que tous les chemins retournent une valeur on retourne true)
                return true;
            }
        });

        if (valideur != null) {
            return valideur;
        }

        // Si on arrive ici c'est que : soit c'est validé automatiquement, soit il n'y a pas encore de validation
        switch (statutControle) {
            case TypeStatutControleEnum.Brouillon:
                return 'Pas encore soumis à validation';
            case TypeStatutControleEnum.EnAttenteValidation:
                return 'En attente de validation';
            case TypeStatutControleEnum.EnAttenteReprise:
            case TypeStatutControleEnum.EnAttenteReponsePrestataire:
            case TypeStatutControleEnum.EnAttenteReponseEnedis:
            case TypeStatutControleEnum.Termine:
                return 'Validé automatiquement';
            default:
                return '';
        }
    });

    getCssValideur = ko.computed(() => {
        const statutControle = this.StatutControle();

        switch (statutControle) {
            case TypeStatutControleEnum.EnAttenteValidation:
            case TypeStatutControleEnum.EnAttenteReprise:
            case TypeStatutControleEnum.Brouillon:
                return { fontStyle: 'italic', color: '#808080' };

            default:
                return { fontStyle: 'normal', color: '#333333' };
        }
    });

    getDateValidationText = ko.computed(() => {
        const statutControle = this.StatutControle();
        let date;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse !== null && q.Reponse.DateValidation() !== null) {
                if (typeof date === "undefined" || date < q.Reponse.DateValidation()) {
                    date = q.Reponse.DateValidation();
                }
            }
        });

        switch (statutControle) {
            case TypeStatutControleEnum.EnAttenteValidation:
            case TypeStatutControleEnum.EnAttenteReprise:
            case TypeStatutControleEnum.Brouillon:
                return '';

            default:
                return (moment(date).format('DD/MM/YYYY'));
        }
    });

    isAgentEnedis(isAgentEnedis) {
        return $.parseJSON(isAgentEnedis.toLowerCase());
    }

    // check de la date à la saisie et à la (tentative) de validation/envoi
    checkDateControle() {
        const dateControle = this.DateControle(),
            dateDuJour = new Date();

        this.DateErrorMessage("");

        if (dateControle != null && dateControle !== '') {
            const actualDateControle = moment(dateControle, 'DD-MM-YYYY').toDate();

            if (actualDateControle > dateDuJour) {
                this.DateControle('');
                this.DateErrorMessage("<span>Date de contrôle saisie supérieure à la date du jour<br/></span>");

                return false;
            }

            return true;
        }

        return false;
    }

    openDatepicker() {
        $('#CCDateControle').trigger("focus");
    }

    /*
            Tri des questions
    */
    SortedQuestions(niveau: number) {
        let addTheme = false;
        const sortedQuestions = this.Questions().filter(q => q.Reponse.Niveau === niveau).sort((left, right) => {
            if (this.IsOrderByOrdre()) {

                if (left.Ordre === right.Ordre) {
                    return 0;
                }
                return (left.Ordre < right.Ordre ? -1 : 1);
            }

            if (this.IsOrderByTheme()) {
                addTheme = true;

                if (left.Theme === right.Theme) {
                    return 0;
                }
                return (left.Theme < right.Theme ? -1 : 1);
            }

            return 0;
        });

        let sortedArray = [];

        $.each(sortedQuestions, (i, item) => {
            let theme;

            if (addTheme) {
                theme = sortedArray.filter(_ => _.Theme === item.Theme);

                if (theme.length > 0) {
                    sortedArray.push({ Theme: "", Question: item });
                } else {
                    sortedArray.push({ Theme: item.Theme, Question: item });
                }
            } else {
                sortedArray.push({ Theme: "", Question: item });
            }
        });

        return sortedArray;
    }

    /*
     * Nombres clé
     */

    CompletudeControle = ko.pureComputed(() => {
        const cc = this._computeCompletudeControle();

        if (this._fixedCompletudeControle != null) {
            return this._fixedCompletudeControle;
        }

        return cc;
    });

    _fixedCompletudeControle: number;

    _computeCompletudeControle() {
        const total = this.Questions().filter(q => q.Reponse.Niveau === this.Niveau()).length,
            answered = this.NbPointsControlesNiveauCourrant();

        if (total === 0) {
            return 0;
        }

        return Math.ceil((answered / total) * 100);
    }


    CompletudeConformiteControle = ko.pureComputed(() => {
        const ccc = this._computeCompletudeConformiteControle();

        if (this._fixedCompletudeConformiteControle != null) {
            return this._fixedCompletudeConformiteControle;
        }

        return ccc;
    });

    _fixedCompletudeConformiteControle: number;

    _computeCompletudeConformiteControle() {
        let total = 0, answered = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {

            if (q.Reponse.Bloquante()) {
                total++;

                if (q.Reponse.Valide()) {
                    answered++;
                }
            }
        });

        if (total === 0) {
            return 0;
        }

        return Math.ceil((answered / total) * 100);
    }

    ConformiteControle = ko.pureComputed(() => {
        const cc = this._computeConformiteControle();

        if (this._fixedConformiteControle != null) {
            if (!this.IsTermine && this._fixedConformiteControle !== cc)
                this._fixedConformiteControle = cc;
            return this._fixedConformiteControle;
        }

        return cc;
    });

    _fixedConformiteControle: number;

    _computeConformiteControle() {
        const val = this.NbNonConforme(),
            total = this.NbPointsControles();

        if (total === 0) {
            return 0;
        }

        return Math.ceil(100 - ((val / total) * 100));
    }

    /*
     * Petits indicateurs
     */

    NbPointsRepondus = ko.pureComputed(() => {
        let answered = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {

            if (q.Reponse.Repondu()) {
                answered++;
            }
        });

        return answered;
    });

    NbPointsControles = ko.pureComputed(() => {
        let answered = 0;
        $.each(this.Questions(), (ix, q: ControleQuestion) => {

            if (q.Reponse.Valide()) {
                answered++;
            }
        });

        return answered;
    });

    NbPointsControlesNiveauCourrant = ko.pureComputed(() => {
        let answered = 0;
        $.each(this.Questions().filter(q => q.Reponse.Niveau === this.Niveau()), (ix, q: ControleQuestion) => {

            if (q.Reponse.Valide()) {
                answered++;
            }
        });

        return answered;
    });

    NbNonConformeBloquante = ko.pureComputed(() => {
        let ret = 0;
        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (!q.Reponse.IsNonConformiteContestee() && q.Reponse.IsNonConformeBloquanteCompletude()) {
                ret++;
            }
        });

        return ret + this.NbLeveeBloquante();
    });

    NbNonConforme = ko.pureComputed(() => {
        let ret = 0;
        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (!q.Reponse.IsNonConformiteContestee() && (q.Reponse.IsNonConformeCompletude() || q.Reponse.IsNonConformeBloquanteCompletude())) {
                ret++;
            }
        });

        return ret + this.NbLevee();
    });


    NbNonTraitee = ko.pureComputed(() => {
        let nonTraitee = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && (q.Reponse.IsNonConformiteValide() || q.Reponse.IsNonConformiteRefusee())) {
                nonTraitee++;
            }
        });

        return nonTraitee;
    });

    NbDemandeLevee = ko.pureComputed(() => {
        let demandeLevee = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && q.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.DemandeDeLevee) {
                demandeLevee++;
            }
        });

        return demandeLevee;
    });

    NbDemandeContestation = ko.pureComputed(() => {
        let demandeContestation = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && q.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.DemandeContestation) {
                demandeContestation++;
            }
        });

        return demandeContestation;
    });

    NbLevee = ko.pureComputed(() => {
        let levee = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && q.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.Levee) {
                levee++;
            }
        });

        return levee;
    });

    NbConstestee = ko.pureComputed(() => {
        let contestee = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && q.Reponse.IsNonConformiteContestee()) {
                contestee++;
            }
        });

        return contestee;
    });

    NbNonTraiteeBloquante = ko.pureComputed(() => {
        let nonTraitee = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && q.Reponse.Bloquante() && (q.Reponse.IsNonConformiteValide() || q.Reponse.IsNonConformiteRefusee())) {
                nonTraitee++;
            }
        });

        return nonTraitee;
    });

    NbDemandeLeveeBloquante = ko.pureComputed(() => {
        let demandeLevee = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && q.Reponse.Bloquante() && q.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.DemandeDeLevee) {
                demandeLevee++;
            }
        });

        return demandeLevee;
    });

    NbDemandeContestationBloquante = ko.pureComputed(() => {
        let demandeContestation = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && q.Reponse.Bloquante() && q.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.DemandeContestation) {
                demandeContestation++;
            }
        });

        return demandeContestation;
    });

    NbLeveeBloquante = ko.pureComputed(() => {
        let levee = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && q.Reponse.Bloquante() && q.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.Levee) {
                levee++;
            }
        });

        return levee;
    });

    NbConstesteeBloquante = ko.pureComputed(() => {
        let contestee = 0;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            if (q.Reponse.Valide() && q.Reponse.Bloquante() && (q.Reponse.IsNonConformiteContestee())) {
                contestee++;
            }
        });

        return contestee;
    });

    /**
     * Popover
     */
    getPopoverNonConformite = ko.computed(() => {
        return '<div class="tooltip-controle-container text-warning" ><span>' +
            this.NbNonTraitee() +
            '</span><span>' +
            (this.NbNonTraitee() > 1 ? ' non traitées' : ' non traitée') +
            '</span><hr /><span>' +
            this.NbDemandeLevee() +
            '</span><span>' +
            (this.NbDemandeLevee() > 1 ? ' demandes de levée' : ' demande de levée') +
            '</span><br /><span>' +
            this.NbDemandeContestation() +
            '</span><span>' +
            (this.NbDemandeContestation() > 1 ? ' contestations' : ' contestation') +
            '</span><hr /><span>' +
            this.NbLevee() +
            '</span><span>' +
            (this.NbLevee() > 1 ? ' levées' : ' levée') +
            '</span> <br /><i><span>' +
            this.NbConstestee() +
            '</span><span>' +
            (this.NbConstestee() > 1 ? ' contestées' : ' contestée') +
            '</span></i></div>';
    });

    getPopoverNonConformiteBloquante = ko.computed(() => {
        return '<div class="tooltip-controle-container" style="color:red;" ><span>' +
            this.NbNonTraiteeBloquante() +
            '</span><span>' +
            (this.NbNonTraiteeBloquante() > 1 ? ' non traitées' : ' non traitée') +
            '</span><hr /><span>' +
            this.NbDemandeLeveeBloquante() +
            '</span><span>' +
            (this.NbDemandeLeveeBloquante() > 1 ? ' demandes de levée' : ' demande de levée') +
            '</span><br /><span>' +
            this.NbDemandeContestationBloquante() +
            '</span><span>' +
            (this.NbDemandeContestationBloquante() > 1 ? ' contestations' : ' contestation') +
            '</span><hr /><span>' +
            this.NbLeveeBloquante() +
            '</span><span>' +
            (this.NbLeveeBloquante() > 1 ? ' levées' : ' levée') +
            '</span> <br /><i><span>' +
            this.NbConstesteeBloquante() +
            '</span><span>' +
            (this.NbConstesteeBloquante() > 1 ? ' contestées' : ' contestée') +
            '</span></i></div>';
    });

    /**
     * COMMENTAIRE
     */
    HasCommentaire = ko.computed(() => {
        return this.Commentaire() !== "" && this.Commentaire() !== null && this.Commentaire() !== undefined;
    });
    /**
     * COMMENTAIRE pour tous
     */
    HasCommentaireAll = ko.computed(() => {
        return this.CommentaireAll() !== "" && this.CommentaireAll() !== null && this.CommentaireAll() !== undefined;
    });

    /**
     * ACTIONS
     */
    reset() {
        return () => {
            //on supprime également les messages d'erreur
            this.HasError(false);

            $.each(this.Questions(), (ix, q: ControleQuestion) => {
                q.Reponse.Value(TypeControleChantierReponse.Null);
                // On supprime également les messages d'erreur
                q.HasError(false);
            });
        };
    }

    SaveMode = 0;

    HasError = ko.observable<boolean>(false);
    HasNewAction = ko.observable<boolean>(false);
    ErrorMessage = ko.observable<string>("");
    DateErrorMessage = ko.observable<string>("");

    /**
     * Valide le formulaire de controle des chantiers
     */
    private Valid = (): boolean => {
        // On valide toute les questions
        let isValid = true;

        $.each(this.Questions(), (ix, q: ControleQuestion) => {
            const valid = q.Valid();

            if (!valid && isValid) {
                isValid = false;
            }

            //Est-ce qu'on a de nouvelles actions ?
            if (q.HasNewAction()) {
                this.HasNewAction(true);
            }
        });

        if (!this.HasNewAction() && (this.ModeControle() === TypeModeControle.Prestataire.toString() && !this.IsEvaluationFournisseur())) {
            isValid = false;
            this.ErrorMessage("Il n'y a aucune nouvelle réponse de votre part à valider<br/>");
            this.HasError(true);
        } else {
            if (!isValid) {
                if (this.ModeControle() === TypeModeControle.Validateur.toString()) {
                    this.ErrorMessage("Il reste des non-conformités à traiter<br/>");
                } else {
                    this.ErrorMessage("Une des questions est en erreur<br/>");
                }

                this.HasError(true);
            }
        }

        //la date de controle est obligatoire
        if (!this.checkDateControle()) {
            isValid = false;
            this.ErrorMessage("La date de contrôle est obligatoire et ne doit pas être supérieure à la date du jour<br/>");
            this.HasError(true);
        }

        return isValid;
    }

    AskForValideur = () => {
        //reset erreur
        this.HasError(false);
        this.HasNewAction(false);

        if (this.Valid()) {

            const modal = $('#modal-choix-valideur');

            if (modal === undefined || modal == null) {
                return;
            }

            modal.modal({
                backdrop: true,
                keyboard: true,
                show: true
            });

            return;
        }
    }

    BouttonRetourAffaire = () => {
        document.location.assign(this.UrlRedirection());
    }

    // Vrai sauvegarde du controle
    ValidAndSend = () => {

        //reset erreur
        this.HasError(false);
        this.HasNewAction(false);

        if (this.Valid()) {

            this.SaveMode = 1;


            //traitement des différents cas : voir CdC page 68
            if (!this.IsEvaluationFournisseur() && this.ModeControle() === TypeModeControle.Prestataire.toString()) {

                const confirmboxCallback = (v) => {
                    if (v === true) {
                        this.PostData();
                    }
                };

                if (this.Questions().some(this.NonBloquanteNonConforme.bind(this))) {
                    //cas 2
                    const delai = this.DelaiReponsePrestataire();
                    const message =
                        `<h3>Certaines non-conformités n'ont pas été traitées.</h3>Vous pourrez les solder dans un délai de ${delai} jours une fois vos premiers retours traités par nos services.`;
                    $.fn.confirmBoxCallbackBS3(
                        message,
                        confirmboxCallback
                    );
                } else if (this.Questions().some(this.BloquanteNonConforme.bind(this))) {
                    //cas 4
                    $.fn.confirmBoxCallbackBS3(
                        "<h3>Il reste des non-conformités bloquantes non traitées.</h3>La réception de votre prestation ne pourra intervenir qu'une fois toutes les non-conformités bloquantes levées.Nous vous invitons à mener les actions nécessaires dans les plus brefs délais.",
                        confirmboxCallback
                    );
                } else {
                    //cas 1
                    $.fn.confirmBoxCallbackBS3(
                        "<h3>Merci ! Vous avez traité l'ensemble des points soulevés.</h3>Nos services étudieront prochainement la suite à donner à votre retour.",
                        confirmboxCallback
                    );
                }


            } else {
                this.PostData();
            }
        }
    }

    private NonConforme(controleQuestion: ControleQuestion) {
        return controleQuestion.Reponse.IsNonConforme() &&
            (controleQuestion.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.Validee ||
                controleQuestion.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.ValideeAutomatiquement ||
                controleQuestion.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.ContestationRefusee ||
                controleQuestion.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.LeveeRefusee);
    }

    private NonBloquanteNonConforme(controleQuestion: ControleQuestion) {
        return !controleQuestion.Bloquante() &&
            this.NonConforme(controleQuestion);
    }

    private BloquanteNonConforme(controleQuestion: ControleQuestion) {
        return controleQuestion.Bloquante() &&
            this.NonConforme(controleQuestion);
    }

    // Sauvegarde en mode brouillon
    Save() {
        //reset erreur
        this.HasError(false);

        if (this.Valid()) {
            this.SaveMode = 0;
            this.PostData();
        }
    }

    PostData() {
        //on enleve l'event de sortie de page
        window.onbeforeunload = null;

        if (this.Posting()) {
            return false;
        }

        // MR : pas fier de moi mais pour débugger au fils de l'eau c'était pratique
        const mapping = {
            'ignore': IgnoredFields
        };

        this._beforeMapping();

        let data: any = ko["mapping"].toJS(this, mapping);
        data.SaveMode = this.SaveMode;
        data.CompletudeControle = this.CompletudeControle();
        data.ConformiteControle = this._computeConformiteControle();
        data.CompletudeConformiteControle = this.CompletudeConformiteControle();
        data.ValideurVouluControle = this.ValideurVouluControle();
        data["__RequestVerificationToken"] = $.fn.findValidationToken();

        // ça va POSTER
        this.Posting(true);

        $.ajax({
            url: '/ControleChantier/TakeControle',
            type: 'POST',
            data: data
        }).done(r => {
            if (r.IsRacing !== undefined && r.NumeroIEP !== undefined) {
                window.location.href =
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    this.UrlRedirection();
                return;
            }
            if (r.url === undefined) {
                //error
                return;
            }
            if (r.url === "RELOAD") {
                //recharge la page
                window.location.reload(true);
            } else {
                window.location.assign(r.url);
            }

        }).fail((e) => {
            this.Posting(false);
        });

        return true;
    }

    Cancel = () => {
        const callB = (isCancel) => {
            if (isCancel) {
                // on annule les modifs, en vrai c'est juste les fichiers rattachés
                $.each(this.Questions(),
                    (ix, q: ControleQuestion) => {

                        $.each(q.Reponse.ListeFichiers(),
                            (ix, f: ControleFichier) => {
                                if (!f.Attached) {
                                    q.Reponse.RemoveFichier(f);
                                }
                            });

                    });

                //on redirige
                document.location.assign(this.UrlRedirection());
            }

            return false;
        };

        $.fn.confirmBoxCallbackBS3('<h3>Vous allez quitter cette page!</h3> Vos eventuelles modifications ne seront pas enregistrées.<br/> <br/>Voulez-vous continuer?', callB);
    }

    _beforeMapping() {
        $.each(this.Questions(), (ix, v: ControleQuestion) => {
            v._beforeMapping();
        });
    }

}
