﻿import { FicheComptageUtils } from "./pposr-fiche-comptage-utils";
import { DisjoncteurDepose } from "./DisjoncteurDepose";
// ReSharper disable InconsistentNaming

export class DisjoncteurPose extends DisjoncteurDepose {
    IsAccessible = ko.observable<boolean>();
    Annee = ko.observable<string>();
    TypeNbPole = ko.observable<string>();
    TypeReglage = ko.observable<string>();
    TypeCalibre = ko.observable<string>();
    TypeNatureProtection = ko.observable<string>();
    TypeEmplacement = ko.observable<string>();

    constructor(data) {
        super(data);
        this.IsAccessible(data.IsAccessible);
        this.Annee(data.Annee);
        this.TypeNbPole(data.TypeNbPole);
        this.TypeCalibre(data.TypeCalibre);
        this.TypeNatureProtection(data.TypeNatureProtection);
        this.TypeEmplacement(data.TypeEmplacement);
        this.TypeReglage(data.TypeReglage);
    }

    _TypeNbPoleDisjoncteurAsString = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TypeNbPole() != null
                ? this.TypeNbPole().toString()
                : "";
        }
    });

    _TypeCalibreDisjoncteurAsString = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TypeCalibre() != null
                ? this.TypeCalibre().toString()
                : "";
        }
    });

    /* Zone des "is" something */
    _isNbPolesM() {
        return this._TypeNbPoleDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forNbPolesM;
    }
    _isNbPolesT() {
        return this._TypeNbPoleDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forNbPolesT;
    }

/* Zone des "init" something */
    _TypeEmplacementDisjoncteurArray = ko.computed(() => {
        return FicheComptageUtils.initEmplacCcpi(FicheComptageUtils.keysForGestion.keyEmplacementDisj);
    });

    _TypeCalibreDisjoncteurArray = ko.computed(() => {
        let key: string;

        if (this._isNbPolesM()) {
            key = FicheComptageUtils.keysForGestion.keyTypePuissSoutirageM;
        } else if (this._isNbPolesT()) {
            key = FicheComptageUtils.keysForGestion.keyTypePuissSoutirageT;
        } else {
            key = FicheComptageUtils.keysForGestion.keyTypePuissSoutirageNone;
        }

        // Si pas de valeur on la force à "Inconnu" pour permettre la sauvegarde en brouillon
        if (this.TypeCalibre() === null || this.TypeCalibre() === undefined) {
            this.TypeCalibre(FicheComptageUtils.reglesGestion.forTypeCalibreDisjoncteurInconnu);
        }

        return FicheComptageUtils.reglesGestion.forTypeCalibreDisjoncteurArray[key];
    });

    _TypeReglageDisjoncteurArray = ko.computed(() => {
        let key: string = "";

        if (this._isNbPolesM()) {
            if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC05A10) {
                key = FicheComptageUtils.keysForGestion.keyMono05A10;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC5A15) {
                key = FicheComptageUtils.keysForGestion.keyMono5A15;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC10A30) {
                key = FicheComptageUtils.keysForGestion.keyMono10A30;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC15A45) {
                key = FicheComptageUtils.keysForGestion.keyMono15A45;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC30A60) {
                key = FicheComptageUtils.keysForGestion.keyMono30A60;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC60A90) {
                key = FicheComptageUtils.keysForGestion.keyMono60A90;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC60A60) {
                key = FicheComptageUtils.keysForGestion.keyMono60A60;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC90A90) {
                key = FicheComptageUtils.keysForGestion.keyMono90A90;
            }
        }
        else if (this._isNbPolesT()) {
            if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC5A15) {
                key = FicheComptageUtils.keysForGestion.keyTri5A15;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC10A30) {
                key = FicheComptageUtils.keysForGestion.keyTri10A30;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC30A60) {
                key = FicheComptageUtils.keysForGestion.keyTri30A60;
            }
            else if (this._TypeCalibreDisjoncteurAsString() === FicheComptageUtils.reglesGestion.forC60A60) {
                key = FicheComptageUtils.keysForGestion.keyTri60A60;
            }
        }

        return  FicheComptageUtils.reglesGestion.forTypeReglageDisjoncteurArray[key];
    });
}
