﻿;
(function ($) {
    $.fn.dependentList = function () {
        var options = null;

        // Pas d'argument : bye bye
        if (arguments.length == 0) {
            return this;

            // Un seul argument de type object
        } else if (arguments.length == 1 && $.isPlainObject(arguments[0])) {
            options = arguments[0];

            // Plusieurs arguments, on mappe les choses
        } else if (arguments.length > 1) {
            options = {
                'source': arguments[0],
                'destitem': arguments[1]
            };

            if (arguments.length > 2) {
                options['ajaxLoader'] = arguments[2];
            }

            if (arguments.length > 3) {
                options['callback'] = arguments[3];
            }
        }

        var settings = $.extend({
            'source': null,
            'destitem': null,
            'ajaxLoader': null,
            'callback': null
        }, options);

        // Il nous faut au moins ces deux params pour fonctionner
        if (settings.source == null || settings.destitem == null) {
            return this;
        }

        return this.each(function () {
            var me = $(this);

            var destItem = $(settings.destitem);

            // Il nous faut un select pour travailler
            if (!me.is('select') || destItem.length == 0 || !destItem.is('select')) {
                return;
            }

            me.change(function () {
                $(settings.ajaxLoader).show();

                $.getJSON(settings.source, { search: me.val(), alreadySelected:destItem.val() }, function (data) {
                    if (data != null && $.isArray(data) && data.length > 0) {
                        destItem.empty();

                        $.each(data, function (index, item) {
                            if (item.Value == null || item.Libelle == null) {
                                return;
                            }

                            var newItem = $('<option />').attr('value', item.Value).html(item.Libelle);
                            if(item.Selected) {
                                newItem.attr('selected', 'selected');
                                console.log('selected');
                            }
                            destItem.append(newItem);
                        });

                        if ($.isFunction(settings.callback)) {
                            try {
                                settings.callback(me, destItem);
                            } catch (e) {
                                // Pas de croutage à cause d'un callback mal codé
                            }
                        }

                        $(settings.ajaxLoader).hide();
                    }
                });
            });
        });
    };
})(jQuery);
