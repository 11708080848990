﻿import { PhasePlanning } from "./PhasePlanning";
import { regexMail, regexTel } from "./inspection-commune-prealable";

export class Intervenant {
    Id = ko.observable<number>();
    FkId = ko.observable<number>();
    RechercheRapide = ko.observable<string>();
    FkIdIntervenant = ko.observable<string>();
    Mail = ko.observable<string>().extend({
        pattern: regexMail
    });
    Telephone = ko.observable<number>().extend({
        pattern: regexTel
    });
    NomPrenomLibelle = ko.observable<string>().extend({ required: "Le libellé est obligatoire" });
    Activite = ko.observable<string>().extend({ required: "L'activité de la partie en présence est obligatoire" });
    TypePresence = ko.observable<number>().extend({ required: "" });
    NumeroCommande = ko.observable<string>();
    NatureIntervention = ko.observable<string>();
    NbSalariesAffectes = ko.observable<number>()/*.extend({ numeric: 0 })*/;
    Qualification = ko.observable<string>();
    RemarquesAvis = ko.observable<string>();
    ListPhases = ko.observableArray<PhasePlanning>();
    IsTemporaire = ko.observable<Boolean>();
    PhaseTemporaire = ko.observable<PhasePlanning>(null);
    IsChargeDeLaSecurite = ko.observable<Boolean>();
    IsPresent = ko.observable<Boolean>();

    WantToBeSignataire = ko.observable<Boolean>(false);
    IsPourOrdre = ko.observable<Boolean>(false);
    _CanEditPourOrdre = ko.observable<Boolean>(false);

    //Juste un alias pour le binding
    PhasesPlanningInspectionCommunePrealableKo = ko.computed({
        owner: this, read: () => {
            return this.ListPhases;
        }
    });

    constructor(data: number);
    constructor(data: Intervenant, fromTemporaire: boolean);
    constructor(data?: any, fromTemporaire?: boolean) {
        if (data) {
            if (fromTemporaire) {
                this.Id(data.Id());
                this.FkId(data.FkId());
                this.FkIdIntervenant(data.FkIdIntervenant());
                this.Mail(data.Mail());
                this.Telephone(data.Telephone());
                if (data.Telephone() != 'null') {
                    this.Telephone(data.Telephone());
                }
                this.NomPrenomLibelle(data.NomPrenomLibelle());
                this.Activite(data.Activite());
                this.TypePresence(data.TypePresence());
                this.NumeroCommande(data.NumeroCommande());
                this.NatureIntervention(data.NatureIntervention());
                this.NbSalariesAffectes(data.NbSalariesAffectes());
                this.Qualification(data.Qualification());
                this.RemarquesAvis(data.RemarquesAvis());
                this.IsTemporaire(data.IsTemporaire());
                this.IsChargeDeLaSecurite(data.IsChargeDeLaSecurite());
                this.IsPresent(data.IsPresent());
                this.IsPourOrdre(data.IsPourOrdre());
                this.WantToBeSignataire(data.WantToBeSignataire());
            } else {
                if (typeof (data) == "number") {
                    this.TypePresence(data);
                    if (this.TypePresence() == 2) { //EE
                        this.WantToBeSignataire(true);
                    }
                } else {
                    this.Id(data.Id);
                    this.FkId(data.FkId);
                    this.FkIdIntervenant(data.FkIdIntervenant);
                    this.Mail(data.Mail);
                    if (data.Telephone && data.Telephone != 'null') {
                        this.Telephone(data.Telephone);
                    }
                    this.NomPrenomLibelle(data.NomPrenomLibelle);
                    this.Activite(data.Activite);
                    this.TypePresence(data.TypePresence);
                    this.NumeroCommande(data.NumeroCommande);
                    this.NatureIntervention(data.NatureIntervention);
                    this.NbSalariesAffectes(data.NbSalariesAffectes);
                    this.Qualification(data.Qualification);
                    this.RemarquesAvis(data.RemarquesAvis);
                    this.IsTemporaire(data.IsTemporaire);
                    this.IsChargeDeLaSecurite(data.IsChargeDeLaSecurite);
                    this.IsPresent(data.IsPresent);
                    this.IsPourOrdre(data.IsPourOrdre);
                    this.WantToBeSignataire(data.WantToBeSignataire);
                    for (var index in data.PhasesPlanningInspectionCommunePrealableKo) {
                        this.ListPhases().push(new PhasePlanning(data.PhasesPlanningInspectionCommunePrealableKo[index], this, false));
                    }
                }
            }
        }
        if (this.TypePresence() == 2 && this.isValid()) { //EE et valide (si pas valide on set la phase temporaire au moment de la validation afin d'avoir le bon NbSalariesAffectes)
            this.PhaseTemporaire(new PhasePlanning(this.NbSalariesAffectes(), this));
        }
        this.resetMaxNbSalariesAffectes();

        this.RemarquesAvis.subscribe(() => {
            window.onbeforeunload = () => "";
        });
        this.IsChargeDeLaSecurite.subscribe(() => {
            window.onbeforeunload = () => "";
        });
    }

    isValid = ko.computed({
        owner: this, read: () => {
            if (!this.NomPrenomLibelle()) {
                return false;
            }
            switch (this.TypePresence()) {
                case 4: //AUTRE
                    if (!this.Activite() || this.Telephone.hasError() || !this.Mail() || this.Mail.hasError()) {
                        return false;
                    }
                    break;
                case 1: //EU
                    if (!this.Activite()) {
                        return false;
                    }
                    break;
                case 6: //EU CEDA
                    if (!this.Activite()) {
                        return false;
                    }
                    break;
                case 2: //EE
                    if (!this.Activite()) {
                        return false;
                    }
                    break;
                case 3: //CHSCT
                    if (!this.Activite() || this.Telephone.hasError() || !this.Mail() || this.Mail.hasError()) {
                        return false;
                    }
                    break;
                case 7: //Signataire
                    if (!this.Mail() || this.Mail.hasError()) {
                        return false;
                    }
                    break;
                //Pour l'instant aucune validation pour ce bonhomme
                //case 5: //Enedis
                //    if (!this.Activite() || !this.Telephone() || this.Telephone.hasError() || !this.Mail() || this.Mail.hasError()) {
                //        return false;
                //    }
                //   break;
            }
            return true;
        }
    });

    raz() {
        this.Id(null);
        //this.FkIdInspectionCommunePrealable(null);
        this.FkIdIntervenant('');
        this.Mail('');
        this.Telephone(null);
        this.NomPrenomLibelle('');
        this.Activite('');
        //this.TypePresence(null);
        this.NumeroCommande('');
        this.NatureIntervention('');
        this.NbSalariesAffectes(null);
        this.Qualification('');
        this.RemarquesAvis('');
        this.ListPhases();
        this.IsTemporaire(false);
        this.PhaseTemporaire();
        this.RechercheRapide('');
        this.IsChargeDeLaSecurite(false);
        this.IsPresent(false);
        this.IsPourOrdre(false);
    }

    //Pour la validation des phases
    resetMaxNbSalariesAffectes() {
        for (var index in this.ListPhases()) {
            var phase = this.ListPhases()[index];
            phase.MaxNbSalariesAffectes(this.NbSalariesAffectes());
            if (phase.NbSalariesAffectes() > phase.MaxNbSalariesAffectes()) {
                //On remet a zéro si c'est pas valide
                phase.NbSalariesAffectes(0);
            }
        }
    }

    getInfoIntervenant() {
        var partternGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if (partternGuid.test(this.RechercheRapide())) {
            this.FkIdIntervenant(this.RechercheRapide());
            $.ajax({
                url: '/Affaire/GetMiniIntervenant',
                type: 'POST',
                data: $.fn.putValidationToken({
                    idIntervenant: this.FkIdIntervenant(),
                    notSoMini: true
                })
            }).done((data: any) => {
                if (data) {
                    this.Mail(data.Mail);
                    this.Telephone(data.Telephone);
                    this.NomPrenomLibelle(data.NomPrenomLibelle);
                    this.Activite(data.Activite);
                }
            });
        } else {
            //Sinon c'est une saisie libre, on swap le fkid et le libelle et on fait rien, on laisse l'user saisir les trucs
            this.NomPrenomLibelle(this.RechercheRapide());
        }
    }

    addPhase(phase: PhasePlanning) {
        if (phase.isValid()) {
            this.ListPhases.push(phase);
            //On raz la phase temporaire
            this.PhaseTemporaire(new PhasePlanning(this.NbSalariesAffectes(), this));
        }
    }

    removePhase(phase: PhasePlanning) {
        this.ListPhases.remove(phase);
        window.onbeforeunload = () => "";
    }

    validIntervenant() {
        if (this.isValid()) {
            this.IsTemporaire(false);
            window.onbeforeunload = () => "";
        }
    }

    devalid() {
        this.IsTemporaire(true);
        this.PhaseTemporaire(null);
        window.onbeforeunload = () => "";
    }
}
