﻿import { Options } from "../Options";
import { TypeFichierEnum } from "../../controle-chantiers/common";
import { ControleFichier } from "../../controle-chantiers/controle-fichier";

export class PreparationAccesEdit {
    UrlGedDeletefile: string = '/GED/DeleteFile';

    Bexs: KnockoutObservableArray<Options>;
    Bos: KnockoutObservableArray<Options>;
    Instructions: KnockoutComputed<Options[]>;
    DR: KnockoutObservable<string>;
    idDR: KnockoutObservable<number>;
    Domaine: KnockoutObservable<string>;
    FkIdCodeInsee: KnockoutObservable<number>;
    FkIdIntervenant: KnockoutObservable<string>;
    FkIdIntervenantRIP: KnockoutObservable<string>;
    FkIdInstruction: KnockoutObservable<number>;
    FkIdBEX: KnockoutObservable<number>;
    FkIdBO: KnockoutObservable<number>;
    Adresse: KnockoutObservable<string>;
    DateDebutTravaux: KnockoutObservable<moment.Moment>;
    DateDebutTravauxComputed: KnockoutComputed<string>;
    DateFinTravaux: KnockoutObservable<moment.Moment>;
    DateFinTravauxComputed: KnockoutComputed<string>;
    DateAffOuvertureFouille: KnockoutObservable<moment.Moment>;
    DateAffOuvertureFouilleComputed: KnockoutComputed<string>;
    DateOuvertureFouilleOptions: KnockoutComputed<any>;
    DateFinOptions: KnockoutComputed<any>;
    DateDebutOptions: KnockoutComputed<any>;
    DateFinDisabled: KnockoutComputed<boolean>;
    DatePrevFinAccesDisabled: KnockoutComputed<boolean>;
    DatePrevDebutAccesOptions: KnockoutComputed<any>;
    DatePrevFinAccesOptions: KnockoutComputed<any>;
    DatePrevDebutAcces: KnockoutObservable<moment.Moment>;
    DatePrevFinAcces: KnockoutObservable<moment.Moment>;
    Commune: KnockoutObservable<any>;
    Communes: KnockoutObservableArray<any>;
    CommuneLibelle: KnockoutObservable<string>;
    CommuneId: KnockoutObservable<string>;
    cancelCodeInsee: KnockoutObservable<string>;

    IntervenantsTravaux: KnockoutObservableArray<any>;
    Intervenant: KnockoutObservable<any>;
    NomIntervenant: KnockoutObservable<string>;
    IdIntervenant: KnockoutObservable<string>;

    IntervenantsRIP: KnockoutObservableArray<any>;
    IntervenantRIP: KnockoutObservable<any>;
    NomIntervenantRIP: KnockoutObservable<string>;
    IdIntervenantRIP: KnockoutObservable<string>;

    DatePrevDebutAccesComputed: KnockoutComputed<string>;
    DatePrevFinAccesComputed: KnockoutComputed<string>;
    Affaire: KnockoutObservable<any>;
    Affaires: KnockoutObservableArray<any>;
    Id: KnockoutObservable<number>;

    FkIdAffaire: KnockoutObservable<number>;

    // Init Fichier
    FileIdAff: KnockoutObservable<number>;
    FileFkId: KnockoutObservable<number>;
    FileTypeFichier: KnockoutObservable<number>;
    FileTypeFichierInterne: KnockoutObservable<number>;

    AffaireLibelle: KnockoutObservable<string>;
    IconStyle: KnockoutObservable<string>;
    StyleComputer: KnockoutComputed<string>;
    IsLoadElement: boolean = false;
    initBexId: number = -1;
    initBoId: number = -1;
    initIntervRip: string = null;


    BlocExterneDataDisabled: KnockoutObservable<boolean>;
    BlocAccesDataDisabled: KnockoutObservable<boolean>;
    BlocCommentDisabled: KnockoutObservable<boolean>;
    IsInterne: KnockoutObservable<boolean>;

    IdBexOrigin: KnockoutObservable<number>;
    IdBoOrigin: KnockoutObservable<number>;

    urlPath: KnockoutComputed<any>;
    ConfigInsee: any;


    IsFileAttachActive = ko.observable<boolean>(true);
    IsFileAttachActiveInterne = ko.observable<boolean>(true);

    isFirst: boolean = true;

    constructor(model: any) {
        // super();
        this.urlPath = model.UrlPath;
        this.IdBexOrigin = ko.observable<number>(model.Item.FkIdBEX);
        this.IdBoOrigin = ko.observable<number>(model.Item.FkIdBO);

        this.BlocExterneDataDisabled = ko.observable<boolean>(model.BlocExterneDataDisabled);
        this.BlocAccesDataDisabled = ko.observable<boolean>(model.BlocAccesDataDisabled);
        this.BlocCommentDisabled = ko.observable<boolean>(model.BlocCommentDisabled);
        this.IsInterne = ko.observable<boolean>(model.IsInterne);

        this.Bexs = ko.observableArray<Options>([]);
        this.Bos = ko.observableArray<Options>([]);
        this.Affaires = ko.observableArray<any>();

        this.CommuneLibelle = ko.observable<string>();
        this.CommuneId = ko.observable<string>();
        this.Communes = ko.observableArray(model.Communes);

        this.Intervenant = ko.observable<any>();
        this.NomIntervenant = ko.observable<string>(model.LibelleIntervenant);
        this.IdIntervenant = ko.observable<string>(model.Item.FkIdIntervenant);
        this.IntervenantsTravaux = ko.observableArray<any>(model.IntervenantsTravaux);
        this.FkIdIntervenant = ko.observable<string>();


        this.IntervenantRIP = ko.observable<any>();
        this.IntervenantsRIP = ko.observableArray<Options>([]);
        this.NomIntervenantRIP = ko.observable<string>(model.LibelleIntervenantRIP);
        this.IdIntervenantRIP = ko.observable<string>(model.Item.FkIdIntervenantRIP);
        this.FkIdIntervenantRIP = ko.observable<string>();

        this.FileIdAff = ko.observable<number>(0);
        this.FileFkId = ko.observable<number>(0);
        this.FileTypeFichier = ko.observable<number>(TypeFichierEnum.GdaPreparationAccesDoc);
        this.FileTypeFichierInterne = ko.observable<number>(TypeFichierEnum.GdaPreparationAccesDocInterne);

        this.FkIdCodeInsee = ko.observable<number>(model.Item.FkIdCodeInsee);
        //this.FkIdInstruction = ko.observable<number>();
        this.FkIdBEX = ko.observable<number>();
        this.FkIdBO = ko.observable<number>();

        this.idDR = ko.observable<number>();
        this.IconStyle = ko.observable<string>();


        this.FkIdAffaire = ko.observable<number>();

        // Les fichiers de la réponse
        $.each(model.ListeFichier, (ix2, f2: any) => {
            const fichierAction = new ControleFichier();
            fichierAction.Id(f2.Id);
            fichierAction.TypeFichier(f2.TypeFichier);
            fichierAction.NomFichier(f2.NomFichier);
            fichierAction.Url(f2.Url);
            fichierAction.Attached = true;

            this.ListeFichiers.push(fichierAction);
        });

        // Les fichiers de la réponse
        $.each(model.ListeFichierInterne, (ix2, f2: any) => {
            const fichierAction = new ControleFichier();
            fichierAction.Id(f2.Id);
            fichierAction.TypeFichier(f2.TypeFichier);
            fichierAction.NomFichier(f2.NomFichier);
            fichierAction.Url(f2.Url);
            fichierAction.Attached = true;

            this.ListeFichiersInterne.push(fichierAction);
        });

        this.IsFileAttachActiveInterne = ko.observable<boolean>(model.IsFileAttachActiveInterne == "1");
        this.IsFileAttachActive = ko.observable<boolean>(model.IsFileAttachActive == "1");


        this.Domaine = ko.observable<string>();
        this.DR = ko.observable<string>(model.LibelleDR);
        this.Commune = ko.observable<any>();


        this.Adresse = ko.observable<string>(model.Item.AdresseTrav);
        this.DateDebutTravaux = ko.observable<moment.Moment>(this.getMomentDate(model.Item.DateAffDebutTrav));
        this.DateFinTravaux = ko.observable<moment.Moment>(this.getMomentDate(model.Item.DateAffFinTrav));
        this.DateAffOuvertureFouille = ko.observable<moment.Moment>(this.getMomentDate(model.Item.DateAffOuvertureFouille));
        this.DatePrevDebutAcces = ko.observable<moment.Moment>(this.getMomentDate(model.Item.DatePrevDebutAcces));
        this.DatePrevFinAcces = ko.observable<moment.Moment>(this.getMomentDate(model.Item.DatePrevFinAcces));

        this.AffaireLibelle = ko.observable<string>(model.Item.NumeroIEP);
        this.Affaire = ko.observable<any>(null);

        // Disabled
        this.DateFinDisabled = ko.computed<boolean>(() => {
            const r = this.DateDebutTravaux() == null || this.DateDebutTravaux() == undefined || this.BlocAccesDataDisabled();
            return r;
        });
        this.DatePrevFinAccesDisabled = ko.computed<boolean>(() => {
            return this.DatePrevDebutAcces() == null || this.DatePrevDebutAcces() == undefined || this.BlocAccesDataDisabled();
        });

        // Date Options
        this.DateDebutOptions = ko.computed<any>(() => {
            return {
                locale: "fr",
                format: "DD/MM/YYYY",
                useCurrent: false
            }
        });
        this.DateFinOptions = ko.computed<any>(() => {
            if (this.DateDebutTravaux() == null) {
                return {
                    locale: "fr",
                    format: "DD/MM/YYYY",
                    useCurrent: false
                }
            }
            else {
                return {
                    minDate: this.DateDebutTravaux(),
                    locale: "fr",
                    format: "DD/MM/YYYY",
                    useCurrent: false

                }
            }
        });
        this.DateOuvertureFouilleOptions = ko.computed<any>(() => {
            return {
                locale: "fr",
                format: "DD/MM/YYYY",
                useCurrent: false
            }
        });
        this.DatePrevDebutAccesOptions = ko.computed<any>(() => {
            return {
                minDate: moment(new Date().setHours(0, 0, 0, 0)),
                locale: "fr",
                format: 'DD/MM/YYYY HH:mm',
                useCurrent: false,
            }
        });
        this.DatePrevFinAccesOptions = ko.computed<any>(() => {
            return {
                minDate: this.DatePrevDebutAcces() !== null ? moment(new Date(this.DatePrevDebutAcces().toString()).setHours(0, 0, 0, 0)) : moment(new Date().setHours(0, 0, 0, 0)),
                locale: "fr",
                format: 'DD/MM/YYYY HH:mm',
                useCurrent: false,
            }
        });

        // subscribe
        this.CommuneLibelle.subscribe((newValue: string) => {
            if(this.isFirst)
            {
                this.Commune(this.Communes()[0]);
                this.isFirst = false;
            }
            else if (newValue != null && newValue != "" && !this.Commune() && newValue.length > 2) {
                $.ajax({
                    url: "/GDA/PreparationAcces/AutocompleteCommune",
                    data: {
                        term: newValue,
                        fkIdBex: this.FkIdBEX,
                    },
                }).done((resp) => {
                    this.Communes(resp);
                });
            }
        });

        this.FkIdBO.subscribe((newValue: number) => {
            this.hideShowButton(newValue);
        });

        this.FkIdBEX.subscribe((newValue: number) => {
            this.setBexId(newValue);
            this.hideShowButton(newValue);
        });

        this.Affaire.subscribe((newValue: any) => {
            if (newValue != null) {
                this.FkIdAffaire(newValue.id);
                this.Adresse(newValue.adresse);
                this.DateDebutTravaux(newValue.dateDebutTravaux != null ? moment(new Date(newValue.dateDebutTravaux)) : null);
                this.DateFinTravaux(newValue.dateFinTravaux != null ? moment(new Date(newValue.dateFinTravaux)) : null);
                this.CommuneLibelle((newValue.commune).charAt(0).toUpperCase() + (newValue.commune).slice(1).toLowerCase());
            }
            else {
                this.FkIdAffaire(null);
                if (this.AffaireLibelle() != null) {
                    this.AffaireLibelle(this.AffaireLibelle());
                }
            }
        });

        this.Intervenant.subscribe((newValue: any) => {
            if (newValue != null) {
                this.FkIdIntervenant(newValue.id);
                this.NomIntervenant(newValue.value);
                this.autoCompleteSet("Intervenant", newValue.value);
            }
            else {
                this.NomIntervenant(null);
                this.FkIdIntervenant(null);
                this.autoCompleteSet("Intervenant", null);
            }
        });

        this.NomIntervenant.subscribe((newValue: string) => {
            if (newValue != null && newValue != "") {
                const hiddenFieldIntervenant = $("input[name='Item.FkIdIntervenant']");
                if (hiddenFieldIntervenant.val() != undefined && hiddenFieldIntervenant.val() != null) {
                    hiddenFieldIntervenant.valid();
                }
            }
        });

        this.IntervenantRIP.subscribe((newValue: any) => {
            if (newValue != null) {
                this.FkIdIntervenantRIP(newValue.id);
                this.NomIntervenantRIP(newValue.value);
                this.autoCompleteSet("IntervenantRIP", newValue.value);
            }
            else {
                this.NomIntervenantRIP(null);
                this.FkIdIntervenantRIP(null);
                this.autoCompleteSet("IntervenantRIP", null);
            }
        });

        this.NomIntervenantRIP.subscribe((newValue: string) => {
            if (newValue != null && newValue != "") {
                const hiddenFieldIntervenant = $("input[name='Item.FkIdIntervenantRIP']");
                if (hiddenFieldIntervenant.val() != undefined && hiddenFieldIntervenant.val() != null) {
                    hiddenFieldIntervenant.valid();
                }
            }
        });


        this.Commune.subscribe((newValue: any) => {
            if (newValue != null) {
                this.FkIdCodeInsee(newValue.id);
                this.initCommuneAjaxId(newValue.id);
                const displayValue = newValue.CodeInsee + " (" + newValue.id + ")";
                this.autoCompleteSet("CodeInsee", displayValue);
            }
            else {
                this.autoCompleteSet("CodeInsee");
                this.CommuneLibelle(null);
                this.FkIdCodeInsee(null);
                this.Bexs([]);
                this.Bos([]);
                this.FkIdBEX(-1);
                this.idDR(-1);
                this.DR(null);
                this.Domaine(null);
            }
        });

        this.CommuneId.subscribe((newValueId: string) => {
            if (newValueId != null && newValueId != "") {
                const commune = this.Communes().filter(c => c.id == newValueId)[0];
                if (commune != null) {
                    this.CommuneLibelle(commune.CodeInsee);
                }
            }
            this.hideShowButton(this.FkIdBEX());
        });

        this.initCommuneId(model.Item.FkIdCodeInsee);
        this.initIntervenantId(model.Item.FkIdIntervenant);

        this.initBEXBORIP(model.Item);
    }

    initBEXBORIP(item: any) {
        if (item.FkIdBEX != null) { this.initBexId = item.FkIdBEX; }
        if (item.FkIdBO != null) { this.initBoId = item.FkIdBO; }
        if (item.FkIdIntervenantRIP != null) { this.initIntervRip = String(item.FkIdIntervenantRIP).toUpperCase(); }
    }

    setRIP(codeRole: string, idDr: number): void {
        let rips = [];
        rips = this.ConfigInsee.RipItems;
        const selectedRips = $.grep(rips, function (i) { return (i.CodeRole == codeRole && i.IdDR == idDr); });

        this.IntervenantsRIP(selectedRips);
        this.autoCompleteSet("IntervenantRIP", null);

        if (this.initIntervRip != null) {
            const selectedRipId = this.initIntervRip;
            const intervRip = $.grep(selectedRips, function (i) { return (i.id == selectedRipId); });

            if (intervRip.length > 0) {
                this.autoCompleteSet("IntervenantRIP", intervRip[0].value);
                this.FkIdIntervenantRIP(selectedRipId);
            }
        }
    }

    setBos(isPs: boolean, idDr: number): void {

        if (this.ConfigInsee != null) {
            let bos = [];
            bos = this.ConfigInsee.BoItems;

            const selectedBos = $.grep(bos, function (i) { return (i.IdDomain == isPs && i.IdDR == idDr); });
            const resultBo = selectedBos.map(i => new Options(i.Label, i.Id.toString()));

            if (resultBo.length >= 1) {
                resultBo.push(new Options("", "-1"));

                resultBo.sort(function (i1, i2) {
                    return i1.Libelle > i2.Libelle ? 1 : i1.Libelle < i2.Libelle ? -1 : 0;
                });
            }
            this.Bos(resultBo);

            // Données initiales
            if (this.initBoId > 0) {
                this.FkIdBO(this.initBoId);
                this.initBoId = -1; // Désactive l'init initial'
            }
            else {
                this.FkIdBO(null);
            }

        }
    }

    setBexId(newValue: number): void {
        if (newValue !== null && newValue !== -1) {
            if (this.ConfigInsee != null) {

                let bexs = [];
                bexs = this.ConfigInsee.BexItems;
                const selectedBex = $.grep(bexs, function (i) { return (i.Id == newValue); })[0];
                if (selectedBex != null) {
                    this.DR(selectedBex.LibelleDR);
                    this.idDR(selectedBex.IdDR);
                    this.Domaine(selectedBex.LibelleDomain);
                    this.FkIdBEX(newValue);

                    const codeRole = `RIP${selectedBex.IdDomain ? "_PS" : ""}`;
                    this.setRIP(codeRole, selectedBex.IdDR);
                    this.setBos(selectedBex.IdDomain, selectedBex.IdDR);
                }
            }
        }
        else {
            this.DR(null);
            this.idDR(-1);
            this.Domaine(null);

            this.FkIdBEX(-1);

            this.FkIdBO(null);
            this.Bos(null);

            this.IntervenantsRIP(null);
            this.autoCompleteSet("IntervenantRIP", null);
            this.FkIdIntervenantRIP(null);

        }
    }


    // init methodes de chargement
    initCommuneAjaxId(codeInsee: string): void {

        const payload = {
            __RequestVerificationToken: $.fn.findValidationToken(),
            model: { CodeInsee: codeInsee, IdBexOrigin: this.IdBexOrigin, IdBoOrigin: this.IdBoOrigin },
        };
        $.ajax({
            url: String(this.urlPath),
            data: payload,
            method: 'POST'
        }).done((result: any) => {
            this.fb_initCommuneAjaxId(result);
        });
    }
    fb_initCommuneAjaxId(data: any): void {
        // todo
        this.ConfigInsee = data;

        const result = data.BexItems.map(i => new Options(i.Label + ' - ' + (i.IdDomain ? "Postes sources" : "Réseau") + ' (' + i.DelaisPrev.toString() + 'j)', i.Id.toString()));
        if (result.length > 1) {
            result.push(new Options("", "-1"));

            result.sort(function (i1, i2) {
                return i1.Libelle > i2.Libelle ? 1 : i1.Libelle < i2.Libelle ? -1 : 0;
            });
        }
        this.Bexs(result);

        if (this.initBexId > 0) {
            this.setBexId(this.initBexId);
            this.initBexId = -1; // Désactive l'init initial'
        }
        else {
            if (this.Bexs().length == 1) {
                const id = parseInt(this.Bexs()[0].Value);
                this.setBexId(id);
            }
            else {
                this.setBexId(-1);
            }
        }

        // Cas initLoad
        const hiddenFieldCodeInsee = $("input[name='Item.FkIdCodeInsee']");
        if (hiddenFieldCodeInsee.val() != undefined && hiddenFieldCodeInsee.val() != null) {
            hiddenFieldCodeInsee.valid();
        }
    }


    // init methodes de chargement
    initCommuneId(codeInsee: string): void {

        if (codeInsee != null && codeInsee != "") {
            const commune = this.Communes().filter(c => c.id == codeInsee)[0];
            if (commune != null) {
                this.CommuneLibelle(commune.CodeInsee);
            }
        }
    }

    initIntervenantId(fkIdIntervenant: string): void {
        const intervenant = this.IntervenantsTravaux().filter(i => i.id == fkIdIntervenant)[0];
        if (intervenant != null) {
            this.Intervenant(intervenant);
        }
    }

    hideShowButton(newValue: number): void {
        // Si c'est charge on fait le traitement
        if (this.IsLoadElement) {
            if ($("#PreparationAcces-form").valid()) {
                if (this.IsInterne()) {

                    const bexId = this.FkIdBEX();
                    let boId = this.FkIdBO();
                    let boIdDef = this.IdBoOrigin();
                    if (boId == -1) { boId = null; }
                    if (boIdDef == -1) { boIdDef = null; }

                    if (bexId == this.IdBexOrigin() && boId == boIdDef) {
                        $("#btnAccepter").show();
                        $("#btnAControlerBEX").show();
                        $("#btnRefuser").show();
                        $("#btnTransmettre").hide();
                    }
                    else {
                        $("#btnAccepter").hide();
                        $("#btnAControlerBEX").hide();
                        $("#btnRefuser").hide();
                        $("#btnTransmettre").show();
                    }
                }
                else {
                    $("#btnAnnuler").show();
                }
            }
            else {
                if (this.IsInterne()) {
                    $("#btnAccepter").hide();
                    $("#btnTransmettre").hide();
                }
                else {
                    $("#btnAnnuler").hide();
                }
            }
        }
    }

    // #region  autocomplete methodes
    autoCompleteSet(codePicker: string, displayLabel: string = null): void {
        if (displayLabel != null) {
            $(`.dl-search-${codePicker}`).hide();
            $(`.dl-gr-${codePicker}`).show();
            $(`.dl-tx-${codePicker}`).val(displayLabel);
        }
        else {
            $(`.dl-search-${codePicker}`).val("");
            $(`.dl-search-${codePicker}`).show();
            $(`.dl-gr-${codePicker}`).hide();
        }
    }

    autoCompleteCancel(codePicker: string): void {
        if (this.IsLoadElement) {
            {
                if (codePicker == "CodeInsee") {
                    this.Commune(null);
                }
                if (codePicker == "Intervenant") {
                    this.Intervenant(null);
                }
                if (codePicker == "IntervenantRIP") {
                    this.IntervenantRIP(null);
                }
            }
        }
    }
    // #endregion

    getAffaireList(searchTerm: string, callback: any): void {
        $.ajax({
            url: '/GDA/Interventions/InterventionAffaireList',
            data: {
                term: searchTerm
            }
        }).done(callback);
    }

    getMomentDate(date: any): any {
        let res = null;
        if (date != null) {
            res = moment(new Date(date));
            if (res.year() < 1000 || res.year() > 3000) return null;
        }
        return res;
    }

    // Liste fichiers

    ListeFichiers = ko.observableArray<ControleFichier>();
    ListeFichiersInterne = ko.observableArray<ControleFichier>();

    AddFichier = (f: ControleFichier) => {
        this.ListeFichiers.push(f);
    };


    RemoveFichier = (f: ControleFichier) => {
        let data = {};

        data["__RequestVerificationToken"] = $.fn.findValidationToken();
        data["id"] = f.Id();

        $.ajax({
            url: this.UrlGedDeletefile,
            type: 'POST',
            data: data
        }).always((e) => {
            this.ListeFichiers.remove(f);
        });
    };



    AddFichierInterne = (f: ControleFichier) => {
        this.ListeFichiersInterne.push(f);
    };


    RemoveFichierInterne = (f: ControleFichier) => {
        let data = {};

        data["__RequestVerificationToken"] = $.fn.findValidationToken();
        data["id"] = f.Id();

        $.ajax({
            url: this.UrlGedDeletefile,
            type: 'POST',
            data: data
        }).always((e) => {

            this.ListeFichiersInterne.remove(f);
        });

    };
}
