﻿import "./SubBundles/jQueryBundle";
import "./jquery-validation";
import "jquery-ui-dist/jquery-ui"
import "tablesorter";

import "../../Scripts/date-fr-FR";
import "../../Scripts/bootstrap-tabs";
import "../../Scripts/bootstrap-alerts";
import "../../Scripts/bootstrap-dropdown";
import "../../Scripts/bootstrap-modal";
import "../../Scripts/bootstrap-twipsy";
import "../../Scripts/bootstrap-popover";
import "../../Scripts/bootstrap-ajaxpopover";
import "../../Scripts/eplans";
import "./SubBundles/MomentBundle";
import "../../Scripts/jquery.autocompleteVille";
import "../../Scripts/pupload/plupload.full";
import "../../Scripts/sticky";
import "../../Scripts/jquery.fileupload-ng";
import "../../Scripts/etapes/echange-information";
import "../../Scripts/etapes/information-utilisateur";
import "../../Scripts/jquery.dependentList";
import "../../Scripts/select2";
import "../../Scripts/jquery.maskedinput-1.3";
import "../../Scripts/jquery.numeric";
import "../../Scripts/jquery.matchHeight";
import "../../Scripts/jquery.highlight";
import "../../Scripts/signature-commande/affaire-commande-general";
import "../../Scripts/gestionLogo";
import "../../Scripts/ArchivageFluxPopUp";
import "../../Scripts/GestionCookies/Cookies"

import "./SubBundles/KnockoutBundle";
