﻿import { FicheComptageUtils } from "./pposr-fiche-comptage-utils";
import { LibelleValue } from "./pposr-fiche-comptage-base";
// ReSharper disable InconsistentNaming

export class OrganeCoupure {
    Calibre = ko.observable<string>();
    TypeFusible = ko.observable<string>();
    CapaciteFusible = ko.observable<string>();
    LiaisonReseau = ko.observable<string>();
    AnneeFabricationOrganeCoupure = ko.observable<string>();
    CoupurePlusAccesssible = ko.observable<string>();
    PointCoupureBranchement = ko.observable<string>();
    ModeExecution = ko.observable<string>();
    TypeNatureOperation = ko.observable<string>();
    AccesCcpi = ko.observable<boolean>();
    EmplacementCcpi = ko.observable<string>();
    _EmplacementCcpiArray = ko.observableArray<LibelleValue>();
    AlimentationApresCompteur = ko.observable<string>();

    constructor(data) {
        this.Calibre(data.Calibre);
        this.TypeFusible(data.TypeFusible);
        this.CapaciteFusible(data.CapaciteFusible);
        this.LiaisonReseau(data.NbreFils);
        this.AnneeFabricationOrganeCoupure(data.AnneeFabricationOrganeCoupure);
        this.CoupurePlusAccesssible(data.CoupurePlusAccesssible);
        this.PointCoupureBranchement(data.PointCoupureBranchement);
        this.ModeExecution(data.ModeExecution);
        this.TypeNatureOperation(data.TypeNatureOperation);
        this.AccesCcpi(data.AccesCcpi);
        this.EmplacementCcpi(data.EmplacementCcpi);
        this.AlimentationApresCompteur(data.AlimentationApresCompteur);

        this._initListEmplacementCcpi();
    }

    _initListEmplacementCcpi() {
        this._EmplacementCcpiArray(FicheComptageUtils.initEmplacCcpi(FicheComptageUtils.keysForGestion.keyEmplacementCcpi));
    }
}
