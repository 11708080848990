﻿import { CommonInfosPrestation } from "./pposr-general";

// ReSharper disable InconsistentNaming

export class PlanificationTravauxPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'planificationtravaux');

        this._IsJ0Visible(data.IsJ0Visible);
        this.J0(data.J0);

        this._IsJLVisible(data.IsJLVisible);
        this.JL(data.JL);

        this._IsJ1Visible(data.IsJ1Visible);
        this.J1(data.J1);

        this._IsJ2Visible(data.IsJ2Visible);
        this.J2(data.J2);

        this._IsJ3Visible(data.IsJ3Visible);
        this.J3Prev(data.J3Prev);
        this.J3Real(data.J3Real);

        this._IsJ4Visible(data.IsJ4Visible);
        this.J4Prev(data.J4Prev);
        this.J4Real(data.J4Real);

        this._IsJ5Visible(data.IsJ5Visible);
        this.J5Prev(data.J5Prev);
        this.J5Real(data.J5Real);

        this._IsJ6Visible(data.IsJ6Visible);
        this.J6Prev(data.J6Prev);
        this.J6Real(data.J6Real);

        this._IsJ7Visible(data.IsJ7Visible);
        this.J7Prev(data.J7Prev);
        this.J7Real(data.J7Real);

        this.CreneauHoraire(data.CreneauHoraire);

        this._IsJ7DiVisible(data.IsJ7DiVisible);
        this.J7DiReal(data.J7DiReal);

        this._IsJ7LrVisible(data.IsJ7LrVisible);
        this.J7LrReal(data.J7LrReal);


        this._IsJ8Visible(data.IsJ8Visible);
        this.J8Real(data.J8Real);

        this._IsJ9Visible(data.IsJ9Visible);
        this.Validation(data.Validation);

        // On conserve les valeurs d'origine
        this._initOrigValues();
    }

    _IsJ0Visible = ko.observable<boolean>();
    J0 = ko.observable<string>();

    _IsJLVisible = ko.observable<boolean>();
    JL = ko.observable<string>();

    _IsJ1Visible = ko.observable<boolean>();
    J1 = ko.observable<string>();

    _IsJ2Visible = ko.observable<boolean>();
    J2 = ko.observable<string>();

    _IsJ3Visible = ko.observable<boolean>();
    J3Prev = ko.observable<string>();
    J3Real = ko.observable<string>();

    _IsJ4Visible = ko.observable<boolean>();
    J4Prev = ko.observable<string>();
    J4Real = ko.observable<string>();

    _IsJ5Visible = ko.observable<boolean>();
    J5Prev = ko.observable<string>();
    J5Real = ko.observable<string>();

    _IsJ6Visible = ko.observable<boolean>();
    J6Prev = ko.observable<string>();
    J6Real = ko.observable<string>();

    _IsJ7Visible = ko.observable<boolean>();
    J7Prev = ko.observable<string>();
    J7Real = ko.observable<string>();
    CreneauHoraire = ko.observable<string>();

    _IsJ7DiVisible = ko.observable<boolean>();
    J7DiReal = ko.observable<string>();

    _IsJ7LrVisible = ko.observable<boolean>();
    J7LrReal = ko.observable<string>();

    _IsJ8Visible = ko.observable<boolean>();
    J8Real = ko.observable<string>();

    _IsJ9Visible = ko.observable<boolean>();
    Validation = ko.observable<string>();

    _J0Orig: string;
    _JLOrig: string;
    _J1Orig: string;
    _J2Orig: string;
    _J3PrevOrig: string;
    _J3RealOrig: string;
    _J4PrevOrig: string;
    _J4RealOrig: string;
    _J5PrevOrig: string;
    _J5RealOrig: string;
    _J6PrevOrig: string;
    _J6RealOrig: string;
    _J7PrevOrig: string;
    _J7RealOrig: string;
    _CreneauHoraireOrig: string;
    _J8RealOrig: string;
    _ValidationOrig: string;

    // On ne peut sélectionner une J4Prev inférieure à la date du jour
    _J4PrevIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            if (this.J4Prev() === null || this.J4Prev() === '') {
                return true;
            }
            var J4PrevAsDate = moment(this.J4Prev(), 'DD/MM/YYYY');

            return J4PrevAsDate >= moment().startOf('day') || this.J4Prev() === this._J4PrevOrig;
        }
    });

    // On ne peut sélectionner une J5Prev inférieure à la date du jour, mais si elle est déjà là on la laisse
    // J5Prev doit aussi être supérieure ou égale à J4Prev
    _J5PrevIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            if (this.J4Prev() === null || this.J4Prev() === ''
                || this.J5Prev() === null || this.J5Prev() === '') {
                return true;
            }

            var J5PrevAsDate = moment(this.J5Prev(), 'DD/MM/YYYY');
            var J4PrevAsDate = moment(this.J4Prev(), 'DD/MM/YYYY');

            return J5PrevAsDate >= J4PrevAsDate
                && (J5PrevAsDate >= moment().startOf('day') || this.J5Prev() === this._J5PrevOrig);
        }
    });

    // On ne peut sélectionner une J6Prev inférieure à la date du jour, mais si elle est déjà là on la laisse
    // J6Prev doit aussi être supérieure ou égale à J5Prev
    _J6PrevIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            if (this.J5Prev() === null || this.J5Prev() === ''
                || this.J6Prev() === null || this.J6Prev() === '') {
                return true;
            }

            var J6PrevAsDate = moment(this.J6Prev(), 'DD/MM/YYYY');
            var J5PrevAsDate = moment(this.J5Prev(), 'DD/MM/YYYY');

            return J6PrevAsDate >= J5PrevAsDate
                && (J6PrevAsDate >= moment().startOf('day') || this.J6Prev() === this._J6PrevOrig);
        }
    });

    // J7Prev doit être supérieure ou égale à J4Prev
    _J7PrevIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            if (this.J7Prev() === null || this.J7Prev() === ''
                || this.J4Prev() === null || this.J4Prev() === '') {
                return true;
            }

            var J7PrevAsDate = moment(this.J7Prev(), 'DD/MM/YYYY');
            var J4PrevAsDate = moment(this.J4Prev(), 'DD/MM/YYYY');

            return J7PrevAsDate >= J4PrevAsDate;
        }
    });

    _isJ7PrevChanged = ko.pureComputed({
        owner: this,
        read: () => {
            return this.J7Prev() !== this._J7PrevOrig;
        }
    });

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            return this._J4PrevIsValid() && this._J5PrevIsValid() && this._J6PrevIsValid() && this._J7PrevIsValid();
        }
    });

    // Sortie du mode modification
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            this._ModeModificationGlobal(false);

            if (resetValues) {
                this.J0(this._J0Orig);
                this.JL(this._JLOrig);
                this.J1(this._J1Orig);
                this.J2(this._J2Orig);
                this.J3Prev(this._J3PrevOrig);
                this.J3Real(this._J3RealOrig);
                this.J4Prev(this._J4PrevOrig);
                this.J4Real(this._J4RealOrig);
                this.J5Prev(this._J5PrevOrig);
                this.J5Real(this._J5RealOrig);
                this.J6Prev(this._J6PrevOrig);
                this.J6Real(this._J6RealOrig);
                this.J7Prev(this._J7PrevOrig);
                this.J7Real(this._J7RealOrig);
                this.CreneauHoraire(this._CreneauHoraireOrig);
                this.J8Real(this._J8RealOrig);
                this.Validation(this._ValidationOrig);
            }

            this._disableChangeAlert();
            this._firstSwitchToModifyPlanifTravaux = false;
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        this._J0Orig = this.J0();
        this._JLOrig = this.JL();
        this._J1Orig = this.J1();
        this._J2Orig = this.J2();
        this._J3PrevOrig = this.J3Prev();
        this._J3RealOrig = this.J3Real();
        this._J4PrevOrig = this.J4Prev();
        this._J4RealOrig = this.J4Real();
        this._J5PrevOrig = this.J5Prev();
        this._J5RealOrig = this.J5Real();
        this._J6PrevOrig = this.J6Prev();
        this._J6RealOrig = this.J6Real();
        this._J7PrevOrig = this.J7Prev();
        this._J7RealOrig = this.J7Real();
        this._CreneauHoraireOrig = this.CreneauHoraire();
        this._J8RealOrig = this.J8Real();
        this._ValidationOrig = this.Validation();

        this._firstSwitchToModifyPlanifTravaux = false;
    }

    _prepareSave() {
        const self = this;

        // pas de message pour les actions à suivre, on veut justement sauver
        window.onbeforeunload = null;
        // Si pas de changement sur la J7Prev on ne créera pas de PA, ou pas de J7Prev, on peut sauver tout de suite
        if (this.J7Prev() === this._J7PrevOrig || this.J7Prev() === null || this.J7Prev() === '') {
            this._doSaveForGoodNoRefresh();

        } else {
            $.ajax({
                url: "/Affaire/IsPointArretNeeded",
                type: 'POST',
                data: $.fn.putValidationToken({
                    prestationId: this.PrestationId(),
                    j7Prev: this.J7Prev()
                })
            }).done((data: any) => {
                // Pas de retour ou PA inutile : on sauve (si pas de retour on ne va pas perdre les changements pour autant)
                if (data == null || !data.Status) {
                    this._doSaveForGoodNoRefresh();

                } else {
                        pointArret.createFromJs(false,
                            this.AffaireEtapeId(),
                            // Valeur obligatoire : PRESTATAIRE - Reprogrammation (D5)
                            "PRESTATAIRE - Reprogrammation (D5)",
                            () => this._doSaveForGoodWithRefresh(self),
                            null);
                }
            });
        }
    }

    // Fonctions ci-dessous utilisées sans paramètres car plus simple pour les passer en callback 
    // (callback utilisé aussi dans pposr-modal-planning avec fonction différente)

    // Sert à sauver pour de bon si un point d'arrêt était inutile ou s'il a été envoyé correctement
    _doSaveForGoodWithRefresh(self: PlanificationTravauxPrestationViewModel) {
        self._save('/Affaire/EnregistrerModifPlanificationTravaux', self._PrestationLinkWithHash(), self._reload, self);
    }

    // On reload si on a sauvé un PA au passage
    _reload() {
        window.location.reload();
    }

    _doSaveForGoodNoRefresh() {
        this._save('/Affaire/EnregistrerModifPlanificationTravaux', this._PrestationLinkWithHash(), this._setNewStateAfterSave, this);
    }

    _setNewStateAfterSave(self: PlanificationTravauxPrestationViewModel) {
        const success = self._PendingSave();

        if (success) {
            self._disableModify(false);

            // Les valeurs sauvées sont les nouvelles valeurs d'origine
            self._initOrigValues();

            self._PendingSave(false);
        }
    }
}
// ReSharper restore InconsistentNaming
