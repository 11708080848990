﻿$(document).ready(function () {
    var idAffaire = $("input[name='IdAffaire']").val();
    var $m = null;

    $('#btnFluxArchiver').click(function () {
        var $me = $(this);
        $.ajax({
            type: 'POST',
            url: '/Export/GetNomArchive/',
            dataType: "json",
            data: $.fn.putValidationToken({
                idAffaire: idAffaire
            }),
            success: function (data) {
                if (data === null || data === -1) {
                    $.ajax({
                        type: 'POST',
                        url: '/Export/IsEnAttenteArchiver/',
                        dataType: "json",
                        data: $.fn.putValidationToken({
                            idAffaire: idAffaire
                        }),
                        success: function (data) {

                            if (data === null || data === -1) {
                                $m = $('#ValidationModalArchivage');
                            } else {
                                $m = $('#ValidationModalenAttenteArchiver');
                                $('#EnAttenteArchivageText').text('La demande d\'export effectuée par ' + data + ' sera disponible demain');
                            }

                            if ($me.data('btnbs')) {
                                $m.removeClass("fade").addClass("hide");
                                $m.modal({
                                    backdrop: true,
                                    keyboard: true,
                                    show: true
                                });
                            } else {
                                $m.removeClass("hide").addClass("fade");
                                $m.modal();
                            }

                        }
                    });

                } else {
                    $('#TelechargerArchive').attr('href', "/DownloadExport/" + data);
                    $m = $('#RgpdAvantTelechargementModal');
                    if ($me.data('btnbs')) {
                        $m.removeClass("fade").addClass("hide");
                        $m.modal({
                            backdrop: true,
                            keyboard: true,
                            show: true
                        });
                    } else {
                        $m.removeClass("hide").addClass("fade");
                        $m.modal();
                    }

                }

            }
        });
    });

    $('#TelechargerArchive').click(function () {
        $m = $('#RgpdAvantTelechargementModal');
        if (!jQuery.isEmptyObject($m)) {
            $m.modal('hide');
        }
        $.ajax({
            type: 'POST',
            url: '/Export/SaveHistoriqueTelechargement/',
            dataType: "json",
            data: $.fn.putValidationToken({
                idAffaire: idAffaire
            })
        });
    });
    $('#CloseDemande,#CloseArchiver').click(function () {
        if (!jQuery.isEmptyObject($m)) {
            $m.modal('hide');
        }
    });
    $('#SupprimerArchiver').click(function () {
        $.ajax({
            type: 'POST',
            url: '/Export/DeleteArchivageFlux/',
            dataType: "json",
            data: $.fn.putValidationToken({
                idAffaire: idAffaire
            }),
            success: function () {
                if (!jQuery.isEmptyObject($m)) {
                    $m.modal('hide');
                }
            }
        });
    });
    $('#validerArchiver').click(function () {
        $.ajax({
            type: 'POST',
            url: '/Export/AddArchivageFlux/',
            dataType: "json",
            data: $.fn.putValidationToken({
                idAffaire: idAffaire
            }),
            success: function () {
                if (!jQuery.isEmptyObject($m)) {
                    $m.modal('hide');
                }
            }
        });
    });
});
