﻿import { CommonInfosPrestation } from "./pposr-general";
// ReSharper disable InconsistentNaming

export class SoumissionEtudePrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'soumissionetude');

        this._CanSubmit(data.CanSubmit);
        this._CanValidate(data.CanValidate);
        this._CanRefus(data.CanRefus);

        this.J3Realise(data.J3Realise);

        this.CommentairePrestataire(data.CommentairePrestataire);
        this.CommentairePrestataireLastMail(this._getCommentLastMail(this.CommentairePrestataire(), data.CommentairePrestataireLastMail));

        // On conserve les valeurs d'origine
        this._initOrigValues();
    }

    _CanSubmit = ko.observable<boolean>();
    _CanValidate = ko.observable<boolean>();
    _CanRefus = ko.observable<boolean>();

    J3Realise = ko.observable<string>();
    _IsPrepareSubmitting: boolean = false;

    CommentairePrestataire = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    CommentairePrestataireLastMail = ko.observable<string>();

    _J3RealiseOrig: string;
    _CommentairePrestataireOrig: string;
    _CommentairePrestataireLastMailOrig: string;

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this.CommentairePrestataire.hasError();
        }
    });

    // Sortie du mode modification        
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            this._ModeModificationGlobal(false);

            if (resetValues) {
                this.J3Realise(this._J3RealiseOrig);
                this.CommentairePrestataire(this._CommentairePrestataireOrig);
                this.CommentairePrestataireLastMail(this._CommentairePrestataireLastMailOrig);
            }

            this._disableChangeAlert();
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        this._J3RealiseOrig = this.J3Realise();
        this._CommentairePrestataireOrig = this.CommentairePrestataire();
        // sera modifié automatiquement à la sortie de la sauvegarde
        this._CommentairePrestataireLastMailOrig = this.CommentairePrestataireLastMail();
    }

    _prepareSave() {
        const self = this;

        if (self._isValid()) {
            this._save('/Affaire/EnregistrerModifSoumissionEtude', this._PrestationLinkWithHash(), this._setNewStateAfterSave, self);
        }
    }

    _setNewStateAfterSave(self: SoumissionEtudePrestationViewModel) {
        const success = self._PendingSave();

        if (success) {
            self._disableModify(false);

            if (self.CommentairePrestataire() !== self._CommentairePrestataireOrig) {
                self.CommentairePrestataireLastMail(self._UserEmail());
            }

            // Les valeurs sauvées sont les nouvelles valeurs d'origine
            self._initOrigValues();

            self._PendingSave(false);
        }
    }

    _prepareSubmit() {
        if (this._IsPrepareSubmitting === false) {
            this._IsPrepareSubmitting = true;

            const $modalSoumission = $('#submit-prestation-modal'),
                idPrestation = this.PrestationId();

            $.ajax({
                url: '/Affaire/GetFormSubmitPrestation',
                type: 'POST',
                data: $.fn.putValidationToken({
                    idPrestation: idPrestation
                })
            }).done((contentData: any) => {
                const $contentBlock = $modalSoumission.find('#content-submit-prestation-modal');

                $contentBlock.html(contentData);

                $modalSoumission.modal({
                    backdrop: true,
                    show: true
                });

                this._IsPrepareSubmitting = false;
            });
        }
    }

    _prepareRefus() {
        if (this._IsPrepareSubmitting === false) {
            this._IsPrepareSubmitting = true;

            this._refusPresta();

            this._IsPrepareSubmitting = false;
        }
    }
}
// ReSharper restore InconsistentNaming
