﻿import { LibelleValue } from "./pposr-fiche-comptage-base";
/*
     * Les infos qui vont nous servir pour les interactions dynamiques entre les champs
     * Evite de maintenir deux Enum, un en CS et un en TS
     * Doit avoir son équivalent dans FicheComptageV2ViewModel
     */

export class TypeCompteurDatas {
    typeTechnologieCompteur: string;
    typeNombrePole: string;
    libelle: string;
    constructeurNom: string;
    constructeurCode: string;
    constructeurCodeGinko: string;
}

export class DynamicListArray {
    condition: string;
    list: LibelleValue[];
}

export class KeysForGestion {
    keyTypeOperationBranchNeufAvecMade: string;
    keyTypeOperationBranchNeufHorsMade: string;
    keyTypeOperationPoseBp: string;
    keyTypeOperationDeposeBp: string;
    keyTypeOperationChangCompteurSurExist: string;
    keyTypeOperationDeposeDef: string;

    keyTypeInterBranchNeuf: string;
    keyTypeInterBranchProvis: string;
    keyTypeInterModifBranch: string;

    keyTypePuissSoutirageNone: string;
    keyTypePuissSoutirageM: string;
    keyTypePuissSoutirageT: string;

    keyEmplacementCcpi: string;
    keyEmplacementCompteur: string;
    keyEmplacementDisj: string;

    keyMono05A10: string;
    keyMono5A15: string;
    keyMono10A30: string;
    keyMono15A45: string;
    keyMono30A60: string;
    keyMono60A90: string;
    keyMono60A60: string;
    keyMono90A90: string;

    keyTri5A15: string;
    keyTri10A30: string;
    keyTri30A60: string;
    keyTri60A60: string;

    constructor(data) {
        this.keyTypeOperationBranchNeufAvecMade = data.KeysForGestion.KeyTypeOperationBranchNeufAvecMade;
        this.keyTypeOperationBranchNeufHorsMade = data.KeysForGestion.KeyTypeOperationBranchNeufHorsMade;
        this.keyTypeOperationPoseBp = data.KeysForGestion.KeyTypeOperationPoseBp;
        this.keyTypeOperationDeposeBp = data.KeysForGestion.KeyTypeOperationDeposeBp;
        this.keyTypeOperationChangCompteurSurExist = data.KeysForGestion.KeyTypeOperationChangCompteurSurExist;
        this.keyTypeOperationDeposeDef = data.KeysForGestion.KeyTypeOperationDeposeDef;

        this.keyTypeInterBranchNeuf = data.KeysForGestion.KeyTypeInterBranchNeuf;
        this.keyTypeInterBranchProvis = data.KeysForGestion.KeyTypeInterBranchProvis;
        this.keyTypeInterModifBranch = data.KeysForGestion.KeyTypeInterModifBranch;

        this.keyTypePuissSoutirageNone = data.KeysForGestion.KeyTypePuissSoutirageNone;
        this.keyTypePuissSoutirageM = data.KeysForGestion.KeyTypePuissSoutirageM;
        this.keyTypePuissSoutirageT = data.KeysForGestion.KeyTypePuissSoutirageT;

        this.keyEmplacementCcpi = data.KeysForGestion.KeyEmplacementCcpi;
        this.keyEmplacementCompteur = data.KeysForGestion.KeyEmplacementCompteur;
        this.keyEmplacementDisj = data.KeysForGestion.KeyEmplacementDisj;

        this.keyMono05A10 = data.KeysForGestion.KeyMono05A10;
        this.keyMono5A15 = data.KeysForGestion.KeyMono5A15;
        this.keyMono10A30 = data.KeysForGestion.KeyMono10A30;
        this.keyMono15A45 = data.KeysForGestion.KeyMono15A45;
        this.keyMono30A60 = data.KeysForGestion.KeyMono30A60;
        this.keyMono60A90 = data.KeysForGestion.KeyMono60A90;
        this.keyMono60A60 = data.KeysForGestion.KeyMono60A60;
        this.keyMono90A90 = data.KeysForGestion.KeyMono90A90;

        this.keyTri5A15 = data.KeysForGestion.KeyTri5A15;
        this.keyTri10A30 = data.KeysForGestion.KeyTri10A30;
        this.keyTri30A60 = data.KeysForGestion.KeyTri30A60;
        this.keyTri60A60 = data.KeysForGestion.KeyTri60A60;
    }
}

export class ReglesGestion {
    forTypeTechnoLinky: string;
    forTypeTechnoCem: string;
    forTypeTechnoCbe: string;

    forTypeInterBranchementNeuf: string;
    forTypeInterBranchementProvisoire: string;
    forTypeInterModificationBranchement: string;

    forTypeOpeBranchementNeufAvecMade: string;
    forTypeOpeBranchementNeufHorsMade: string;
    forTypeOpePoseBp: string;
    forTypeOpeModDeposeDefinitive: string;

    forSimpleTarif: string;
    forDoubleTarif: string;
    forTempo: string;
    forEJP: string;

    forGrilleDistributeurBase: string;
    forGrilleDistributeurDouble: string;
    forGrilleDistributeur4Postes: string;

    forNbPolesM: string;
    forNbPolesT: string;

    forEmplacementCoffret: string;

    forTypeCalibreDisjoncteurInconnu: string;
    forC05A10: string;
    forC5A15: string;
    forC10A30: string;
    forC15A45: string;
    forC30A60: string;
    forC60A90: string;
    forC60A60: string;
    forC90A90: string;

    forLabelBleuHpSimpleTarif: string;
    forLabelBleuHpDoubleTarif: string;
    forLabelBleuHpTempo: string;

    forLabelBleuHcDoubleTarif: string;
    forLabelBleuHcTempo: string;

    forLabelBlancHp: string;
    forLabelBlancHc: string;
    forLabelRougeHp: string;
    forLabelRougeHc: string;

    forLabelBleuHpLinkyBase: string;
    forLabelBleuHpLinkyDouble: string;
    forLabelBleuHpLinky4Postes: string;
    forLabelBleuHcLinkyDouble: string;
    forLabelBleuHcLinky4Postes: string;
    forLabelBlancHpLinky4Postes: string;
    forLabelBlancHcLinky4Postes: string;

    forHorairesHeuresCreusesDefaut: string;

    forTypeOptionTarifaireArray: DynamicListArray[];
    forGrilleDistributeurArray: DynamicListArray[];
    forTypeOperationArray: DynamicListArray[];
    forTypeEmplacementArray: DynamicListArray[];
    forTypeCalibreDisjoncteurArray: DynamicListArray[];
    forTypeReglageDisjoncteurArray: DynamicListArray[];

    constructor(data) {
        this.forTypeTechnoLinky = data.ForReglesGestion.ForTypeTechnoLinky.toString();
        this.forTypeTechnoCem = data.ForReglesGestion.ForTypeTechnoCem.toString();
        this.forTypeTechnoCbe = data.ForReglesGestion.ForTypeTechnoCbe.toString();

        this.forTypeInterBranchementNeuf = data.ForReglesGestion.ForTypeInterBranchementNeuf.toString();
        this.forTypeInterBranchementProvisoire = data.ForReglesGestion.ForTypeInterBranchementProvisoire.toString();
        this.forTypeInterModificationBranchement =
            data.ForReglesGestion.ForTypeInterModificationBranchement.toString();

        this.forTypeOpeBranchementNeufAvecMade = data.ForReglesGestion.ForTypeOpeBranchementNeufAvecMade.toString();
        this.forTypeOpeBranchementNeufHorsMade = data.ForReglesGestion.ForTypeOpeBranchementNeufHorsMade.toString();
        this.forTypeOpePoseBp = data.ForReglesGestion.ForTypeOpePoseBp.toString();
        this.forTypeOpeModDeposeDefinitive = data.ForReglesGestion.ForTypeOpeModDeposeDefinitive.toString();

        this.forSimpleTarif = data.ForReglesGestion.ForSimpleTarif.toString();
        this.forDoubleTarif = data.ForReglesGestion.ForDoubleTarif.toString();
        this.forTempo = data.ForReglesGestion.ForTempo.toString();
        this.forEJP = data.ForReglesGestion.ForEJP.toString();

        this.forGrilleDistributeurBase = data.ForReglesGestion.ForGrilleDistributeurBase.toString();
        this.forGrilleDistributeurDouble = data.ForReglesGestion.ForGrilleDistributeurDouble.toString();
        this.forGrilleDistributeur4Postes = data.ForReglesGestion.ForGrilleDistributeur4Postes.toString();

        this.forNbPolesM = data.ForReglesGestion.ForNbPolesM.toString();
        this.forNbPolesT = data.ForReglesGestion.ForNbPolesT.toString();

        this.forEmplacementCoffret = data.ForReglesGestion.ForEmplacementCoffret.toString();

        this.forTypeCalibreDisjoncteurInconnu = data.ForReglesGestion.ForTypeCalibreDisjoncteurInconnu.toString();
        this.forC05A10 = data.ForReglesGestion.ForC05A10.toString();
        this.forC5A15 = data.ForReglesGestion.ForC5A15.toString();
        this.forC10A30 = data.ForReglesGestion.ForC10A30.toString();
        this.forC15A45 = data.ForReglesGestion.ForC15A45.toString();
        this.forC30A60 = data.ForReglesGestion.ForC30A60.toString();
        this.forC60A90 = data.ForReglesGestion.ForC60A90.toString();
        this.forC60A60 = data.ForReglesGestion.ForC60A60.toString();
        this.forC90A90 = data.ForReglesGestion.ForC90A90.toString();

        this.forLabelBleuHpSimpleTarif = data.ForReglesGestion.ForLabelBleuHpSimpleTarif.toString();
        this.forLabelBleuHpDoubleTarif = data.ForReglesGestion.ForLabelBleuHpDoubleTarif.toString();
        this.forLabelBleuHpTempo = data.ForReglesGestion.ForLabelBleuHpTempo.toString();

        this.forLabelBleuHcDoubleTarif = data.ForReglesGestion.ForLabelBleuHcDoubleTarif.toString();
        this.forLabelBleuHcTempo = data.ForReglesGestion.ForLabelBleuHcTempo.toString();

        this.forLabelBlancHp = data.ForReglesGestion.ForLabelBlancHp.toString();
        this.forLabelBlancHc = data.ForReglesGestion.ForLabelBlancHc.toString();
        this.forLabelRougeHp = data.ForReglesGestion.ForLabelRougeHp.toString();
        this.forLabelRougeHc = data.ForReglesGestion.ForLabelRougeHc.toString();

        this.forLabelBleuHpLinkyBase = data.ForReglesGestion.ForLabelBleuHpLinkyBase.toString();
        this.forLabelBleuHpLinkyDouble = data.ForReglesGestion.ForLabelBleuHpLinkyDouble.toString();
        this.forLabelBleuHpLinky4Postes = data.ForReglesGestion.ForLabelBleuHpLinky4Postes.toString();
        this.forLabelBleuHcLinkyDouble = data.ForReglesGestion.ForLabelBleuHcLinkyDouble.toString();
        this.forLabelBleuHcLinky4Postes = data.ForReglesGestion.ForLabelBleuHcLinky4Postes.toString();
        this.forLabelBlancHpLinky4Postes = data.ForReglesGestion.ForLabelBlancHpLinky4Postes.toString();
        this.forLabelBlancHcLinky4Postes = data.ForReglesGestion.ForLabelBlancHcLinky4Postes.toString();

        this.forHorairesHeuresCreusesDefaut = data.ForReglesGestion.ForHorairesHeuresCreusesDefaut;

        this.buildArrays(data);
    }

    buildArrays(data) {
        this.forTypeOptionTarifaireArray = data.ForReglesGestion.ForTypeOptionTarifaireArray;
        this.forGrilleDistributeurArray = data.ForReglesGestion.ForGrilleDistributeurArray;
        this.forTypeOperationArray = data.ForReglesGestion.ForTypeOperationArray;
        this.forTypeEmplacementArray = data.ForReglesGestion.ForTypeEmplacementArray;
        this.forTypeCalibreDisjoncteurArray = data.ForReglesGestion.ForTypeCalibreDisjoncteurArray;
        this.forTypeReglageDisjoncteurArray = data.ForReglesGestion.ForTypeReglageDisjoncteurArray;
    }
}

export class FicheComptageUtils {
    static keysForGestion: KeysForGestion;
    static reglesGestion: ReglesGestion;

    static setUtilsValues(data) {
        this.keysForGestion = new KeysForGestion(data);
        this.reglesGestion = new ReglesGestion(data);
    }

    // Initialisation générique d'un array à partir du retour d'un appel Ajax
    static initArray(array: KnockoutObservableArray<any>, url: string, data: any) {
        array.removeAll();

        $.ajax({
            url: url,
            type: "get",
            contentType: "application/json",
            async: false,
            data: data,
            success(result) {
                $.each(result,
                    (index, value) => {
                        array.push(new LibelleValue(value.Libelle, value.Value));
                    });
            }
        });
    }

    static getTypeCompteurData(typeCompteur: number): TypeCompteurDatas {
        var ret = new TypeCompteurDatas();
        if (typeCompteur == null || typeCompteur === 0)
            return ret;

        $.ajax({
            url: "/FicheComptage/GetTypeCompteurDetail",
            type: "get",
            contentType: "application/json",
            async: false,
            data: {
                libelleTypeCompteur: typeCompteur
            },
            success(result) {
                ret.constructeurNom = result.ConstructeurNom;
            }
        });
        return ret;
    }

    static initEmplacCcpi(typeEntite: string): LibelleValue[] {
        let key = '';

        if (typeEntite === this.keysForGestion.keyEmplacementCcpi) {
            key = this.keysForGestion.keyEmplacementCcpi;
        }
        if (typeEntite === this.keysForGestion.keyEmplacementCompteur) {
            key = this.keysForGestion.keyEmplacementCompteur;
        }
        if (typeEntite === this.keysForGestion.keyEmplacementDisj) {
            key = this.keysForGestion.keyEmplacementDisj;
        }

        const array = this.reglesGestion.forTypeEmplacementArray[key];

        return array;
    }

    static identifiantIsValid(identifiant: string): boolean {
        return identifiant != null && identifiant.length === 12;
    }
}
