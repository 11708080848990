﻿import { TypeOuvrage } from "./TypeOuvrage";

export class InstructionViewModel {
    initialize = ko.observable<boolean>();
    Id = ko.observable<number>();
    AccesPermanent = ko.observable<boolean>();
    IsAccesPermanentEnabled = ko.observable<boolean>();
    NumeroDR = ko.observable<number>();
    TypeInstruction = ko.observable<number>();
    FirstDigit = ko.observable<number>();
    LastDigit = ko.observable<number>();
    Perimeter = ko.observable();
    MinDate = ko.observable<Date>();
    FkIdInstructionNationale = ko.observable<string>();
    IsReferentielDisabled = ko.observable<boolean>();
    Version = ko.observable<string>();
    NumVersion = ko.observable<string>();
    Titre = ko.observable<string>();
    DateApplication = ko.observable<string>();
    SelectedTypeOuvrage = ko.observable<string>();

    InstructionsNationales: any[];
    InstructionsDR: any[];
    TypesOuvrageOrig: any[];

    Options = ko.computed(() => {
        return {
            showOn: "both",
            minDate: this.MinDate(),
            buttonImage: "/Content/assets/calendar.png",
            buttonImageOnly: true
        }
    });

    TypesOuvrage = ko.computed(() => {
        let filteredOuvrages = [];
        const typeOuvrages: TypeOuvrage[] = [];
        switch (this.TypeInstruction()) {
            case 1:
                filteredOuvrages = this.TypesOuvrageOrig.filter(ouvrage => ouvrage.IsIPS === true).sort(ouvrage => ouvrage.LibelleLong);
                break;
            case 2:
                filteredOuvrages = this.TypesOuvrageOrig.filter(ouvrage => ouvrage.IsITST === true).sort(ouvrage => ouvrage.LibelleLong);
                break;
        }
        $.each(filteredOuvrages, (i, item) => {
            typeOuvrages.push(new TypeOuvrage(item.Id, item.LibelleLong, item.LibelleCourt));
        });

        return typeOuvrages.sort((ouvrage1, ouvrage2) => {
            if (ouvrage1.LibelleLong > ouvrage2.LibelleLong)
                return 1;
            else if (ouvrage1.LibelleLong < ouvrage2.LibelleLong)
                return -1;
            else
                return 0;
        });
    });

    NumeroCalcule = ko.computed(() => {
        const typeinstruction = this.TypeInstruction() === 1 ? "IPS" : "ITST";

        const typeouvrage = this.SelectedTypeOuvrage() !== undefined ?
            this.TypesOuvrageOrig.filter(ouvrage => ouvrage.Id === this.SelectedTypeOuvrage())[0]
            : undefined;

        if (this.TypesOuvrageOrig !== null && typeouvrage !== undefined && typeouvrage !== null &&
            this.FirstDigit() !== null && this.LastDigit() !== null) {
            const numeroCourt = this.FirstDigit() + '.' + this.LastDigit();
            const libelleCourtOuvrage = typeouvrage.LibelleCourt;
            const referentiel = this.Perimeter() === "National" ? "000" : this.NumeroDR();
            const num = typeinstruction + '-' + numeroCourt + '-' + libelleCourtOuvrage + '-' + referentiel;
            return num;
        }
        return null;
    });

    constructor(model) {
        this.initialize = ko.observable(true);
        this.Id = ko.observable(model.Id);
        this.AccesPermanent = ko.observable(model.AccesPermanent);
        this.IsAccesPermanentEnabled = ko.observable();
        this.NumeroDR = ko.observable(model.NumeroDR);
        this.TypeInstruction = ko.observable(model.TypeInstruction);
        this.FirstDigit = ko.observable(model.FirstDigit);
        this.LastDigit = ko.observable(model.LastDigit);
        this.Perimeter = ko.observable();
        this.MinDate = ko.observable(new Date());
        this.FkIdInstructionNationale = ko.observable(model.FkIdInstructionNationale);
        this.InstructionsNationales = model.InstructionsNationales;
        this.InstructionsDR = model.InstructionsDR;
        this.IsReferentielDisabled = ko.observable(!model.ScopeCanBeChanged);
        this.Version = ko.observable(`v${model.NumeroVersion}`);
        this.NumVersion = ko.observable(model.NumeroVersion);
        this.Titre = ko.observable(model.Titre);
        this.DateApplication = ko.observable(model.DateApplication);
        this.SelectedTypeOuvrage = ko.observable();
        this.TypesOuvrageOrig = model.TypesOuvrage;

        this.FkIdInstructionNationale.subscribe((newValue) => {
            if (newValue != null && newValue !== undefined) {
                const instructionsNationaleParentActive = this.InstructionsNationales.filter(i => i.Id === newValue)[0];
                this.AccesPermanent(instructionsNationaleParentActive.AccesPermanent);
                this.IsAccesPermanentEnabled(false);
                const dateApplicationNumbers = instructionsNationaleParentActive.DateApplication.match(/\d+/)[0] * 1;
                const dateApplicationInstructionNationaleParente = new Date(dateApplicationNumbers);
                this.MinDate(this.MinDate().valueOf() < dateApplicationInstructionNationaleParente.valueOf() ? dateApplicationInstructionNationaleParente : this.MinDate());
            } else {
                this.MinDate(new Date());
            }
        });

        this.Perimeter.subscribeChanged((newValue, oldValue) => {
            const numeroCalcule = $('#NumeroCalcule');
            switch (newValue) {
                case "National":
                case "National décliné en DR":
                    $("#FirstDigit").attr({ 'disabled': false });
                    $("#FirstDigit").prop('max', 8);
                    if (oldValue === "Spécifique DR") {
                        this.FirstDigit(null);
                        numeroCalcule.parents('.col-xs-3').removeClass('error').find('.error-numeroCalculeDuplicateOnDR').hide();
                    }

                    this.IsAccesPermanentEnabled(true);
                    if (this.NumeroDR() !== null) {
                        this.IsAccesPermanentEnabled(false);
                    }

                    break;
                case "Spécifique DR":
                    if (this.TypeInstruction() !== undefined && this.TypeInstruction() == 1) {
                        this.IsAccesPermanentEnabled(true);
                    }

                    $("#FirstDigit").attr({ 'disabled': 'disabled' });
                    this.FirstDigit(9);
                    break;
            }
        });

        this.Perimeter(model.Perimeter !== null ? model.Perimeter : "National");

        this.Id.subscribe(this._disableAllFields);

        this.TypeInstruction.subscribe((newValue) => {
            switch (newValue) {
                case 1:
                    if (this.NumeroDR() === null) {
                        this.IsAccesPermanentEnabled(true);
                    } else {
                        if (this.Perimeter() === "Spécifique DR") {
                            this.IsAccesPermanentEnabled(true);
                        }
                    }
                    break;
                case 2:
                    this.AccesPermanent(true);
                    this.IsAccesPermanentEnabled(false);
                    break;
            }
        });

        this.NumeroCalcule.subscribe((newValue) => {
            if (newValue !== null && newValue !== undefined && newValue !== '') {
                const numeroCalcule = $('#NumeroCalcule');
                if (this.NumeroDR() !== null) { //instructions DR
                    switch (this.Perimeter()) {
                        case "National":
                        case "National décliné en DR":
                            {
                                const instructionsNationalesActives = this.InstructionsNationales.filter(
                                    i => i.NumeroCalcule.slice(0, -4) === newValue.slice(0, -4) && i.Status === 2);
                                if (instructionsNationalesActives !== null && instructionsNationalesActives !== undefined &&instructionsNationalesActives.length > 0) {
                                    numeroCalcule.parents('.col-xs-3').removeClass('error').find('.error-numeroCalcule')
                                        .hide();
                                    this.FkIdInstructionNationale(null);
                                    this.generateRadioWithApplicableVersions(instructionsNationalesActives);
                                } else {
                                    numeroCalcule.parents('.col-xs-3').removeClass('error').find('.error-numeroCalcule')
                                        .show();
                                    this.removeRadioWithApplicableVersion();
                                    this.AccesPermanent(false);
                                    this.IsAccesPermanentEnabled(true);
                                    this.MinDate(new Date());
                                }
                            }
                            break;
                        case "Spécifique DR":
                            numeroCalcule.parents('.col-xs-3').removeClass('error').find('.error-numeroCalcule').hide();
                            this.removeRadioWithApplicableVersion();
                            this.FkIdInstructionNationale(null);
                            break;
                    }

                    if (this.isDuplicateNumeroCalculeOnDR(newValue, this.NumVersion())) {
                        numeroCalcule.parents('.col-xs-3').removeClass('error').find('.error-numeroCalculeDuplicateOnDR').show();
                    } else {
                        numeroCalcule.parents('.col-xs-3').removeClass('error').find('.error-numeroCalculeDuplicateOnDR').hide();
                    }
                } else {//instructionNationales
                    if (this.isDuplicateNumeroCalcule(newValue, this.NumVersion())) {
                        numeroCalcule.parents('.col-xs-3').removeClass('error').find('.error-numeroCalculeDuplicate').show();
                    } else {
                        numeroCalcule.parents('.col-xs-3').removeClass('error').find('.error-numeroCalculeDuplicate').hide();
                    }
                }
            }
        });
    }

    isDuplicateNumeroCalcule(numeroCalcule, numVersion) {
        const existingInstructionNationale = this.InstructionsNationales.filter(i => i.NumeroCalcule.slice(0, -4) === numeroCalcule.slice(0, -4) && i.NumVersion === numVersion)[0];
        if (existingInstructionNationale !== null && existingInstructionNationale !== undefined) {
            return true;
        }
        return false;
    }

    isDuplicateNumeroCalculeOnDR(numeroCalcule, numVersion) {
        const existingInstructionDr = this.InstructionsDR.filter(i => i.NumeroCalcule === numeroCalcule && i.NumVersion === numVersion)[0];
        if (existingInstructionDr !== null && existingInstructionDr !== undefined)
            return true;
        return false;
    }

    existingInstructionNationalesActives(numeroCalcule) {
        const existingInstructionNationale = this.InstructionsNationales.filter(i => i.NumeroCalcule.slice(0, -4) === numeroCalcule.slice(0, -4) && i.Status === 2);
        if (existingInstructionNationale !== null && existingInstructionNationale !== undefined) {
            return existingInstructionNationale;
        }
        return null;
    }

    findClosestApplicableNationaleInstructionDate(instructionsNationalesActives, dateApplicationCurrentInstruction) {
        instructionsNationalesActives = instructionsNationalesActives.filter(i => i.DateApplication <= dateApplicationCurrentInstruction);

        if (instructionsNationalesActives === null || instructionsNationalesActives === undefined || instructionsNationalesActives.length === 0)
            return null;

        instructionsNationalesActives.sort((a, b) => {
            const diffDateA = Math.abs(dateApplicationCurrentInstruction - a.DateApplication);
            const diffDateB = Math.abs(dateApplicationCurrentInstruction - b.DateApplication);
            return diffDateA - diffDateB;
        })

        return instructionsNationalesActives[0];
    }

    generateRadioWithApplicableVersions(instructionsNationalesActives) {
        $('.versionsActives').remove();
        let divVsersionApplicables = '<div class="col-xs-6 versionsActives"><label> basée sur la version';
        if (instructionsNationalesActives.length === 1) {
            const uniqueInstructionNationaleActive = instructionsNationalesActives[0];
            const dateApplication = moment(uniqueInstructionNationaleActive.DateApplication).format('dd/MM/yyyy');
            const label = `v${uniqueInstructionNationaleActive.NumVersion} version applicable au ${dateApplication} - ${uniqueInstructionNationaleActive.Titre}`;
            divVsersionApplicables += `<label><input type='radio' checked='true' disabled='true' name='FkIdInstructionNationale' value=${uniqueInstructionNationaleActive.Id}data-bind ='checked : FkIdInstructionNationale'/>${label}`;
            this.FkIdInstructionNationale(uniqueInstructionNationaleActive.Id);
        } else {
            $.each(instructionsNationalesActives, (i, item) => {
                divVsersionApplicables += `<label>${item.NumeroCalcule}<input type='radio' name='FkIdInstructionNationale' value=${item.Id}data-bind ='checked : FkIdInstructionNationale'/>`;
                const dateApplication = moment(item.DateApplication).format('dd/MM/yyyy');
                const label = `v${item.NumVersion} version applicable au ${dateApplication} - ${item.Titre}`;
                divVsersionApplicables += `<label><input type='radio' checked='true' disabled='true' name='FkIdInstructionNationale' value=${item.Id}data-bind ='checked : FkIdInstructionNationale'/>${label}`;
            });
        }
        divVsersionApplicables += '</label></div>';
        $('#blocVersion').append(divVsersionApplicables);
    }

    removeRadioWithApplicableVersion() {
        const versionActives = $('#blocVersion').find('.versionsActives');
        versionActives.remove();
    }

    enregistrer() {
        if (this.NumeroDR() !== undefined && this.NumeroDR() !== null && (this.Perimeter() === "National" || this.Perimeter() === "National décliné en DR")) {
            if (!$.fn.checkNumeroCalculeNational({
                fieldNumeroCalcule: '#NumeroCalcule',
                instructionsNationales: this.InstructionsNationales
            })) {
                return;
            }
        }

        if (this.NumeroDR() === null && this.Perimeter() === "National") {
            if (!$.fn.checkNumeroCalculeNationalDuplicate({
                fieldNumeroCalcule: '#NumeroCalcule',
                instructionsNationales: this.InstructionsNationales
            })) {
                return;
            }
        }

        if (this.NumeroDR() !== null && this.NumeroDR() !== 0) {
            if (!$.fn.checkNumeroCalculeDRDuplicate({
                fieldNumeroCalcule: '#NumeroCalcule',
                instructionsDR: this.InstructionsDR,
                numeroVersion: this.NumVersion()
            })) {
                return;
            }
        }

        if ($('#instruction-form').valid()) {
            $("input[class=mod]").removeAttr('disabled');
            $('#instruction-form').submit();
        }
    }

    private _disableAllFields(instructionId) {
        if (instructionId !== 0 || instructionId !== null) {
            $('#AccesPermanent').attr('disabled', 'disabled');
            $('#Typeinstruction').attr('disabled', 'disabled');
        }
    }
}
