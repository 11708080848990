﻿// ReSharper disable InconsistentNaming

export class DisjoncteurDepose {
    IsPresent = ko.observable<boolean>();
    IsBranchementProvisoire = ko.observable<boolean>();
    ConstructeurCode = ko.observable<string>();
    Identifiant = ko.observable<string>();
    IdentifiantCle = ko.observable<string>();

    constructor(data) {
        this.IsPresent(data.IsPresent);
        this.IsBranchementProvisoire(data.IsBranchementProvisoire);
        this.ConstructeurCode(data.ConstructeurCode);
        this.Identifiant(data.Identifiant);
        this.IdentifiantCle(data.IdentifiantCle);
    }

    _identifiantIsValid(): boolean {
        const identifiant = this.Identifiant();

        return identifiant != null && identifiant.length === 12;
    }
}
