﻿(function ($) {
    //on se bind sur l'evenements de changement d'onglet car si l'element n'est pas visible
    $(document).bind('CurrentEtapeTabChanged', function () {
        $.fn.checkInformationUtilisateur();
    });

    var checkRunning = false;

    $.fn.checkInformationUtilisateur = function () {
        if (checkRunning) {
            return;
        }
        checkRunning = true;

        var finished = function () {
            checkRunning = false;
        }

        // on identifie les étapes et champs visibles pour récupérer ensuite les infos valides correspondant à l'emplacement actuel
        var etapes = [];
        $.each($('.form-groupe'),
            function() {
                var $me = $(this),
                    etapeId = $me.closest('form').find("input[name=WfId]").val();
                
                if (etapeId != null && etapeId !== '' && $.inArray(etapeId, etapes) === -1) {
                    etapes.push(etapeId);
                }
            });

        var champs = [];
        $.each($('label[data-info-id]'),
            function () {
                var $me = $(this),
                    champId = $me.data('info-id');

                if (champId != null && champId !== '' && $.inArray(champId, champs) === -1) {
                    champs.push(champId);
                }
            });

        $.ajax({
            type: 'POST',
            data: $.fn.putValidationToken({
                etapesId: etapes,
                champsId: champs
            }),
            dataType: "html",
            url: '/InformationUtilisateur/InjectInformationUtilisateur'
        }).done(function (html) {
            if (html == null || html == "") {
                finished();
                return;
            }

            // La target
            var target = $(html).data("info-utillisateur-target"),
                backdropTarget = $(target),
                realBackDropTarget;

            // Détermination de la vrai target
            switch ($(html).data("info-utilisateur-type")) {
                case 2 /*Etapes*/:
                    realBackDropTarget = backdropTarget.parents('form').find('div.form-groupe');
                    break;

                case 3 /*Champs*/:
                    realBackDropTarget = backdropTarget.parents('td');
                    break;

                default:
                    realBackDropTarget = backdropTarget;
            }

            if (realBackDropTarget == null) {
                finished();
                return;
            }

            // Si il y avait une target et que l'on a rien trouvé c'est que l'on pas sur le bonne onglet...
            // On s'arrache
            var height = realBackDropTarget.height();

            if (target != '' && (height == 0
                || height == 'undefined'
                || height == null)) {
                finished();
                return;
            }

            // Ajout de la modal au DOM
            $('body').append(html);

            var $m = $("div.modal-info-utilisateur");

            // On ajoute le backdrop
            var element = $.fn.maskedbackDrop(realBackDropTarget);

            // Gèrer la position de la modal pour que l'element mis en valeur soit visible
            var arrowSize = 50;

            var hdl = null;

            switch ($(html).data("info-utilisateur-type")) {
                case 1 /*bandeau*/:
                    hdl = function () {
                        var el = element();
                        $m.offset({ top: el.bottom + arrowSize });
                        $.fn.arrow(el.bottom + arrowSize, arrowSize, false);
                    };
                    hdl();
                    break;

                case 2 /*Etapes*/:
                case 3 /*Champs*/:
                    hdl = function () {
                        var el = element();
                        if (el.bottom > 500 && el.top > ($m.height() + arrowSize)) {
                            $m.offset({ top: el.top - arrowSize - $m.height() });
                            $.fn.arrow(el.top, arrowSize, true);
                        }
                        if ((el.bottom + arrowSize + $m.height()) > $(window).height()) {
                            //l'écran est trop petit on ne fait rien
                            return;
                        }
                        else {
                            $m.offset({ top: el.bottom + arrowSize + $(window).scrollTop() });
                            $.fn.arrow(el.bottom + arrowSize, arrowSize, false);
                        }
                    };
                    hdl();
                    break;
            }

            if (hdl != null) {
                $(window).on('scroll.iu', function () {
                    $('.svg-arrow').remove();
                    hdl();
                });
            }

            // Evenement de click
            $('a.btn', $m).on('click', function (e) {
                e.preventDefault();
                //on desactive les boutons
                $('a.btn', $m).addClass('disabled');

                //url de requete pour le choix de la réponse
                var url = $(this).attr('href');

                $.ajax({
                    url: url,
                    type: 'POST'
                }).always(function () { // Même si ça croute tant pis, faut pas gener l'appli
                    // Après la requete on cache
                    $("div.svg-backdrop, div.modal-info-utilisateur, div.svg-arrow").remove();

                    $(window).unbind('.iu');

                    finished();
                    //on revérifie s'il n'y a pas d'autres infos 
                    $.fn.checkInformationUtilisateur();
                });
            });
        });
    };

    $.fn.maskedbackDrop = function (jqElement) {
        var backdrop = $('<div class="svg-backdrop" style="background: none; position: fixed; top: 0; left: 0; right: 0; bottom: 0;z-index: 10000;"><svg id="svg-screen" height="100%" width="100%"><path id="Path" d="M 0,0 L 2000,0 L 2000,2000 L 0,2000 L 0,0 z" style="fill: rgb(0,0,0);fill-opacity: 0.5" fill-rule="evenodd" /></svg></div>');
        var element = { top: 0, bottom: 0 };

        //avec ou sans trou !!!
        if (jqElement.length > 0) {
            var setupPath = function () {
                var screenWidth = $(window).width(),
                    screenHeight = $(window).height(),
                    elementMargin = 5,
                    elementPosition = jqElement.offset(),

                    pointAX = elementPosition.left - elementMargin,
                    pointAY = elementPosition.top - elementMargin - $(window).scrollTop(),
                    pointBX = pointAX + jqElement.width() + (elementMargin * 2),
                    pointBY = pointAY + jqElement.height() + (elementMargin * 2);

                var path = "M 0,0 L " + screenWidth + ",0 L " + screenWidth + "," + screenHeight + " L 0," + screenHeight + " L 0,0 z M " + pointAX + "," + pointAY + " L " + pointBX + "," + pointAY + " L " + pointBX + "," + pointBY + " L " + pointAX + "," + pointBY + " " + pointAX + "," + pointAY + " z";
                backdrop.find('path').attr("d", path);

                //l'emlement du backdrop est-il visible
                element.top = pointAY;
                element.bottom = pointBY;
            };

            $(window).bind('resize.iu, scroll.iu', function () {
                setupPath();
            });

            setupPath();
        }

        $('body').append(backdrop);

        return function () {
            return element;
        };
    };

    $.fn.arrow = function (top, size, elementIsUp) {
        var backdrop = $('<div class="svg-arrow" style="background: none; position: fixed; top: 0; left: 0; right: 0; bottom: 0;z-index: 10000;"><svg id="svg-arrow" height="100%" width="100%"><path id="Path" d="M 0,0 L 2000,0 L 2000,2000 L 0,2000 L 0,0 z" style="stroke: #404040; stroke-width:1; fill: rgb(255,255,255);" fill-rule="evenodd" /></svg></div>');

        var setupPath = function () {
            var screenWidth = $(window).width(),
                refX = screenWidth / 2,
                pointAX = refX,
                pointAY = elementIsUp ? top : top - size,
                pointBX = refX + (size / 2),
                pointBY = elementIsUp ? top - size : top + 2,
                pointCX = refX - (size / 2),
                pointCY = elementIsUp ? top - size : top + 2;

            var path = "M" + pointAX + "," + pointAY + " L" + pointBX + "," + pointBY + " L" + pointCX + "," + pointCY + " L" + pointAX + "," + pointAY + " z";

            backdrop.find("path").attr("d", path);
        };

        $(window).bind('resize.iu, scroll.iu', function () {
            setupPath();
        });

        setupPath();

        $('body').append(backdrop);
    };
})(jQuery);
