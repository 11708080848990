﻿import { FicheComptageUtils } from "./pposr-fiche-comptage-utils";
import { LibelleValue, FicheComptageIndexViewModel } from "./pposr-fiche-comptage-base";
import { Compteur } from "./Compteur";
// ReSharper disable InconsistentNaming

export class CompteurPose extends Compteur {
    _CompteurPoseConstructeurArray = ko.observableArray<LibelleValue>();
    NombreFilsTension = ko.observable<string>();
    Calibre = ko.observable<string>();
    AnneeConstruction = ko.observable<string>();
    NombreDeRoues = ko.observable<string>();
    Generation = ko.observable<string>();
    SituationPhysique = ko.observable<string>();
    _TypeSituationPhysiqueCompteurArray = ko.observableArray<LibelleValue>();
    AccessibiliteIndex = ko.observable<boolean>();
    HoraireHeuresCreuses = ko.observable<string>();
    PuissanceSouscrite = ko.observable<number>();
    MiseSousTension = ko.observable<boolean>();
    IdentifiantTelereport = ko.observable<string>();
    CleBoitierBtr = ko.observable<string>();
    AccesCompteur = ko.observable<boolean>();
    EssaiComptageEffectue = ko.observable<boolean>();

    constructor(data, isBp: boolean) {
        super(isBp);
        this.IsPresent(data.IsPresent);
        this.Constructeur(data.ConstructeurNom);
        this.Matricule(data.Matricule);
        this.Identifiant(data.Identifiant);
        this.IdentifiantCle(data.IdentifiantCle);
        this.Technologie(data.Technologie);
        this.NombreFilsTension(data.NombreFilsTension);
        this.Calibre(data.Calibre);
        this.AnneeConstruction(data.AnneeConstruction);
        this.NombreDeRoues(data.NombreDeRoues);
        this.TypeCompteur(data.TypeCompteurId);
        this.Generation(data.Generation);
        this.SituationPhysique(data.SituationPhysique);
        this.AccessibiliteIndex(data.AccessibiliteIndex);
        this.OptionTarifaire(data.OptionTarifaire);
        this.GrilleDistributeur(data.GrilleDistributeur);
        this.HoraireHeuresCreuses(data.HoraireHeuresCreuses);
        this.PuissanceSouscrite(data.PuissanceSouscrite);
        this.MiseSousTension(data.MiseSousTension);
        this.IdentifiantTelereport(data.IdentifiantTelereport);
        this.CleBoitierBtr(data.CleBoitierBtr);
        this.AccesCompteur(data.AccesCompteur);
        this.EssaiComptageEffectue(data.EssaiComptageEffectue);

        this._initAll(data);
    }

    /*Zone des "init" something*/
    private _initHorairesHeuresCreuses() {
        if (this._isCbe()) {
            this.HoraireHeuresCreuses(FicheComptageUtils.reglesGestion.forHorairesHeuresCreusesDefaut);
        } else {
            this.HoraireHeuresCreuses("");
        }
    }

    _initListTypeCompteur() {
        super._initListTypeCompteur(this.NombreFilsTension());
    }

    _initListTypeSituationPhysiqueCompteur() {
        this._TypeSituationPhysiqueCompteurArray(
            FicheComptageUtils.initEmplacCcpi(FicheComptageUtils.keysForGestion.keyEmplacementCompteur));
    }

    _setIndexIfNeeded(data: any) {
        const dataIndex: KnockoutObservable<FicheComptageIndexViewModel> = data != null
            ? data.Index
            : null;

        this.Index(new FicheComptageIndexViewModel(dataIndex));
    }

    _initAll(data) {
        this._initListTypeCompteur();
        this._setIndexIfNeeded(data);
        this._initListTypeSituationPhysiqueCompteur();
    }

    _triggerAccesIndex() {
        if (this.AccesCompteur() && this._isLinky()) {
            this.AccessibiliteIndex(true);
        }
    }

    _triggerChangesFromTypeTechno() {
        this._setIndexIfNeeded(null);
        this._triggerAccesIndex();
        this._initListTypeCompteur();
        this._initHorairesHeuresCreuses();
    }
}
