﻿import { ConfigurationContacts } from "../admin/AdminContacts";
import { IntervenantSupport } from "../admin/Interlocuteur";
import { MailSpyViewModel } from "../admin/MailSpyViewModel";
import { DocumentDatasViewModel } from "../admin/MaquetteDocument";
import { Risques } from "../admin/risques";
import { RoleAffectationViewModel } from "../admin/RoleEdit";
import { IntervenantSupportbs3 } from "../admin/SupportUtilisateurbs3";
import { ControleChantierForm } from "../controle-chantiers/controle-chantier";
import { Convocation } from "../CoordinationSecurite/Convocation";
import { Coordonnateur } from "../CoordinationSecurite/Coordonnateur";
import { FicheAnalyseRisques } from "../CoordinationSecurite/fiche-analyse-risque";
import { InspectionCommunePrealable } from "../CoordinationSecurite/inspection-commune-prealable";
import { ListeRcFssCommandeViewModel } from "../etapes/ListeRcFssCommandeViewModel";
import { TransmissionsPGODForm } from "../etapes/TransmissionsPGOD";
import { FiltreInstructionBase } from "../GDA/instructionsEntreprise/FiltreInstructionBase";
import { InstructionEntrepriseReponseViewModel } from "../GDA/instructionsEntreprise/InstructionEntrepriseReponseViewModel";
import { InstructionEntrepriseViewModel } from "../GDA/instructionsEntreprise/InstructionEntrepriseViewModel";
import { InstructionsReferentielCreateVersionViewModel } from "../GDA/instructionsReferentiel/InstructionsReferentielCreateVersionViewModel";
import { InstructionsReferentielEditionViewModel } from "../GDA/instructionsReferentiel/InstructionsReferentielEditionViewModel";
import { InstructionReferentielViewModel } from "../GDA/instructionsReferentiel/InstructionsReferentielViewModel";
import { InstructionViewModel } from "../GDA/InstructionViewModel";
import { InterventionViewModel } from "../GDA/interventions/InterventionViewModel";
import { PreparationAccesEdit } from "../GDA/Views/PreparationAcces.Edit.view";
import { FicheComptageViewModel } from "../PpOsr/FicheComptage/pposr-fiche-comptage";
import { PlanificationEtudePrestationViewModel } from "../PpOsr/pposr-etude-planification";
import { SoumissionEtudePrestationViewModel } from "../PpOsr/pposr-etude-soumission";
import { ContactClientPrestationViewModel } from "../PpOsr/pposr-infoge-contact-client";
import { InterlocuteurPrestationViewModel } from "../PpOsr/pposr-infoge-interlocuteur";
import { SousInfosGeneralesViewModel } from "../PpOsr/pposr-infoge-sousinfosgenerales";
import { PiecesJointesPrestationViewModel } from "../PpOsr/pposr-piecesjointes";
import { AttachementPrestationViewModel } from "../PpOsr/pposr-prestation-attachement";
import { EnvironnementRisquesPrestationViewModel } from "../PpOsr/pposr-soltech-enviro-risques";
import { InjectionPrestationViewModel } from "../PpOsr/pposr-soltech-injection";
import { SolutionTravauxClientPrestationViewModel } from "../PpOsr/pposr-soltech-solution-trav-client";
import { TypeModificationPrestationViewModel } from "../PpOsr/pposr-soltech-type-modification";
import { PlanificationTravauxPrestationViewModel } from "../PpOsr/pposr-travaux-planification";
import { SoumissionTravauxPrestationViewModel } from "../PpOsr/pposr-travaux-soumission";

$(() => {
    function KoAutoBinding(node) {
        const koVm = node.dataset.koVm;
        let data = null;
        if (koVm !== null && koVm !== undefined) {
            data = JSON.parse(koVm);
        }

        let postAction: () => void;

        // ReSharper disable once SpecifyVariableTypeExplicitly
        let vm;

        switch (node.dataset.koTypeVm) {
            case "SousInfosGeneralesViewModel":
                vm = new SousInfosGeneralesViewModel(data);
                break;
            case "ContactClientPrestationViewModel":
                vm = new ContactClientPrestationViewModel(data);
                break;
            case "InterlocuteurPrestationViewModel":
                vm = new InterlocuteurPrestationViewModel(data);
                break;
            case "PlanificationEtudePrestationViewModel":
                vm = new PlanificationEtudePrestationViewModel(data);
                break;
            case "SoumissionEtudePrestationViewModel":
                vm = new SoumissionEtudePrestationViewModel(data);
                break;
            case "EnvironnementRisquesPrestationViewModel":
                vm = new EnvironnementRisquesPrestationViewModel(data);
                break;
            case "InjectionPrestationViewModel":
                vm = new InjectionPrestationViewModel(data);
                break;
            case "SolutionTravauxClientPrestationViewModel":
                vm = new SolutionTravauxClientPrestationViewModel(data);
                break;
            case "TypeModificationPrestationViewModel":
                vm = new TypeModificationPrestationViewModel(data);
                break;
            case "PlanificationTravauxPrestationViewModel":
                vm = new PlanificationTravauxPrestationViewModel(data);
                break;
            case "SoumissionTravauxPrestationViewModel":
                vm = new SoumissionTravauxPrestationViewModel(data);
                break;
            case "AttachementPrestationViewModel":
                vm = new AttachementPrestationViewModel(data);
                break;
            case "DocumentDatasViewModel":
                vm = new DocumentDatasViewModel(data);
                break;
            case "ConfigurationContacts":
                vm = new ConfigurationContacts();
                vm.parseForm($(node));
                break;
            case "Risques":
                vm = new Risques(data);
                break;
            case "IntervenantSupportbs3":
                vm = window['instance'] = new IntervenantSupportbs3(data);
                break;
            case "RoleAffectationViewModel":
                vm = new RoleAffectationViewModel(data);
                break;
            case "MailSpyViewModel":
                {
                    vm = new MailSpyViewModel();
                    const hub = $.connection.mailHub;

                    hub.client = $.extend(hub.client,
                        {
                            messageSent: function (message) {
                                vm.push(message);
                            }
                        });

                    hub.connection.start().done(() => {
                        vm.Connected(true);
                    }).fail(() => {
                        vm.Connected(false);
                    });
                }
                break;
            case "FicheComptageViewModel":
                vm = new FicheComptageViewModel(data);
                break;
            case "IntervenantSupport":
                vm = window['instance'] = new IntervenantSupport(data);
                break;
            case "InstructionReferentielViewModel":
                vm = new InstructionReferentielViewModel(data);
                break;
            case "InstructionsReferentielCreateVersionViewModel":
                vm = new InstructionsReferentielCreateVersionViewModel(data);
                break;
            case "InstructionsReferentielEditionViewModel":
                {
                    vm = new InstructionsReferentielEditionViewModel(data);
                    postAction = () => {
                        // Initialisation de la table avec la version JQuery DataTables 1.10 (options : DataTables.Settings)
                        const table = $('#instructionTable').DataTable({
                            searching: false,
                            language: {
                                lengthMenu: "_MENU_ par page",
                                zeroRecords: "...",
                                info: "_START_ / _END_ sur _TOTAL_ instructions",
                                infoEmpty: "0 / 0 sur 0 instructions",
                                infoFiltered: " filtré sur _MAX_ affaires",
                                search: "Rechercher dans la liste",
                                paginate: {
                                    next: "Suivant",
                                    previous: "Précédent",
                                    first: "Première page",
                                    last: "Dernière page"
                                }
                            },
                            pagingType: "full_numbers"
                        });

                        // EPL - 7379 TM 26 / 08 / 2020 : je conserve le commentaire de TAL datant d'avant la refonte ko/ts/Parcel 
                        // et concernant \ePlans.Web\Areas\GDA\Views\Instruction\Edit.cshtml
                        // pas eu le choix que de mettre ce code ici, définir la fonction de selection dans un binding knockout
                        //bloque la propagation du click sur les colonnes
                        $('#instructionTable tbody').on('click', 'tr',
                            function () {
                                if ($(this).hasClass('selected')) {
                                    $(this).removeClass('selected');
                                } else {
                                    table.$('tr.selected').removeClass('selected');
                                    $(this).addClass('selected');
                                    vm.SelectedInstructionId(table.row(this).id());
                                }
                            });
                    }
                }
                break;
            case "InstructionViewModel":
                vm = new InstructionViewModel(data);
                break;
            case "InstructionEntrepriseViewModel":
                vm = new InstructionEntrepriseViewModel(data);
                break;
            case "InstructionEntrepriseReponseViewModel":
                vm = new InstructionEntrepriseReponseViewModel(data);
                break;
            case "FiltreInstructionBase":
                vm = new FiltreInstructionBase(data);
                break;
            case "InterventionViewModel":
                vm = new InterventionViewModel(data);
                postAction = () => {
                    vm.IsLoadElement = true;
                }
                break;
            case "PreparationAccesEdit":
                vm = new PreparationAccesEdit(data);
                postAction = () => {
                    vm.IsLoadElement = true;
                };
                break;
            case "ListeRcFssCommandeViewModel":
                vm = new ListeRcFssCommandeViewModel(data);
                postAction = () => {
                    vm.initEvents();
                }
                break;
            case "Coordonnateur":
                vm = new Coordonnateur(data);
                break;
            case "Convocation":
                vm = new Convocation(data);
                break;
            case "FicheAnalyseRisques":
                vm = new FicheAnalyseRisques(data);
                break;
            case "InspectionCommunePrealable":
                vm = new InspectionCommunePrealable(data);
                break;
            case "TransmissionsPGODForm":
                vm = new TransmissionsPGODForm(data);
                postAction = () => {
                    vm.initEventForBloc();
                }
                break;
            case "PiecesJointesPrestationViewModel":
                {
                    vm = new PiecesJointesPrestationViewModel(data);
                    postAction = () => {
                        const vmParam = node.dataset.koVmParam;
                        window["vmP"] = window["vmP"] || [];
                        window["vmP"][vmParam] = vm;
                    }
                }
                break;
            case "ControleChantierForm":
                vm = new ControleChantierForm(data);
                break;
            default:
                return;
        }

        ko.applyBindings(vm, node);

        if (postAction) {
            postAction();
        }
    }

    window['KoAutoBinding'] = KoAutoBinding;

    $('.ko-auto-binding').each((idx, node) => KoAutoBinding(node));
});
