﻿import { CommonInfosPrestation } from "./pposr-general";

// ReSharper disable InconsistentNaming
export class OgedPieceJointePpOsr {
    Title = ko.observable<string>();
    Name = ko.observable<string>();
    TypeDoc = ko.observable<string>();
    TypeCodeDocId = ko.observable<string>();
    Keywords = ko.observable<string>();

    Modele = ko.observable<string>();
    Id = ko.observable<string>();
    NumAffaire = ko.observable<string>();
    NNI = ko.observable<string>();
    Date = ko.observable<Date>();

    Email = ko.observable<string>();
    IdPresta = ko.observable<string>();
    TypeDocLabel = ko.observable<string>();

    NumCmd = ko.observable<string>();
    Siret = ko.observable<string>();
    NumFss = ko.observable<string>();

    canDelete = ko.pureComputed({
        owner: this,
        read: () => { // pas d'affichage du bouton supprimer pour les types fichiers retours'
            const type = String(this.TypeCodeDocId());  // TypeCodeDocumentOgedEnum
            const keywords = String(this.Keywords());

            const fcAuto = (type === '16' && keywords === 'AutoCreate');   // Fiche de comptage  AutoGénéré -  doss_tech_rac_net_doc 16 
            // Attention à conserver la cohérence avec _unDeletableTypes de PieceJointeHelper
            return (type !== '25' && type !== '18' && type !== '19' && !fcAuto);
        }
    });

    labelToExport = ko.pureComputed({
        owner: this,
        read: () => {
            const type = String(this.TypeCodeDocId());  // TypeCodeDocumentOgedEnum
            if (type === '501') { // Type FSS 
                return this.TypeDocLabel() + ' ' + this.NumFss();
            }
            return this.TypeDocLabel() + ' ' + this.Title();
        }
    });
}

export class PiecesJointesPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'piecesjointes');

        this.CanCreate(data.CanCreate);

        // Les fichiers de la réponse
        $.each(data.PJs, (ix2, f2: any) => {
            this.ListeFichiers.push(this.mapfichier(f2));
        });
    }

    // affichage du bouton "Ajouter un document"
    CanCreate = ko.observable<boolean>();
    // affichage du bouton "Modifier un document"
    CanModify = ko.observable<boolean>();
    // affichage du bouton "Supprimer"
    CanValidate = ko.observable<boolean>();
    // affichage du bouton "..."
    CanSubmit = ko.observable<boolean>();

    ListeFichiers = ko.observableArray<OgedPieceJointePpOsr>();

    //Téléchargement d'un fichier depuis OGED
    GetFichier = (file: OgedPieceJointePpOsr) => {

        const ogedId = file.Id();
        const affaireId = this.AffaireId();
        const url = "/Affaire/DownloadPpOsrFile?ogedId=" + ogedId + '&affaireId=' + affaireId;
        window.open(url, 'exportpj');
    }


    GenerateCommandeExec() {
        //0: COMMANDE D'ETUDE EN VUE D'ETABLIR UN DEVIS
        //1: COMMANDE D'ETUDE TRAVAUX
        //2: COMMANDE DE REALISATION DE TRAVAUX
        let typeDoc = 0; // etude devis par defaut
        if (this._isEtudeTechnique()) {
            typeDoc = 1; //1: COMMANDE D'ETUDE TRAVAUX
        }
        if (this._isTravaux()) {
            typeDoc = 2; //2: COMMANDE DE REALISATION DE TRAVAUX
        }

        const url = "/CommandeExecution/GetDocumentCommandePrestation?prestationId=" + this.PrestationId() + '&typeDoc=' + typeDoc;
        window.open(url, '_blank');
    }

    SupprFichier = (file: OgedPieceJointePpOsr) => {
        const idPrestation = this.PrestationId();
        const ogedId = file.Id();
        const fileInfo = {
            Date: file.Date,
            Email: file.Email,
            Name: file.Name,
            TypeDocLabel: file.TypeDocLabel,
            Title: file.Title
        }

        const confirmPhrase = "Confirmer la suppression de ce document ?";
        $.fn.confirmBoxCallback(confirmPhrase,
            v => {
                if (v) {
                    $.ajax({
                        url: '/Affaire/SupprimerPiecesJointePrestation',
                        type: 'POST',
                        dataType: 'html',
                        data: $.fn.putValidationToken({
                            prestationId: idPrestation,
                            ogedId: ogedId,
                            fileInfo: fileInfo
                        })
                    }).done(data => {
                        this.ListeFichiers.remove(file);
                    }).fail(err => {
                        $.fn.sticky(`Une erreur est survenue lors de la suppression :  ${err.statusText}`,
                            { cssClass: 'orange' });
                    });
                }
            });

    }

    private mapfichier(f2: any) {
        let fichier = new OgedPieceJointePpOsr();
        fichier.Id(f2.Id);
        fichier.Title(f2.Title);
        fichier.Name(f2.Name);
        fichier.TypeDoc(f2.TypeDoc);
        fichier.TypeCodeDocId(f2.TypeCodeDocId);
        fichier.Keywords(f2.Keywords);
        fichier.Modele(f2.Modele);
        fichier.Date(f2.Date);
        fichier.Email(f2.Email);
        fichier.IdPresta(f2.IdPresta);
        fichier.TypeDocLabel(f2.TypeDocLabel);
        fichier.NumCmd(f2.NumCmd);
        fichier.Siret(f2.Siret);
        fichier.NumFss(f2.NumFss);
        return fichier;
    }


    ModifierFichier = (file: OgedPieceJointePpOsr) => {
        const idPrestation = this.PrestationId();
        let fileInfo = {
            Date: file.Date,
            Email: file.Email,
            Name: file.Name,
            Id: file.Id,
            TypeCodeDocId: file.TypeCodeDocId,
            Keywords: file.Keywords,
            TypeDocLabel: file.TypeDocLabel,
            Title: file.Title
        }

        const sender = this;
        $.ajax({
            url: '/Affaire/GetFormPiecesJointes',
            type: 'POST',
            dataType: 'html',
            data: $.fn.putValidationToken({
                prestationId: idPrestation,
                fileInfo: fileInfo
            })
        }).done(data => {
            sender.loadModalFichier(data, "MODIFIER DOCUMENT");
        });
    }

    // Faire une seule pop initialisé lors du premier appel
    AjouterFichier() {
        const idPrestation = this.PrestationId();
        const sender = this;
        $.ajax({
            url: '/Affaire/GetFormPiecesJointes',
            type: 'POST',
            dataType: 'html',
            data: $.fn.putValidationToken({
                prestationId: idPrestation
            })
        }).done(data => {
            sender.loadModalFichier(data, 'AJOUTER DES DOCUMENTS');
        });
    }

    // Chargement du contenu de la modal + affichage
    loadModalFichier(data, title) {
        const $modalPiecesJointes = $('#pposr-piecesjointes-modal');
        const $contentBlock = $modalPiecesJointes.find('#content-pposr-piecesjointes-modal');
        const $contentBlockLoading = $modalPiecesJointes.find('#content-pposr-piecesjointes-modal-loading');
        const $labelTitle = $('#pposr-piecesjointes-modal-title');

        $contentBlock.html(data);
        $labelTitle.html(title);

        $contentBlockLoading.hide();
        $contentBlock.show();

        $modalPiecesJointes.modal({
            backdrop: true,
            show: true
        });
    }

    modalPjMgr: OgedPposr;

    AjouterFichierToListe(f2: any) {
        this.ListeFichiers.unshift(this.mapfichier(f2));
    }

    // Suppression de la ligne
    ActualiserFichierToListe(f2: any, oldFile: any) {
        this.ListeFichiers.unshift(this.mapfichier(f2));
        let toRemove = null;
        $.each(this.ListeFichiers(), (ix2, f3: any) => {
            if (f3.Id() === oldFile.Id) {
                toRemove = f3;
            }
        });

        if (toRemove != null) {
            this.ListeFichiers.remove(toRemove);
        }
    }
}

export class OgedPposr {

    // membres privés 
    private instanceName: string; // Nom de l'instance 
    private _Config: any;
    private keyField: string;
    private forbiddenRegex = new RegExp("^.*\\.(exe|dll|bat|vbs|js)$", "i");/* Non sensible à la casse*/


    private max_file_size = 10485760;

    // constructor(config: any) -- Initialisation de la configuration des actions + lien vers dataTable
    constructor(config: any) { //
        this._Config = config;
        $("#btn-pposr-exportpj").show();
    }

    selectInputFile() {
        return "#pjupl_File";
    }

    // Récupère le fichier à télécharger
    getSelectedFile() {
        const fileInput: any = $(this.selectInputFile())[0];
        return fileInput.files[0];
    }

    getSelectedFiles() {
        const fileInput: any = $(this.selectInputFile())[0];
        return fileInput.files;
    }

    // [Event] - onClickToLoadInputFile  - Au click sur le bouton : Rattacher ou Modifier  // T
    onClickToLoadInputFile() {
        $(this.selectInputFile()).click();
    }

    //**********************************************
    // Modification document
    //********************************************
    modalActionModifier() {
        //  Récupération du fichier si selectionné
        let file = null;
        const files = this.getSelectedFiles();
        if (files.length > 0) {
            const extOk = this.getFileExt(this.modalItemOgedFileEdit.Name);
            if (this.checkFileInfo(files[0], extOk)) {
                file = files[0];
            }
            else {
                return;
            }
        }
        this.setLoading(true);
        this.sendAjaxOged_UpdateDoc(file);
    }

    sendAjaxOged_UpdateDoc(file) {
        const sender = this;
        $.ajax({
            url: '/Affaire/ModifierPiecesJointePrestation',
            type: 'POST',
            contentType: false,
            data: this.prepareDataUpdateToPost(file),
            processData: false,
            success: function (data) {
                sender._onSuccessAjaxOgedUpdateDoc(data);
            },
            error: function (err) {
                sender.modalActionAnnuler();
                $.fn.sticky("Une erreur est survenue pendant la modification. :  " + err.statusText, { cssClass: 'orange' });
            }
        });
    }
    // config de modification
    prepareDataUpdateToPost(file): any {
        const objFormData = this.prepareDataToPost(file);
        objFormData.append('OgedId', $("#pjupl_IdOged").val());
        return objFormData;
    }

    _onSuccessAjaxOgedUpdateDoc(data) {
        // envoi de la presta
        const prestaId = $("#pjupl_IdPresta").val();
        const vm = this._Config.getVm(prestaId);

        vm.ActualiserFichierToListe(data, this.modalItemOgedFileEdit);
        $.fn.sticky("Piece jointe modifiée avec succès.", { cssClass: 'green' });
        $('#pposr-piecesjointes-modal').modal('hide');
    }

    modalItemInitView() {
        if (this.modalItemOgedFileEdit != null && this.modalItemOgedFileEdit.Id != null) { // initialisation du contenu de la modal en mode modification
            const file = this.modalItemOgedFileEdit;
            $("#pjupl_FileDesc").val(file.Title);
            $("#pjupl_IdOged").val(file.Id);

            //
            this.modalOnchangeType();
            $('#pjupl_DocType').val(file.TypeCodeDocId);
        }
    }

    // [AppelExt] - Action de chargement  // Control des données
    // Action itérative sur la liste des fichiers
    actionLoadDocs() {
        const files = this.getSelectedFiles();
        if (files != null) {
            $.each(files, (i, file) => {
                this.actionLoadDoc(file);
            });
            if (files.length === 0) {
                this.alertFileInfo("La sélection d'un document est obligatoire.");
            }
        }
    }

    private getFileExt(fileName) {
        const re = /(?:\.([^.]+))?$/;
        const ext = re.exec(fileName)[1];
        return String(ext).toLowerCase();
    }

    private alertFileInfo(message) {
        $("#alert_pjupl_Fil").show();
        $("#alert_pjupl_Fil").html(message);
    }

    private checkFileInfo(file, extension = null) {
        if (extension != null) {
            const ext = this.getFileExt(file.name);
            if (extension !== ext) {
                this.alertFileInfo(`'${file.name}' : Ce type de fichier [*.${ext}] n'est pas autorisé dans le cas de la modification.\n Type attendu : [*.${extension}]`);
                return false;
            }
        }
        if (this.forbiddenRegex.test(file.name)) {
            this.alertFileInfo(`'${file.name}' : Ce type de fichier n'est pas autorisé`);
            return false;
        }
        else if (file.size > this.max_file_size) {
            this.alertFileInfo("La taille des fichiers est limitée à 10 Mo");
            return false;
        }
        return true;
    }

    actionLoadDoc(fileToSave = null) {
        let file = this.getSelectedFile();
        if (fileToSave != null) {
            file = fileToSave;
        }
        let alertInfo = "";
        if ($("#pjupl_DocType").val() === null) {
            alertInfo += 'La sélection du type de document est obligatoire.';
        }

        if (file === null) {
            alertInfo += "La sélection d'un document est obligatoire.";
        }

        if (alertInfo.length > 0) {
            this.alertFileInfo(alertInfo);
        }
        else {
            if (this.checkFileInfo(file)) {
                this.setLoading(true);
                this.sendAjaxOged_LoadDoc(file);
            }
        }
    }

    sendAjaxOged_LoadDoc(file) {
        const sender = this;
        $.ajax({
            url: '/Affaire/AjouterPiecesJointePrestation',
            type: 'POST',
            contentType: false,
            data: this.prepareDataToPost(file),
            //JQUERY CONVERT THE FILES ARRAYS INTO STRINGS.SO processData:false
            processData: false,
            cache: false,
            success: function (data) {
                sender._onSuccessAjaxOgedDoc(data);
            },
            error: function (xhr, status, error) {
                sender.modalActionAnnuler();
                $.fn.sticky(`Une erreur est survenue pendant la sauvegarde. :  ${xhr.statusText}`, { cssClass: 'orange' });
            }
        });
    }

    // Définition de la requete
    prepareDataToPost(file): any {
        let objFormData = new FormData();
        objFormData.append('__RequestVerificationToken', $().findValidationToken());
        objFormData.append('TypeDocument', $("#pjupl_DocType").val().toString());
        objFormData.append('Titre', $("#pjupl_FileDesc").val().toString());
        objFormData.append('PrestationId', $("#pjupl_IdPresta").val().toString());
        objFormData.append('OgedFile', file);

        return objFormData;
    }

    public _onSuccessAjaxOgedDoc(data) {

        // envoi de la presta
        const prestaId = $("#pjupl_IdPresta").val();
        const vm = this._Config.getVm(prestaId);
        vm.AjouterFichierToListe(data);
        $.fn.sticky("Piece jointe ajoutée avec succès.", { cssClass: 'green' });

        $('#pposr-piecesjointes-modal').modal('hide');
    }

    setLoading(viewLoading: boolean) {
        if (viewLoading) {
            $('#content-pposr-piecesjointes-modal').hide();
            $('#content-pposr-piecesjointes-modal-loading').show();
        }
        else {
            $('#content-pposr-piecesjointes-modal').show();
            $('#content-pposr-piecesjointes-modal-loading').hide();
        }
    }

    // fonctions modal docs
    modalActionAnnuler() {
        $("#pposr-piecesjointes-modal").modal({
            backdrop: false,
            show: false
        });
    }

    modalActionValider() {
        this.actionLoadDocs();
    }

    // events 
    modalTypesDocItems: any;
    modalItemOgedFileEdit: any;

    modalOnchangeType() {
        const docTypeP = $('#pjupl_DocTypeP').val();
        const docType = $('#pjupl_DocType');

        docType.find('option').remove();
        $.each(this.modalTypesDocItems, function (i, item) {
            if (docTypeP === item.Code) {
                docType.append($('<option>', {
// ReSharper disable TsResolvedFromInaccessibleModule
                    value: item.Id,
// ReSharper restore TsResolvedFromInaccessibleModule
// ReSharper disable TsNotResolved
                    text: item.Label
// ReSharper restore TsNotResolved
                }));
            }
        });
    }

    // fonctions modal Export PJ 
    private nbPjToExport = 0;
    OpenExportPJ() {

        // Récupération de la liste des documents de chaque sous bloc
        this.nbPjToExport = 0;

        let htmlAff = this.getHtmlPrestaLine('0');  // Bloc PJ Osr
        htmlAff += this.getHtmlPrestaLine('PGI');  // Bloc PGI pour precup FSS 

        const sender = this;
        $.each(this._Config.prestaIds, function (i, item) {
            htmlAff += sender.getHtmlPrestaLine(item);
        });

        if (this.nbPjToExport > 1) {
            htmlAff = `<p><input type='checkbox' class='' onclick='modalPjMgr.modalActionExportSwapCB(this);' />  Cochez tout/ décocher tout </p>${htmlAff}`;
        }

        $("#content-pposr-export-pj-modal").html(htmlAff);

        $("#pposr-export-pj-modal").modal({
            backdrop: true,
            show: true
        });
    }

    modalActionExportSwapCB(cb) {
        $('.export-pj-item').prop('checked', $(cb).prop('checked'));
    }

    getHtmlPrestaLine(idPresta) {
        const config = this._Config.getVm(idPresta);
        if (config == null) return '';

        var docs: OgedPieceJointePpOsr[] = config.ListeFichiers();
        let htmlRes = "";
        const sender = this;
        $.each(docs, function (i, item) {
            if (i === 0) {
                const labTitle = (idPresta !== 'PGI') ? ((item.IdPresta() != null && item.IdPresta().length > 0) ? item.IdPresta() : config._TypeDrLabel()) : "FSS";
                htmlRes += `<p><b>${labTitle}</b><br/>`;
            }
            htmlRes += `&#160;&#160;<input type='checkbox' class='export-pj-item'  value='${item.Id()}'/>   ${item.labelToExport()}<br/>`;
            sender.nbPjToExport++;
        });

        if (docs.length > 0) {
            htmlRes += "</p>";
        }
        return htmlRes;
    }

    modalActionExportAnnuler() {
        $("#pposr-export-pj-modal").modal({
            backdrop: false,
            show: false
        });
    }

    modalActionExportValider() {
        const numAffaire = this._Config.numAffaire;

        let ogedIds = "";
        const items = $(".export-pj-item:checkbox:checked");

        if (items.length > 0) {
            $.each(items, function (i, item) {
                ogedIds += ((i > 0) ? "-" : "") + $(item).val().toString();
            });

            const url = `/Affaire/DownloadZipPpOsrFile?ogedIds=${ogedIds}&numAffaire=${numAffaire}`;
            window.open(url, 'exportpj'); // lancement du téléchargement dans une iframe afin de ne pas afficher l'URL

            this.modalActionExportAnnuler();
        }
    }
}
// ReSharper restore InconsistentNaming
