﻿import { regexMail, regexTel } from "./inspection-commune-prealable";

export class ContactUrgence {
    TypeNumeroUrgencePlanPrevention = ko.observable<number>();
    Description = ko.observable<string>();
    Telephone = ko.observable<string>().extend({
        pattern: regexTel
    });
    Mail = ko.observable<string>().extend({
        pattern: regexMail
    });
    constructor(data: number);
    constructor(data: ContactUrgence, fromTemporaire: boolean);
    constructor(data: any, fromTemporaire?: boolean) {
        if (typeof (data) == "number") {
            this.TypeNumeroUrgencePlanPrevention(data);
        }
        else if (fromTemporaire) {
            this.TypeNumeroUrgencePlanPrevention(data.TypeNumeroUrgencePlanPrevention());
            this.Description(data.Description());
            this.Telephone(data.Telephone());
            this.Mail(data.Mail());
        } else {
            this.TypeNumeroUrgencePlanPrevention(data.TypeNumeroUrgencePlanPrevention);
            this.Description(data.Description);
            this.Telephone(data.Telephone);
            this.Mail(data.Mail);
        }
    }

    raz() {
        this.Description('');
        this.Telephone(null);
        this.Mail('');
    }

    isValid = ko.computed({
        owner: this, read: () => {
            if (!this.TypeNumeroUrgencePlanPrevention() || (this.Mail() != '' && this.Mail.hasError()) || !this.Telephone() || this.Telephone.hasError() || !this.Description()) {
                return false;
            }
            return true;
        }
    });
}
