﻿import { Response } from "../Response";

export class InstructionEntrepriseViewModel {
    Instructions: KnockoutObservableArray<any> = ko.observableArray<any>();

    // membres
    VersionsApplicables: any[];
    TypeInstructionSurDr: any[] = [];
    TypesOuvragesSurDr: any[] = [];
    InstructionsDrActives: any[] = [];
    SelectedNumeroCalcule = ko.observable<string>();
    SelectedInstructionId = ko.observable<string>();
    TypeInstruction = ko.observable<string>();
    TypesInstructions = ko.observableArray<any>();
    TypesOuvrages = ko.observableArray<any>();

    SelectedTypeOuvrage = ko.observable<string>();
    SelectedDR = ko.observable<string>();
    SelectedVersion = ko.observable<number>();
    ActionForm = ko.observable<string>();
    AccesPermanentInstructionSelectionnee = ko.observable<boolean>();
    CorrespondingNumeroCalculeVersions = ko.observableArray<any>();
    ModeReponse = ko.observable<boolean>();

    
    constructor(model: any) {
        ko.subscribable.fn.subscribeChanged = function (callback) {
            let oldValue;
            this.subscribe(_oldValue => {
                oldValue = _oldValue;
            }, this, 'beforeChange');

            this.subscribe(newValue => {
                callback(newValue, oldValue);
            });
        };

        this.VersionsApplicables = [];

        if (model.VersionsActives !== null && model.VersionsActives.length > 0) {
            this.VersionsApplicables = model.VersionsActives;
            this.Instructions(this.VersionsApplicables.map(v => v.NumeroCalcule)
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                }).map((n) => {
                    return { NumeroCalcule: n, Value: n }
                }));

            this.Instructions(this.Instructions().filter((value, index, self) => {
                return self.indexOf(value) === index;
            }));
            this.Instructions().push({ NumeroCalcule: "", Value: "" });
        }

        this.CorrespondingNumeroCalculeVersions = ko.observableArray<any>();
        this.SelectedTypeOuvrage = ko.observable<string>(model.TypeOuvrage === 0 ? "" : model.TypeOuvrage.toString());
        this.TypeInstruction = ko.observable<string>(model.TypeInstruction === 0 ? "" : model.TypeInstruction.toString());
        this.TypesInstructions = ko.observableArray<any>(model.TypesInstructions);
        this.TypesOuvrages = ko.observableArray<any>(model.TypesOuvrages);
        this.SelectedDR = ko.observable<string>(model.FkIdPlaqueERDF === 0 ? "" : model.FkIdPlaqueERDF.toString());
        this.SelectedNumeroCalcule = ko.observable<string>(model.InstructionDRNumeroCalcule == null ? "" : model.InstructionDRNumeroCalcule);

        if (this.SelectedNumeroCalcule() !== null) {
            this.CorrespondingNumeroCalculeVersions(this.VersionsApplicables.filter(ins => ins.NumeroCalcule == this.SelectedNumeroCalcule()));
        }
        this.SelectedInstructionId = ko.observable<string>();
        this.SelectedVersion = ko.observable<number>();
        this.ActionForm = ko.observable<string>();
        this.AccesPermanentInstructionSelectionnee = ko.observable<boolean>();
        this.ModeReponse = ko.observable<boolean>(model.ModeReponse);

        // Calls Ajax 
        
        this.SelectedDR.subscribe((newValue) => {

            this.SelectedTypeOuvrage("");
            this.TypeInstruction("");
            this.SelectedNumeroCalcule("");
            this.TypesInstructions([]);
            this.TypesOuvrages([]);
            this.Instructions([]);

            if (newValue !== null && newValue !== undefined && newValue !== "") {
                $.ajax("TypesInstructionsSurDr",
                    {
                        context: this,
                        data: { idPlaqueERDF: newValue },
                        success: (data: any) => {
                            data.Data.push({ Libelle: "", Value: "" });
                            this.TypeInstructionSurDr = data.Data.sort((typeInstruction1, typeInstruction2) => typeInstruction1.Libelle > typeInstruction2.Libelle ? 1 : typeInstruction1.Libelle < typeInstruction2.Libelle ? -1 : 0).map((t: any) => {
                                return { Text: t.Libelle, Value: t.Value }
                            });
                            this.refreshTypeInstruction();
                        }

                    } as JQuery.AjaxSettings);
            }
        });

        this.TypeInstruction.subscribe((newValue) => {

            this.SelectedTypeOuvrage("");
            this.SelectedNumeroCalcule("");
            this.TypesOuvrages([]);
            this.Instructions([]);

            if (newValue !== null && newValue !== undefined && newValue !== "") {
                $.ajax("TypesOuvragesSurDr",
                    {
                        context: this,
                        data: { idPlaqueERDF: this.SelectedDR(), typeInstruction: newValue },
                        success: (data: any) => {
                            //this.Instructions(data.Data);
                            data.Data.push({ Libelle: "", Value: "" });
                            this.TypesOuvragesSurDr = data.Data.sort((typeOuvrage1, typeOuvrage2) => typeOuvrage1.Libelle > typeOuvrage2.Libelle ? 1 : typeOuvrage1.Libelle < typeOuvrage2.Libelle ? -1 : 0).map(t => ({ Text: t.Libelle, Value: t.Value }));

                            this.refreshTypeOuvrage();
                        }
                    } as JQuery.AjaxSettings);
            }
        });

        this.SelectedTypeOuvrage.subscribe((newValue) => {
            this.SelectedNumeroCalcule("");
            this.Instructions([]);
            if (newValue !== null && newValue !== undefined && newValue !== "") {
                $.ajax("InstructionsDrActives",
                    {
                        context: this,
                        data: { idPlaqueERDF: this.SelectedDR(), typeInstruction: this.TypeInstruction(), typeOuvrage: newValue },
                        success: (data: Response) => {
                            let distinctsNumeroCalcules: string[] = data.Data.map(instruction => instruction.NumeroCalcule);

                            distinctsNumeroCalcules = distinctsNumeroCalcules.filter((value, index, self) => {
                                return self.indexOf(value) === index;
                            });

                            const instructionDr = distinctsNumeroCalcules.map(numeroCalcule => ({ NumeroCalcule: numeroCalcule, Value: numeroCalcule }));

                            instructionDr.push({ NumeroCalcule: "", Value: "" });
                            instructionDr.sort((numeroCalcule1, numeroCalcule2) => numeroCalcule1.NumeroCalcule > numeroCalcule2.NumeroCalcule ? 1 : numeroCalcule1.NumeroCalcule < numeroCalcule2.NumeroCalcule ? -1 : 0);
                            this.InstructionsDrActives = instructionDr;
                            this.SetVersionApplicables(data.Data);
                            this.refresh();
                        }
                    } as JQuery.AjaxSettings);
            }
        });

        // Events Page 

        this.SelectedNumeroCalcule.subscribe((newValue) => {

            if (newValue !== null && newValue !== undefined && newValue !== "") {
                $('.versionsActives').remove();
                this.CorrespondingNumeroCalculeVersions(this.VersionsApplicables.filter(ins => ins.NumeroCalcule == newValue));
                if (this.CorrespondingNumeroCalculeVersions() !== null && this.CorrespondingNumeroCalculeVersions().length >= 1) {
                    this.SelectedVersion(this.CorrespondingNumeroCalculeVersions().reduce((previousValue, currentValue) => (previousValue.Version < currentValue.Version) ? previousValue : currentValue).Id);
                }
            }
            else {
                $('.versionsActives').remove();
                $(".field-validation-error").hide();
                this.SelectedVersion(null);
            }
        });

        this.SelectedVersion.subscribeChanged((newValue: number, oldValue: number) => {
            if (newValue !== null) {
                const selectedInstruction = this.VersionsApplicables.filter(ins => ins.Id == newValue)[0];
                if (selectedInstruction != null) {

                    this.SelectedNumeroCalcule(selectedInstruction.NumeroCalcule);
                    this.AccesPermanentInstructionSelectionnee(selectedInstruction.AccesPermanent);

                    $("#pj_modele").html(this.HtmlLinkPieceJointOged(selectedInstruction.PjoModele));
                    $("#pj_annexe").html(this.HtmlLinkPieceJointOged(selectedInstruction.PjoAnnexe));

                }
            }
            else {
                $("#pj_modele").html("");
                $("#pj_annexe").html("");
            }

            if ($(".field-validation-error").css("display") == "inline") {
                if (oldValue !== newValue) {
                    $(".field-validation-error").hide();
                }
            }
        });

        this.SelectedVersion(model.FkIdInstruction !== null && model.FkIdInstruction !== 0 ? model.FkIdInstruction : null);
    }


    refresh(): void {
        this.Instructions(this.InstructionsDrActives);
    }

    refreshTypeInstruction(): void {
        this.TypesInstructions(this.TypeInstructionSurDr);
    }

    refreshTypeOuvrage(): void {
        this.TypesOuvrages(this.TypesOuvragesSurDr);
    }


    SetVersionApplicables(data: any[]) {
        this.VersionsApplicables = data;
        this.CorrespondingNumeroCalculeVersions(this.VersionsApplicables);
    }


    // Construction du lien HTML de téléchargement à partir d'une instance PieceJointeOGED
    HtmlLinkPieceJointOged(data: any, isShowFileName: boolean = true): string {
        let res = "";

        if (data != null) {
            const cssClass = "btPdf";  // TODO  Gestion du type
            res = '<a href="/OGED/DownloadFile/' + data.Id + '" ' +
                ' class="btn btn-default btIcon ' + cssClass
                + ' title = "' + data.Value + '" ';
            if (isShowFileName) {
                res += '> &#160; ' + data.Value;
            }
            else {
                res += 'style = "padding- left:8px" > &#160; ';
            }
            res += '</a>';
        }

        return res;
    }

}
