﻿export class Utils {
    /**
     * Fait du nettoyage sur les props considerées comme privées sur un objet.
     * On vire tout ce qui a un nom qui commence par '_'
     */
    static cleanPrivateProps(k, v): Object {
        if (k.length > 0 && k.charAt(0) === '_' && k !== '_destroy') {
            return undefined;
        }

        return v;
    }
}

