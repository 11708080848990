﻿import { CommonInfosPrestation } from "./pposr-general";
// ReSharper disable InconsistentNaming

export class RisquesValue {
    // A l'initialisation du modèle Knockout, les valeurs reçues ne sont pas des observables, ensuite si
    constructor(public risque, public objectIsObservable, public prestationId) {
        if (objectIsObservable) {
            // vient d'un observable du modèle ts
            this._Label(risque._Label());
            this._Value(risque._Value());
            this._PotentialValue(risque._PotentialValue());
            this._NamePrefix(risque._NamePrefix());
            this._Name(risque._NamePrefix() + prestationId);
        } else {
            // vient du modèle C#
            this._Label(risque.Label);
            this._Value(risque.Value);
            this._PotentialValue(risque.PotentialValue);
            this._NamePrefix(risque.NamePrefix);
            this._Name(risque.NamePrefix + prestationId);
        }
    }

    _Label = ko.observable<string>();
    _Value = ko.observable<number>();
    _PotentialValue = ko.observable<number>();
    _Name = ko.observable<string>();
    _NamePrefix = ko.observable<string>();

    _affectRisqueValue() {
        if (this._Value() === 0) {
            this._Value(this._PotentialValue());
        } else {
            this._Value(0);
        }

        // Attention, sans le return true; le comportement par défaut du radio bouton est empêché par le binding sur le click event
        return true;
    }

    // Le risque est un problème d'accès et il est sur oui
    _isProbAccessTrue = ko.pureComputed({
        owner: this,
        read: () => {
            // 8 : valeur dans TypeRisquesEnum
            return this._PotentialValue() === 8 && this._Value() > 0;
        }
    });

    // Le risque est un problème de circulation et il est sur oui
    _isProbCirculationTrue = ko.pureComputed({
        owner: this,
        read: () => {
            // 16 : valeur dans TypeRisquesEnum
            return this._PotentialValue() === 16 && this._Value() > 0;
        }
    });

    // Le risque est un problème d'amiante et il est sur oui
    _isProbAmianteTrue = ko.pureComputed({
        owner: this,
        read: () => {
            // 32 : valeur dans TypeRisquesEnum
            return this._PotentialValue() === 32 && this._Value() > 0;
        }
    });
}

export class EnvironnementRisquesPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'envirorisques');

        this._IsRacsiOrRaci(data.IsRacsiOrRaci);
        this.RegimeVoirie(data.RegimeVoirie);
        this.RegimeVoirieAutre(data.RegimeVoirieAutre);
        this.AutorisationVoirie(String(data.AutorisationVoirie));
        this.NumeroDipole(data.NumeroDipole);
        this.NomPosteHtaBt(data.NomPosteHtaBt);
        this.GdoPosteHtaBt(data.GdoPosteHtaBt);
        this.NomDepartBt(data.NomDepartBt);
        this.CodeDepartBt(data.CodeDepartBt);
        this.NatureReseau(data.NatureReseau);
        this.SectionCable(data.SectionCable);
        this.GpsLong(data.GpsLong);
        this.GpsLat(data.GpsLat);
        this.PropositionIntervention(data.PropositionIntervention);
        this.Risques(data.Risques);

        this.CommentaireAcces(data.CommentaireAcces);
        this.CommentaireAccesEmail(this._getCommentLastMail(this.CommentaireAcces(), data.CommentaireAccesEmail));

        this.CommentaireCirculation(data.CommentaireCirculation);
        this.CommentaireCirculationEmail(this._getCommentLastMail(this.CommentaireCirculation(), data.CommentaireCirculationEmail));

        this.CommentaireAmiante(data.CommentaireAmiante);
        this.CommentaireAmianteEmail(this._getCommentLastMail(this.CommentaireAmiante(), data.CommentaireAmianteEmail));

        this.CommentaireEnedisEtude(data.CommentaireEnedisEtude);
        this.CommentaireEnedisEtudeEmail(this._getCommentLastMail(this.CommentaireEnedisEtude(), data.CommentaireEnedisEtudeEmail));

        this.CommentairePrestataireRisques(data.CommentairePrestataireRisques);
        this.CommentairePrestataireRisquesEmail(this._getCommentLastMail(this.CommentairePrestataireRisques(), data.CommentairePrestataireRisquesEmail));


        this._RisquesArrayOrig = new Array<RisquesValue>();

        for (let i = 0; i < data.RisquesArray.length; i++) {
            this._RisquesArray.push(new RisquesValue(data.RisquesArray[i], false, this.PrestationId()));
        }

        // Les radio boutons
        this._AutorisationVoirieName = ko.observable<string>(`autorisationVoirie${this.PrestationId()}`);

        // On conserve les valeurs d'origine
        this._initOrigValues();
    }

    // NB: les valeurs des radio boutons sont des string volontairement pour que le "checked" fonctionne
    _IsRacsiOrRaci = ko.observable<boolean>();
    RegimeVoirie = ko.observable<string>();
    RegimeVoirieAutre = ko.observable<string>();
    AutorisationVoirie = ko.observable<string>();
    NumeroDipole = ko.observable<string>().extend({ pattern: /^\d{10}$/ });
    NomPosteHtaBt = ko.observable<string>();
    GdoPosteHtaBt = ko.observable<string>();
    NomDepartBt = ko.observable<string>();
    CodeDepartBt = ko.observable<string>();
    NatureReseau = ko.observable<string>();
    SectionCable = ko.observable<number>().extend({ pattern: /^[0-9]{0,50}$/ });
    GpsLong = ko.observable<string>().extend({ pattern: window.eplans.regexGpsLon });
    GpsLat = ko.observable<string>().extend({ pattern: window.eplans.regexGpsLat });
    PropositionIntervention = ko.observable<string>();
    Risques = ko.observable<number>();

    CommentaireAcces = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    CommentaireAccesEmail = ko.observable<string>();

    CommentaireCirculation = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    CommentaireCirculationEmail = ko.observable<string>();

    CommentaireAmiante = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    CommentaireAmianteEmail = ko.observable<string>();

    CommentairePrestataireRisques = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    CommentairePrestataireRisquesEmail = ko.observable<string>();

    CommentaireEnedisEtude = ko.observable<string>();
    CommentaireEnedisEtudeEmail = ko.observable<string>();

    _AutorisationVoirieName = ko.observable<string>();

    _RisquesArray = ko.observableArray<RisquesValue>();

    // Valeurs d'origine pour restauration si annulation des modifications en cours
    _RegimeVoirieOrig: string;
    _RegimeVoirieAutreOrig: string;
    _AutorisationVoirieOrig: string;
    _NumeroDipoleOrig: string;
    _NomPosteHtaBtOrig: string;
    _GdoPosteHtaBtOrig: string;
    _NomDepartBtOrig: string;
    _CodeDepartBtOrig: string;
    _NatureReseauOrig: string;
    _SectionCableOrig: number;
    _GpsLongOrig: string;
    _GpsLatOrig: string;
    _PropositionInterventionOrig: string;
    _RisquesOrig: number;
    _CommentaireAccesOrig: string;
    _CommentaireAccesEmailOrig: string;
    _CommentaireCirculationOrig: string;
    _CommentaireCirculationEmailOrig: string;
    _CommentaireAmianteOrig: string;
    _CommentaireAmianteEmailOrig: string;
    _CommentairePrestataireRisquesOrig: string;
    _CommentairePrestataireRisquesEmailOrig: string;

    _RisquesArrayOrig: RisquesValue[];

    _risquesValueArray() {
        return this._RisquesArray().filter(_ => _._Label().length > 0);
    }

    _regimeVoirieIsAutre = ko.pureComputed({
        owner: this,
        read: () => {
            return this.RegimeVoirie() === "4";
        }
    });

    _coherentCoordinates() {
        return ((this.GpsLong() === null || this.GpsLong() === "") && (this.GpsLat() === null || this.GpsLat() === "")) ||
            (this.GpsLong() !== null && this.GpsLong() !== "" && this.GpsLat() !== null && this.GpsLat() !== "");
    }

    _latitudeIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this.GpsLat.hasError()
                && this._coherentCoordinates();
        }
    });

    _longitudeIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this.GpsLong.hasError()
                && this._coherentCoordinates();
        }
    });

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this.CommentaireAcces.hasError()
                && !this.CommentaireCirculation.hasError()
                && !this.CommentaireAmiante.hasError()
                && !this.CommentairePrestataireRisques.hasError()
                && !this.GpsLat.hasError()
                && !this.GpsLong.hasError()
                && this._coherentCoordinates();
        }
    });

    // Sortie du mode modification        
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            this._ModeModificationGlobal(false);

            if (resetValues) {
                this.RegimeVoirie(this._RegimeVoirieOrig);
                this.RegimeVoirieAutre(this._RegimeVoirieAutreOrig);
                this.AutorisationVoirie(this._AutorisationVoirieOrig);
                this.NumeroDipole(this._NumeroDipoleOrig);
                this.NomPosteHtaBt(this._NomPosteHtaBtOrig);
                this.GdoPosteHtaBt(this._GdoPosteHtaBtOrig);
                this.NomDepartBt(this._NomDepartBtOrig);
                this.CodeDepartBt(this._CodeDepartBtOrig);
                this.NatureReseau(this._NatureReseauOrig);
                this.SectionCable(this._SectionCableOrig);
                this.GpsLong(this._GpsLongOrig);
                this.GpsLat(this._GpsLatOrig);
                this.PropositionIntervention(this._PropositionInterventionOrig);
                this.Risques(this._RisquesOrig);
                this.CommentaireAcces(this._CommentaireAccesOrig);
                this.CommentaireAccesEmail(this._CommentaireAccesEmailOrig);
                this.CommentaireCirculation(this._CommentaireCirculationOrig);
                this.CommentaireCirculationEmail(this._CommentaireCirculationEmailOrig);
                this.CommentaireAmiante(this._CommentaireAmianteOrig);
                this.CommentaireAmianteEmail(this._CommentaireAmianteEmailOrig);
                this.CommentairePrestataireRisques(this._CommentairePrestataireRisquesOrig);
                this.CommentairePrestataireRisquesEmail(this._CommentairePrestataireRisquesEmailOrig);

                this._RisquesArray.removeAll();

                for (let i = 0; i < this._RisquesArrayOrig.length; i++) {
                    this._RisquesArray.push(new RisquesValue(this._RisquesArrayOrig[i], true, this.PrestationId()));
                }
            }

            this._disableChangeAlert();
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        this._RegimeVoirieOrig = this.RegimeVoirie();
        this._RegimeVoirieAutreOrig = this.RegimeVoirieAutre();
        this._AutorisationVoirieOrig = this.AutorisationVoirie();
        this._NumeroDipoleOrig = this.NumeroDipole();
        this._NomPosteHtaBtOrig = this.NomPosteHtaBt();
        this._GdoPosteHtaBtOrig = this.GdoPosteHtaBt();
        this._NomDepartBtOrig = this.NomDepartBt();
        this._CodeDepartBtOrig = this.CodeDepartBt();
        this._NatureReseauOrig = this.NatureReseau();
        this._SectionCableOrig = this.SectionCable();
        this._GpsLongOrig = this.GpsLong();
        this._GpsLatOrig = this.GpsLat();
        this._PropositionInterventionOrig = this.PropositionIntervention();
        this._RisquesOrig = this.Risques();
        this._CommentaireAccesOrig = this.CommentaireAcces();
        this._CommentaireAccesEmailOrig = this.CommentaireAccesEmail();
        this._CommentaireCirculationOrig = this.CommentaireCirculation();
        this._CommentaireCirculationEmailOrig = this.CommentaireCirculationEmail();
        this._CommentaireAmianteOrig = this.CommentaireAmiante();
        this._CommentaireAmianteEmailOrig = this.CommentaireAmianteEmail();
        this._CommentairePrestataireRisquesOrig = this.CommentairePrestataireRisques();
        this._CommentairePrestataireRisquesEmailOrig = this.CommentairePrestataireRisquesEmail();

        this._RisquesArrayOrig = new Array<RisquesValue>();

        for (let i = 0; i < this._RisquesArray().length; i++) {
            this._RisquesArrayOrig.push(new RisquesValue(this._RisquesArray()[i], true, this.PrestationId()));
        }
    }

    _prepareSave() {
        const self = this;

        if (self._isValid()) {
            // On reconstitue et assigne la valeur à retourner à partir du tableau des risques
            const sumRisques = self._RisquesArray().reduce(((pv, cv) => pv + cv._Value()), 0);
            self.Risques(sumRisques);

            self._save('/Affaire/EnregistrerModifEnvironnementRisques', this._PrestationLinkWithHash(), this._setNewStateAfterSave, self);
        }
    }

    _setNewStateAfterSave(self: EnvironnementRisquesPrestationViewModel) {
        const success = self._PendingSave();

        // Si succès de la sauvegarde, avant d'établir les nouvelles valeurs d'origine, on change l'email lié au commentaire en cas de modification seulement
        if (success) {
            self._disableModify(false);

            if (self.CommentaireAcces() !== self._CommentaireAccesOrig) {
                self.CommentaireAccesEmail(self._UserEmail());
            }
            if (self.CommentaireCirculation() !== self._CommentaireCirculationOrig) {
                self.CommentaireCirculationEmail(self._UserEmail());
            }
            if (self.CommentaireAmiante() !== self._CommentaireAmianteOrig) {
                self.CommentaireAmianteEmail(self._UserEmail());
            }
            if (self.CommentairePrestataireRisques() !== self._CommentairePrestataireRisquesOrig) {
                self.CommentairePrestataireRisquesEmail(self._UserEmail());
            }

            // Les valeurs sauvées sont les nouvelles valeurs d'origine
            self._initOrigValues();

            self._PendingSave(false);
        }
    }
}
// ReSharper restore InconsistentNaming
