﻿import { FicheComptageUtils } from "./pposr-fiche-comptage-utils";
import { FicheComptageBaseViewModel } from "./pposr-fiche-comptage-base";
import { InformationsGenerales } from "./InformationsGenerales";
import { DisjoncteurPose } from "./DisjoncteurPose";
import { CompteurPose } from "./CompteurPose";
import { DisjoncteurDepose } from "./DisjoncteurDepose";
import { CompteurDepose } from "./CompteurDepose";
import { OrganeCoupure } from "./OrganeCoupure";
import { Consuel } from "./Consuel";
// ReSharper disable InconsistentNaming
export class FicheComptageViewModel extends FicheComptageBaseViewModel {
    InformationsGenerales = ko.observable<InformationsGenerales>();
    DisjoncteurPose = ko.observable<DisjoncteurPose>();
    CompteurPose = ko.observable<CompteurPose>();
    DisjoncteurDepose = ko.observable<DisjoncteurDepose>();
    CompteurDepose = ko.observable<CompteurDepose>();
    OrganeCoupure = ko.observable<OrganeCoupure>();
    Consuel = ko.observable<Consuel>();

    constructor(data) {
        super(data);

        this.InformationsGenerales(new InformationsGenerales(data.InformationsGenerales));
        this.DisjoncteurPose(new DisjoncteurPose(data.DisjoncteurPose));
        this.CompteurPose(new CompteurPose(data.CompteurPose, this._isBranchProvis()));
        this.DisjoncteurDepose(new DisjoncteurDepose(data.DisjoncteurDepose));
        this.CompteurDepose(new CompteurDepose(data.CompteurDepose, this._isBranchProvis()));
        this.OrganeCoupure(new OrganeCoupure(data.OrganeCoupure));
        this.Consuel(new Consuel(data.Consuel));
    }

    private _isBranchNeuf(): boolean {
        const typeInter = this.TypeIntervention();

        if (typeInter == null) {
            return false;
        }

        return FicheComptageUtils.reglesGestion.forTypeInterBranchementNeuf === typeInter.toString();
    }

    private _isBranchProvis(): boolean {
        const typeInter = this.TypeIntervention();

        if (typeInter == null) {
            return false;
        }

        return FicheComptageUtils.reglesGestion.forTypeInterBranchementProvisoire === typeInter.toString();
    }

    private _isModifBranch(): boolean {
        const typeInter = this.TypeIntervention();

        if (typeInter == null) {
            return false;
        }

        return FicheComptageUtils.reglesGestion.forTypeInterModificationBranchement === typeInter.toString();
    }

    private _isDeposeDefinitive(): boolean {
        const typeOperation = this.TypeOperation();

        if (typeOperation == null) {
            return false;
        }

        return FicheComptageUtils.reglesGestion.forTypeOpeModDeposeDefinitive === typeOperation.toString();
    }

    private _isPoseBp(): boolean {
        const typeOperation = this.TypeOperation();

        if (typeOperation == null) {
            return false;
        }

        return FicheComptageUtils.reglesGestion.forTypeOpePoseBp === typeOperation.toString();
    }

    /* Init des "init" something */
    _TypeOperationArray = ko.computed(() => {
        let key: string = '';

        if (this._isBranchNeuf()) {
            key = FicheComptageUtils.keysForGestion.keyTypeInterBranchNeuf;
        } else if (this._isBranchProvis()) {
            key = FicheComptageUtils.keysForGestion.keyTypeInterBranchProvis;
        } else if (this._isModifBranch()) {
            key = FicheComptageUtils.keysForGestion.keyTypeInterModifBranch;
        }

        return FicheComptageUtils.reglesGestion.forTypeOperationArray[key];
    });

    _TypeOptionTarifaireArray = ko.computed(() =>{
        let key: string;

        // Le seul cas à part est celui-ci, donc on se permet un else, il faudra être plus précis si cela évolue
        if (this._isPoseBp()) {
            key = FicheComptageUtils.keysForGestion.keyTypeOperationPoseBp;
        } else {
            key = FicheComptageUtils.keysForGestion.keyTypeOperationBranchNeufAvecMade;
        }

        return FicheComptageUtils.reglesGestion.forTypeOptionTarifaireArray[key];
    });

    _TypeGrilleDistributeurArray = ko.computed(() => {
        let key: string;

        // Le seul cas à part est celui-ci, donc on se permet un else, il faudra être plus précis si cela évolue
        if (this._isModifBranch()) {
            key = FicheComptageUtils.keysForGestion.keyTypeInterModifBranch;
        } else {
            key = FicheComptageUtils.keysForGestion.keyTypeInterBranchNeuf;
        }

        return FicheComptageUtils.reglesGestion.forGrilleDistributeurArray[key];
    });

    /* Zone des "is" something */
    _isBranchementNeuf = ko.pureComputed({
        owner: this,
        read: () => {
            return this._isBranchNeuf();
        }
    });

    _isModificationBranchement = ko.pureComputed({
        owner: this,
        read: () => {
            return this._isModifBranch();
        }
    });

    _isBranchementNeufAvecMade = ko.pureComputed({
        owner: this,
        read: () => {
            const typeOperation = this.TypeOperation();

            return typeOperation != null &&
                FicheComptageUtils.reglesGestion.forTypeOpeBranchementNeufAvecMade === typeOperation.toString();
        }
    });

    _isModDeposeDefinitive = ko.pureComputed({
        owner: this,
        read: () => {
            const typeOperation = this.TypeOperation();

            return typeOperation != null &&
                FicheComptageUtils.reglesGestion.forTypeOpeModDeposeDefinitive === typeOperation.toString();
        }
    });

    _compteurPoseIsSimpleTarif = ko.pureComputed({
        owner: this,
        read: () => {
            return this.CompteurPose()._compteurIsTarif(FicheComptageUtils.reglesGestion.forSimpleTarif);
        }
    });

    /* Zone des "display" something */
    _displayHorairesHcPose = ko.pureComputed({
        owner: this,
        read: () => {
            const typeTechno = this.CompteurPose().Technologie();

            return typeTechno != null
                && FicheComptageUtils.reglesGestion.forTypeTechnoCbe === typeTechno.toString()
                && (this.CompteurPose()._compteurIsTarifComplexe());
        }
    });

    _displayTrancheeCommuneFt = ko.pureComputed({
        owner: this,
        read: () => {
            return this._isBranchNeuf() || this._isModifBranch();
        }
    });
}
