﻿;
(function ($) {
    $.fn.autocompleteVille = function (options) {
        var settings = {
            codeInseeField: null,
            triggerChange: false,
            requestFormat: function (request) {
                return '/Villes/AutoComplete';
            },
            requestData: function (request) {
                return { search: request.term };
            },
            afterSelect: function (item) { }
        };

        if (options) {
            $.extend(settings, options);
        }

        var me = $(this);

        var sourceFormat = function (request, response) {
            $.ajax({
                url: settings.requestFormat(request),
                data: settings.requestData(request),
                success: function (data) {
                    response($.map(data, function (item) {
                        var display = item.CodePostal + ' - ' + item.Commune;
                        return {
                            label: display,
                            value: display,
                            codePostal: item.CodePostal,
                            codeInsee: item.CodeInsee,
                            commune: item.Commune,
                            id: item.CodeInsee
                        };
                    }));
                }
            });
        };

        me.autocomplete({
            source: sourceFormat,
            select: function (event, ui) {
                var item = ui.item;
                $(settings.codeInseeField).each(function () {
                    var $me = $(this);

                    $me.val(item.id);

                    if (settings.triggerChange) {
                        $me.change();
                    }

                });

                if (typeof (settings.afterSelect) == 'function') {
                    settings.afterSelect(item);
                }
            }
        });

        return me;
    };
})(jQuery);