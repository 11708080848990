import { IMappingCallbacks, TypeActionEnum, TypeModeControle, TypeStatutNonConformiteEnum, TypeFichierEnum } from "./common";
import { ControleReponse } from "./controle-reponse";
import { ControleFichier } from "./controle-fichier";

// ReSharper disable  InconsistentNaming
///<reference path="../typings/moment/moment.d.ts" />

export class ControleAction implements IMappingCallbacks {
    constructor(reponse: ControleReponse, modeControle: string, currentUser: string, typeAction: TypeActionEnum) {
        this.ParentReponse = reponse;
        this.ModeControle(modeControle);
        this.TypeAction(typeAction);
        this.CurrentUtilisateur = currentUser;
        // Droit d'acc�s au bouton "rattacher"
        var md = this.ModeControle();

        switch (this.TypeAction()) {
            case TypeActionEnum.DemandeDeRepriseComplement:
                this.IsFileAttachActive(md === TypeModeControle.Validateur.toString());
                break;
            case TypeActionEnum.ReponseDeRepriseComplement:
                this.IsFileAttachActive(md === TypeModeControle.Controleur.toString());
                break;

            case TypeActionEnum.DemandeDeLevee:
            case TypeActionEnum.DemandeContestation:
                this.IsFileAttachActive(md === TypeModeControle.Prestataire.toString());
                break;

            case TypeActionEnum.ReponseDemandeDeLevee:
            case TypeActionEnum.ReponseContestation:
                // #3981 TM 22/ 11 / 2016Code comment� : standby suite � �change avec Pierre et possibles demande de changement
                this.IsFileAttachActive((md === TypeModeControle.Controleur.toString()/* && this.IsLastAction()*/
                    || md === TypeModeControle.Validateur.toString())

                    // une fois le choix fait on ne peut plus rattach� de fichier
                    && reponse.StatutReponse() !== TypeStatutNonConformiteEnum.ContesteConforme
                    && reponse.StatutReponse() !== TypeStatutNonConformiteEnum.ContesteNonObserve
                    && reponse.StatutReponse() !== TypeStatutNonConformiteEnum.ContestationRefusee
                    && reponse.StatutReponse() !== TypeStatutNonConformiteEnum.Levee
                    && reponse.StatutReponse() !== TypeStatutNonConformiteEnum.LeveeRefusee
                );
                break;

            default:
                this.IsFileAttachActive(false);
        }

        this.ListeFichiers.extend({ rateLimit: 50 });

        // pour transmettre la r�ponse � la r�ponse sous jacentes
        this.ReponseAction.subscribe(() => {
            //
            var val = parseInt(this.ReponseAction().toString());

            this.ParentReponse.NewReponse(val);
        });
    }
    ParentReponse: ControleReponse;

    IsLastAction = ko.observable<boolean>(false);

    IsFileAttachActive = ko.observable<boolean>(false);
    ModeControle = ko.observable<string>(TypeModeControle.Controleur.toString());
    TypeAction = ko.observable<TypeActionEnum>();

    Id = ko.observable(0);
    Author = ko.observable("");
    AuthorId = ko.observable("");
    DestinataireId = ko.observable("");
    Message = ko.observable("");

    _deferDateAction = ko.observable<boolean>(false);
    DateAction = ko.observable<Date>();

    CurrentUtilisateur: string;

    ListeFichiers = ko.observableArray<ControleFichier>();

    IsSend = ko.computed(() => {
        var ta = this.TypeAction(),
            md = this.ModeControle(),
            dt = this.DateAction();

        switch (ta) {
            case TypeActionEnum.ReponseContestation:
            case TypeActionEnum.ReponseDemandeDeLevee:
            case TypeActionEnum.ReponseDeRepriseComplement:
                if (md !== TypeModeControle.Prestataire.toString()) {
                    return moment(dt).year() > 2000;
                }
                break;
        }

        if (dt === undefined) {
            return false;
        }

        // Si l'action est abouti pas d'acc�s
        this.IsFileAttachActive(false);

        return true;
    });

    NeedMessage = ko.observable<boolean>(false);

    PrepareAcceptation = ko.observable<boolean>(false);
    PrepareRefus = ko.observable<boolean>(false);
    ReponseAction = ko.observable<Number>(0);

    IsDemandeComplement = ko.computed(() => { return this.TypeAction() === TypeActionEnum.DemandeDeRepriseComplement });
    IsReponseComplement = ko.computed(() => { return this.TypeAction() === TypeActionEnum.ReponseDeRepriseComplement });

    IsDemandeDeLevee = ko.computed(() => { return this.TypeAction() === TypeActionEnum.DemandeDeLevee });
    IsReponseDemandeDeLevee = ko.computed(() => { return this.TypeAction() === TypeActionEnum.ReponseDemandeDeLevee });

    IsContestation = ko.computed(() => { return this.TypeAction() === TypeActionEnum.DemandeContestation });
    IsReponseContestation = ko.computed(() => { return this.TypeAction() === TypeActionEnum.ReponseContestation });

    IsEmptySlot = ko.computed(() => { return this.DateAction() !== undefined && new Date(this.DateAction().toString()).getFullYear() < 2000 });

    /**
     * Demande de complm�net ou validation
     */
    SendDemandeComplement = (parents: ControleReponse[]) => {
        this.DateAction(new Date());
        parents[1].SendDemandeComplement();
    }

    ValideNonConformite = (parents: ControleReponse[]) => {
        this.DateAction(new Date());
        parents[1].ValideNonConformite();
    }

    AskDemandeComplement = (parents: ControleReponse[]) => {
        this.DateAction(new Date());
        parents[1].AskDemandeComplement();
    }


    SendReponseComplement = (parents: ControleReponse[]) => {
        this.DateAction(new Date());
        parents[1].SendReponseComplement();
    }

    CancelDemandeComplement = (parents: ControleReponse[]) => {
        parents[1].CancelDemandeComplement(this);
    }


    CancelDemandeDeLevee = (parents: ControleReponse[]) => {
        parents[1].CancelDemandeDeLevee(this);
    }

    CancelContestation = (parents: ControleReponse[]) => {
        parents[1].CancelContestation(this);
    }

    /**
     * REPONSE CONTESTATION
     */

    // #3981 TM 22/ 11 / 2016Code comment� : standby suite � �change avec Pierre et possibles demande de changement

    PrepareAccepteContestation = () => {
        this._deferDateAction(true);

        this.PrepareAcceptation(true);
        this.IsFileAttachActive(true);

        this.ParentReponse.AccepteContestation();
    }

    CancelPrepareAccepteContestation = () => {
        this._deferDateAction(false);

        this.PrepareAcceptation(false);
        this.IsFileAttachActive(false);

        this.ParentReponse.CancelReponseAContestation();
    }

    PrepareRefusContestation = () => {
        this._deferDateAction(true);

        this.PrepareRefus(true);
        this.IsFileAttachActive(true);

        this.ParentReponse.RefuseContestation();
    }

    CancelPrepareRefusContestation = () => {
        this._deferDateAction(false);

        this.PrepareRefus(false);
        this.IsFileAttachActive(false);

        this.ParentReponse.CancelReponseAContestation();
    }

    /**
   * REPONSE DEMANDE DE LEVEE
   */

    PrepareAccepteDemandeDeLevee = () => {
        this._deferDateAction(true);

        this.PrepareAcceptation(true);
        this.IsFileAttachActive(true);

        this.ParentReponse.AccepteDemandeDeLevee();
        // pas de choix "Conforme" ou "Non observ�" pour les demandes de lev�es
        this.ReponseAction(1);
    }

    CancelPrepareAccepteDemandeDeLevee = () => {
        this._deferDateAction(false);

        this.PrepareAcceptation(false);
        this.IsFileAttachActive(false);

        this.ParentReponse.CancelReponseALevee();
        this.ReponseAction(0);
    }

    PrepareRefusDemandeDeLevee = () => {
        this._deferDateAction(true);

        this.PrepareRefus(true);
        this.IsFileAttachActive(true);

        this.ParentReponse.RefuseDemandeDeLevee();
    }

    CancelPrepareRefusDemandeDeLevee = () => {
        this._deferDateAction(false);

        this.PrepareRefus(false);
        this.IsFileAttachActive(false);

        this.ParentReponse.CancelReponseALevee();
    }

    /**
     * COMMUN
     */
    Prepare = ko.computed(() => {
        let pr = this.PrepareRefus(),
            pa = this.PrepareAcceptation();
        return pr || pa;
    });

    /*
   * fichiers
   */

    TypeFichier = ko.pureComputed(() => {
        switch (this.TypeAction()) {
            case TypeActionEnum.DemandeDeRepriseComplement:
                return TypeFichierEnum.ControleChantierDemandeDeRepriseComplement;

            case TypeActionEnum.ReponseDeRepriseComplement:
                return TypeFichierEnum.ControleChantierReponseRepriseComplement;

            case TypeActionEnum.DemandeDeLevee:
                return TypeFichierEnum.ControleChantierDemandeDeLevee;

            case TypeActionEnum.ReponseDemandeDeLevee:
                return TypeFichierEnum.ControleChantierReponseDemandeDeLevee;

            case TypeActionEnum.DemandeContestation:
                return TypeFichierEnum.ControleChantierContestation;

            case TypeActionEnum.ReponseContestation:
                return TypeFichierEnum.ControleChantierReponseContestation;
        }
    });

    AddFichier = (f: ControleFichier) => {
        this.ListeFichiers.push(f);
    };

    RemoveFichier = (f: ControleFichier) => {
        this.ListeFichiers.remove(f);
    }

    _beforeMapping() {
        if (this._deferDateAction == null) {
            throw new Error("Proto invalide, c'est quoi ce merdier ?");
        }

        if (this._deferDateAction()) {
            this.DateAction(new Date());
        }
    }
}
