﻿import { CommonInfosPrestation, TravauxValue } from "./pposr-general";
// ReSharper disable InconsistentNaming

export class SolutionTravauxClientPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'solutiontravclient');

        this.Phase(data.Phase);
        this.PuissanceSoutirage(data.PuissanceSoutirage);
        this.NombreFilsComptage(data.NombreFilsComptage);
        this.TechniqueBranchement(data.TechniqueBranchement);
        this.PDLConso(data.PDLConso);
        this.TypeBranchement(data.TypeBranchement);
        this.TypeCompteur(data.TypeCompteur);
        this.LongueurPublic(String(data.LongueurPublic));
        this.LongueurPrive(String(data.LongueurPrive));
        this.LocalHabitationPlus2Ans(String(data.LocalHabitationPlus2Ans));
        this.AutorisationPassageNecessaire(String(data.AutorisationPassageNecessaire));
        this.SupportImplante(String(data.SupportImplante));
        this.NatureTravaux(data.NatureTravaux);

        this.CommentaireChantier(data.CommentaireChantier);
        this.CommentaireChantierEmail(this._getCommentLastMail(this.CommentaireChantier(),
            data.CommentaireChantierEmail));

        this.NumDMR(data.NumDMR);
        this.NumDMRLinkky(data.NumDMRLinkky);
        this.TechniqueReport(data.TechniqueReport);
        this.CanalisationMultiple(data.CanalisationMultiple);
        this.DeposeBranchementProvisiore(String(data.DeposeBranchementProvisiore));
        this.Coffret(data.Coffret);
        this.Terrassement(data.Terrassement);
        this.Raccordement(data.Raccordement);
        this.ModeRealisation(data.ModeRealisation);
        this.IsTravauxRealiserClient(String(data.IsTravauxRealiserClient));
        this.TravauxRealiserClient(data.TravauxRealiserClient);

        this.CommentaireTravauxClient(data.CommentaireTravauxClient);
        this.CommentaireTravauxClientEmail(this._getCommentLastMail(this.CommentaireTravauxClient(),
            data.CommentaireTravauxClientEmail));

        this._TravauxClientArrayOrig = new Array<TravauxValue>();

        for (let i = 0; i < data.TravauxClientArray.length; i++) {
            this._TravauxClientArray.push(new TravauxValue(data.TravauxClientArray[i], false));
        }

        this._LocalHabitationPlus2AnsName = ko.observable<string>(`localHabitation${this.PrestationId()}`);
        this._AutorisationPassageNecessaireName =
            ko.observable<string>(`autorisationPassage${this.PrestationId()}`);
        this._SupportImplanteName = ko.observable<string>(`supportImplante${this.PrestationId()}`);
        this._DeposeBranchementProvisioreName = ko.observable<string>(`deposeBranchement${this.PrestationId()}`);
        this._IsTravauxRealiserClientName = ko.observable<string>(`isTravauxRealiserClient${this.PrestationId()}`);


        this._isDemandeBp(data.IsDemandeBp);
        this.PuissanceSouscriteBp(data.PuissanceSouscriteBp);
        this.OptionTarifaireBp(data.OptionTarifaireBp);
        this.StructureComptageBp(data.StructureComptageBp);
        this.NatureBranchementBp(data.NatureBranchementBp);

        // On conserve les valeurs d'origine
        this._initOrigValues();
    }

    // NB: les valeurs des radio boutons sont des string volontairement pour que le "checked" fonctionne
    Phase = ko.observable<number>();
    PuissanceSoutirage = ko.observable<number>();
    NombreFilsComptage = ko.observable<number>();
    TechniqueBranchement = ko.observable<string>();
    PDLConso = ko.observable<string>();
    TypeBranchement = ko.observable<string>();
    TypeCompteur = ko.observable<string>();
    LongueurPublic = ko.observable<string>().extend({ roundedDecimalLongueurPpOsr: 1 });
    LongueurPrive = ko.observable<string>().extend({ roundedDecimalLongueurPpOsr: 1 });
    LocalHabitationPlus2Ans = ko.observable<string>();
    AutorisationPassageNecessaire = ko.observable<string>();
    SupportImplante = ko.observable<string>();
    NatureTravaux = ko.observable<number>();

    CommentaireChantier = ko.observable<string>();
    CommentaireChantierEmail = ko.observable<string>();

    NumDMR = ko.observable<string>();
    NumDMRLinkky = ko.observable<string>();
    TechniqueReport = ko.observable<number>();
    CanalisationMultiple = ko.observable<boolean>();
    DeposeBranchementProvisiore = ko.observable<string>();
    Coffret = ko.observable<number>();
    Terrassement = ko.observable<number>();
    Raccordement = ko.observable<number>();
    ModeRealisation = ko.observable<number>();
    IsTravauxRealiserClient = ko.observable<string>();
    // Ne sera mis à jour qu'à la sauvegarde pour conserver les valeurs tant qu'on est sur la vue et qu'on bascule de Non à Oui sur IsTravauxRealiserClient
    TravauxRealiserClient = ko.observable<number>();

    CommentaireTravauxClient = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    CommentaireTravauxClientEmail = ko.observable<string>();

    PuissanceSouscriteBp = ko.observable<number>();
    OptionTarifaireBp = ko.observable<number>();
    StructureComptageBp = ko.observable<string>();
    NatureBranchementBp = ko.observable<string>();
    _isDemandeBp = ko.observable<boolean>();

    _LocalHabitationPlus2AnsName = ko.observable<string>();
    _AutorisationPassageNecessaireName = ko.observable<string>();
    _SupportImplanteName = ko.observable<string>();
    _DeposeBranchementProvisioreName = ko.observable<string>();
    _IsTravauxRealiserClientName = ko.observable<string>();

    _TravauxClientArray = ko.observableArray<TravauxValue>();

    _ErrorTravauxClient: string = "Vous devez sélectionner au moins un des travaux client dans la liste.";

    _TechniqueBranchementOrig: string;
    _TypeBranchementOrig: string;
    _LongueurPublicOrig: string;
    _LongueurPriveOrig: string;
    _LocalHabitationPlus2AnsOrig: string;
    _AutorisationPassageNecessaireOrig: string;
    _SupportImplanteOrig: string;
    _NatureTravauxOrig: number;
    _TechniqueReportOrig: number;
    _DeposeBranchementProvisioreOrig: string;
    _CoffretOrig: number;
    _TerrassementOrig: number;
    _RaccordementOrig: number;
    _ModeRealisationOrig: number;
    _IsTravauxRealiserClientOrig: string;
    _TravauxRealiserClientOrig: number;
    _CommentaireTravauxClientOrig: string;
    _CommentaireTravauxClientEmailOrig: string;

    _PuissanceSouscriteBpOrig: number;
    _OptionTarifaireBpOrig: number;
    _StructureComptageBpOrig: string;
    _NatureBranchementBpOrig: string;


    _TravauxClientArrayOrig: TravauxValue[];


    _isAerien = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TechniqueBranchement() === "Branchement complet aérien";
        }
    });

    _noTechBranchement = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TechniqueBranchement() === "Aucune";
        }
    });

    _noTypeBranchement = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TypeBranchement() === "0";
        }
    });

    _isTravauxRealiserClient = ko.pureComputed({
        owner: this,
        read: () => {
            return this.IsTravauxRealiserClient() === "true";
        }
    });

    _isBranchementType2 = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TypeBranchement() === "2";
        }
    });

    _travauxClientArray() {
        return this._TravauxClientArray().filter(_ => _._Label().length > 0);
    }

    _longueurIsValid(longueur: string) {
        return longueur === '' || longueur === null || longueur.match(/^\d{1,6}((\.|\,)\d{1})?$/);
    }

    _longueurPublicIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            return this._longueurIsValid(this.LongueurPublic());
        }
    });

    _typeBranchementVisible = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TechniqueBranchement() === "Branchement complet aéro-souterrain" ||
                this.TechniqueBranchement() === "Branchement complet souterrain" ||
                this.TechniqueBranchement() === "Liaison privative";
        }
    });

    _longueurPublicVisible = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TechniqueBranchement() === "Branchement complet aérien" ||
                this.TechniqueBranchement() === "Branchement complet aéro-souterrain" ||
                this.TechniqueBranchement() === "Branchement complet souterrain" ||
                this.TechniqueBranchement() === "Liaison publique aéro-souterraine" ||
                this.TechniqueBranchement() === "Liaison publique souterraine" ||
                this.TechniqueBranchement() === "Ajout de compteur sur branchement existant";
        }
    });

    _longueurPriveVisible = ko.pureComputed({
        owner: this,
        read: () => {
            return this.TechniqueBranchement() === "Branchement complet aérien" ||
                this.TechniqueBranchement() === "Branchement complet aéro-souterrain" ||
                this.TechniqueBranchement() === "Branchement complet souterrain" ||
                this.TechniqueBranchement() === "Liaison privative" ||
                this.TechniqueBranchement() === "Ajout de compteur sur branchement existant";
        }
    });


    _longueurPriveIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            return this._longueurIsValid(this.LongueurPrive());
        }
    });

    _longueurPriveIsCoherentType = ko.pureComputed({
        owner: this,
        read: () => {
            // si pas de test à réalisé, c'est forcement OK
            if (!this._longueurPriveVisible() ||
                !this._longueurPriveIsValid() ||
                !this._typeBranchementVisible() ||
                this.TypeBranchement() !== "1")
                return true;

            return Number(this.LongueurPrive()) <= 30;
        }
    });

    // Si on a coché Oui pour les travaux client à réaliser, au moins un doit être sélectionné
    _travauxClientIsValid = ko.pureComputed({
        owner: this,
        read: () => {
            return (!this._isTravauxRealiserClient() ||
                this._isTravauxRealiserClient() &&
                this._TravauxClientArray()
                    .reduce((accumulator, currentValue) => accumulator + currentValue._Value(), 0)) >
                0;
        }
    });

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this.CommentaireTravauxClient.hasError() &&
                this._longueurIsValid(this.LongueurPublic()) &&
                this._longueurIsValid(this.LongueurPrive()) &&
                this._travauxClientIsValid();
        }
    });

    // Sortie du mode modification
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            this._ModeModificationGlobal(false);

            if (resetValues) {
                this.TechniqueBranchement(this._TechniqueBranchementOrig);
                this.TypeBranchement(this._TypeBranchementOrig);
                this.LongueurPublic(this._LongueurPublicOrig);
                this.LongueurPrive(this._LongueurPriveOrig);
                this.LocalHabitationPlus2Ans(this._LocalHabitationPlus2AnsOrig);
                this.AutorisationPassageNecessaire(this._AutorisationPassageNecessaireOrig);
                this.SupportImplante(this._SupportImplanteOrig);
                this.NatureTravaux(this._NatureTravauxOrig);
                this.TechniqueReport(this._TechniqueReportOrig);
                this.DeposeBranchementProvisiore(this._DeposeBranchementProvisioreOrig);
                this.Coffret(this._CoffretOrig);
                this.Terrassement(this._TerrassementOrig);
                this.Raccordement(this._RaccordementOrig);
                this.ModeRealisation(this._ModeRealisationOrig);
                this.IsTravauxRealiserClient(this._IsTravauxRealiserClientOrig);
                this.CommentaireTravauxClient(this._CommentaireTravauxClientOrig);
                this.CommentaireTravauxClientEmail(this._CommentaireTravauxClientEmailOrig);
                this.TravauxRealiserClient(this._TravauxRealiserClientOrig);

                this.PuissanceSouscriteBp(this._PuissanceSouscriteBpOrig);
                this.OptionTarifaireBp(this._OptionTarifaireBpOrig);
                this.StructureComptageBp(this._StructureComptageBpOrig);
                this.NatureBranchementBp(this._NatureBranchementBpOrig);

                this._TravauxClientArray.removeAll();

                for (let i = 0; i < this._TravauxClientArrayOrig.length; i++) {
                    this._TravauxClientArray.push(new TravauxValue(this._TravauxClientArrayOrig[i], true));
                }
            }

            this._disableChangeAlert();
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        this._TechniqueBranchementOrig = this.TechniqueBranchement();
        this._TypeBranchementOrig = this.TypeBranchement();
        this._LongueurPublicOrig = this.LongueurPublic();
        this._LongueurPriveOrig = this.LongueurPrive();
        this._LocalHabitationPlus2AnsOrig = this.LocalHabitationPlus2Ans();
        this._AutorisationPassageNecessaireOrig = this.AutorisationPassageNecessaire();
        this._SupportImplanteOrig = this.SupportImplante();
        this._NatureTravauxOrig = this.NatureTravaux();
        this._TechniqueReportOrig = this.TechniqueReport();
        this._DeposeBranchementProvisioreOrig = this.DeposeBranchementProvisiore();
        this._CoffretOrig = this.Coffret();
        this._TerrassementOrig = this.Terrassement();
        this._RaccordementOrig = this.Raccordement();
        this._ModeRealisationOrig = this.ModeRealisation();
        this._IsTravauxRealiserClientOrig = this.IsTravauxRealiserClient();
        this._TravauxRealiserClientOrig = this.TravauxRealiserClient();
        this._CommentaireTravauxClientOrig = this.CommentaireTravauxClient();
        // sera modifié automatiquement à la sortie de la sauvegarde
        this._CommentaireTravauxClientEmailOrig = this.CommentaireTravauxClientEmail();

        this._PuissanceSouscriteBpOrig = this.PuissanceSouscriteBp();
        this._OptionTarifaireBpOrig = this.OptionTarifaireBp();
        this._StructureComptageBpOrig = this.StructureComptageBp();
        this._NatureBranchementBpOrig = this.NatureBranchementBp();


        this._TravauxClientArrayOrig = new Array<TravauxValue>();

        for (let i = 0; i < this._TravauxClientArray().length; i++) {
            this._TravauxClientArrayOrig.push(new TravauxValue(this._TravauxClientArray()[i], true));
        }
    }

    _prepareSave() {
        const self = this;

        if (self._isValid()) {
            let sumtravauxClient = 0;

            // Si les travaux clients sont cochés, on retourne l'int correspondant à l'ensemble des travaux clients sélectionnés
            if (self.IsTravauxRealiserClient() === "true") {
                sumtravauxClient = self._TravauxClientArray().reduce(((pv, cv) => pv + cv._Value()), 0);

                // Sinon pas de travaux client, on met tout à zéro : pour décocher on doit setter la Value du flag à 0, la PotentialValue restant la même
            } else {
                for (let i = 0; i < self._TravauxClientArray().length; i++) {
                    self._TravauxClientArray()[i]._Value(0);
                }
            }

            // La valeur sera 0 si les travaux client sont décochés, la somme des travaux cochés sinon
            self.TravauxRealiserClient(sumtravauxClient);

            self._save('/Affaire/EnregistrerModifSolutionTravauxClient',
                this._PrestationLinkWithHash(),
                this._setNewStateAfterSave,
                self);
        }
    }

    _setNewStateAfterSave(self: SolutionTravauxClientPrestationViewModel) {
        const success = self._PendingSave();

        // Si succès de la sauvegarde, avant d'établir les nouvelles valeurs d'origine, on change l'email lié au commentaire en cas de modification seulement
        if (success) {
            self._disableModify(false);

            if (self.CommentaireTravauxClient() !== self._CommentaireTravauxClientOrig) {
                self.CommentaireTravauxClientEmail(self._UserEmail());
            }

            // Les valeurs sauvées sont les nouvelles valeurs d'origine
            self._initOrigValues();

            self._PendingSave(false);
        }
    }
}
// ReSharper restore InconsistentNaming
