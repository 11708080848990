﻿export class RoleAffectationViewModel {
    constructor(data?: any) {
        if (data == null) {
            return;
        }

        this.id(data.id || "");
        this.available(data.available || []);
        this.affected(data.affected || []);
        this.ajaxMode(data.ajaxMode && true);
        this.expandActive(data.expandActive && true);
    }

    id = ko.observable<string>();
    isBusy = ko.observable<boolean>(false);
    available = ko.observableArray<string>();
    affected = ko.observableArray<string>();
    ajaxMode = ko.observable<boolean>(true);
    expandActive = ko.observable<boolean>(false);

    expandedDomaines = ko.observableArray<string>();

    isInRole(role: string) {
        return this.affected().indexOf(role) !== -1;
    }

    toggleRole(role: string) {
        if (!this.isInRole(role)) {
            this.addToRole(role);
        } else {
            this.removeFromRole(role);
        }
    }

    domaineExpand(domaine: string) {
        if (this.isDomaineExpanded(domaine)) {
            this.expandedDomaines.remove(domaine);
        } else {
            this.expandedDomaines.push(domaine);
        }
    }

    isDomaineExpanded(domaine: string) {
        let exp = this.expandedDomaines(),
            active = this.expandActive();

        if (active) {
            return exp.indexOf(domaine) > -1;
        }

        return true;
    }

    private addToRole(role: string) {
        return this._ajaxBase('/AccountRole/AddUserToRole', {
            id: this.id(),
            role: role
        }).done((a) => {
            this.affected.push(role);
            let statutComptePgi = "oui";
            if (a !== undefined && a !== null && a !== "") {
                if (a.Status === true) {
                    $.sticky(a.Message, { cssClass: 'orange' });
                    statutComptePgi = "Non";
                } else {
                    if (a.Error !== undefined) {
                        $.sticky(a.Error, { cssClass: 'red' });
                        statutComptePgi = "Non concerné";
                    }
                }
            }

            if (role === "PRCDEMAT") {
                $('#statutComptePgi').text(statutComptePgi);
            }
        });
    }

    private removeFromRole(role: string) {
        return this._ajaxBase('/AccountRole/RemoveUserFromRole', {
            id: this.id(),
            role: role
        }).done(() => {
            this.affected.remove(role);
            if (role === "PRCDEMAT") {
                $('#statutComptePgi').text("Non concerné");
            }
        });
    }

    // ReSharper disable once InconsistentNaming
    private _ajaxBase(url: string, data: any): JQueryPromise<any> {
        if (!this.ajaxMode()) {
            return $.when({});
        }

        if (this.isBusy()) {
            return ($.Deferred().rejectWith({ message: 'BUSY' }));
        }

        const sts = {
            type: 'POST',
            url: url,
            data: $.fn.putValidationToken(data)
        };

        return $.ajax(sts).always(() => {
            this.isBusy(false);
        });
    }
}
