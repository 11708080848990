﻿import { FicheComptageUtils } from "./pposr-fiche-comptage-utils";
// ReSharper disable InconsistentNaming

export class LibelleValue {
    Text = ko.observable<string>();
    Value = ko.observable<string>();

    constructor(libelle, value) {
        this.Text(libelle);
        this.Value(value);
    }
}

export class FicheComptageIndexViewModel {
    BleuHp = ko.observable<string>().extend({ addZerosBefore: 5 });
    BleuHc = ko.observable<string>().extend({ addZerosBefore: 5 });
    BlancHp = ko.observable<string>().extend({ addZerosBefore: 5 });
    BlancHc = ko.observable<string>().extend({ addZerosBefore: 5 });
    RougeHp = ko.observable<string>().extend({ addZerosBefore: 5 });
    RougeHc = ko.observable<string>().extend({ addZerosBefore: 5 });

    constructor(dataIndex: any) {
        const defaultValue = "";
        let bleuHp = defaultValue,
            bleuHc = defaultValue,
            blancHp = defaultValue,
            blancHc = defaultValue,
            rougeHp = defaultValue,
            rougeHc = defaultValue;

        if (dataIndex !== null && dataIndex !== undefined) {
            bleuHp = dataIndex["BleuHp"];
            bleuHc = dataIndex["BleuHc"];
            blancHp = dataIndex["BlancHp"];
            blancHc = dataIndex["BlancHc"];
            rougeHp = dataIndex["RougeHp"];
            rougeHc = dataIndex["RougeHc"];
        }

        this.BleuHp(bleuHp);
        this.BleuHc(bleuHc);
        this.BlancHp(blancHp);
        this.BlancHc(blancHc);
        this.RougeHp(rougeHp);
        this.RougeHc(rougeHc);
    }
}

export class FicheComptageBaseViewModel {
    IdComptage = ko.observable<string>();
    IdAffaire = ko.observable<string>();
    IdEntreprise = ko.observable<string>();
    _IsEdition = ko.observable<boolean>();
    TypeIntervention = ko.observable<string>();
    TypeOperation = ko.observable<string>();

    _FullConstructeurArray = ko.observableArray<LibelleValue>();

    constructor(data) {
        FicheComptageUtils.setUtilsValues(data);

        this.IdComptage(data.IdComptage);
        this.IdAffaire(data.IdAffaire);
        this.IdEntreprise(data.IdEntreprise);
        this._IsEdition(data.IsEdition);
        this.TypeIntervention(data.InformationsGenerales.TypeIntervention);
        this.TypeOperation(data.InformationsGenerales.TypeOperation);
    }
}

$(document).ready(() => {
    $('#form-collecte-pposr .help-block').each(function () {
        var $me = $(this),
            $formGroup = $me.closest('.form-group');
        const targetNode = $me[0];

        if (targetNode.innerText !== "") {
            $formGroup.addClass('has-error');
        } else {
            $formGroup.removeClass('has-error');
        }

        // On observe les changements sur la liste des enfants
        const config = { childList: true };

        // Fonction callback à exécuter quand une mutation est observée
        const callback = mutationsList => {
            for (let mutation of mutationsList) {
                if ($me.children().length) {
                    $formGroup.addClass('has-error');
                } else {
                    $formGroup.removeClass('has-error');
                }
            }
        };

        const observer = new MutationObserver(callback);

        // Commence à observer le noeud cible pour les mutations sur childList
        observer.observe(targetNode, config);
    });

    $('#form-collecte-pposr .actions .btn:not(.cancel-collecte)').click(function () {
        const $me = $(this),
            $actionRow = $me.parent(),
            $form = $me.closest('form');

        if ($form.valid()) {
            $actionRow.find('.btn').hide();
            $actionRow.find('.wait-for-save-collecte').show();
        }
    });

    $('#form-collecte-pposr input, #form-collecte-pposr select').change(function () {
        const $me = $(this),
            errorClass = 'field-validation-error',
            $myError = $me.next(`.${errorClass}`),
            $myFormGroup = $me.closest('.form-group');

        // Retour de validation à masquer, pas la validation client
        if (!$myError.children().length) {
            $myFormGroup.removeClass('has-error');
        }
    });
});
