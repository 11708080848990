﻿export class TypeOuvrage {
    Id: string;
    LibelleLong: string;
    LibelleCourt: string;
    constructor(id, libelleLong, libelleCourt) {
        this.Id = id;
        this.LibelleLong = libelleLong;
        this.LibelleCourt = libelleCourt;
    }
}
