﻿import { PlanPrevention } from "./PlanPrevention";
import { MiniFile } from "./MiniFile";
import { Intervenant } from "./Intervenant";
import { Risque } from "./Risque";
import { Utils } from "../typings/Utils";

const modalChoixNeedIcp = $('#modal-choix-need-icp');
if (modalChoixNeedIcp !== null && modalChoixNeedIcp !== undefined && modalChoixNeedIcp.length > 0 && !modalChoixNeedIcp.data('modal')) {
    modalChoixNeedIcp.modal({ backdrop: true });
    modalChoixNeedIcp.find('[data-dismiss="modal"]').click(e => {
        e.preventDefault();
        modalChoixNeedIcp.modal('hide');
    });
}

export class FicheAnalyseRisques extends PlanPrevention {

    DateFAR = ko.observable<string>().extend({ required: "" });
    ConsistanceTravaux = ko.observable<string>();
    Synthese = ko.observable<string>();

    ConsistanceFiles = ko.observableArray<MiniFile>();

    _IntervenantsEE = ko.observableArray<Intervenant>();
    _IntervenantsEnedis = ko.observableArray<Intervenant>();

    _IntervenantTemporaireEE = ko.observable<Intervenant>(new Intervenant(2));
    _IntervenantTemporaireEnedis = ko.observable<Intervenant>(new Intervenant(5));

    constructor(data) {
        super(data, false);
        if (!data) {
            return;
        }
        this.DateFAR(data.DateFAR);
        this.ConsistanceTravaux(data.ConsistanceTravaux);
        this.Synthese(data.Synthese);
        this.IsBrouillon(data.IsBrouillon);
        for (const index in data.Risques) {
            if (Object.prototype.hasOwnProperty.call(data.Risques, index)) {
                const risque = new Risque(data.Risques[index]);
                this._Risques().push(risque);
            }
        }
        for (const index in data.ConsistanceFiles) {
            if (Object.prototype.hasOwnProperty.call(data.ConsistanceFiles, index)) {
                this.ConsistanceFiles().push(new MiniFile(data.ConsistanceFiles[index]));
            }
        }
        for (const index in data.Intervenants) {
            if (Object.prototype.hasOwnProperty.call(data.Intervenants, index)) {
                const intervenant = new Intervenant(data.Intervenants[index]);
                switch (intervenant.TypePresence()) {
                    case 2:
                        this._IntervenantsEE().push(intervenant);
                        break;
                    case 5:
                        this._IntervenantsEnedis().push(intervenant);
                        break;
                }
            }
        }
    }

    save(brouillon: boolean, fromVisualiser: boolean) {
        this.IsBrouillon(brouillon);
        if (brouillon || fromVisualiser) {
            this._realSave(fromVisualiser);
        } else {
            modalChoixNeedIcp.modal('show');
            $('#valid-need-icp').on('click',
                () => {
                    const checked = $('input[name="needICP"]:checked').val();
                    let needIcp: boolean;
                    if (checked == 'Oui') {
                        needIcp = true;
                    }
                    else if (checked == 'Non') {
                        needIcp = false;
                    }
                    if (typeof needIcp != 'undefined') {
                        this._realSave(false, needIcp);
                    }
                });
        }
    }

    _realSave(fromVisualiser: boolean, needIcp?: boolean) {
        $.sticky("Sauvegarde en cours...", { cssClass: '' });

        return $.ajax({
            url: '/Affaire/SaveFAR',
            type: 'POST',
            //Pour que le target _blank fonctionne faut que ça soit un trusted event et pour ce faire il faut le async false et gérer l'ouverture de la tab dans le success() et non le done()
            async: !fromVisualiser,
            success: () => {
                if (fromVisualiser) {
                    const url = `/Affaire/DisplayFAR/${this.Id()}`;
                    window.open(url, '_blank');
                }
            },
            data: {
                __RequestVerificationToken: $.fn.findValidationToken(),
                far: ko.toJSON(this, Utils.cleanPrivateProps),
                needIcp: needIcp
            }
        })
            .done((data) => {
                if (!fromVisualiser) {
                    location.reload();
                }
            });
    }

    addIntervenant(intervenant: Intervenant, listConcerned: KnockoutObservableArray<Intervenant>) {
        const newInterv = new Intervenant(intervenant, true);
        listConcerned.push(newInterv);
        intervenant.raz();//Pour remettre a zero les champs d'ajout
    }

    _addRisque(risque: Risque) {
        const newRisque = new Risque(risque, true);
        newRisque.IsTemporaire(false);
        this._Risques.push(newRisque);
        risque.raz(); //On remet le risque temporaire pour la vue

        /*Si le formulaire est une FAR on va sauvegarder le risque afin de pouvoir y ajouter des pièces jointes*/
        /*Il nous faut son ID*/
        $.ajax({
            url: '/Affaire/SaveRisque',
            type: 'POST',
            data: {
                __RequestVerificationToken: $.fn.findValidationToken(),
                risque: ko.toJSON(newRisque),
                far: ko.toJSON(this, Utils.cleanPrivateProps)
            }
        })
            .done((data: any) => {
                if (data.Status) {
                    newRisque.Id(data.Data);
                }
            });
    }

    _allIntervenantNameValide = ko.computed({
        owner: this, pure: true, read: () => {
            let allNames = new Array<Object>();
            let interv: Intervenant;
            const intervenantsEe = this._IntervenantsEE();
            for (const index in intervenantsEe) {
                if (Object.prototype.hasOwnProperty.call(intervenantsEe, index)) {
                    interv = intervenantsEe[index];
                    allNames.push({ id: interv.NomPrenomLibelle(), text: interv.NomPrenomLibelle() });
                }
            }
            const intervenantsEnedis = this._IntervenantsEnedis();
            for (const index in intervenantsEnedis) {
                if (Object.prototype.hasOwnProperty.call(intervenantsEnedis, index)) {
                    interv = intervenantsEnedis[index];
                    allNames.push({ id: interv.NomPrenomLibelle(), text: interv.NomPrenomLibelle() });
                }
            }
            return ko.toJSON(allNames);
        }
    });

    //Juste un alias pour le binding
    Intervenants = ko.computed({
        owner: this, pure: true, read: () => {
            return this._IntervenantsEE().concat(this._IntervenantsEnedis());
        }
    });

    //Juste un alias pour le binding
    Risques = ko.computed({
        owner: this, pure: true, read: () => {
            return this._allRisquesValid();
        }
    });

}
