﻿import { regexMail } from "./inspection-commune-prealable";
import { Utils } from "../typings/Utils";

export class Coordonnateur {
    Id = ko.observable<number>();
    Nom = ko.observable<string>();
    Prenom = ko.observable<string>();
    Entreprise = ko.observable<string>();
    FkIdAffaire = ko.observable<number>();
    FkIdIntervenant = ko.observable<string>();
    Mail = ko.observable<string>().extend({
        pattern: regexMail
    });
    Telephone = ko.observable<string>();

    constructor(idAffaire) {
        if (idAffaire) {
            this.FkIdAffaire(idAffaire);
        }
    }

    _canEditInfo = ko.computed({
        owner: this, read: () => {
            //Si on a une FK, c'est un interne on lui met pas la possibilité de modifier les champs
            return !this.FkIdIntervenant() || this.FkIdIntervenant().length == 0;
        }
    });

    _razFkIdIntervenant() {
        this.FkIdIntervenant('');
    }

    _isValid = ko.computed({
        owner: this, read: () => {
            if (!this._canEditInfo()) { //Si c'est un interne
                return true;
            } else { //Sinon, un externe doit au moins avoir un mail
                return (this.Mail() && !this.Mail.hasError());
            }
        }
    });

    _save() {
        if (this._isValid()) { //Au cas ou
            $.sticky("Sauvegarde en cours...", { cssClass: '' });
            return $.ajax({
                url: '/Affaire/SetCoordonnateur',
                type: 'POST',
                data: {
                    __RequestVerificationToken: $.fn.findValidationToken(),
                    coordonnateur: ko.toJSON(this, Utils.cleanPrivateProps)
                }
            }).done(() => {
                location.reload();
            });
        }
    }
}
