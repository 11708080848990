﻿declare const instance: IntervenantSupportbs3;

export class IntervenantSupportbs3 {
    // ReSharper disable InconsistentNaming
    Id = ko.observable<number>();
    Prenom = ko.observable<string>();
    Nom = ko.observable<string>();
    Telephone = ko.observable<string>();
    Email = ko.observable<string>();
    IsGenerique = ko.observable<boolean>();
    // ReSharper restore InconsistentNaming

    constructor(data) {
        this.Id(data);

        jQuery.validator.addMethod("telephone", function (value, element) {
            return this.optional(element) || /^0[0-9]([-. ]?\d{2}){4}[-. ]?$/.test(value);
        }, 'Veuillez saisir un numéro de téléphone valide');


        $(document).on('click', '.support-delete', function (event) {

            const $item = $(this).parent();

            instance.deleteUtilisateur($item, event.type);
        });

        $('.support-source').sortable({
            dropOnEmpty: true,
            cursor: "pointer",
            connectWith: ".support-target",
            placeholder: 'ui-state-highlight'

        });

        $('.support-target').sortable({
            items: '> :not(.generique)',
            dropOnEmpty: true,
            cursor: "pointer",
            connectWith: ".support-source",
            placeholder: 'ui-state-highlight'
        });

        $(".support-source").bind("sortreceive", <any>function (e, ui) {
            const $item = $(ui.item);
            instance.deleteUtilisateur($item, e.type);
            $item.find("a").remove();
        });

        //Remove with icon on list support utilisateur
        $(".support-target").on("sortreceive", (e, ui) => {
            $.ajax({
                type: "POST",
                url: "/DR/AddSupport",
                dataType: "json",
                data: $.fn.putValidationToken({
                    Id: instance.Id(),
                    IdIntervenant: ui.item.data("idintervenant"),
                    Prenom: ui.item.data("prenom"),
                    Nom: ui.item.data("nom"),
                    Email: ui.item.data("mail"),
                    Telephone: ui.item.data("telephone"),
                    Type: ui.item.data("type"),
                    generique: false
                })
            }).done((result: any) => {
                ui.item.attr("data-id", result.Data);
                ui.item.append('<a href="#" class="col-sm-1 pull-right support-delete"></a>');
            });

        });

        $('#search-supportutilisateur').keyup(function () {
            const $me = $(this),
                search = $me.val().toString(),
                $li = $(".support-source li");

            if (search != null && search !== "") {
                $li.each(function () {
                    if ($(this).text().toLowerCase().search(search.toLowerCase()) >= 0) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                $li.each(function () {
                    $(this).show();
                });
            }
        });

        $('button#addCollab').on('click', e => {
            e.preventDefault();

            let valid = true;
            const $prenom = $('#prenom'),
                $nom = $('#nom'),
                $mail = $('#mail'),
                $telephone = $('#telephone'),

                addOrRemoveError = ($el, highlight: boolean, errorMessage: string) => {
                    const $formGroup = $el.closest('.form-group');

                    if (highlight) {
                        if (!$formGroup.hasClass('has-error')) {
                            $formGroup.addClass('has-error');
                            $(`<span id="${$el.attr('id')}-error" class="input-validation-error">${errorMessage}</span>`).insertAfter($el);
                        }
                    }
                    else {
                        $formGroup.removeClass('has-error');
                        $formGroup.find('span.input-validation-error').remove();
                    }
                },

                hasValue = ($el) => {
                    return $el.val() !== undefined && $el.val() !== '';
                }

            if (!hasValue($prenom)) {
                addOrRemoveError($prenom, true, 'Le champs prénom est obligatoire');
                valid = false;
            } else {
                addOrRemoveError($prenom, false, '');
            }

            if (!hasValue($nom)) {
                addOrRemoveError($nom, true, 'Le champs nom est obligatoire');
                valid = false;
            } else {
                addOrRemoveError($nom, false, '');
            }

            if (!hasValue($mail)) {
                addOrRemoveError($mail, true, 'Le champs mail est obligatoire');
                valid = false;
            } else if (!/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i.test($mail.val().toString())) {
                addOrRemoveError($mail, true, 'Veuillez saisir une adresse mail valide');
                valid = false;
            } else {
                addOrRemoveError($mail, false, '');
            }

            if (hasValue($telephone) && !/^0[0-9]([-. ]?\d{2}){4}[-. ]?$/.test($telephone.val().toString())) {
                addOrRemoveError($telephone, true, 'Veuillez saisir un numéro de téléphone valide');
                valid = false;
            } else {
                addOrRemoveError($telephone, false, '');
            }


            if (valid) {
                instance.addUtilisateurGenerique();
            }

            const $form = $('#form-support-generique');

            // Vire la validation unobstrusive qui c'est attachée
            $.removeData($form[0]);

            $form.off('submit').submit(e => {
                e.preventDefault();


            });
        });
    }

    deleteUtilisateur($itm: JQuery, evtType: string) {
        const id = $itm.data("id");

        $itm.find("a").remove();
        $itm.removeAttr("data-id");
        $itm.remove();

        $.ajax({
            type: "POST",
            url: "/DR/DeleteSupport/",
            dataType: "json",
            data: $.fn.putValidationToken({ id: id })
        }).always(() => {
            if (evtType === "click" || $itm.hasClass("support-generique")) {
                $itm.remove();
                $itm.fadeOut();
                return;
            }

            $itm.appendTo('.support-source');
        });
    }

    addUtilisateurGenerique() {
        $.ajax({
            type: "POST",
            url: "/DR/AddSupport",
            dataType: "json",
            data: $.fn.putValidationToken({
                Id: this.Id(),
                Prenom: this.Prenom(),
                Nom: this.Nom(),
                Email: this.Email(),
                Telephone: this.Telephone(),
                generique: true,
            })
        }).done((data: any) => {
            //les petits nouveaux on leur met une classe generique pour les reconnaitres
            let item: string;
            if (this.Telephone() != null && this.Telephone() !== '') {
                item = `<li class="support-generique" data-id="${data.Data}"><span class="pull-left col-sm-9">${this.Prenom()} ${this.Nom()} ${this.Telephone()} ${this.Email()}</span><a href="#" class=" col-sm-1 pull-right support-delete"></a></li>`;
            } else {
                item = `<li class="support-generique" data-id="${data.Data}"><span class="pull-left col-sm-9">${this.Prenom()} ${this.Nom()} ${this.Email()}</span><a href="#" class=" col-sm-1 pull-right support-delete"></a></li>`;
            }


            $(".support-target").append(item);
            this.Prenom("");
            this.Nom("");
            this.Telephone("");
            this.Email("");
        });

    }

}
