﻿// ReSharper disable  InconsistentNaming
export class TransmissionPGODForm {
    Id = ko.observable(0);
    Exploitant = ko.observable();
    MailExploitant = ko.observable();
    Expediteur = ko.observable();
    DateEnvoi = ko.observable<Date>(null);
    Reponse = ko.observable<string>();
    ReponseArray = ko.observableArray<string>();
    ReponseAEnvoyer = ko.observable();
    Commentaire = ko.observable();
    IdTransmissionFile = ko.observable(0);
    NameTransmissionFile = ko.observable();

    FkIdTypeTransmission = 2;
    FkIdAffaire = ko.observable<number>();
    FkIdAffaireEtape = 0;
    FkIdWorkFlowGroupe = ko.observable<number>();


    HasTransmissionFile = ko.pureComputed(() => {
        return this.IdTransmissionFile() !== 0;
    });

    LinkDownloadFile = ko.pureComputed(() => {
        return `/Affaire/DownloadPieceJointe/${this.IdTransmissionFile()}`;
    });

    HasDateEnvoi = ko.pureComputed(() => {
        return this.DateEnvoi() !== null;
    });

    CanUploadFile = ko.pureComputed(() => {
        return this.Exploitant() !== "" && this.MailExploitant() !== "" && this.Reponse() !== undefined && this.Reponse() !== null;
    });

    TransmissionInfos = ko.pureComputed(() => {
        return this.HasDateEnvoi() ? `<em>Transmis <strong>par ${this.Expediteur()} le ${(new Date(this.DateEnvoi().toString())).toLocaleDateString()}</strong></em>` : '';
    });

    constructor(data: any, isEtude: boolean) {
        if (typeof data !== "undefined" && data !== null) {
            for (const k in data) {
                if (this.hasOwnProperty(k)) {
                    if (ko.isObservable(this[k])) {
                        this[k](data[k]);
                    } else {
                        this[k] = data[k];
                    }
                }
            }
        } else {
            this.Exploitant('');
            this.MailExploitant('');
            this.Expediteur('');
        }

        if (isEtude) {
            if (this.Reponse() !== undefined && this.Reponse() !== null) {
                for (let i = 0; i < this.Reponse().length; i++) {
                    this.ReponseArray.push(this.Reponse()[i]);
                }
            }

            this.ReponseArray.subscribe((newValue) => {
                let rep = '';
                $.each(this.ReponseArray(),
                    (index, item: string) => {
                        rep += item;
                    });
                this.Reponse(rep);
            });
        }
    }

    GetControlName(name) {
        return `PGOD_${name}_${this.Id()}`;
    }
}

export class TransmissionsPGODForm {
    ListTrans = ko.observableArray<TransmissionPGODForm>();
    IsInit = ko.observable(false);
    ForcePa = ko.observable(false);
    FkIdWorkFlowGroupe;
    IdContainer;
    IdTranche;
    CanEdit;
    HasPa;
    IsGeneric;
    IdAffaire;
    IdAffaireEtape;
    IdWorkflow;
    IdEntreprise;
    IsEtude;

    DisabledValidation = ko.computed(() => {
        let enabledValidation = false;
        let disabledValidation = false;
        $.each(this.ListTrans(),
            (ix, t: TransmissionPGODForm) => {
                if (!t.HasDateEnvoi()) {
                    enabledValidation = enabledValidation || true;
                }

                if (t.ReponseAEnvoyer() === null) {
                    disabledValidation = disabledValidation || true;
                }
            });

        const $parentForm = $(`#${this.IdContainer}`).parents('form');
        const $validationButton = $('input[data-action-type="validation"]', $parentForm);

        if (!disabledValidation && enabledValidation && !this.DisabledSave()) {
            $validationButton.removeAttr('disabled');
        } else {
            $validationButton.attr('disabled', 'disabled');
        }
    });

    DisabledSave = ko.computed(() => {
        if (!this.IsGeneric) {
            let disabledSave = false;
            $.each(this.ListTrans(),
                (ix, t: TransmissionPGODForm) => {
                    if (t.ReponseAEnvoyer() === false && (t.Commentaire() === null || t.Commentaire() === '')) {
                        disabledSave = disabledSave || true;
                    }
                });

            const $parentForm = $(`#${this.IdContainer}`).parents('form');
            const $saveButton = $('input[data-action-type="enregistrement"]', $parentForm);

            if (disabledSave) {
                $saveButton.attr('disabled', 'disabled');
            } else {
                $saveButton.removeAttr('disabled');
            }

            return disabledSave;
        }

        return false;
    });

    DisabledAdd = ko.pureComputed(() => {
        const length = this.ListTrans().length;
        return length !== 0 && this.ListTrans()[length - 1].Id() === 0;
    });

    StopForPa = ko.computed(() => {
        if (this.IsInit() && !this.HasPa && !this.ForcePa() && !this.IsGeneric && !this.IsEtude) {

            let disableForPa = false;
            $.each(this.ListTrans(),
                (index, t: TransmissionPGODForm) => {
                    if (t.Reponse() === 'c') {
                        disableForPa = disableForPa || true;
                    }
                });

            const $parentForm = $(`#${this.IdContainer}`).parents('form');
            const $buttons = $('input:submit:not([data-action-type=enregistrement])', $parentForm);

            if (disableForPa) {
                $buttons.attr('disabled', 'disabled').attr('data-force-disabled', '');
            } else {
                $buttons.removeAttr('data-force-disabled');

                $.each($buttons,
                    (index, button) => {
                        if ($(button).data('validates') === "True") {
                            $(button).removeAttr('disabled');
                        }
                    });
            }
        }
    });

    constructor(data) {
        this.IdContainer = data.Ufid;
        this.CanEdit = data.CanEdit;
        this.HasPa = data.HasPa;
        this.IsGeneric = data.IsDtDictGeneric;
        this.FkIdWorkFlowGroupe = data.FkIdWorkflowGroupe;
        this.IsEtude = this.FkIdWorkFlowGroupe === 3;

        const $parentForm = $(`#${this.IdContainer}`).parents('form');

        this.IdAffaire = $('input:hidden[name=IdAffaire]', $parentForm).val();
        this.IdAffaireEtape = $('input:hidden[name=idEtape]', $parentForm).val();
        this.IdWorkflow = $('input:hidden[name=WfId]', $parentForm).val();
        this.IdEntreprise = $('input:hidden[name=Entreprise]', $parentForm).val();
        this.IdTranche = $('input:hidden[name=Tranche]', $parentForm).val();


        for (let i = 0; i < data.Transmissions.length; i++) {
            this.ListTrans.push(new TransmissionPGODForm((data.Transmissions)[i], this.IsEtude));
        }

        if (!this.IsEtude && !this.IsGeneric && !this.HasPa) {
            $.each(this.ListTrans(),
                (index, trans: TransmissionPGODForm) => {
                    if (trans.Reponse() === 'c') {
                        this.ForcePa(true);
                    }
                });

            if (this.ForcePa()) {
                $('input:submit[data-action-type=validation-intermediaire]', $parentForm).attr('disabled', 'disabled')
                    .attr('data-force-disabled', '');
            }
        }

        if (this.ListTrans().length === 0) {
            this.addTrans(2);
        }

        this.IsInit(true);
    }

    addTrans(type) {
        const newTrans = new TransmissionPGODForm({
            Exploitant: '',
            MailExploitant: '',
            Expediteur: '',
            FkIdAffaire: this.IdAffaire,
            FkIdAffaireEtape: this.IdAffaireEtape,
            FkIdEntreprise: this.IdEntreprise,
            FkIdTypeTransmission: type,
            FkIdWorkFlowGroupe: this.FkIdWorkFlowGroupe,
            TransmissionFile: null
        }, this.IsEtude);

        this.ListTrans.push(newTrans);

        return newTrans;
    }

    addTransInAction($el) {
        this.addTrans($el.data('id-type'));

        const $tr = $el.parents('tbody:first').prev().find('tr:not(.tr-info-transmission-pgod):last');
        this.initFileUploadPGOD($('input:file.trans-pgod-file', $tr));
        this.initButtonRemoveTrans($tr);
        this.initButtonRemoveFileTrans($tr);

        return false;
    }

    removeTransInAction(el) {
        if (confirm('Etes-vous sûr(e) de vouloir supprimer cette transmission ?')) {
            const trans = ko.dataFor(el);

            if (trans.Id() !== 0) {
                $.ajax({
                    url: '/Affaire/DeleteOnePgod',
                    type: 'POST',
                    data: $.fn.putValidationToken({
                        idPgod: trans.Id()
                    })
                }).done((er: any) => {
                    if (er.Status) {
                        this.ListTrans.remove(trans);
                        $.sticky(`<b>La transmission a bien été supprimée</b>`, { cssClass: 'green' });
                    } else {
                        $.sticky(`<b>${er.Error}</b>`, { cssClass: 'red' });
                    }
                }).fail((er) => {
                    $.sticky(`<b>${er.responseJSON.Error}</b>`, { cssClass: 'red' });
                });
            } else {
                this.ListTrans.remove(trans);
            }
        }
    }

    removeFileTrans(el) {
        const trans = ko.dataFor(el);
        $.ajax({
            url: '/Affaire/DeletePgodPieceJointe',
            type: 'POST',
            data: $.fn.putValidationToken({
                idPJ: trans.IdTransmissionFile()
            })
        }).done((er: any) => {
            if (er.Status) {
                trans.IdTransmissionFile(0);
                $.sticky(er.Message, { cssClass: 'green' });
            } else {
                $.sticky(er.Error, { cssClass: 'red' });
            }
        }).fail((er) => {
            $.sticky(er.responseJSON.Error, { cssClass: 'red' });
        });
    }

    initEventForBloc() {
        this.initFileUploadPGOD($('input:file.trans-pgod-file', $(`#${this.IdContainer}`)));
        this.initButtonAddTrans();
        this.initButtonRemoveTrans();
        this.initButtonRemoveFileTrans();
    }

    initFileUploadPGOD($DlCtrl) {
        $DlCtrl.each((ix, me) => {
            const $me = $(me);
            const trans = ko.dataFor(me);

            $me.simpleFileUpload({
                action: '/Affaire/UploadPgodFile',
                btnLabel: `<img src="/Content/assets/fugue/plus.png"/>${$me.data("label")}`,
                multiFiles: false,
                updateDataBeforeUpload: true,
                multipartData: () => ({
                    idPgod: trans.Id(),
                    exploitant: trans.Exploitant(),
                    mailExploitant: trans.MailExploitant(),
                    reponseReclassement: trans.Reponse() === undefined ? null : trans.Reponse(),
                    typeTransmissionPgod: trans.FkIdTypeTransmission,
                    idAffaire: $me.data("idaffaire"),
                    idWorkFlowGroup: $me.data("idworkflowgroupe"),
                    idAffaireEtape: this.IdAffaireEtape,
                    idWorkflow: this.IdWorkflow,
                    idEntreprise: this.IdEntreprise,
                    idTranche: this.IdTranche,
                }),
                onFileUploaded: (up, file, info) => {
                    const data = $.parseJSON(info.response);
                    if (data.Status) {
                        trans.Id(data.Data.TransmissionId);
                        trans.IdTransmissionFile(data.Data.IdTransmissionFile);
                        trans.NameTransmissionFile(data.Data.NameTransmissionFile);
                        $.sticky(`<b>Fichier uploadé :</b><br/>${file.name}`, { cssClass: 'green' });
                    } else {
                        $.sticky(data.Error, { cssClass: 'red' });
                    }
                },
            });
        });
    }

    initButtonAddTrans() {
        $('button.btn-add-trans', $(`#${this.IdContainer}`)).on('click',
            (e) => {
                this.addTransInAction($(e.currentTarget));
            });
    }

    initButtonRemoveTrans($container = undefined) {
        const container = $container ? $container : $(`#${this.IdContainer}`);
        $('button.btn-remove-trans', container).on('click', (e) => {
            this.removeTransInAction(e.currentTarget);
        });
    }

    initButtonRemoveFileTrans($container = undefined) {
        const container = $container ? $container : $(`#${this.IdContainer}`);
        $('button.file-delete-pgod', container).on('click', (e) => {
            if (confirm("Etes-vous sûr(e) de vouloir supprimer ce fichier ?")) {
                this.removeFileTrans(e.currentTarget);
            }
        });
    }
}
