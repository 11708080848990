﻿
const showImageAfterUpload = input => {
    if (input.files && input.files[0]) {
        const $lblError = $('label.label-logo-error', $(input).parents('.modal-add-update-logo')),
            $submit = $('input[type=submit][value=Enregistrer]', $(input).parents('.modal-add-update-logo')),
            typeAllowed = ['PNG', 'JPG'],
            urlArray = input.files[0].name.split('.');

        if (typeAllowed.indexOf(urlArray[urlArray.length - 1].toUpperCase()) === -1) {
            $lblError.show();
            $(input).val('');
            $submit.attr('disabled', 'disabled');
        } else {
            $lblError.hide();
            $submit.removeAttr('disabled');
            const filerdr = new FileReader();
            filerdr.onload = e => {
                $('.modal-add-update-logo img').attr('src', <string>filerdr.result).show();
            }
            filerdr.readAsDataURL(input.files[0]);
        }
    }
}

$(document).ready(() => {
    window['showImageAfterUpload'] = showImageAfterUpload;

    $('img[data-type=logo]').on('error', () => {
        this.onerror = null;
        this.src = '';
        $(this).hide();
    });

    const $modalLogo = $('div.modal-add-update-logo');

    $('input[name="Action"][value="Enregistrer"]', $modalLogo).on('click',
        button => {
            const input = ($('input[type=file]', $(button.currentTarget).parents('.modal-add-update-logo'))[0]) as HTMLInputElement;
            return input.files.length > 0;
        });
});
