﻿import { CommonInfosPrestation } from "./pposr-general";
// ReSharper disable InconsistentNaming

// NB : pour ne pas tomber dans la référence circulaire au ko.toJSON(this), ne pas passer le parent à Interlocuteur (par exemple)
export class ContactClientPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'contactclient');

        this.TelFixe(data.TelFixe);
        this.TelPortable(data.TelPortable);

        this._J2Label(data.J2Label);
        this.J2(data.J2);

        this._J3PrevuLabel(data.J3PrevuLabel);
        this.J3Prevu(data.J3Prevu);
        this.CreneauHoraire(data.CreneauHoraire);

        this._J3RealiseLabel(data.J3RealiseLabel);
        this.J3Realise(data.J3Realise);

        this.IsTravauxClient(data.IsTravauxClient);
        this.IsMOAR(data.IsMOAR);

        this._CommentaireEnedisLabel(data.CommentaireEnedisLabel);
        this.CommentaireEnedis(data.CommentaireEnedis);
        this.CommentaireEnedisEmail(this._getCommentLastMail(this.CommentaireEnedis(), data.CommentaireEnedisEmail));

        this._CommentairePrestataireLabel(data.CommentairePrestataireLabel);
        this.CommentairePrestataire(data.CommentairePrestataire);
        this.CommentairePrestataireEmail(this._getCommentLastMail(this.CommentairePrestataire(), data.CommentairePrestataireEmail));

        // On conserve les valeurs d'origine
        this._initOrigValues();
    }

    TelFixe = ko.observable<string>().extend({ pattern: window.eplans.regexTel });
    TelPortable = ko.observable<string>().extend({ pattern: window.eplans.regexTel });

    _J2Label = ko.observable<string>();
    J2 = ko.observable<string>();

    _J3PrevuLabel = ko.observable<string>();
    J3Prevu = ko.observable<string>();
    CreneauHoraire = ko.observable<string>();

    _J3RealiseLabel = ko.observable<string>();
    J3Realise = ko.observable<string>();

    IsTravauxClient = ko.observable<boolean>();
    IsMOAR = ko.observable<boolean>();

    _CommentaireEnedisLabel = ko.observable<string>();
    CommentaireEnedis = ko.observable<string>();
    CommentaireEnedisEmail = ko.observable<string>();


    CommentairePrestataire = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    _CommentairePrestataireLabel = ko.observable<string>();
    CommentairePrestataireEmail = ko.observable<string>();

    // Les valeurs d'origine dont on se servira si on quitte le mode modification sans sauvegarder, on n'y touchera pas
    _TelFixeOrig: string;
    _TelPortableOrig: string;
    _J2Orig: string;
    _J3PrevuOrig: string;
    _CreneauHoraireOrig: string;
    _J3RealiseOrig: string;
    _CommentaireEnedisOrig: string;
    _CommentairePrestataireOrig: string;
    _CommentairePrestataireEmailOrig: string;

    _hasJ3Prev = ko.pureComputed({
        owner: this,
        read: () => {
            return this.J3Prevu() != null && this.J3Prevu() != undefined;
        }
    });

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this.CommentairePrestataire.hasError();
        }
    });

    // Sortie du mode modification
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            this._ModeModificationGlobal(false);

            if (resetValues) {
                this.TelFixe(this._TelFixeOrig);
                this.TelPortable(this._TelPortableOrig);
                this.J2(this._J2Orig);
                this.J3Prevu(this._J3PrevuOrig);
                this.CreneauHoraire(this._CreneauHoraireOrig);
                this.J3Realise(this._J3RealiseOrig);
                this.CommentaireEnedis(this._CommentaireEnedisOrig);
                this.CommentairePrestataire(this._CommentairePrestataireOrig);
                this.CommentairePrestataireEmail(this._CommentairePrestataireEmailOrig);
            }

            this._disableChangeAlert();
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        this._TelFixeOrig = this.TelFixe();
        this._TelPortableOrig = this.TelPortable();
        this._J2Orig = this.J2();
        this._J3PrevuOrig = this.J3Prevu();
        this._CreneauHoraireOrig = this.CreneauHoraire();
        this._J3RealiseOrig = this.J3Realise();
        this._CommentaireEnedisOrig = this.CommentaireEnedis();
        this._CommentairePrestataireOrig = this.CommentairePrestataire();
        // sera modifié automatiquement à la sortie de la sauvegarde
        this._CommentairePrestataireEmailOrig = this.CommentairePrestataireEmail();
    }

    _prepareSave() {
        const self = this;

        if (self._isValid()) {
            self._save('/Affaire/EnregistrerModifContactClient', this._PrestationLinkWithHash(), this._setNewStateAfterSave, self);
        }
    }

    _setNewStateAfterSave(self: ContactClientPrestationViewModel) {
        const success = self._PendingSave();

        // Si succès de la sauvegarde, avant d'établir les nouvelles valeurs d'origine, on change l'email lié au commentaire en cas de modification seulement
        if (success) {
            self._disableModify(false);

            if (self.CommentairePrestataire() !== self._CommentairePrestataireOrig) {
                self.CommentairePrestataireEmail(self._UserEmail());
            }

            // Les valeurs sauvées sont les nouvelles valeurs d'origine
            self._initOrigValues();

            self._PendingSave(false);
        }
    }
}
// ReSharper restore InconsistentNaming
