﻿export class TypeSegmentTravaux {
    Id = ko.observable<number>();
    Libelle = ko.observable<string>();
    textSegmentVide = 'Veuillez saisir un segment';

    checkSegmentVide = ko.computed({
        owner: this, read: () => {
            if (this.Id()) {
                return false;
            }
            return true;
        }
    });
}

export class MesurePrevention {
    Id = ko.observable<number>();
    Libelle = ko.observable<string>();
    textMesureError = 'Veuillez saisir une mesure de prévention pour ce risque, celle-ci ne doit pas excéder 500 caractères';

    checkMesureError = ko.computed({
        owner: this, read: () => {
            if (this.Libelle() && this.Libelle().length > 0 && this.Libelle().length < 501) {
                return false;
            }
            return true;
        }
    });
}

export class Risques {

    FkIdPlaqueERDF = ko.observable<number>();
    Id = ko.observable<number>();
    Libelle = ko.observable<string>();
    TypeSousCategorieRisque = ko.observable<number>();
    LibelleTypeSousCategorieRisque = ko.observable<string>();
    AideCourte = ko.observable<string>();
    AideLongue = ko.observable<string>();
    ListTypeSegmentTravaux = ko.observableArray<TypeSegmentTravaux>();
    ListMesurePrevention = ko.observableArray<MesurePrevention>();
    TypeRisque = ko.observable<string>();
    textLibelleError = 'Le libellé est obligatoire et doit faire moins de 70 caractères';
    textAideCourteError = 'L\'aide courte doit faire moins de 50 caractères';
    textFormError = 'Tous les éléments du formulaire ne sont pas valide, merci de corriger';

    constructor(data) {
        if (data.Id != null && data.Id > 0) {
            this.Libelle(data.Libelle);
            this.AideCourte(data.AideCourte);
            this.AideLongue(data.AideLongue);
            this.Id(data.Id);
            for (const segment in data.TypeSegmentTravaux) {
                let segmentToCompute = new TypeSegmentTravaux();
                segmentToCompute.Id(data.TypeSegmentTravaux[segment].Id);
                segmentToCompute.Libelle(data.TypeSegmentTravaux[segment].Libelle);
                this.ListTypeSegmentTravaux.push(segmentToCompute);
            }
            for (const mesure in data.MesurePrevention) {
                let mesureToCompute = new MesurePrevention();
                mesureToCompute.Id(data.MesurePrevention[mesure].Id);
                mesureToCompute.Libelle(data.MesurePrevention[mesure].Libelle);
                this.ListMesurePrevention.push(mesureToCompute);
            }
        }
        this.FkIdPlaqueERDF(data.FkIdPlaqueERDF);
        this.TypeSousCategorieRisque(data.TypeSousCategorieRisque);
        this.LibelleTypeSousCategorieRisque(data.LibelleTypeSousCategorieRisque);
        this._getTypeRisque();
    }

    checkLibelleError = ko.computed({
        owner: this, read: () => {
            if (this.Libelle() && this.Libelle().length > 0 && this.Libelle().length < 71) {
                return false;
            }
            return true;
        }
    });

    _getTypeRisque() {
        const $me = this;
        $.ajax({
            url: '/Admin/DR/GetTypeRisque',
            type: 'POST',
            data: $.fn.putValidationToken({
                idSousCat: $me.TypeSousCategorieRisque()
            })
        }).done((data: any) => {
            if (data) {
                this.TypeRisque(data);
            }
        });
    }

    checkAideCourteError = ko.computed({
        owner: this, read: () => {
            if (!this.AideCourte() || this.AideCourte().length < 51) {
                return false;
            }
            return true;
        }
    });

    addTypeSegmentTravaux() {
        this.ListTypeSegmentTravaux.push(new TypeSegmentTravaux());
    }

    removeTypeSegmentTravaux(segment: TypeSegmentTravaux) {
        this.ListTypeSegmentTravaux.remove(segment);
    }

    addMesurePrevention() {
        this.ListMesurePrevention.push(new MesurePrevention());
    }

    removeMesurePrevention(mesure: MesurePrevention) {
        this.ListMesurePrevention.remove(mesure);
    }

    //Afin d'envoyé que les ID dans le post
    getIdSegment() {
        let output = [];
        for (let i = 0; i < this.ListTypeSegmentTravaux.length; i++) {
            output.push(this.ListTypeSegmentTravaux()[i].Id);
        }
        return output;
    }

    isValid = ko.computed({
        owner: this, read: () => {
            if (this.checkLibelleError() && this.checkAideCourteError()) {
                return false;
            }
            for (let i = 0; i < this.ListMesurePrevention().length; i++) {
                if (this.ListMesurePrevention()[i].checkMesureError()) {
                    return false;
                }
            }
            for (let i = 0; i < this.ListTypeSegmentTravaux().length; i++) {
                if (this.ListTypeSegmentTravaux()[i].checkSegmentVide()) {
                    return false;
                }
            }
            return true;
        }
    });

    saveRisque() {
        if (this.isValid()) {
            return $.ajax({
                url: '/Admin/DR/PostActionRisque',
                type: 'POST',
                data: $.fn.putValidationToken({
                    idRisque: this.Id(),
                    fkIdPlaqueERDF: this.FkIdPlaqueERDF(),
                    libelle: this.Libelle(),
                    fkIdTypeSousCategorieRisque: this.TypeSousCategorieRisque(),
                    aideCourte: this.AideCourte(),
                    aideLongue: this.AideLongue(),
                    mesures: ko.toJSON(this.ListMesurePrevention()),
                    segments: ko.toJSON(this.ListTypeSegmentTravaux())
                })
            }).done(res => {
                window.location.href = '/Admin/DR/Edit/' + this.FkIdPlaqueERDF();
            });
        }
    }
}

export var instance: Risques;
