﻿import { CommonInfosPrestation } from "./pposr-general";
// ReSharper disable InconsistentNaming

export class InjectionPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'injection');

        this._PdlProduction(data.PdlProduction);
        this._PdlNonConsommation(data.PdlNonConsommation);
        this._PuissanceRaccordement(data.PuissanceRaccordement);
        this._NombrePhaseInjection(String(data.NombrePhaseInjection));
        this._TypeInjection(data.TypeInjection);
        this._TypeProduction(data.TypeProduction);
    }

    _PdlProduction = ko.observable<string>();
    _PdlNonConsommation = ko.observable<string>();
    _PuissanceRaccordement = ko.observable<string>();
    _NombrePhaseInjection = ko.observable<string>();
    _TypeInjection = ko.observable<string>();
    _TypeProduction = ko.observable<string>();
}
// ReSharper restore InconsistentNaming
