export class AdminContacts {
    static _cacheIntervenantString;

    static hashToArray(v: any) {
        var r = [];
        for (var k in v) {
            if (v.hasOwnProperty(k)) {
                r.push(v[k]);
            }
        }

        return r;
    }
}

export class TypeIntervenant {
    constructor(id: number, libelle: string) {
        this.Id = id;
        this.Libelle = libelle;
    }

    static fromId(id: number): TypeIntervenant {
        return new TypeIntervenant(id, ConfigurationContacts.IntervenantString(id));
    }

    Id: number;
    Libelle: string;
}

export class ConfigurationContacts {
    // ReSharper disable InconsistentNaming
    Id = ko.observable();
    FkIdWorkflowEtape = ko.observable();
    FkIdPlaqueERDF = ko.observable();
    TypeConfiguration = ko.observable();
    WorkflowRacing = ko.observable();

    /**
     * @obsolete
     */
    Active = ko.observable();

    CanEdit = ko.observable();

    IntervenantsAutorises = ko.observable<string[]>();
    IntervenantsDest = ko.observable<string[]>();
    IntervenantsOrder: string[];

    IntervenantsAutorisesList = ko.pureComputed(() => this._orderedIntervenantsAutorises());

    _orderedIntervenantsAutorises() {
        const ia = this.IntervenantsAutorises(),
            is = this.IntervenantsDest();

        let tmp = {};

        if (ia != null) {
            $.each(ia, (ix, ti) => {
                    tmp[`${ti}`] = TypeIntervenant.fromId(parseInt(ti));
            });
        }

        if (is != null) {
            $.each(is, (ix, ti) => {
                    tmp[`${ti}`] = TypeIntervenant.fromId(parseInt(ti));
            });
        }

        let ret = [];

        if (this.IntervenantsOrder != null && this.IntervenantsOrder.length > 0) {
            // Application des éléments dans l'ordre spécifié
            for (let i = 0; i < this.IntervenantsOrder.length; i++) {
                const k = `${this.IntervenantsOrder[i]}`;
                if (tmp.hasOwnProperty(k)) {
                    ret.push(tmp[k]);
                    delete tmp[k];
                }
            }

            // Les clés qui restent
            for (const v in tmp) {
                if (tmp.hasOwnProperty(v)) {
                    ret.push(tmp[v]);
                }
            }
        } else {
            ret = AdminContacts.hashToArray(tmp);
        }

        return ret;
    }

    _sending = ko.observable(false);
    _editorOpen = ko.observable(false);

    _canEdit = ko.computed(() => {
        const ce = this.CanEdit();

        if (ce === "True" || ce === true) {
            return true;
        }

        return false;
    });

    _statusText = ko.observable<string>(null);

    _isConfigured = ko.pureComputed(() => {
        const pl = parseInt(<any>this.FkIdPlaqueERDF()),
            tc = parseInt(<any>this.TypeConfiguration());

        if (pl === 0) {
            return tc === 2 /* Locale */;
        }

        return tc === 2 || tc === 0;
    });

    _displayFullEditor = ko.pureComputed(() => {
        return parseInt(<any>this.TypeConfiguration());
    });

    _configurationValid = ko.pureComputed(() => {
        const tc = parseInt(<any>this.TypeConfiguration()),
            users = this.IntervenantsAutorises(),
            total = this.IntervenantsAutorisesList();

        if (tc === 2) {
            return users != null && users.length > 0 && total != null && total.length > 1;
        }

        return true;
    });

    /**
     * Sauvegarde de la configuration avant sauvegarde
     */
    _orignalData: any = null;

    // ReSharper restore InconsistentNaming

    toggleEdit() {
        this._editorOpen(!this._editorOpen());
    }

    makeDataObject() {
        let final: any = {};
        for (const gk in this) {
            if (!this.hasOwnProperty(gk)) {
                continue;
            }

            if (gk[0] === '_') {
                // Champs privé, passe pas
                continue;
            }

            const obs: any = this[gk];

            if (ko.isObservable(obs) && !ko.isComputed(obs)) {
                final[gk] = obs();
            }
        }

        final["IntervenantsAutorises"] = (final["IntervenantsAutorises"] || []).join(';');
        final["IntervenantsDest"] = (final["IntervenantsDest"] || []).join(';');

        let iorder = [],
            rawInterOrder = this._orderedIntervenantsAutorises();

        for (const k in rawInterOrder) {
            if (rawInterOrder.hasOwnProperty(k)) {
                iorder.push(rawInterOrder[k].Id);
            }
        }

        final["IntervenantsOrder"] = iorder.join(';');

        return final;
    }

    save() {
        if (this._sending()) {
            return;
        }

        this._statusText(null);
        this._sending(true);

        $.ajax({
            url: '/Admin/ContactConfiguration/EditConfig/',
            type: 'POST',
            data: $.fn.putValidationToken({
                data: this.makeDataObject()
            })
        }).done((data: any) => {
            this.Id(data.Id);
            this._statusText("Configuration sauvegardée avec succès");
            this._orignalData = this.makeDataObject();
        }).fail(() => {
            this._statusText("Une erreur est survenue pendant la sauvegarde");
        }).always(() => {
            this._sending(false);
        });
    }

    cancel() {
        this._editorOpen(false);
        this._statusText(null);

        if (this._orignalData != null) {
            $.each(this._orignalData, (k: string, v: any) => {
                const setter = this[k];

                if (ko.isObservable(setter) && !ko.isComputed(setter)) {
                    switch (k) {
                        case "IntervenantsOrder":
                        case "IntervenantsAutorises":
                        case "IntervenantsDest":
                            if (v != null && v !== '') {
                                v = v.split(';');
                            } else {
                                v = [];
                            }
                            break;
                    }
                    setter(v);
                }
            });

            $('.select2').trigger('change');
        }
    }

    parseForm($f: JQuery) {
        const $fields = $f.find(':input[data-bind]');

        $fields.each((ix, field) => {
            const $field = $(field);
            let name = $field.attr('name');

            // Exceptions à cause de MVC
            if (name === 'iva') {
                name = 'IntervenantsAutorises';
            }

            if (name === 'ivd') {
                name = "IntervenantsDest";
            }

            if (name === "IntervenantsOrder") {
                this.IntervenantsOrder = `${$field.val()}`.split(';');
                if (this.IntervenantsOrder.length === 1 && this.IntervenantsOrder[0] === "") {
                    this.IntervenantsOrder = [];
                }
                return;
            }

            if (this.hasOwnProperty(name) && ko.isObservable(this[name])) {
                if ($field.is('input[type=checkbox]')) {
                    this[name]($field.prop('checked'));

                } else if ($field.is('input[type=radio]')) {
                    // Ne pas merger les deux if, on veut justement que ça 
                    // soit la valeur checkée qui soit sauvée
                    if ($field.prop('checked')) {
                        this[name]($field.val());
                    }

                } else {
                    this[name]($field.val());
                }
            }
        });

        this._orignalData = this.makeDataObject();
    }

    _isDisabled(id) {
        const c = this.IntervenantsAutorises();

        if (c != null && typeof c.indexOf === "function") {
            return c.indexOf(id) !== -1;
        }
        return false;
    }

    // ReSharper disable once InconsistentNaming
    static IntervenantString(id) {
        id = parseInt(id);
        if (AdminContacts._cacheIntervenantString == null) {
            AdminContacts._cacheIntervenantString = {};
            $("#allTypes option").each((ix, i) => {
                const $i = $(i);
                AdminContacts._cacheIntervenantString[parseInt($i.val().toString())] = $i.text();
            });
        }

        return AdminContacts._cacheIntervenantString[id];
    }
}

$(document).ready(() => {
    $('.contact-display-order')
        .sortable({
            update: function (evt, ui) {
                const koDt = ko.contextFor(this);
                if (koDt == null) {
                    return;
                }

                let rData = [];
                const $ul = $(this),
                    data = <ConfigurationContacts>koDt.$root;

                $ul.children('li')
                    .each((ix, li) => {
                        const id = $(li).data('id');
                        if (id == null) {
                            return;
                        }
                        rData.push(`${id}`);
                    });

                data.IntervenantsOrder = rData;
            }
        });
});
