﻿$(document).ready(function () {
    /************************************************************/
    /* Vue Admin de récap des mails OGED                        */
    /************************************************************/
    $('.archiver-task').click(function (e) {
        e.preventDefault();

        var confirmPhrase = "Êtes-vous sûr de vouloir solder ces tâches ?";
        if ($.fn.confirmBoxCallback(confirmPhrase, function (v) {
            if (v) {
                var arrayOfIds = $(".table-oged-tasks input.chkCheckBoxId:checkbox:checked").map(function () {
                    return $(this).val();
                }).get();

                if (arrayOfIds.length === 0) {
                    $.sticky("Merci de sélectionner au moins une tâche à solder", { cssClass: 'blue' });
                    return null;
                } else {
                    $.sticky("Enregistrement...", { cssClass: 'blue' });
                }

                var formdata = new FormData();

                $.each(arrayOfIds, function (i, value) {
                    formdata.append('ids[]', value);
                });

                formdata.append('__RequestVerificationToken', $.fn.findValidationToken());

                return $.ajax({
                    url: '/NotificationsCommandes/ArchiverTask',
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    data: formdata
                }).done(function (data) {
                    if (data && !data.Status) {
                        $.sticky("<b>" + data.Error + "</b><br/>", { cssClass: 'orange' });
                    } else {
                        location.reload();
                    }
                });
            }
        }));
    });

    /************************************************************/
    /* Pour le suivi déploiement des signatures                 */
    /************************************************************/
    $('#FilteredPeriod').change(function () {
        $('input[name="DateDebut"]').val(null);
        $('input[name="DateFin"]').val(null);
    });
});
