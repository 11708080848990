﻿import { CommonInfosPrestation } from "./pposr-general";
// ReSharper disable InconsistentNaming

export class PlanificationEtudePrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'planificationetude');

        this.J0(data.J0);
        this.JL(data.JL);
        this.J1(data.J1);
        this.J2(data.J2);
        this.J3Prev(data.J3Prev);
        this.J3Real(data.J3Real);

        this.J4Real(data.J4Real);
        this._IsJ4Visible(data.IsJ4Visible);

        this.Validation(data.Validation);
    }

    J0 = ko.observable<string>();
    JL = ko.observable<string>();
    J1 = ko.observable<string>();
    J2 = ko.observable<string>();
    J3Prev = ko.observable<string>();
    J3Real = ko.observable<string>();

    J4Real = ko.observable<string>();
    _IsJ4Visible = ko.observable<boolean>();

    Validation = ko.observable<string>();
}

// ReSharper restore InconsistentNaming
