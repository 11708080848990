﻿import { TypeFichierEnum } from "./common";

// ReSharper disable  InconsistentNaming

export class ControleFichier {

    TypeFichier = ko.observable<TypeFichierEnum>();

    Id = ko.observable<number>();

    NomFichier = ko.observable<string>();

    Url = ko.observable<string>();

    Ext = ko.pureComputed(() => {

        var patt1 = /\.[0-9a-z]+$/i;
        var filename = this.NomFichier();
        if (filename == null) {
            return "";
        }

        return filename.match(patt1);
    });

    Attached = false;

}
