﻿import { CommonInfosPrestation } from "./pposr-general";
// ReSharper disable InconsistentNaming

export class SousInfosGeneralesViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'sousinfosge');

        this.NatureDemande(data.NatureDemande);
        this.TypeTravaux(data.TypeTravaux);
        this.TechniqueBranchement(data.TechniqueBranchement);

        this.TravauxAdresse(data.TravauxAdresse);

        this.TravauxAdresseComplement(data.TravauxAdresseComplement);
        this.TravauxCp(data.TravauxCp);
        this.TravauxCommune(data.TravauxCommune);
        this.GpsLong(data.GpsLong);
        this.GpsLat(data.GpsLat);

        this.CommentaireSurLieuxTravaux(data.CommentaireSurLieuxTravaux);
        this.CommentaireSurLieuxTravauxEmail(this._getCommentLastMail(this.CommentaireSurLieuxTravaux(), data.CommentaireSurLieuxTravauxEmail));

        // On conserve les valeurs d'origine
        this._initOrigValues();

        this._CanAcceptOrRefuse(data.CanAcceptOrRefuse);
    }

    NatureDemande = ko.observable<string>();
    TypeTravaux = ko.observable<string>();
    TechniqueBranchement = ko.observable<string>();

    TravauxAdresse = ko.observable<string>();

    TravauxAdresseComplement = ko.observable<string>();
    TravauxCp = ko.observable<string>();
    TravauxCommune = ko.observable<string>();
    GpsLong = ko.observable<string>();
    GpsLat = ko.observable<string>();

    CommentaireSurLieuxTravaux = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    CommentaireSurLieuxTravauxEmail = ko.observable<string>();

    _CanAcceptOrRefuse = ko.observable<boolean>();

    _CommentaireSurLieuxTravauxOrig: string;
    _CommentaireSurLieuxTravauxEmailOrig: string;

    _lienGoogleMaps = ko.pureComputed({
        owner: this,
        read: () => {
            if (this.GpsLong() !== null && this.GpsLong().length > 0 && this.GpsLat() !== null && this.GpsLat().length > 0) {
                return `https://www.google.com/maps/?q=${this.GpsLat().replace(',', '.')},${this.GpsLong().replace(',', '.')}`;
            } else {
                return null;
            }
        }
    });

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this.CommentaireSurLieuxTravaux.hasError();
        }
    });

    // Sortie du mode modification
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            this._ModeModificationGlobal(false);

            if (resetValues) {
                this.CommentaireSurLieuxTravaux(this._CommentaireSurLieuxTravauxOrig);
                this.CommentaireSurLieuxTravauxEmail(this._CommentaireSurLieuxTravauxEmailOrig);
            }

            this._disableChangeAlert();
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        this._CommentaireSurLieuxTravauxOrig = this.CommentaireSurLieuxTravaux();
        // sera modifié automatiquement à la sortie de la sauvegarde
        this._CommentaireSurLieuxTravauxEmailOrig = this.CommentaireSurLieuxTravauxEmail();
    }

    _prepareSave() {
        const self = this;

        if (self._isValid()) {
            self._save('/Affaire/EnregistrerModifInformationsGenerales', this._PrestationLinkWithHash(), this._setNewStateAfterSave, self);
        }
    }

    _setNewStateAfterSave(self: SousInfosGeneralesViewModel) {
        const success = self._PendingSave();

        // Si succès de la sauvegarde, avant d'établir les nouvelles valeurs d'origine, on change l'email lié au commentaire en cas de modification seulement
        if (success) {
            self._disableModify(false);

            if (self.CommentaireSurLieuxTravaux() !== self._CommentaireSurLieuxTravauxOrig) {
                self.CommentaireSurLieuxTravauxEmail(self._UserEmail());
            }

            // Les valeurs sauvées sont les nouvelles valeurs d'origine
            self._initOrigValues();

            self._PendingSave(false);
        }
    }
}
// ReSharper restore InconsistentNaming
