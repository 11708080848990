export class Options {
    Libelle: string;
    Value: string;

    constructor(libelle: string, value: string) {
        this.Libelle = libelle;
        this.Value = value;
    }

    compare(other: Options) {
        const p1Lower = this.Libelle.toLowerCase();
        const p2Lower = other.Libelle.toLowerCase();
        if (p1Lower < p2Lower) {
            return -1;
        } else if (p1Lower > p2Lower) {
            return 1;
        }
        return 0;
    }
}
