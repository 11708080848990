﻿import { CommonInfosPrestation } from "./pposr-general";

export class AttachementPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'attachement');

        this.Commentaire(data.Commentaire);
        this.CommentaireEmail(this._getCommentLastMail(this.Commentaire(), data.CommentaireEmail));

        // On conserve les valeurs d'origine
        this._initOrigValues();
    }

    Commentaire = ko.observable<string>().extend({ commentaire: { email: this._UserEmail(), maxLength: 2000 } });
    CommentaireEmail = ko.observable<string>();

    _CommentaireOrig: string;
    _CommentaireEmailOrig: string;

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            return !this.Commentaire.hasError();
        }
    });

    // Sortie du mode modification        
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            this._ModeModificationGlobal(false);

            if (resetValues) {
                this.Commentaire(this._CommentaireOrig);
                this.CommentaireEmail(this._CommentaireOrig);
            }

            this._disableChangeAlert();
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        this._CommentaireOrig = this.Commentaire();
        this._CommentaireEmailOrig = this.CommentaireEmail();
    }

    _prepareSave() {
        const self = this;
        if (self._isValid()) {
            self._save('/Affaire/EnregistrerModifAttachement',
                this._PrestationLinkWithHash(),
                this._setNewStateAfterSave,
                self);
        }
    }

    _setNewStateAfterSave(self: AttachementPrestationViewModel) {
        const success = self._PendingSave();

        // Si succès de la sauvegarde, avant d'établir les nouvelles valeurs d'origine, on change l'email lié au commentaire en cas de modification seulement
        if (success) {
            self._disableModify(false);

            if (self.Commentaire() !== self._CommentaireOrig) {
                self.CommentaireEmail(self._UserEmail());
            }

            // Les valeurs sauvées sont les nouvelles valeurs d'origine
            self._initOrigValues();

            self._PendingSave(false);
        }
    }
}

