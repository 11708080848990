﻿import { eplans } from "../../eplans-static";

// ReSharper disable InconsistentNaming

export class InformationsGenerales {
    NumeroAffaire = ko.observable<string>();
    NomClient = ko.observable<string>();
    NumeroPdl = ko.observable<string>();
    AdresseTravaux = ko.observable<string>();
    CodePostal = ko.observable<string>();
    Ville = ko.observable<string>();
    EntreprisePrestataire = ko.observable<string>();
    PuissanceRaccordement = ko.observable<string>();
    TechniqueBranchement = ko.observable<string>();
    TypeTravaux = ko.observable<string>();
    Latitude = ko.observable<string>().extend({ pattern: eplans.regexGpsLat });
    Longitude = ko.observable<string>().extend({ pattern: eplans.regexGpsLon });
    DateRealisationComptage = ko.observable<string>();
    BpNatureBranchement = ko.observable<string>();

    constructor(data) {
        this.NumeroAffaire(data.NumeroAffaire);
        this.NomClient(data.NomClient);
        this.NumeroPdl(data.NumeroPdl);
        this.AdresseTravaux(data.AdresseTravaux);
        this.CodePostal(data.CodePostal);
        this.Ville(data.Ville);
        this.EntreprisePrestataire(data.EntreprisePrestataire);
        this.PuissanceRaccordement(data.PuissanceRaccordement);
        this.TechniqueBranchement(data.TechniqueBranchement);
        this.TypeTravaux(data.TypeTravaux);
        this.Latitude(data.Latitude);
        this.Longitude(data.Longitude);
        this.DateRealisationComptage(data.DateRealisationComptage);
        this.BpNatureBranchement(data.BpNatureBranchementEnum);
    }
}
