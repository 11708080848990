﻿interface JQuery {
    unhighlight(options);
    highlight(...options);
}

export class Highlight {
    /**
     * Mapping pour le match non accent sensitve, orienté Français, il en manque d'un point de vue
     * totalement international
     */
    static ACCENT_MAP = {
        'e': ['e', 'é', 'è', 'ê', 'ë'],
        'a': ['a', 'à', 'â', 'ä'],
        'i': ['i', 'ù', 'û', 'ü'],
        'c': ['c', 'ç']
    };

    static highlight(node, re, nodeName, className) {
        if (node.nodeType === 3) {
            let match = node.data.match(re);
            if (match) {
                let highlight = document.createElement(nodeName || 'span');
                highlight.className = className || 'highlight';

                let wordNode = node.splitText(match.index);
                wordNode.splitText(match[0].length);

                let wordClone = wordNode.cloneNode(true);
                highlight.appendChild(wordClone);
                wordNode.parentNode.replaceChild(highlight, wordNode);

                return 1; //skip added node in parent
            }
        } else if ((node.nodeType === 1 && node.childNodes) && // only element nodes that have children
            !/(script|style)/i.test(node.tagName) && // ignore script and style nodes
            !(node.tagName === nodeName.toUpperCase() && node.className === className)
        ) { // skip if already highlighted

            for (var i = 0; i < node.childNodes.length; i++) {
                i += Highlight.highlight(node.childNodes[i], re, nodeName, className);
            }
        }
        return 0;
    }

}

jQuery($ => {
    $.fn.extend({
        unhighlight: function (options) {
            let settings = { className: 'highlight', element: 'span' };

            $.extend(settings, options);

            return this.find(settings.element + "." + settings.className).each(function () {
                let parent = this.parentNode;
                parent.replaceChild(this.firstChild, this);
                parent.normalize();
            }).end();
        }
    });

    $.fn.extend({
        highlight: function (words: string | string[], options) {
            var settings = {
                className: 'highlight',
                element: 'span',
                caseSensitive: false,
                accentsSensitive: false,
                wordsOnly: false
            };

            $.extend(settings, options);

            if (words.constructor === String) {
                const tmp = new Array<string>(1);
                tmp[0] = words.toString();
                words = tmp;
            }

            words = $.grep(words, (word, i) => (word != ''));
            words = $.map(words, (word, i) => word.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"));

            if (words.length == 0) {
                return this;
            };

            let flag = settings.caseSensitive ? "" : "i",
                pattern = `(${words.join("|")})`;

            if (!settings.accentsSensitive) {
                $.each(Highlight.ACCENT_MAP,
                    (letter, map) => {
                        var reg = new RegExp(letter, 'g'),
                            rpl = `[${map.join('|')}]`;

                        pattern = pattern.replace(reg, rpl);
                    });
            }

            if (settings.wordsOnly) {
                pattern = `\\b${pattern}\\b`;
            }

            var re = new RegExp(pattern, flag);

            return this.each(function () {
                Highlight.highlight(this, re, settings.element, settings.className);
            });
        }
    });
});

