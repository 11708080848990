import { MesurePrevention } from "./MesurePrevention";
import { MiniFile } from "./MiniFile";

export class Risque {
    Id = ko.observable<number>();
    Libelle = ko.observable<string>().extend({ required: "" });

    MesurePrevention = ko.observableArray<MesurePrevention>();
    MesureVide = ko.observable<MesurePrevention>();
    TypeCategorieRisque = ko.observable<number>();
    FkTypeSousCategorieRisque = ko.observable<number>();
    IsTemporaire = ko.observable<Boolean>(false);
    AideCourte = ko.observable<string>();
    AideLongue = ko.observable<string>();
    Commentaire = ko.observable<string>();
    PiecesJointes = ko.observableArray<MiniFile>();
    FkIdRisques = ko.observable<number>();

    constructor(data?: any);
    constructor(data: Risque, fromTemporaire: boolean);
    constructor(data?: any, fromTemporaire?: boolean) {
        if (data) {
            if (fromTemporaire) {
                this.Id(data.Id());
                this.Libelle(data.Libelle());
                this.AideCourte(data.AideCourte());
                this.AideLongue(data.AideLongue());
                this.Commentaire(data.Commentaire());
                this.FkIdRisques(data.FkIdRisques());
                for (var index in data.MesurePrevention()) {
                    this.addMesurePrevention(new MesurePrevention(data.MesurePrevention()[index], true));
                }
                this.TypeCategorieRisque(data.TypeCategorieRisque());
                this.FkTypeSousCategorieRisque(data.FkTypeSousCategorieRisque());

                for (var index in data.PiecesJointes()) {
                    var file = new MiniFile(data.PiecesJointes[index]());
                    this.PiecesJointes().push(file);
                }
            } else {
                this.Id(data.Id);
                this.Libelle(data.Libelle);
                this.AideCourte(data.AideCourte);
                this.AideLongue(data.AideLongue);
                this.Commentaire(data.Commentaire);
                this.FkIdRisques(data.FkIdRisques);
                for (var index in data.MesurePrevention) {
                    this.addMesurePrevention(new MesurePrevention(data.MesurePrevention[index], false));
                }
                this.TypeCategorieRisque(data.TypeCategorieRisque);
                this.FkTypeSousCategorieRisque(data.FkTypeSousCategorieRisque);

                for (var index in data.PiecesJointes) {
                    var file = new MiniFile(data.PiecesJointes[index]);
                    this.PiecesJointes().push(file);
                }
            }
        }
        //la mesure vide
        this.MesureVide(new MesurePrevention());
    }

    raz() {
        this.Id(null);
        this.Libelle('');
        this.TypeCategorieRisque();
        this.FkTypeSousCategorieRisque();
        this.AideCourte('');
        this.AideLongue('');
        this.Commentaire('');
    }

    changeResponsableVide(jElem, event) {
        //Devrait marcher dans le binding handlers et marchait avant, ne marche plus depuis que c'est pas sur une propriétée directe mais un fils. Truandage soucis de temps
        this.MesureVide().ResponsableMiseEnOeuvre(event.val);
    }

    addMesurePrevention(mesure: MesurePrevention) {
        this.MesurePrevention.push(new MesurePrevention(mesure, true));
        mesure.raz();
    }

    removeMesurePrevention(mesure: MesurePrevention) {
        this.MesurePrevention.remove(mesure);
    }

    isValid = ko.computed({
        owner: this, read: () => {
            if (!this.Libelle() || this.Libelle.hasError()) {
                return false;
            }
            return true;
        }
    });

    //visibilité de l'aide
    // redondant avec la même chose dans controle-question.ts, mais je n'ai pas trouvé le moyen de factoriser
    helpVisible = ko.observable(false);

    toggleHelp() {
        if (!this.hasAide()) {
            return;
        }

        this.helpVisible(!this.helpVisible());
    }

    showAideLongue() {
        const modal = $(".modal-aide");

        if (modal === undefined || modal == null) {
            return;
        }

        $('.popover').hide();
        modal.find('.modal-body').html(this.AideLongue());

        modal.modal({
            backdrop: true,
            keyboard: true,
            show: true
        });
    }

    private aideLongueNotNullOrEmpty() {
        return this.AideLongue() !== null && this.AideLongue() !== "";
    }

    hasAide = ko.computed(() => {
        return this.AideCourte() !== null && this.AideCourte() !== ""
            || this.aideLongueNotNullOrEmpty();
    });

    hasAideLongue = ko.computed(() => {
        return this.aideLongueNotNullOrEmpty();
    });
}
