﻿import { FicheComptageUtils } from "./pposr-fiche-comptage-utils";
import { FicheComptageIndexViewModel } from "./pposr-fiche-comptage-base";
import { Compteur } from "./Compteur";
// ReSharper disable InconsistentNaming

    export class CompteurDepose extends Compteur {
        BranchementProvisoire = ko.observable<boolean>();

        constructor(data, isBp: boolean) {
            super(isBp);

            this.IsPresent(data.IsPresent);
            this.BranchementProvisoire(data.IsBranchementProvisoire);
            this.Constructeur(data.ConstructeurNom);
            this.Matricule(data.Matricule);
            this.Identifiant(data.Identifiant);
            this.IdentifiantCle(data.IdentifiantCle);
            this.Technologie(data.Technologie);
            this.TypeCompteur(data.TypeCompteurId);
            this.OptionTarifaire(data.OptionTarifaire);
            this.GrilleDistributeur(data.GrilleDistributeur);
            this._initAll(data);
        }

        /*Zone des "init" something*/
        _setConstructeur() {
            const dataTypeCompteur = FicheComptageUtils.getTypeCompteurData(this.TypeCompteur());
            this.Constructeur(dataTypeCompteur.constructeurNom);
        }

        _triggerChangesFromTypeTechno() {
            this._setIndexIfNeeded(null);
            this._initListTypeCompteur();
        }

        _setIndexIfNeeded(data: any) {
            const dataIndex: KnockoutObservable<FicheComptageIndexViewModel> = data != null
                ? data.Index
                : null;

            this.Index(new FicheComptageIndexViewModel(dataIndex));
        }

        _initListTypeCompteur() {
            super._initListTypeCompteur("0");
        }

        _initAll(data) {
            this._initListTypeCompteur();
            this._setIndexIfNeeded(data);
        }
    }
