﻿export class Intervenant {
    Id = ko.observable<string>();
    Mail = ko.observable<string>();
    Telephone = ko.observable<string>();
    NomPrenomLibelle = ko.observable<string>();
    Representant = ko.observable<string>();
    Activite = ko.observable<string>();
    WasProposed = ko.observable<boolean>(false);
    constructor(data?) {
        if (data) {
            this.Id(data.Id);
            this.Activite(data.Activite);
            this.Mail(data.Mail);
            this.Telephone(data.Telephone);
            this.NomPrenomLibelle(data.NomPrenomLibelle);
            this.Representant(data.Representant);
        }
    }
}

export class Convocation {
    Id = ko.observable<number>();
    FkIdPlanPrevention = ko.observable<number>();
    Type = ko.observable<number>();
    Lieu = ko.observable<string>();
    IsICP = ko.observable<boolean>();
    Date = ko.observable<Date>();

    ErrorSaisieLibre = ko.observable<boolean>(false);

    //Pour la recherche select2
    RechercheRapide = ko.observable<string>();

    //List des vrais intervenants sur la convocation
    ListIntervenant = ko.observableArray<Intervenant>();
    ListProposition = ko.observableArray<Intervenant>();

    //Intervenant de saisie peut etre libre ou avec un vrai intervenant de la base
    IntervenantTemporaire = ko.observable<Intervenant>();
    WantAddIntervenant = ko.observable<boolean>();

    constructor(data) {
        this.WantAddIntervenant(false); //De base

        this.Id(data.Id);
        this.Type(data.Type);
        if (this.Type() == 1) {
            this.IsICP(true);
        } else {
            this.IsICP(false);
        }
        this.FkIdPlanPrevention(data.FkIdPlanPrevention);
        this.Lieu(data.Lieu);
        this.Date(data.Date);
        for (const intervenant in data.ListIntervenant) {
            if (Object.prototype.hasOwnProperty.call(data.ListIntervenant, intervenant)) {
                this.ListIntervenant().push(new Intervenant(data.ListIntervenant[intervenant]));
            }
        }
        for (const intervenant in data.ListIntervenantProposition) {
            if (Object.prototype.hasOwnProperty.call(data.ListIntervenantProposition, intervenant)) {
                this.ListProposition().push(new Intervenant(data.ListIntervenantProposition[intervenant]));
            }
        }
        this.IntervenantTemporaire(new Intervenant());
    }

    validProposition(intervenant: Intervenant) {
        this.ListProposition.remove(intervenant);
        intervenant.WasProposed(true);
        this.ListIntervenant.push(intervenant);
    }

    addIntervenant() {
        this.WantAddIntervenant(true);
    }

    postActionSearch() {
        $.ajax({
            url: '/Affaire/GetMiniIntervenant',
            type: 'POST',
            data: $.fn.putValidationToken({
                idIntervenant: this.RechercheRapide()
            })
        }).done(data => {
            if (data) {
                this.IntervenantTemporaire(new Intervenant(data));
            }
        });
    }

    validIntervenant() {
        if (this.IntervenantTemporaire().Mail() &&
            this.IntervenantTemporaire().NomPrenomLibelle()) {
            //On enlève le message d'erreur si y'en a un
            this.ErrorSaisieLibre(false);
            //remise en forme de la vue
            this.WantAddIntervenant(false);
            //On le remet à zéro au cas ou on a besoin de le réafficher
            this.RechercheRapide('');

            //Ajout de l'intervenant temporaire à la liste des intervenants définitif
            this.ListIntervenant.push(this.IntervenantTemporaire());
            //Nettoyage de l'intervenant temporaire
            this.IntervenantTemporaire(new Intervenant());
        } else {
            this.ErrorSaisieLibre(true);
        }
    }

    removeIntervenant(intervenant: Intervenant) {
        this.ListIntervenant.remove(intervenant);
        //Quand on remove un intervenant, on l'ajoute à la liste des propositions, ça sera mieux je pense, s'ils veulent pas, dé-commenter
        //if (intervenant.WasProposed()) {
        this.ListProposition.push(intervenant);
        //}
    }

    isValid = ko.computed({
        owner: this, read: () => {
            return this.Lieu() && this.Date() && this.FkIdPlanPrevention() && this.ListIntervenant().length > 0;
        }
    });

    saveConvocation() {
        const self = this;
        const confirmPhrase = "Êtes-vous sûr de valider cette convocation ? Cette validation est définitive et provoque l'envoi d'un mail de convocation à tous les participants";
        if ($.fn.confirmBoxCallback(confirmPhrase, v => {
            if (v) {
                $.sticky("Enregistrement...", { cssClass: '' });

                let formdata = new FormData();
                formdata.append('id', self.Id().toString());
                formdata.append('FkIdPlanPrevention', self.FkIdPlanPrevention().toString());
                formdata.append('lieu', self.Lieu());
                formdata.append('date', self.Date().toString());
                formdata.append('intervenants', ko.toJSON(self.ListIntervenant()));
                formdata.append('files', $('#piece-jointe')[0].files[0]);
                formdata.append('__RequestVerificationToken', $.fn.findValidationToken());
                return $.ajax({
                    url: '/Affaire/PostActionConvocation',
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    data: formdata
                }).done(() => {
                    location.reload();
                });
            }
        })) { }
    }
}

export var convoc: Convocation;
