﻿(function ($) {
    //override du validateur de date
    $.validator.methods.date = function (value, element) {
        return this.optional(element) || !/Invalid|NaN/.test(new Date(value)) || /^\d{2}[\/-]\d{2}[\/-]\d{4}(\s\d{2}:\d{2}:\d{2})?/.test(value);
    };
    //override du validateur de nombre
    $.validator.methods.number = function (value, element) {
        return this.optional(element) || /^-?\d+[\.|,]?\d*$/.test(value);
    };

    //https://stackoverflow.com/questions/833997/validate-that-end-date-is-greater-than-start-date-with-jquery
    $.validator.methods.dateGreaterThan = function (value, element, params) {
        var newYear = value.split("/")[2];
        var newMonth = value.split("/")[1];
        var newDay = value.split("/")[0];
        var otherValue = $(params).val();
        var otherYear = otherValue.split("/")[2];
        var otherMonth = otherValue.split("/")[1];
        var otherDay = otherValue.split("/")[0];
        if (!/Invalid|NaN/.test(new Date(newYear, newMonth, newDay))) {
            return new Date(newYear, newMonth, newDay) > new Date(otherYear, otherMonth, otherDay);
        }
        return (Number(value) > Number($(params).val()));
    };

    $.validator.unobtrusive.adapters.add('requiredif', ['dependentproperty', 'desiredvalue'], function (options)
    {
        options.rules['requiredif'] = options.params;
        options.messages['requiredif'] = options.message;
    });
    
    $.validator.methods.requiredif = function (value, element, parameters) {
        let desiredValue = parameters.desiredvalue;
        desiredValue = (desiredValue == null ? '' : desiredValue).toString();
        const controlType = $("input[id$='" + parameters.dependentproperty + "']").attr("type");
        let actualValue;
        if (controlType === "checkbox" || controlType === "radio") {
            const control = $("input[id$='" + parameters.dependentproperty + "']:checked");
            actualValue = control.val();
        } else {
            actualValue = $("#" + parameters.dependentproperty).val();
        }
        if ($.trim(desiredValue).toLowerCase() === $.trim(actualValue).toLocaleLowerCase()) {
            return $.validator.methods.required.call(this, value, element, parameters);
        }
        return true;
    };
}(jQuery));
