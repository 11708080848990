import { IMappingCallbacks, TypeModeControle, TypeStatutNonConformiteEnum, TypeActionEnum } from "./common";
import { ControleAction } from "./controle-action";
import { ControleReponse } from "./controle-reponse";

// ReSharper disable  InconsistentNaming
export class ControleQuestion implements IMappingCallbacks {
    constructor(modeControle: string, currentUser: string) {
        this.ModeControle(modeControle);
        this.CurrentUtilisateur = currentUser;
    }

    CurrentUtilisateur: string;
    ModeControle = ko.observable<string>(TypeModeControle.Controleur.toString());

    //id de la question
    Id = ko.observable(0);
    Bloquante = ko.observable(false);
    Libelle = ko.observable("");

    uniqueName = ko.pureComputed(() => {
        return `q${this.Id()}`;
    });

    //pour le tri
    Ordre: number;
    Theme: string;

    //La réponse
    Reponse: ControleReponse;

    /*
     * Aide
     */

    //les datas inportante de la question
    AideCourte = ko.observable("");
    AideLongue = ko.observable("");

    //visibilité de l'aide
    helpVisible = ko.observable(false);

    toggleHelp() {
        if (!this.hasAide()) {
            return;
        }

        this.helpVisible(!this.helpVisible());
    }

    showAideLongue() {
        const modal = $(".modal-aide");

        if (modal === undefined || modal == null) {
            return;
        }

        modal.find('.modal-body').html(this.AideLongue());
        modal.removeClass("fade");
        modal.modal({
            backdrop: true,
            keyboard: true,
            show: true
        });
    }

    hasAide = ko.computed(() => {
        return this.AideCourte() !== null || this.AideLongue() !== null;
    });

    hasAideLongue = ko.computed(() => {
        return this.AideLongue() !== null;
    });

    /*
    * Validation
    */
    HasError = ko.observable<boolean>(false);
    HasNewAction = ko.observable<boolean>(false);
    ErrorMessage = ko.observable<string>("");

    Valid = (): boolean => {
        this.HasError(false);
        this.HasNewAction(false);

        /*Step 1 : 1er saisie du controleur*/
        let mc = this.ModeControle(),
            allAct = this.Reponse.Actions(),
            isNonConforme = this.Reponse.IsNonConforme(),
            justification = this.Reponse.Justification();

        if ((mc === TypeModeControle.Controleur.toString() || mc === TypeModeControle.EvalFounisseurEvaluateur.toString()) &&
            isNonConforme) {
            if ((justification === "" || justification === null)) {

                this.ErrorMessage("Une justification est obligatoire<br/>");
                this.HasError(true);
            }
        }

        // TM 17/10/2016, confirmé par O. Klein
        /*step 2a : le valideur doit répondre à tout */
        if ((mc === TypeModeControle.Validateur.toString() || mc === TypeModeControle.EvalFounisseurValideur.toString()) &&
            isNonConforme &&
            this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.EnAttenteValidation
        ) {
            this.ErrorMessage("Cette non-conformité n'a pas été traitée<br/>");
            this.HasError(true);
        }

        /*step 2b : le valideur lui aussi doit saisir */
        if ((mc === TypeModeControle.Validateur.toString() || mc === TypeModeControle.EvalFounisseurValideur.toString()) &&
            isNonConforme &&
            this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.EnAttenteReprise /*le statut est un peu anticipé mais c'est ça*/
        ) {
            $.each(allAct,
                (ix, a: ControleAction) => {

                    if (a.TypeAction() === TypeActionEnum.DemandeDeRepriseComplement &&
                        (a.Message() === "" || a.Message() === null)) {

                        this.ErrorMessage("Une justification pour la demande de reprise/compléments est obligatoire<br/>");
                        this.HasError(true);
                    }
                });
        }

        /*step 3 : le controleur doit repréciser */
        if ((mc === TypeModeControle.Controleur.toString() || mc === TypeModeControle.EvalFounisseurEvaluateur.toString()) &&
            isNonConforme &&
            this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.EnAttenteReprise
        ) {
            $.each(allAct,
                (ix, a: ControleAction) => {
                    if (a.TypeAction() === TypeActionEnum.ReponseDeRepriseComplement &&
                        (a.Message() === "" || a.Message() === null)) {
                        this.ErrorMessage("Une nouvelle justification est obligatoire<br/>");
                        this.HasError(true);
                    }
                });
        }

        /*step 4 : le prestataire fait une demande de contestation*/
        if (mc === TypeModeControle.Prestataire.toString() &&
            isNonConforme &&
            this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.DemandeContestation
        ) {
            $.each(allAct,
                (ix, a: ControleAction) => {
                    if (a.TypeAction() === TypeActionEnum.DemandeContestation) {
                        // C'est la dernière action, on a une demande de contestation : il y a bien au moins un point traité
                        // (NB : IsLactAction retourne la dernière action avant le traitement par le presta donc pas bon ici)
                        if (allAct.indexOf(a) === allAct.length - 1) {
                            this.HasNewAction(true);
                        }
                        if (a.Message() === "" || a.Message() === null) {
                            this.ErrorMessage("Un motif de contestation est obligatoire<br/>");
                            this.HasError(true);
                        }
                    }
                });
        }

        /*step 5 : le prestataire fait une demande de levée*/
        if (mc === TypeModeControle.Prestataire.toString() &&
            isNonConforme &&
            this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.DemandeDeLevee
        ) {
            $.each(allAct,
                (ix, a: ControleAction) => {
                    if (a.TypeAction() === TypeActionEnum.DemandeDeLevee) {
                        // C'est la dernière action, on a une demande de levée : il y a bien au moins un point traité
                        // (NB : IsLactAction retourne la dernière action avant le traitement par le presta donc pas bon ici)
                        if (allAct.indexOf(a) === allAct.length - 1) {
                            this.HasNewAction(true);
                        }

                        if (a.Message() === "" || a.Message() === null) {
                            this.ErrorMessage("Un motif de levée est obligatoire<br/>");
                            this.HasError(true);
                        }
                    }
                });
        }

        /*step 5 bis : le prestataire doit répondre forcément à tous */
        if (mc !== TypeModeControle.Prestataire.toString() &&
            isNonConforme &&
            (this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.DemandeDeLevee ||
                this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.DemandeContestation) &&
            this.Reponse.IsStatutAttenteReponseERDF()
        ) {
            this.ErrorMessage("Vous devez statuer sur le retour prestataire<br/>");
            this.HasError(true);
        }

        /*step 6 : réponse à la demande de contestation => accepté */
        if (mc !== TypeModeControle.Prestataire.toString()
            //car la réponse peut être donnée par un controleur ou un valisuer
            &&
            isNonConforme &&
            this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.ContesteConforme
        ) {

            $.each(allAct,
                (ix, a: ControleAction) => {
                    if (a.TypeAction() === TypeActionEnum.ReponseContestation) {
                        if (a.IsSend()) {
                            return; // C'est deja envoyé, on revalide pas
                        }

                        if (a.ReponseAction() === undefined || a.ReponseAction() === 0) {

                            this.ErrorMessage("Une nouvelle réponse (conforme/non-observé) est obligatoire<br/>");
                            this.HasError(true);
                        }

                        //if ((a.Message() === "" || a.Message() === null)) {
                        //    this.ErrorMessage("Un commentaire est obligatoire<br/>");
                        //    this.HasError(true);
                        //}
                    }
                });
        }

        /*step 6 bis : réponse à la demande de levée  => accepté*/
        // #3990 TM 02/11/2016 : pas de "Conforme" ou "Non observé" pour les demandes de levées,
        // et le commentaire n'est pas obligatoire

        /*if (mc !== TypeModeControle.Prestataire.toString()
            //car la réponse peut être donnée par un controleur ou un valisuer
            &&
            isNonConforme &&
            this.Reponse.StatutReponse === TypeStatutNonConformiteEnum.Levee
        ) {
            $.each(allAct,
                (ix, a: ControleAction) => {
                    if (a.IsSend()) {
                        return; // C'est deja envoyé, on revalide pas
                    }
                    if (a.TypeAction() === TypeActionEnum.ReponseDemandeDeLevee) {
                        if (a.ReponseAction() === undefined || a.ReponseAction() === 0) {
                            this.ErrorMessage("Une nouvelle réponse (conforme/non-observé) est obligatoire<br/>");
                            this.HasError(true);
                        }

                        //if ((a.Message() === "" || a.Message() === null)) {
                        //    this.ErrorMessage("Un commentaire est obligatoire<br/>");
                        //    this.HasError(true);
                        //}
                    }
                });
        }*/

        /*step 7 : réponse à la demande de contestation => refusé */
        if (mc !== TypeModeControle.Prestataire.toString()
            //car la réponse peut être donnée par un controleur ou un valideur
            &&
            isNonConforme &&
            this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.ContestationRefusee
        ) {
            $.each(allAct,
                (ix, a: ControleAction) => {
                    if (a.TypeAction() === TypeActionEnum.ReponseContestation) {
                        if ((a.Message() === "" || a.Message() === null)) {
                            this.ErrorMessage("Un commentaire (pour rejet contestation) est obligatoire<br/>");
                            this.HasError(true);
                        }
                    }
                });
        }

        /*step 7 bis : réponse à la demande de levée  => refusé*/
        if (mc !== TypeModeControle.Prestataire.toString()
            //car la réponse peut être donnée par un controleur ou un valisuer
            &&
            isNonConforme &&
            this.Reponse.StatutReponse() === TypeStatutNonConformiteEnum.LeveeRefusee
        ) {
            $.each(allAct,
                (ix, a: ControleAction) => {
                    if (a.TypeAction() === TypeActionEnum.ReponseDemandeDeLevee) {

                        if ((a.Message() === "" || a.Message() === null)) {
                            this.ErrorMessage("Un commentaire (pour rejet levée) est obligatoire<br/>");
                            this.HasError(true);
                        }
                    }
                });
        }

        return !this.HasError();
    };

    _beforeMapping() {
        if (this.Reponse != null) {
            let act = this.Reponse.Actions();

            $.each(act, (ix, v: ControleAction) => {
                v._beforeMapping();
            });
        } else {
            console.warn("this.Reponse == null");
        }
    }
}
