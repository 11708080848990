﻿import { Options } from "../Options";

export class FiltreInstructionBase {

    // TODO
    TypesDouvrages: KnockoutObservableArray<Options>;
    TypesDinstructions: KnockoutObservableArray<Options>;
    NumerosConcats: KnockoutObservableArray<Options>;
    AccesPermanents: KnockoutObservableArray<Options>;
    Statuts: KnockoutObservableArray<Options>;
    FiltreTypeInstruction: KnockoutObservableArray<string>;
    FiltreTypeOuvrage: KnockoutObservableArray<string>;
    FiltreNumeroConcat: KnockoutObservableArray<string>;
    FiltreAccesPermanent: KnockoutObservableArray<string>;
    FiltreStatut: KnockoutObservableArray<string>;
    FiltreTitre: KnockoutObservable<string>;
    DateFinEntre1: KnockoutObservable<Date>;
    DateFinEntre2: KnockoutObservable<Date>;
    DatePickerOptions: KnockoutObservable<any>;
    DatePickerOptionsDateFin2: KnockoutComputed<any>;
    DateFinEntre2Disabled: KnockoutComputed<boolean>;
    CorrelatedFilters: KnockoutComputed<any>;
    FilterSummary: KnockoutObservable<string>;

    constructor(model: any) {

        ko.subscribable.fn.subscribeChanged = function (callback) {
            let oldValue;
            this.subscribe(function (_oldValue) {
                oldValue = _oldValue;
            }, this, 'beforeChange');

            this.subscribe(function (newValue) {
                callback(newValue, oldValue);
            });
        };

        this.TypesDinstructions = ko.observableArray<Options>(
            model.TypesDinstructions.length > 0 ?
                model.TypesDinstructions.map(i => new Options(i.Libelle, i.Value.toString())).sort((opt1: Options, opt2: Options) => {
                    return opt1.Libelle.toLowerCase() < opt2.Libelle.toLowerCase() ? -1 : opt1.Libelle.toLowerCase() > opt2.Libelle.toLowerCase() ? 1 : 0
                }) : []);

        this.TypesDouvrages = ko.observableArray<Options>(
            model.TypesDouvrages.length > 0 ?
                model.TypesDouvrages.map(i => new Options(i.Libelle, i.Value.toString())).sort((opt1: Options, opt2: Options) => {
                    return opt1.Libelle.toLowerCase() < opt2.Libelle.toLowerCase() ? -1 : opt1.Libelle.toLowerCase() > opt2.Libelle.toLowerCase() ? 1 : 0
                }) : []);

        this.NumerosConcats = ko.observableArray<Options>(
            model.NumerosConcats.length > 0 ?
                model.NumerosConcats.map(i => new Options(i.Libelle, i.Value)).sort((opt1: Options, opt2: Options) => {
                    return opt1.Libelle.toLowerCase() < opt2.Libelle.toLowerCase() ? -1 : opt1.Libelle.toLowerCase() > opt2.Libelle.toLowerCase() ? 1 : 0
                }) : []);

        this.AccesPermanents = ko.observableArray<Options>(
            model.AccesPermanents.length > 0 ?
                model.AccesPermanents.map(i => new Options(i.Libelle, i.Value.toString())).sort((opt1: Options, opt2: Options) => {
                    return opt1.Libelle.toLowerCase() < opt2.Libelle.toLowerCase() ? -1 : opt1.Libelle.toLowerCase() > opt2.Libelle.toLowerCase() ? 1 : 0
                }) : []);

        this.Statuts = ko.observableArray<Options>(
            model.Statuts.length > 0 ?
                model.Statuts.map(i => new Options(i.Libelle, i.Value.toString())).sort((opt1: Options, opt2: Options) => {
                    return opt1.Libelle.toLowerCase() < opt2.Libelle.toLowerCase() ? -1 : opt1.Libelle.toLowerCase() > opt2.Libelle.toLowerCase() ? 1 : 0
                }) : []);

        this.FiltreTypeInstruction = ko.observableArray<string>(model.FiltreTypeInstruction.map(ti => ti.toString()));
        this.FiltreTypeOuvrage = ko.observableArray<string>(model.FiltreTypeOuvrage.map(to => to.toString()));
        this.FiltreNumeroConcat = ko.observableArray<string>(model.FiltreNumeroConcat);
        this.FiltreAccesPermanent = ko.observableArray<string>(model.FiltreAccesPermanent.map(ap => ap.toString()));
        this.FiltreStatut = ko.observableArray<string>(model.FiltreStatut.map(s => s.toString()));
        this.DateFinEntre1 = ko.observable<Date>();
        if (model.DateFinEntre1 != null) {
            const modelDateFinEntre1Number: number = model.DateFinEntre1.match(/\d+/) * 1;
            this.DateFinEntre1 = ko.observable<Date>(new Date(modelDateFinEntre1Number));
        }
        this.DateFinEntre2 = ko.observable<Date>();
        if (model.DateFinEntre2 != null) {
            const modelDateFinEntre2Number: number = model.DateFinEntre2.match(/\d+/) * 1;
            this.DateFinEntre2 = ko.observable<Date>(new Date(modelDateFinEntre2Number));
        }
        this.DateFinEntre2Disabled = ko.computed<boolean>(() => { return this.DateFinEntre1() == null });
        this.DatePickerOptions = ko.observable<any>({
            altFormat: "dd/mm/yy",
            showOn: "both",
            buttonImage: "/Content/assets/calendar.png",
            buttonImageOnly: true
        });

        this.DatePickerOptionsDateFin2 = ko.computed<any>({
            read: () => {
                return {
                    altFormat: "dd/mm/yy",
                    showOn: "both",
                    minDate: this.DateFinEntre1(),
                    buttonImage: "/Content/assets/calendar.png",
                    buttonImageOnly: true
                }
            }
        });

        this.FilterSummary = ko.observable<string>(model.Summary);
    }
}
