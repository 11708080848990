﻿export class DocumentDatasViewModel {
    // ReSharper disable InconsistentNaming
    TitreDocument = ko.observable<string>();
    TitreDescription = ko.observable<string>();
    TexteDescription = ko.observable<string>();
    Keywords = ko.observable<string>();
    DernierePublication = ko.observable<string>();
    // ReSharper restore InconsistentNaming

    constructor(data?: any) {
        if (data != null) {
            $.each(data, (k, v) => {
                // Magic <3
                if (this.hasOwnProperty(k) && ko.isObservable(this[k])) {
                    try {
                        this[k](v);
                    } catch (e) {
                        console.error(e);
                    }
                }
            });
        }
    }

    shouldDisplayMiseAJour = ko.pureComputed(() => {
        const v = this.DernierePublication();
        if (v == null || v === "") {
            return true;
        }
        return false;
    });

    titreDocumentIsNullOrEmpty = ko.pureComputed(() => {
        const v = this.TitreDocument();
        if (v == null || v === "") {
            return true;
        }
        return false;
    });

    titreDescriptionIsNullOrEmpty = ko.pureComputed(() => {
        const v = this.TitreDescription();
        if (v == null || v === "") {
            return true;
        }
        return false;
    });

    texteDescriptionIsNullOrEmpty = ko.pureComputed(() => {
        const v = this.TexteDescription();
        if (v == null || v === "") {
            return true;
        }
        return false;
    });

    keyWordsDescriptionIsNullOrEmpty = ko.pureComputed(() => {
        const v = this.Keywords();
        if (v == null || v === "") {
            return true;
        }
        return false;
    });
}
