﻿import { CommonInfosPrestation } from "./pposr-general";
// ReSharper disable InconsistentNaming

export enum TypeInterlocuteurForDisplay {
    Demandeur = "Demandeur" as any,
    InterlocuteurTechnique = "Interlocuteur Technique" as any,
    TiersMandate = "Tiers mandaté / autorisé" as any,
    EmetteurPrestation = "Emetteur de la prestation" as any,
    ChargeAffaire = "Chargé d'affaire" as any,
    ChargeEtude = "Chargé d'étude" as any
}

// Les données d'origine à l'arrivée sur la vue, on n'y touchera pas
export class InterlocuteurOrigData {
    constructor(public interlocuteur, public typeInterlocuteur) {
        this._TypeInterlocuteurForDisplay = typeInterlocuteur;
        this.Are = interlocuteur.Are;
        this.Nom = interlocuteur.Nom;
        this.Prenom = interlocuteur.Prenom;
        this.TelFixe = interlocuteur.TelFixe;
        this.TelPortable = interlocuteur.TelPortable;
        this.Adresse = interlocuteur.Adresse;
        this.AdresseComplement = interlocuteur.AdresseComplement;
        this.Commune = interlocuteur.Commune;
        this.Fax = interlocuteur.Fax;
        this.Mail = interlocuteur.Mail;
    }

    _TypeInterlocuteurForDisplay: TypeInterlocuteurForDisplay;
    Are: string;
    Nom: string;
    Prenom: string;
    TelFixe: string;
    TelPortable: string;
    Adresse: string;
    AdresseComplement: string;
    Commune: string;
    Fax: string;
    Mail: string;
}

export class Interlocuteur {
    constructor(public interlocuteur) {
        this._TypeInterlocuteurForDisplay(interlocuteur._TypeInterlocuteurForDisplay);
        this.Are(interlocuteur.Are);
        this.Nom(interlocuteur.Nom);
        this.Prenom(interlocuteur.Prenom);
        this.TelFixe(interlocuteur.TelFixe);
        this.TelPortable(interlocuteur.TelPortable);
        this.Adresse(interlocuteur.Adresse);
        this.AdresseComplement(interlocuteur.AdresseComplement);
        this.Commune(interlocuteur.Commune);
        this.Fax(interlocuteur.Fax);
        this.Mail(interlocuteur.Mail);

        this._ModeModification(false);
    }

    _TypeInterlocuteurForDisplay = ko.observable<TypeInterlocuteurForDisplay>();
    Are = ko.observable<string>();
    Nom = ko.observable<string>();
    Prenom = ko.observable<string>();
    TelFixe = ko.observable<number>().extend({ pattern: window.eplans.regexTel });
    TelPortable = ko.observable<number>().extend({ pattern: window.eplans.regexTel });
    Adresse = ko.observable<string>().extend({ maxLength: 200 });
    AdresseComplement = ko.observable<string>().extend({ maxLength: 200 });
    Commune = ko.observable<string>().extend({ maxLength: 200 });
    Fax = ko.observable<string>().extend({ pattern: window.eplans.regexTel });
    Mail = ko.observable<string>().extend({ pattern: window.eplans.regexMail });

    _ModeModification = ko.observable<boolean>();

    _isDemandeur() {
        return this._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.Demandeur;
    }

    _isEmetteurPrestation() {
        return this._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.EmetteurPrestation;
    }

    _isReponsablePrestation() {
        return this._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.EmetteurPrestation
            || this._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.ChargeAffaire
            || this._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.ChargeEtude;
    }

    _isChargeAffaireOuEtude() {
        return this._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.ChargeAffaire
            || this._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.ChargeEtude;
    }

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            var telFixeHasError = this.TelFixe.hasError(),
                telPortableHasError = this.TelPortable.hasError(),
                // Fax : erreur seulement si demandeur, n'est pas affiché pour les autres
                faxHasError = this._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.Demandeur && this.Fax.hasError(),
                mailHasError = this.Mail.hasError();

            if (telFixeHasError || telPortableHasError || faxHasError || mailHasError) {
                return false;
            }

            return true;
        }
    });
}

export class TypeInterlocuteur {
    constructor(id: string, description: string) {
        this.Id = id;
        this.Description = description;
    }

    Id: string;
    Description: string;
}

// NB : pour ne pas tomber dans la référence circulaire au ko.toJSON(this), ne pas passer le parent à Interlocuteur (par exemple)
export class InterlocuteurPrestationViewModel extends CommonInfosPrestation {
    constructor(data) {
        super(data, 'interlocuteur');

        this._DemandeurOrig = new InterlocuteurOrigData(data.Demandeur, TypeInterlocuteurForDisplay.Demandeur);
        this._InterlocuteurTechniqueOrig = new InterlocuteurOrigData(data.InterlocuteurTechnique, TypeInterlocuteurForDisplay.InterlocuteurTechnique);
        this._TiersMandateOrig = new InterlocuteurOrigData(data.TiersMandate, TypeInterlocuteurForDisplay.TiersMandate);
        this._EmetteurPrestationOrig = new InterlocuteurOrigData(data.EmetteurPrestation, TypeInterlocuteurForDisplay.EmetteurPrestation);
        this._ChargeAffaireOrig = new InterlocuteurOrigData(data.ChargeAffaire, TypeInterlocuteurForDisplay.ChargeAffaire);
        this._ChargeEtudeOrig = new InterlocuteurOrigData(data.ChargeEtude, TypeInterlocuteurForDisplay.ChargeEtude);

        this._populateListInterlocuteurs();
    }

    _ListInterlocuteurs = ko.observableArray<Interlocuteur>();

    Demandeur = ko.observable<Interlocuteur>();
    InterlocuteurTechnique = ko.observable<Interlocuteur>();
    TiersMandate = ko.observable<Interlocuteur>();
    EmetteurPrestation = ko.observable<Interlocuteur>();
    ChargeAffaire = ko.observable<Interlocuteur>();
    ChargeEtude = ko.observable<Interlocuteur>();

    _MailHasFocus = ko.observable<boolean>(false);

    _DemandeurOrig: InterlocuteurOrigData;
    _InterlocuteurTechniqueOrig: InterlocuteurOrigData;
    _TiersMandateOrig: InterlocuteurOrigData;
    _EmetteurPrestationOrig: InterlocuteurOrigData;
    _ChargeAffaireOrig: InterlocuteurOrigData;
    _ChargeEtudeOrig: InterlocuteurOrigData;

    _populateListInterlocuteurs() {
        this._ListInterlocuteurs.removeAll();

        this._ListInterlocuteurs.push(new Interlocuteur(this._DemandeurOrig));
        this._ListInterlocuteurs.push(new Interlocuteur(this._InterlocuteurTechniqueOrig));
        this._ListInterlocuteurs.push(new Interlocuteur(this._TiersMandateOrig));
        this._ListInterlocuteurs.push(new Interlocuteur(this._EmetteurPrestationOrig));
        this._ListInterlocuteurs.push(new Interlocuteur(this._ChargeAffaireOrig));
        this._ListInterlocuteurs.push(new Interlocuteur(this._ChargeEtudeOrig));
    }

    _isValid = ko.pureComputed({
        owner: this,
        read: () => {
            for (let i = 0; i < this._ListInterlocuteurs().length; i++) {
                const interlo = this._ListInterlocuteurs()[i];

                if (!interlo._isValid()) {
                    return false;
                }
            }

            return true;
        }
    });

    _setMailHasFocus(hasFocus: boolean) {
        this._MailHasFocus(hasFocus);
    }

    _switchToModifyInterlocuteur() {
        if (this._CanModify()) {
            let atLeastOne = false;

            // Les données des interlocuteurs ne sont modifiables que s'ils ont un nom et un prénom
            for (let i = 0; i < this._ListInterlocuteurs().length; i++) {
                const interlo = this._ListInterlocuteurs()[i];

                if (interlo.Prenom() || interlo.Nom()) {
                    atLeastOne = true;

                    interlo._ModeModification(true);
                }
            }

            // Si au moins un interlocuteur est modifiable, on pas en ModeModificationGlobal
            this._ModeModificationGlobal(atLeastOne);

            if (atLeastOne) {
                this._scrollToBandeauPrestation(this._PrestationLinkWithHash());
            }
        }
    }

    // Sortie du mode modification pour tous les interlocuteurs, puis au niveau global
    // resetValues : selon l'usage, on remet les valeurs d'origine ou pas
    _disableModify(resetValues: boolean) {
        if (this._ModeModificationGlobal()) {
            // on va réafficher le bouton Modifier au lieu du bouton Enregistrer
            this._ModeModificationGlobal(false);

            // on passe chaque interlocuteur en mode Consultation
            for (let i = 0; i < this._ListInterlocuteurs().length; i++) {
                const interlo = this._ListInterlocuteurs()[i];

                interlo._ModeModification(false);
            }

            if (resetValues) {
                this._populateListInterlocuteurs();
            }

            this._disableChangeAlert();
        }
    }

    // Pour conserver les valeurs d'origine en cas d'annulation de modification
    _initOrigValues() {
        // Les valeurs sauvées sont les nouvelles valeurs d'origine
        this._DemandeurOrig = new InterlocuteurOrigData(
            this._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.Demandeur)[0],
            TypeInterlocuteurForDisplay.Demandeur);
        this._InterlocuteurTechniqueOrig = new InterlocuteurOrigData(
            this._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.InterlocuteurTechnique)[0],
            TypeInterlocuteurForDisplay.InterlocuteurTechnique);
        this._TiersMandateOrig = new InterlocuteurOrigData(
            this._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.TiersMandate)[0],
            TypeInterlocuteurForDisplay.TiersMandate);
        this._EmetteurPrestationOrig = new InterlocuteurOrigData(
            this._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.EmetteurPrestation)[0],
            TypeInterlocuteurForDisplay.EmetteurPrestation);
        this._ChargeAffaireOrig = new InterlocuteurOrigData(
            this._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.ChargeAffaire)[0],
            TypeInterlocuteurForDisplay.ChargeAffaire);
        this._ChargeEtudeOrig = new InterlocuteurOrigData(
            this._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.ChargeEtude)[0],
            TypeInterlocuteurForDisplay.ChargeEtude);

    }

    _prepareSave() {
        const self = this;

        if (self._isValid()) {
            self.Demandeur(self._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.Demandeur)[0]);
            self.InterlocuteurTechnique(self._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.InterlocuteurTechnique)[0]);
            self.TiersMandate(self._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.TiersMandate)[0]);
            self.EmetteurPrestation(self._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.EmetteurPrestation)[0]);
            self.ChargeAffaire(self._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.ChargeAffaire)[0]);
            self.ChargeEtude(self._ListInterlocuteurs().filter(_ => _._TypeInterlocuteurForDisplay() === TypeInterlocuteurForDisplay.ChargeEtude)[0]);

            self._save('/Affaire/EnregistrerModifInterlocuteurs', self._PrestationLinkWithHash(), this._setNewStateAfterSave, self);
        }
    }

    _setNewStateAfterSave(self: InterlocuteurPrestationViewModel) {
        const success = self._PendingSave();

        if (success) {
            self._disableModify(false);

            self._initOrigValues();
        }
    }
}
// ReSharper restore InconsistentNaming
