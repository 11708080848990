﻿$(() => {
    $('.cookies-accept-all').click(e => {
        e.preventDefault();

        $.ajax({
            type: 'POST',
            url: '/Home/CookiesSaveAcceptAll',
            data: $.fn.putValidationToken({})
        }).always(() => {
            $('#container-cookies-popup').addClass('hide');
        });
    });

    $('.block-cookies-row td').click(e => {
        e.preventDefault();

        let $target = $(e.target).closest('tr').find('input[type="checkbox"]:not([disabled])');

        if ($target.length === 1) {
            $target.prop('checked', !$target.prop('checked'));
        }
    });
});
